import { all } from 'redux-saga/effects';

/**
 * Import Sagas
 */

import sagas from './sagas';

/**
 * Yield all sagas
 */
export default function* root() {
    yield all(sagas);
}
