import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';

/**
 * Utils import
 */
import { creator, errorMessageGenerator, downloadFile } from 'uwork-app-core';
import { getLocalStorageAuthToken } from '../../../utils/getLocalStorageAuthToken';

/**
 * Constants
 */
export const REQUEST = '@app/workers/exportcsvsaga/REQUEST';
export const SUCCESS = '@app/workers/exportcsvsaga/SUCCESS';
export const FAILURE = '@app/workers/exportcsvsaga/FAILURE';
export const CLEAR = '@app/workers/exportcsvsaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const token = getLocalStorageAuthToken();
            downloadFile(`/workers/csv${payload ? `${payload}&` : '?'}token=${token}`);
        } catch (e) {
            yield put(creator(CLEAR));
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
