import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/settings/accountsettingssaga/REQUEST';
export const SUCCESS = '@app/settings/accountsettingssaga/SUCCESS';
export const FAILURE = '@app/settings/accountsettingssaga/FAILURE';
export const SET_LOADING = '@app/settings/accountsettingssaga/SET_LOADING';
export const SET_MESSAGE = '@app/settings/accountsettingssaga/SET_MESSAGE';
export const SET_ITEMS = '@app/settings/accountsettingssaga/SET_ITEMS';
export const CLEAR = '@app/settings/accountsettingssaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    messageSuccess: false,
    items: [],
    meta: {
        current_page: 1,
        last_page: 1
    }
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                draft.meta = payload.meta;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case SET_MESSAGE:
                draft.messageSuccess = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            const response = yield axios.post('/user/', payload);
            yield put(creator(SET_MESSAGE, true));
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
