import produce from 'immer';
import axios from 'utils/axios';
import { /* takeEvery, */ takeLatest, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { creator as createAction } from 'uwork-app-core';

/**
 * import outer actions
 */
import { actions as notificationsActions } from 'sagas/app/notifications/notificationsSaga';
import { redirectToAuth } from 'utils/redirectToAuth';

// action types
export const LOGIN_REQUEST = '@app/auth/user/LOGIN_REQUEST';
export const LOGOUT = '@app/auth/user/LOGOUT';
export const LOGIN = '@app/auth/user/LOGIN';
export const TOGGLE_FORM_SUBMITTING = '@app/auth/user/TOGGLE_FORM_SUBMITTING';
export const SET_FORM_STATUS = '@app/auth/user/SET_FORM_STATUS';
export const SET_TOKEN = '@app/auth/user/SET_TOKEN';
export const SET_LOGED_IN = '@app/auth/user/SET_LOGED_IN';
export const GET_ME = '@app/auth/user/GET_ME';
export const SET_ME = '@app/auth/user/SET_ME';
export const CHANGE_NOTIFICATION_STATUS = '@app/auth/user/CHANGE_NOTIFICATION_STATUS';

// initial reducer state
const initState = {
    logedIn: false,
    LoginformIsSubmitting: false,
    LoginformStatus: null,
    token: null,
    me: null
};

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TOGGLE_FORM_SUBMITTING:
                if (payload !== undefined || payload !== null) {
                    draft.LoginformIsSubmitting = payload;
                    break;
                }
                draft.LoginformIsSubmitting = !draft.formIsSubmitting;
                break;
            case SET_FORM_STATUS:
                draft.LoginformStatus = payload;
                break;
            case SET_TOKEN:
                draft.token = payload;
                break;
            case SET_LOGED_IN:
                draft.logedIn = payload;
                break;
            case SET_ME:
                draft.me = payload;
                break;
            case CHANGE_NOTIFICATION_STATUS:
                draft.me.notification_status = payload ? 1 : 10;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    setToken: (payload) => createAction(SET_TOKEN, payload),
    loginRequest: (payload) => createAction(LOGIN_REQUEST, payload),
    login: (payload) => createAction(LOGIN, payload),
    logout: () => createAction(LOGOUT),
    getMe: () => createAction(GET_ME),
    cahngeNotificationStatus: (payload) => createAction(CHANGE_NOTIFICATION_STATUS, payload)
};

/**
 * Selectors
 */
export const selectors = {
    getDepartments: (state) => state.app.user.me.departments
};

export const sagas = {
    // login request saga
    *loginRequest(action) {
        const { payload } = action;

        // set defaults
        yield put(createAction(TOGGLE_FORM_SUBMITTING, true));
        yield put(createAction(SET_FORM_STATUS, null));

        try {
            // make login api call
            yield put(createAction(SET_ME, null));
            const response = yield axios.post('/auth/login', payload);
            // setup login
            yield put(actions.login(response.data.token));
        } catch ({ response }) {
            // error response handle
            yield put(createAction(TOGGLE_FORM_SUBMITTING, false));
            yield put(
                createAction(SET_FORM_STATUS, {
                    success: false,
                    message: response.data && response.data.message
                })
            );
        }
    },

    *logout() {
        yield put(createAction(SET_FORM_STATUS, null));
        yield put(createAction(TOGGLE_FORM_SUBMITTING, false));
        yield put(createAction(SET_LOGED_IN, false));
        yield put(createAction(SET_ME, null));
        yield put(actions.setToken(null));
        localStorage.setItem('auth.token.admin', null);
        redirectToAuth();
    },

    *login(action) {
        yield put(actions.setToken(action.payload));
        yield put(createAction(SET_LOGED_IN, true));
    },

    *getMe() {
        try {
            const response = yield axios.get('/user/me');
            yield put(createAction(SET_ME, response.data.data));
            yield put(notificationsActions.request());
        } catch (e) {
            console.error(e);
        }
    }
};

export const watcher = function* watch() {
    yield takeLatest(LOGIN_REQUEST, sagas.loginRequest);
    yield takeLatest(LOGOUT, sagas.logout);
    yield takeLatest(LOGIN, sagas.login);
    yield takeLatest(GET_ME, sagas.getMe);
};
