import produce from 'immer';
import { put, takeLatest, select, delay } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';
import { differenceInHours, parseISO } from 'date-fns';

/**
 * Utils
 */
import serializeToQueryString from 'utils/serializeToQueryString';
import downloadFile from 'utils/downloadFile';

/**
 * External Ations
 */
import { actions as companiesActions } from './companiesSaga';
import { LocalStorageKeyEnums } from '../../../interfaces/enums/LocalStorageKey.enums';
import { getLocalStorageAuthToken } from '../../../utils/getLocalStorageAuthToken';

/**
 * Constants
 */
export const REQUEST = '@app/timesheet/timesheetsaga/REQUEST';
export const INIT_REQUEST = '@app/timesheet/timesheetsaga/INIT_REQUEST';
export const INIT_LAST_REQUEST = '@app/timesheet/timesheetsaga/INIT_LAST_REQUEST';
export const SUCCESS = '@app/timesheet/timesheetsaga/SUCCESS';
export const FAILURE = '@app/timesheet/timesheetsaga/FAILURE';
export const SET_LOADING = '@app/timesheet/timesheetsaga/SET_LOADING';
export const SET_ITEMS = '@app/timesheet/timesheetsaga/SET_ITEMS';
export const SET_LAT_REQUEST_PAYLOAD = '@app/timesheet/timesheetsaga/SET_LAT_REQUEST_PAYLOAD';
export const CLEAR = '@app/timesheet/timesheetsaga/CLEAR';
export const CHECK_ALL = '@app/timesheet/timesheetsaga/CHECK_ALL';

export const SEND = '@app/timesheet/timesheetsaga/SEND';
export const SEND_SUCCESS = '@app/timesheet/timesheetsaga/SEND_REQUEST';

export const DOWNLOAD = '@app/timesheet/timesheetsaga/DOWNLOAD';
export const DOWNLOAD_SUCCESS = '@app/timesheet/timesheetsaga/DOWNLOAD_SUCCESS';

/**
 * Initial state
 */
const initState = {
    loading: true,
    errorStatus: '',
    infoStatus: '',
    items: [],
    totalDuration: 0,
    lastRequestPayload: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                // eslint-disable-next-line no-case-declarations
                let _totalDuration = 0;
                draft.items = payload.data.map((item) => {
                    item.checked = false;
                    item.duration = differenceInHours(parseISO(item.to), parseISO(item.from));
                    _totalDuration += item.duration;
                    return item;
                });
                draft.totalDuration = _totalDuration;
                break;
            case SEND_SUCCESS:
                draft.infoStatus = 'Timesheet sent via email successfully!';
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case SET_ITEMS:
                draft.items = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.infoStatus = '';
                draft.items = [];
                draft.totalDuration = 0;
                break;
            case SET_LAT_REQUEST_PAYLOAD:
                draft.lastRequestPayload = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */
export const selectors = {
    getItems: (state) => state.app.timesheet.timesheet.items,
    lastRequestPayload: (state) => state.app.timesheet.timesheet.lastRequestPayload
};

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    initLastRequest: (data) => creator(INIT_LAST_REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    checkAll: (data) => creator(CHECK_ALL, data),
    send: (data) => creator(SEND, data),
    download: (data) => creator(DOWNLOAD, data)
};

/**
 * Helper functions
 */
const filterPayload = (payload) => {
    if (!payload.shift) {
        payload = Object.assign({}, payload, { shift: undefined });
    }
    if (!payload.worker) {
        payload = Object.assign({}, payload, { worker: undefined });
    }

    return payload;
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        yield delay(delay(500, true));

        // save request payload
        yield put(creator(SET_LAT_REQUEST_PAYLOAD, payload));

        // filter payload
        payload = filterPayload(payload);
        const queryString = serializeToQueryString(payload);

        // get companies
        yield put(companiesActions.request(queryString));

        try {
            const _payload = { ...payload };
            if (_payload.company) {
                _payload.company_id = _payload.company;
                delete _payload.company;
            }
            const query = serializeToQueryString(_payload);
            const response = yield axios.get(`/orders/timesheets?${query}`);
            yield put(creator(CLEAR));
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    },

    *send({ payload }) {
        yield put(creator(SET_LOADING, true));

        // filter payload
        payload = filterPayload(payload);
        const queryString = serializeToQueryString(payload);

        try {
            const response = yield axios.get(`/orders/timesheets/mail?${queryString}`);
            yield put(creator(SEND_SUCCESS, response.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    },

    // eslint-disable-next-line require-yield
    *download({ payload }) {
        // filter payload
        payload = filterPayload(payload.data);
        payload.token = getLocalStorageAuthToken();
        const queryString = serializeToQueryString(payload);
        downloadFile(`/orders/timesheets/pdf?${queryString}`);
    },

    *checkAll({ payload = true }) {
        const items = yield select(selectors.getItems);
        yield put(
            creator(
                SET_ITEMS,
                items.map((item) => {
                    item.checked = payload;
                    return item;
                })
            )
        );
    },

    *initLastRequst() {
        const lastPayload = yield select(selectors.lastRequestPayload);
        yield put(actions.request(lastPayload));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(INIT_LAST_REQUEST, sagas.initLastRequst);
    yield takeLatest(CHECK_ALL, sagas.checkAll);
    yield takeLatest(SEND, sagas.send);
    yield takeLatest(DOWNLOAD, sagas.download);
};
