import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/workers/workerssaga/REQUEST';
export const SUCCESS = '@app/workers/workerssaga/SUCCESS';
export const FAILURE = '@app/workers/workerssaga/FAILURE';
export const SET_LOADING = '@app/workers/workerssaga/SET_LOADING';
export const CLEAR = '@app/workers/workerssaga/CLEAR';
export const STAGE_CHANGE = '@app/workers/workerfilters/STAGE_CHANGE';
export const REMOVE_WORKER = '@app/workers/workerfilters/REMOVE_WORKER';
export const TOGGLE_IS_PUBLIC = '@app/workers/workerfilters/TOGGLE_IS_PUBLIC';
export const TOGGLE_SHOW_ON_WEB = '@app/workers/workerfilters/TOGGLE_SHOW_ON_WEB';
export const CLEAR_MAKE_PUBLIC_ALERT = '@app/workers/workerfilters/CLEAR_MAKE_PUBLIC_ALERT';
export const SET_PROFILE_URL = '@app/workers/workerfilters/SET_PROFILE_URL';

/**
 * Initial state
 */
const initState = {
    items: [],
    meta: {
        current_page: 1,
        last_page: 1,
        total: 0
    },
    workersId: [],
    loading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.items.data;
                draft.workersId = payload.workersId;
                draft.meta = payload.items.meta;
                draft.loading = false;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case SET_PROFILE_URL:
                draft.url = payload;
                break;
            case CLEAR:
                draft.items = [];
                draft.workersId = [];
                draft.meta = {
                    current_page: 1,
                    last_page: 1,
                    total: 0
                };
                break;
            case STAGE_CHANGE:
                draft.items = draft.items.map((worker) => {
                    if (worker.id === payload.id) {
                        return { ...payload };
                    }
                    return worker;
                });
                break;
            case TOGGLE_IS_PUBLIC:
                draft.items = draft.items.map((worker) => {
                    if (worker.user_id === payload.id) {
                        return {
                            ...worker,
                            user: {
                                ...worker.user,
                                is_public: payload.is_public
                            }
                        };
                    }
                    return worker;
                });
                draft.showAlert = payload.is_public === 1 ? true : null;
                break;
            case CLEAR_MAKE_PUBLIC_ALERT:
                draft.showAlert = null;
                draft.url = '';
                break;
            case TOGGLE_SHOW_ON_WEB:
                draft.items = draft.items.map((worker) => {
                    if (worker.user_id === payload.id) {
                        return {
                            ...worker,
                            user: {
                                ...worker.user,
                                display_on_website: payload.display_on_website
                            }
                        };
                    }
                    return worker;
                });
                break;
            case REMOVE_WORKER:
                draft.items = draft.items.filter((worker) => worker.id !== payload);
                break;
            case SET_LOADING:
                draft.loading = payload;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    stage_change: (data) => creator(STAGE_CHANGE, data),
    remove_worker: (data) => creator(REMOVE_WORKER, data),
    toggle_is_public: (data) => creator(TOGGLE_IS_PUBLIC, data),
    toogle_show_on_web: (data) => creator(TOGGLE_SHOW_ON_WEB, data),
    set_loading: (data) => creator(SET_LOADING, data),
    clear: () => creator(CLEAR),
    clearAlert: () => creator(CLEAR_MAKE_PUBLIC_ALERT),
    set_profile_url: (data) => creator(SET_PROFILE_URL, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const searchParam = new URLSearchParams(payload.search);

            if (searchParam.has('query')) {
                let _query = searchParam.get('query');
                if (_query) {
                    _query = _query.replace('+', '%20');
                    searchParam.set('query', _query);
                } else {
                    searchParam.delete('query');
                }
            }

            const queryString = searchParam.toString().replace('+', '%20');
            let response = {};

            if (searchParam.has('lat') && searchParam.has('lng')) {
                const primaryLatValue = searchParam.get('lat');
                const primaryLngValue = searchParam.get('lng');
                let lat = primaryLatValue ? primaryLatValue.split(',') : null;
                let lng = primaryLngValue ? primaryLngValue.split(',') : null;

                // validate lat and lng
                if (!!lat && !!lng && lat.length === 2 && lng.length === 2) {
                    response = yield axios.post(`/workers/locations/search?${queryString}`, {
                        lat,
                        lng
                    });
                } else {
                    response = yield axios.get(`/workers?${queryString}`);
                }
            } else {
                response = yield axios.get(`/workers?${queryString}`);
            }
            let responseId = {};

            if (payload.isFilter) {
                responseId = yield axios.get(`/workers/fetch-worker-ids?${queryString}`);
            }

            // Temporary array of workers to make requests for checking if worker is allowed to change stage in ready stage
            let tempWorkers = response?.data?.data;

            // commented for now since is not being used and causes performance issues
            // Sending request for each worker to check if worker is allowed to change stage in ready stage

            // for (let i = 0; i < tempWorkers.length - 1; i++) {
            //     const readyStageResponse = yield axios.get(`/workers/stage-ready-allowed/${tempWorkers[i].id}`);
            //     tempWorkers[i]['ready_stage_errors'] = readyStageResponse?.data?.errors || []
            // }

            yield put(
                actions.success({
                    items: { data: tempWorkers, meta: response.data.meta },
                    workersId: responseId.data
                })
            );
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
            yield put(actions.clear());
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
