import React from 'react';
import { Route } from 'react-router-dom';
import RouteMotion from './RouteMotion';

function AnimatedRoute({ exact, path, children, animation, animationProps, className, ...rest }) {
    return (
        <RouteMotion animation={animation} animationProps={animationProps} className={className}>
            <Route exact={exact} path={path} {...rest} />
        </RouteMotion>
    );
}
export default AnimatedRoute;
