import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * Constants
 */
const GET_REQUEST = '@app/workers/workersprofile/workerSalary/GET_REQUEST';
const GET_SUCCESS = '@app/workers/workersprofile/workerSalary/GET_SUCCESS';
const GET_FAILURE = '@app/workers/workersprofile/workerSalary/GET_FAILURE';

const POST_REQUEST = '@app/workers/workersprofile/workerSalary/POST_REQUEST';
const POST_SUCCESS = '@app/workers/workersprofile/workerSalary/POST_SUCCESS';
const POST_FAILURE = '@app/workers/workersprofile/workerSalary/POST_FAILURE';

const TRIGGER_MODAL = '@app/workers/workersprofile/workerSalary/TRIGGER_MODAL';
const COLLAPSE_MODAL = '@app/workers/workersprofile/workerSalary/COLLAPSE_MODAL';

/**
 * Initial state
 */

const initialSalary = {
    loading: false,
    errorStatus: false,
    item: {}
};
const initState = {
    salaries: [],
    loading: false,
    errorStatus: '',
    modal: false,
    salary: initialSalary
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
                draft.loading = true;
                break;
            case GET_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case GET_SUCCESS:
                draft.salaries = payload;
                draft.loading = false;
                draft.errorStatus = '';
                break;

            case POST_REQUEST:
                draft.salary.loading = true;
                break;
            case POST_FAILURE:
                draft.salary.loading = false;
                draft.salary.errorStatus = payload;
                break;
            case POST_SUCCESS:
                draft.salary.errorStatus = '';
                draft.salary.loading = false;
                draft.modal = false;
                break;

            case TRIGGER_MODAL:
                draft.modal = true;
                draft.salary.item = draft.salaries.filter((el) => el.id === payload)[0];
                break;
            case COLLAPSE_MODAL:
                draft.modal = false;
                draft.salary = initialSalary;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    get_request: (data) => creator(GET_REQUEST, data),
    get_success: (data) => creator(GET_SUCCESS, data),
    get_failure: (data) => creator(GET_FAILURE, data),
    post_request: (data) => creator(POST_REQUEST, data),
    post_success: (data) => creator(POST_SUCCESS, data),
    post_failure: (data) => creator(POST_FAILURE, data),
    trigger_modal: (data) => creator(TRIGGER_MODAL, data),
    collapse_modal: () => creator(COLLAPSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *get_request({ payload }) {
        try {
            const response = yield axios.get(`/workers/salaries/${payload}`);
            yield put(actions.get_success(response.data.data));
        } catch (e) {
            yield put(actions.get_failure('Something went wrong please contact support!'));
        }
    },

    *post_request({ payload }) {
        try {
            const { worker_id, company_id } = payload;
            yield axios.post(`/workers/salaries/${worker_id}/${company_id}`, payload);
            yield put(actions.post_success());
            yield put(actions.get_request(worker_id));
        } catch (e) {
            yield put(actions.post_success('Something went wrong please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get_request);
    yield takeLatest(POST_REQUEST, sagas.post_request);
};
