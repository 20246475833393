import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofile/screenings/REQUEST';
const SUCCESS = '@app/workers/workersprofile/screenings/SUCCESS';
const FAILURE = '@app/workers/workersprofile/screenings/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                break;
            case FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case SUCCESS:
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: () => creator(SUCCESS),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { worker_id, id } = payload;
            yield axios.put(`/workers/components/${id}`, payload);
            yield put(actions.success());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure('Submit failed, please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
