/**
 * Module import
 */
import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Containers
 */
import PrivateContent from 'containers/PrivateContent';
import Navigation from 'containers/Navigation';
/**
 * Components
 */
import AnimatedRoute from 'components/Animations/AnimatedRoute';
import AnimatedSwitch from 'components/Animations/AnimatedSwitch';
import { MainLayout } from 'uwork-app-core';

/**
 * Pages Import
 */
import {
    NotFound,
    CompanyShow,
    Companies,
    // CompanyEditAccount,
    CompanyEditProfileUploads,
    CompanyEditIndustries,
    CompanyEditAbout,
    CompanyEditNDA,
    CompanyEditBreaks,
    CompanyEditContract,
    CompanyEditSalaryConditions,
    DepartmentsShow,
    SelectWorkers,
    WorkersProfileFeed,
    WorkerAccount,
    WorkerPersonalInformation,
    WorkerProfileUpload,
    WorkerProfilePayment,
    WorkerProfileShifts,
    WorkerProfileExport,
    WorkerProfileAttachments,
    WorkerProfileAgreements,
    WorkerProfileAdvancedSettings,
    WorkerProfileCoursesAndSkills,
    WorkerProfileNotifications,
    WorkerProfileGCC,
    SelectNewOrder,
    ActiveOrders,
    CompletedOrders,
    OngoingOrders,
    TimesheetLegacy,
    AccountSettings,
    Guidelines,
    Notifications,
    Courses,
    Skills,
    Titles,
    Tags,
    Badges,
    EmailLogs,
    SingleEmailLog,
    NewOrderManager,
    Dashboard,
    Metrics,
    OrderViewPage,
    WorkersFilterPage,
    WorkersAvailabilityPage,
    RecommendationsFilterPage,
    NewRecommendation,
    // departments 2.0
    DeptPatientInformation,
    DeptGlobalInformation,
    OvertimePage,
    CompanyEditWorkingHourScheme,
    CompanyEditCollectiveAgreement,
    CompanyEditOrderShiftTypes,
    DeptEditOrderShiftType,
    TimesheetPage,
    WorkerProfileIndustryPage,
    CompanyEditMarkupFeePage,
    CompanyEditSalaryResultVersionPage,
    CompanyEditSalaryResultVersionDiffPage,
    CompanyEditSalaryMatrixPage,
    CompanyEditSalaryRulePage,
    CompanyEditSalaryRuleClockAdditionPage,
    CompanyEditSalaryRuleDateAdditionPage,
    CompanyEditSalaryRuleLengthAdditionPage,
    CompanyEditSalaryRuleWeekdayAdditionPage,
    WorkerProfileSalaryRelation,
    CompanyEditSalaryRelationPage,
    CompanyPage,
    DepartmentPage
} from 'pages';

const DashboardRoutes = () => (
    <PrivateContent>
        <MainLayout className="padding-200" outerHeaderContent={<Navigation />}>
            <AnimatedSwitch>
                <AnimatedRoute exact path="/" render={(props) => <Dashboard {...props} />} />

                {/* Metrics */}
                <AnimatedRoute exact path="/metrics" render={(props) => <Metrics {...props} />} />

                {/* Companies Routes */}
                <AnimatedRoute
                    exact
                    path="/companies-old"
                    render={(props) => <Companies {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/companies"
                    render={(props) => <CompanyPage {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/departments"
                    render={(props) => <DepartmentPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/companies/show/:id/:tab"
                    render={(props) => <CompanyShow {...props} />}
                />

                {/* Company Edit Settings */}
                {/* <AnimatedRoute exact path="/company/settings/account/:id" render={(props) => <CompanyEditAccount {...props} />} /> */}
                <AnimatedRoute
                    exact
                    path="/company/settings/profile-uploads/:id"
                    render={(props) => <CompanyEditProfileUploads {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/industries/:id"
                    render={(props) => <CompanyEditIndustries {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/about/:id"
                    render={(props) => <CompanyEditAbout {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/order-shift-types/:id"
                    render={(props) => <CompanyEditOrderShiftTypes {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/markup-fee/:id"
                    render={(props) => <CompanyEditMarkupFeePage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-matrix/:id"
                    render={(props) => <CompanyEditSalaryMatrixPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-rules/:id"
                    render={(props) => <CompanyEditSalaryRulePage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-rules/clock-additions/:id"
                    render={(props) => <CompanyEditSalaryRuleClockAdditionPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-relations/:id"
                    render={(props) => <CompanyEditSalaryRelationPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-rules/date-additions/:id"
                    render={(props) => <CompanyEditSalaryRuleDateAdditionPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-rules/length-additions/:id"
                    render={(props) => <CompanyEditSalaryRuleLengthAdditionPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-rules/weekday-additions/:id"
                    render={(props) => <CompanyEditSalaryRuleWeekdayAdditionPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/salary-result-versions/:id"
                    render={(props) => <CompanyEditSalaryResultVersionPage {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/salary-result-version-diff/:id"
                    render={(props) => <CompanyEditSalaryResultVersionDiffPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/company/settings/nda/:id"
                    render={(props) => <CompanyEditNDA {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/breaks/:id"
                    render={(props) => <CompanyEditBreaks {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/overtime/workinghourscheme/:id"
                    render={(props) => <CompanyEditWorkingHourScheme {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/overtime/collectiveagreement/:id"
                    render={(props) => <CompanyEditCollectiveAgreement {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/contract/:id"
                    render={(props) => <CompanyEditContract {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/company/settings/salary/:id"
                    render={(props) => <CompanyEditSalaryConditions {...props} />}
                />

                {/* Departments Routes */}
                <AnimatedRoute
                    exact
                    path="/departments/show/:id/:tab"
                    render={(props) => <DepartmentsShow {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/workers"
                    render={(props) => (
                        <WorkersFilterPage
                            {...props}
                            forOrder={false}
                            renotify={false}
                            recommendation={false}
                        />
                    )}
                />
                <AnimatedRoute
                    exact
                    path="/workers-availability"
                    render={(props) => (
                        <WorkersAvailabilityPage
                            {...props}
                            forOrder={false}
                            renotify={false}
                            recommendation={false}
                        />
                    )}
                />
                <AnimatedRoute
                    exact
                    path="/filter"
                    render={(props) => (
                        <WorkersFilterPage
                            {...props}
                            forOrder
                            renotify={false}
                            recommendation={false}
                        />
                    )}
                />
                <AnimatedRoute
                    exact
                    path="/renotify-workers"
                    render={(props) => (
                        <WorkersFilterPage
                            {...props}
                            forOrder={false}
                            renotify
                            recommendation={false}
                        />
                    )}
                />
                <AnimatedRoute
                    exact
                    path="/recommend-workers"
                    render={(props) => (
                        <WorkersFilterPage
                            {...props}
                            forOrder={false}
                            renotify={false}
                            recommendation
                        />
                    )}
                />
                <AnimatedRoute
                    exact
                    path="/recommendations"
                    render={(props) => <RecommendationsFilterPage {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/recommendations/new"
                    render={(props) => <NewRecommendation {...props} />}
                />
                <AnimatedRoute
                    animation={{}}
                    exact
                    path="/worker/:id"
                    render={(props) => <WorkersProfileFeed {...props} />}
                />
                <AnimatedRoute
                    animation={{}}
                    exact
                    path="/worker/:id/:tab"
                    render={(props) => <WorkersProfileFeed {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/account/:id"
                    render={(props) => <WorkerAccount {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/personal-information/:id"
                    render={(props) => <WorkerPersonalInformation {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/profile-uploads/:id"
                    render={(props) => <WorkerProfileUpload {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/payment/:id"
                    render={(props) => <WorkerProfilePayment {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/shift-travel/:id"
                    render={(props) => <WorkerProfileShifts {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/export/:id"
                    render={(props) => <WorkerProfileExport {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/salary-relations/:id"
                    render={(props) => <WorkerProfileSalaryRelation {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/woker/settings/attachments/:id"
                    render={(props) => <WorkerProfileAttachments {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/gcc/:id"
                    render={(props) => <WorkerProfileGCC {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/agreements/:id"
                    render={(props) => <WorkerProfileAgreements {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/worker/settings/industries/:id"
                    render={(props) => <WorkerProfileIndustryPage {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/woker/settings/advanced/:id"
                    render={(props) => <WorkerProfileAdvancedSettings {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/courses-skills/:id"
                    render={(props) => <WorkerProfileCoursesAndSkills {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/woker/settings/notifications/:id"
                    render={(props) => <WorkerProfileNotifications {...props} />}
                />

                {/* Departments 2.0 */}
                <AnimatedRoute
                    exact
                    path="/departments/settings/patients/:id"
                    render={(props) => <DeptPatientInformation {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/departments/settings/information/:id"
                    render={(props) => <DeptGlobalInformation {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/departments/settings/order-shift-types/:id"
                    render={(props) => <DeptEditOrderShiftType {...props} />}
                />

                {/* Order Routes */}
                <AnimatedRoute
                    exact
                    path="/orders/select-new-order"
                    render={(props) => <SelectNewOrder {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/orders/new/:id"
                    render={(props) => <NewOrderManager {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/orders/active"
                    render={(props) => <ActiveOrders {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/orders/completed"
                    render={(props) => <CompletedOrders {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/orders/ongoing"
                    render={(props) => <OngoingOrders {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/orders/select-workers/:id"
                    render={(props) => <SelectWorkers {...props} />}
                />

                {/* Do not remove until seperation is completed */}
                <AnimatedRoute
                    exact
                    path="/orders/view/:id"
                    render={(props) => <OrderViewPage {...props} />}
                />

                {/* Timesheet Routes */}
                <AnimatedRoute
                    exact
                    path="/timesheet-legacy"
                    render={(props) => <TimesheetLegacy {...props} />}
                />

                <AnimatedRoute
                    exact
                    path="/timesheet"
                    render={(props) => <TimesheetPage {...props} />}
                />

                {/* Overtime Routes */}
                <AnimatedRoute
                    exact
                    path="/overtime"
                    render={(props) => <OvertimePage {...props} />}
                />

                {/* Administration Routes */}
                <AnimatedRoute
                    exact
                    path="/administration/guidelines"
                    render={(props) => <Guidelines {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/skills"
                    render={(props) => <Skills {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/courses"
                    render={(props) => <Courses {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/titles"
                    render={(props) => <Titles {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/tags"
                    render={(props) => <Tags {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/badges"
                    render={(props) => <Badges {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/emails"
                    render={(props) => <EmailLogs {...props} />}
                />
                <AnimatedRoute
                    exact
                    path="/administration/emails/:id"
                    render={(props) => <SingleEmailLog {...props} />}
                />

                {/* Notifications Routes */}
                <AnimatedRoute
                    exact
                    path="/notifications"
                    render={(props) => <Notifications {...props} />}
                />

                {/* Settings Routes */}
                <AnimatedRoute
                    exact
                    path="/settings"
                    render={(props) => <AccountSettings {...props} />}
                />

                {/* Playground - reactivate when needed */}
                {/* <AnimatedRoute exact path="/playground" render={(props) => <Playground {...props} />} /> */}

                {/* Erro Pages */}
                <AnimatedRoute exact path="/error/404" render={() => <NotFound />} />
                <AnimatedRoute exact path="/*" render={() => <Redirect to="/error/404" />} />
            </AnimatedSwitch>
        </MainLayout>
    </PrivateContent>
);

export default DashboardRoutes;
