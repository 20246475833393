import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Components
 */
import { Toast } from 'uwork-app-core';

import { actions as alertActions } from 'sagas/app/alertSaga';

const ToastAlert = () => {
    /**
     * Local state
     */
    const [show, toggleShow] = useState(false);

    /**
     * Consts
     */
    const dispatch = useDispatch();

    /**
     * Selectors
     */
    const { type, message } = useSelector((state) => state.app.alert);

    /**
     * Handlers
     */
    const handleClose = () => {
        toggleShow(false);
        dispatch(alertActions.clear());
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (type) {
            toggleShow(true);
        }
    }, [type]);

    return <Toast message={message} open={show} handleClose={handleClose} />;
};
export default ToastAlert;
