import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator, parseQueryString as parse } from 'uwork-app-core';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/workers/ongoingOrderssaga/REQUEST';
export const SUCCESS = '@app/workers/ongoingOrderssaga/SUCCESS';
export const FAILURE = '@app/workers/ongoingOrderssaga/FAILURE';
export const SET_LOADING = '@app/workers/ongoingOrderssaga/SET_LOADING';
export const SET_ITEMS = '@app/workers/ongoingOrderssaga/SET_ITEMS';
export const CLEAR = '@app/workers/ongoingOrderssaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    items: [],
    meta: {
        current_page: 1,
        last_page: 1
    }
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                draft.meta = payload.meta;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            const sort = parse(payload)?.sort;
            const response = yield axios.get(`/orders/ongoing?${payload}`);
            let { data, links, meta } = response.data;

            if (sort) {
                data = data.sort((el1, el2) => {
                    let compared = el1.company.name.localeCompare(el2.company.name);
                    if (sort === 'desc') {
                        return -compared;
                    }
                    return compared;
                });
            }
            yield put(actions.success({ data, links, meta }));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
