import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/workers/workerslocationssaga/REQUEST';
export const SUCCESS = '@app/workers/workerslocationssaga/SUCCESS';
export const FAILURE = '@app/workers/workerslocationssaga/FAILURE';
export const CLEAR = '@app/workers/workerslocationssaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        const searchParam = new URLSearchParams(payload);
        let queryString = searchParam.toString();

        let response;

        try {
            if (searchParam.has('lat') && searchParam.has('lng')) {
                const primaryLatValue = searchParam.get('lat');
                const primaryLngValue = searchParam.get('lng');
                let lat = primaryLatValue ? primaryLatValue.split(',') : null;
                let lng = primaryLngValue ? primaryLngValue.split(',') : null;
                if (!!lat && !!lng && lat.length === 2 && lng.length === 2) {
                    response = yield axios.get(`/workers/map?${queryString}`);
                } else {
                    searchParam.set('lat', [5.022398299730241, 73.45890488694582]);
                    searchParam.set('lng', [-51.73286527499999, 74.302290975]);
                    response = yield axios.get(`/workers/map?${searchParam.toString()}`);
                }
            } else {
                searchParam.set('lat', [5.022398299730241, 73.45890488694582]);
                searchParam.set('lng', [-51.73286527499999, 74.302290975]);
                response = yield axios.get(`/workers/map?${searchParam.toString()}`);
            }

            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
