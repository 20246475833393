import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
export const EDIT_REQUEST = '@app/workers/workersprofilesaga/experience/edit/EDIT_REQUEST';
export const EDIT_SUCCESS = '@app/workers/workersprofilesaga/experience/edit/EDIT_SUCCESS';
export const EDIT_FAILURE = '@app/workers/workersprofilesaga/experience/edit/EDIT_FAILURE';

export const ADD_REQUEST = '@app/workers/workersprofilesaga/experience/edit/ADD_REQUEST';
export const ADD_SUCCESS = '@app/workers/workersprofilesaga/experience/edit/ADD_SUCCESS';
export const ADD_FAILURE = '@app/workers/workersprofilesaga/experience/edit/ADD_FAILURE';

export const DELETE_REQUEST = '@app/workers/workersprofilesaga/experience/edit/DELETE_REQUEST';
export const DELETE_SUCCESS = '@app/workers/workersprofilesaga/experience/edit/DELETE_SUCCESS';
export const DELETE_FAILURE = '@app/workers/workersprofilesaga/experience/edit/DELETE_FAILURE';

export const TRIGGER_MODAL = '@app/workers/workersprofilesaga/experience/edit/TRIGGER_MODAL';
export const TRIGGER = '@app/workers/workersprofilesaga/experience/edit/TRIGGER';

/**
 * Initial state
 */

const initFormData = {
    id: '',
    name: '',
    title: '',
    started: '',
    ended: '',
    current: 0,
    description: '',
    start_date_month: null,
    start_date_year: null,
    end_date_month: null,
    end_date_year: null
};

const initialState = {
    item: initFormData,
    modal: false,
    loading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_SUCCESS:
            case EDIT_SUCCESS:
                draft.item = initFormData;
                draft.loading = false;
                draft.modal = false;
                break;
            case ADD_FAILURE:
            case EDIT_FAILURE:
                draft.error = payload;
                draft.loading = false;
                break;
            case DELETE_REQUEST:
                draft.deleteExperienceLoading = true;
                break;
            case DELETE_SUCCESS:
                draft.deleteExperienceLoading = false;
                break;
            case DELETE_FAILURE:
                draft.deleteError = payload;
                draft.deleteExperienceLoading = false;
                break;
            case TRIGGER_MODAL:
                draft.modal = payload.modal;
                draft.item = payload.data || initFormData;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    add_request: (data) => creator(ADD_REQUEST, data),
    add_success: () => creator(ADD_SUCCESS),
    add_failure: (data) => creator(ADD_FAILURE, data),
    edit_request: (data) => creator(EDIT_REQUEST, data),
    edit_success: () => creator(EDIT_SUCCESS),
    edit_failure: (data) => creator(EDIT_FAILURE, data),
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: () => creator(DELETE_SUCCESS),
    delete_failure: (data) => creator(DELETE_FAILURE, data),
    trigger_modal: (data) => creator(TRIGGER_MODAL, data)
};

/**
 * Sagas
 */
export const sagas = {
    *add_request({ payload }) {
        try {
            yield axios.post(`/workers/experiences/${payload.userID}`, payload);
            yield put(actions.add_success());
            yield put(profileActions.request(payload.workerID));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.add_failure(error));
        }
    },
    *edit_request({ payload }) {
        try {
            yield axios.put(`/workers/experiences/${payload.id}`, payload);
            yield put(actions.edit_success());
            yield put(profileActions.request(payload.workerID));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.edit_failure(error));
        }
    },
    *delete_request({ payload }) {
        try {
            yield axios.delete(`/workers/experiences/${payload.id}`);
            yield put(actions.delete_success());
            yield put(profileActions.request(payload.workerID));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.delete_failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(ADD_REQUEST, sagas.add_request);
    yield takeLatest(EDIT_REQUEST, sagas.edit_request);
    yield takeLatest(DELETE_REQUEST, sagas.delete_request);
};
