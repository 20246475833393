import produce from 'immer';
import { creator } from 'uwork-app-core';

/**
 * External Ations
 */

/**
 * Constants
 */
export const SET_COMPANIES =
    '@app/workers/recommendUWorker/newTemporaryRecommendation/SET_COMPANIES';
export const SET_WORKERS = '@app/workers/recommendUWorker/newTemporaryRecommendation/SET_WORKERS';
export const REMOVE_COMPANY =
    '@app/workers/recommendUWorker/newTemporaryRecommendation/REMOVE_COMPANY';
export const REMOVE_WORKER =
    '@app/workers/recommendUWorker/newTemporaryRecommendation/REMOVE_WORKER';
export const CLEAR = '@app/workers/recommendUWorker/newTemporaryRecommendation/CLEAR';

/**
 * Initial state
 */
const initState = {
    companies: [],
    workers: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_COMPANIES:
                draft.companies = [
                    ...draft.companies,
                    { ...payload.data, uuid: Math.floor(Math.random() * 100) }
                ];
                break;
            case REMOVE_COMPANY:
                draft.companies = draft.companies.filter((el) => el.uuid !== payload);
                break;
            case SET_WORKERS:
                draft.workers = payload;
                break;
            case CLEAR:
                draft.companies = [];
                draft.workers = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    set_companies: (data) => creator(SET_COMPANIES, data),
    set_workers: (data) => creator(SET_WORKERS, data),
    remove_company: (data) => creator(REMOVE_COMPANY, data),
    clear: () => creator(CLEAR)
};
