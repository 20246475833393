import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */
import { actions as timesheetActions } from 'sagas/app/timesheet/timesheetSaga';
import { actions as orderActions } from 'sagas/app/orders/orderSaga';

/**
 * Constants
 */
export const REQUEST = '@app/timesheet/absencerequestsaga/REQUEST';
export const SUCCESS = '@app/timesheet/absencerequestsaga/SUCCESS';
export const FAILURE = '@app/timesheet/absencerequestsaga/FAILURE';
export const DELETE_REQUEST = '@app/timesheet/absencerequestsaga/DELETE_REQUEST';
export const DELETE_SUCCESS = '@app/timesheet/absencerequestsaga/DELETE_SUCCESS';
export const DELETE_FAILURE = '@app/timesheet/absencerequestsaga/DELETE_FAILURE';
export const SET_CLOSE_MODAL = '@app/timesheet/absencerequestsaga/SET_CLOSE_MODAL';
export const SET_OPEN_MODAL = '@app/timesheet/absencerequestsaga/SET_OPEN_MODAL';

/**
 * Initial state
 */
const initAbsence = {
    id: null,
    absence_type: '',
    absence_comment: ''
};

const initState = {
    loading: false,
    isModalOpen: false,
    errorStatus: '',
    absence: initAbsence
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case DELETE_SUCCESS:
            case SUCCESS:
                draft.isModalOpen = false;
                draft.loading = false;
                draft.errorStatus = '';
                break;
            case DELETE_FAILURE:
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case DELETE_REQUEST:
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case SET_CLOSE_MODAL:
                draft.isModalOpen = false;
                draft.loading = false;
                draft.errorStatus = '';
                break;
            case SET_OPEN_MODAL:
                draft.isModalOpen = true;
                draft.absence = payload;
                draft.loading = false;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),
    setCloseModal: (data) => creator(SET_CLOSE_MODAL, data),
    setOpenModal: (data) => creator(SET_OPEN_MODAL, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { options, orderId } = payload;
            const response = yield axios.put(`/orders/order-shifts/absence/${options.id}`, {
                absence_type: options.absence_type,
                absence_comment: options.absence_comment
            });
            yield put(actions.success(response.data));
            if (+orderId) {
                yield put(orderActions.request(orderId));
            } else {
                yield put(timesheetActions.initLastRequest());
            }
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },
    *delete_request({ payload }) {
        try {
            const { id, orderId } = payload;
            const response = yield axios.delete(`/orders/order-shifts/absence/${id}`);
            yield put(actions.delete_success(response.data));
            if (+orderId) {
                yield put(orderActions.request(orderId));
            } else {
                yield put(timesheetActions.initLastRequest());
            }
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.delete_failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(DELETE_REQUEST, sagas.delete_request);
};
