/* eslint-disable no-param-reassign */
// import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
// import { push } from 'react-router-redux';
import { creator as createAction } from 'uwork-app-core';

// import config
import { NAVBAR_SIDEBAR_BREAKPOINT } from 'config';

// action types
export const SET_ACTIVE_ITEM_SLUG = 'app/navigation/activeSlug/SET';
export const NAV_TOGGLE = 'app/navigation/menu/TOGGLE';

// show navigation init
const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
let showNav = true;
if (windowWidth < NAVBAR_SIDEBAR_BREAKPOINT) {
    showNav = false;
}

// initial reducer state
const initState = {
    nav: {
        activeItem: {
            slug: '',
            showDropdown: false,
            dropdownActiveItemSlug: ''
        },
        showNav
    },
    menu: {}
};

// Reducer
const reducer = (state = initState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_ACTIVE_ITEM_SLUG:
                draft.nav.activeItem = action.payload;
                break;
            case NAV_TOGGLE:
                draft.nav.showNav = action.payload !== null ? action.payload : !state.nav.showNav;
                break;
            default:
                break;
        }
    });
export default reducer;

// actions
export const actions = {
    setActiveSlug: (payload) => createAction(SET_ACTIVE_ITEM_SLUG, payload),
    navToggle: (show = null) => createAction(NAV_TOGGLE, show)
};

// export const sagas = {
// };

// export const watcher = function* watch() {
// };
