/**
 * Modules import
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

/**
 * History import
 */
import { history } from 'uwork-app-core';

/**
 * Reducers import
 */
// default reducer
import loadersReducer from 'sagas/app/loadersSaga';
import errorsReducer from 'sagas/app/errorsSaga';

// auth reducers
import authUserReducer from 'sagas/app/auth/userSaga';

// navigation
import navigationReducer from 'sagas/app/navigation';

// ui
import uiReducer from 'sagas/app/uiSaga';

// company
import companiesPageReduce from 'sagas/app/companies/companiesPageSaga';
import companyReducer from 'sagas/app/companies/companySaga';
import companiesPlacesReducer from 'sagas/app/companies/companiesPlacesSaga';
import companiesCategoryReducer from 'sagas/app/companies/companiesCategorySaga';
import companiesWorkerReducer from 'sagas/app/companies/companiesWorkersSaga';
import companiesAddCompanyReducer from 'sagas/app/companies//companiesAddCompanySaga';
import companiesSearchWorkerReducer from 'sagas/app/companies/companiesSearchWorkerSaga';
import companiesAddNewTeamMemberReducer from 'sagas/app/companies/companiesAddNewTeamMemberSaga';
import companiesSalaryTabReducer from 'sagas/app/companies/salaryTabSaga';
import companiesWorkersTabWorkers from 'sagas/app/companies/workersTabSaga';
import companiesTabTeamMembersReducer from 'sagas/app/companies/teamMembersSaga';
import companiesAssignBMKReducer from 'sagas/app/companies/companyAssignBMKSaga';
import companiesAssignRequestRecieverReducer from 'sagas/app/companies/orderNotificationRequesters';
import companiesTeamMembersFetchReducer from 'sagas/app/companies/fetchTeamMembersSaga';
import companiesDeleteReducer from 'sagas/app/companies/companiesDeleteSaga';
import companiesTeamMembersUpdateTitle from 'sagas/app/companies/updateTitleSaga';

// company edit parent
import companiesParentEditAccountReducer from 'sagas/app/companies/companiesParentEditAccountSaga';
import companiesParentEditProfileUploadsReducer from 'sagas/app/companies/companiesParrentEditProfileUploadsSaga';
import companiesParentEditCategoriesReducer from 'sagas/app/companies/companiesParentEditCategoriesSaga';
import companiesParentEditAboutReducer from 'sagas/app/companies/companiesParentEditAboutSaga';
import companyParentEditNDAReducer from 'sagas/app/companies/companyParentEditNDASaga';
import companiesParentEditBreaksReducer from 'sagas/app/companies/companiesParentEditBreaksSaga';
import companiesParentEditContractReducer from 'sagas/app/companies/companiesParentEditContractSaga';
import switchDepartamentRreducer from 'sagas/app/companies/switchDepartamentSaga';
import companiesParentEditAddSalaryConditionReducer from 'sagas/app/companies/companiesParentEditAddSalaryConditionSaga';

// departments
import departmentSaga from 'sagas/app/departments/departmentSaga';
import departmentCategoriesSaga from 'sagas/app/departments/categoriesSaga';
import addNewDepartmentSaga from 'sagas/app/departments/addNewDepartmentSaga';
import departmentMembers from 'sagas/app/departments/departmentMembersSaga';
import patientInformationReducer from 'sagas/app/departments/patientInformationSaga';
import coursesInformationReducer from 'sagas/app/departments/departmentCoursesSaga';
import allDepartmentCoursesReducer from 'sagas/app/departments/allCoursesSaga';
import allDepartmentSkillsReducer from 'sagas/app/departments/allSkillsSaga';
import departmentSkillsReducer from 'sagas/app/departments/departmnetSkillsSaga';
import departmentBasicInfoReducer from 'sagas/app/departments/departmentBasicInfoSaga';
import editDepartmentInformationsReducer from 'sagas/app/departments/editDepartmentInformations';

// notifications
import notificationsPageReducer from 'sagas/app/notifications/notificationsPageSaga';
import notificationSwitchReducer from 'sagas/app/notifications/notificationSwitchSaga';
import notificationsReducer from 'sagas/app/notifications/notificationsSaga';

// import timesheet reducers
import shiftTypes from 'sagas/app/timesheet/shiftTypesSaga';
import timesheet from 'sagas/app/timesheet/timesheetSaga';
import timesheetCompanies from 'sagas/app/timesheet/companiesSaga';
import absenceRequest from 'sagas/app/timesheet/absenceRequestSaga';
import orderShift from 'sagas/app/timesheet/orderShiftSaga';
import timesheetWorkersReducer from 'sagas/app/timesheet/timesheetWorkerSaga';

// departments
import departmentReducer from 'sagas/app/department/departmentSaga';
import getCompanyDepartmentsReducer from 'sagas/app/departments/getCompanyDepartments';

// workers
import workersReducer from 'sagas/app/workers/workersSaga';
import workersSimpleSearchReducer from 'sagas/app/workers/workersSimpleSearchSaga';
import workerFiltersReducer from 'sagas/app/workers/workerFiltersSaga';
import workersLocationsReducer from 'sagas/app/workers/workersLocationsSaga';
import workersProfileReducer from 'sagas/app/workers/workersProfileSaga';
import selectWorkersReducer from 'sagas/app/workers/selectWorkersSaga';
import selectedDepartmentWorkersReducer from 'sagas/app/workers/selectedDepartmentWorkersSaga';
import workerMapFilterReducer from 'sagas/app/workers/workerMapFilterSaga';
import workerCRUDEducationReducer from 'sagas/app/workers/workerProfileEducationSaga';
import workerCRUDExperienceReducer from 'sagas/app/workers/workerProfileExperienceSaga';
import workerZipCodeReducers from 'sagas/app/workers/zipCodeSaga';
import workerTitleReducer from 'sagas/app/workers/titlesSaga';
import workerPersonalInformationReducer from 'sagas/app/workers/personalInfromationSaga';
import workerAccountSettingsReducer from 'sagas/app/workers/accountSettingsSaga';
import workerProfileUploadReducer from 'sagas/app/workers/profileUploadsSaga';
import workerPaymentReducer from 'sagas/app/workers/workersPaymentSaga';
import workerShiftDistanceReducer from 'sagas/app/workers/workersShiftSaga';
import workerExportReducer from 'sagas/app/workers/workerExportSaga';
import workerSalaryReducer from 'sagas/app/workers/workerSalarySaga';
import workerAttachmentReducer from 'sagas/app/workers/workerAttachmentSaga';
import workerGCCCategoriesReducer from 'sagas/app/workers/workerGCCCategoriesSaga';
import workerGCCReducer from 'sagas/app/workers/workerGCCSaga';
import workerScreeningsReducer from 'sagas/app/workers/workerScreeningsSaga';
import workerAgreementAndPrecentageReducer from 'sagas/app/workers/workerAgreementAndPrecentageSaga';
import workerNotesReducer from 'sagas/app/workers/notesSaga';
import workerBadgesReducer from 'sagas/app/workers/workerBadgesSaga';
import workerCategoriesReducer from 'sagas/app/workers/workerCategoriesSaga';
import workerSkillsReducer from 'sagas/app/workers/workerSkillsSaga';
import workerCoursesReducer from 'sagas/app/workers/workerCoursesSaga';
import workerInterviewsReducer from 'sagas/app/workers/interviewSaga';
import workerInterviewTemplatesReducer from 'sagas/app/workers/interviewTemplatesSaga';
import workerInterviewMappingTemplatesReducer from 'sagas/app/workers/interviewMappingTemplatesSaga';
import workerReferenceReducer from 'sagas/app/workers/referenceSaga';
import workerReferenceTemplatesReducer from 'sagas/app/workers/referenceTemplatesSaga';
import workerAccessReducer from 'sagas/app/workers/giveAccessSaga';
import workerNotificationsEditReducer from 'sagas/app/workers/workerNotficationsSettingsSaga';
import workerAdminsReducer from 'sagas/app/workers/adminsSaga';
import workerAssignAdminReducer from 'sagas/app/workers/assignAdminSaga';
import workerFetchParentCompaniesReducer from 'sagas/app/workers/fetchParentCompanies';
import workerBlockedCompaniesRelationsReducer from 'sagas/app/workers/blockedRelationsSaga';
import workerFilterCoursesReducer from 'sagas/app/workers/coursesSaga';
import workerActivityReducer from 'sagas/app/workers/workerActivitySaga';
import workerCheckPresentationReducer from 'sagas/app/workers/workerCheckPresentationSaga';
import workerUpdatePresentationReducer from 'sagas/app/workers/workerUpdatePresentationSaga';
import renotifyWorkersReducer from 'sagas/app/workers/renotifyWorkersSaga';
import workerReadyStageCheckerReducer from 'sagas/app/workers/workerReadyStageCheckerSaga';
import workerFetchTeamMembersReducer from 'sagas/app/workers/fetchTeamMembersSaga';
import workerSearchDepartmentsReducer from 'sagas/app/workers/searchDepartmensSaga';
import workerTagsReducer from 'sagas/app/workers/workerTagsSaga';

// recommendations
import recommendationsFilterReducer from 'sagas/app/recommendations/recommendationsFilterSaga';
import recommendedWorkersReducer from 'sagas/app/recommendations/recommendedWorkersSaga';
import recommendedCompaniesReducer from 'sagas/app/recommendations/recommendedCompaniesSaga';
import recommendedDepartmentUsersReducer from 'sagas/app/recommendations/recommendedDepartmentUsersSaga';
import recommendedAdminsReducer from 'sagas/app/recommendations/recommendedAdminsSaga';
import deleteRecommendationReducer from 'sagas/app/recommendations/deleteRecommendationSaga';
import newTemporaryRecommendationReducer from 'sagas/app/recommendations/newTemporaryRecommendationSaga';
import newRecommendationReducer from 'sagas/app/recommendations/newRecommendationSaga';

// orders
import activeOrdersReducer from 'sagas/app/orders/activeOrdersSaga';
import completedOrdersReducer from 'sagas/app/orders/completedOrdersSaga';
import ongoingOrdersReducer from 'sagas/app/orders/ongoingOrdersSaga';
import getCompaniesReducer from 'sagas/app/orders/getCompaniesSaga';
import newOrderReducer from 'sagas/app/orders/newOrderSaga';
import orderTemplatesReducer from 'sagas/app/orders/orderTemplateSaga';
import tempNewOrderReducer from 'sagas/app/orders/tempNewOrderSaga';
import orderReducer from 'sagas/app/orders/orderSaga';
import notifyOrderReducer from 'sagas/app/orders/notifyOrderSaga';
import orderShiftReducer from 'sagas/app/orders/editOrderShiftSaga';
import temporaryOrderReducer from 'sagas/app/orders/temporaryOrderSaga';
import filterOrderWorkersReducer from 'sagas/app/orders/filterOrderWorkersSaga';
import selectedOrderWorkersReducer from 'sagas/app/orders/selectWorkerSaga';
import resendOrderConfirmationReducer from 'sagas/app/orders/resendOrderConfirmationSaga';
import editOrderReducer from 'sagas/app/orders/editOrderSaga';
import orderActivityLogReducer from 'sagas/app/orders/activityLogSaga';
import renotifyOrderParticipantsByChannelWatcher from 'sagas/app/orders/renotifyOrderParticipantsByChannel';
import syncSalaryOrderReducer from 'sagas/app/orders/syncSalaryOrderSaga';
import directOrdersTypeViolationReducer from 'sagas/app/orders/directOrderTypeViolationSaga';

// order filters
import orderFilterWorkersReducer from 'sagas/app/orders/orderFilterFetchWorker';
import orderFilterCompaniesReducer from 'sagas/app/orders/orderFilterFetchCompanies';
import orderNotifiableCountReducer from 'sagas/app/orders/getNumberOfNotifiedWorker';

// settings

import userSettings from 'sagas/app/settings/accountSettingsSaga';

// administration
import guidelinesReducer from 'sagas/app/administration/guidlinesSaga';
import companiesWithoutGuidelines from 'sagas/app/administration/companiesSaga';
import coursesReducer from 'sagas/app/administration/coursesSaga';
import administrationSkillsReducer from 'sagas/app/administration/skillsSaga';
import administrationTitlesReducer from 'sagas/app/administration/titlesSaga';
import administrationTagsReducer from 'sagas/app/administration/tagsSaga';
import administrationBadgesReducer from 'sagas/app/administration/badgesSaga';
import administrationEmailLogsReducer from 'sagas/app/administration/emailLogsSaga';
import administrationEmailReducer from 'sagas/app/administration/singleEmailSaga';

// other
import zipcodeReducer from 'sagas/app/zipcodeSaga';
import cityReducer from 'sagas/app/citySaga';

// dashboard
import dashboardReducer from 'sagas/app/dashboardSaga';
import lastSeenStatsReducer from 'sagas/app/lastSeenStatsSaga';

// alert notification
import alertReducer from 'sagas/app/alertSaga';

const createReducer = (injectedReducers = {}) => {
    const rootReducer = combineReducers({
        app: combineReducers({
            // navigation
            navigation: navigationReducer,

            // UI
            ui: uiReducer,

            // dashboard
            dashboard: dashboardReducer,
            lastSeenStats: lastSeenStatsReducer,

            // app functionallity
            loaders: loadersReducer,
            errors: errorsReducer,

            // auth reducers
            user: authUserReducer,

            accountSettings: userSettings,

            // company reducers
            companies: combineReducers({
                data: companiesPageReduce,
                company: companyReducer,
                places: companiesPlacesReducer,
                categories: companiesCategoryReducer,
                workers: companiesWorkerReducer,
                searchWorkers: companiesSearchWorkerReducer,
                addCompany: companiesAddCompanyReducer,
                addTeamMember: companiesAddNewTeamMemberReducer,
                salaryTab: companiesSalaryTabReducer,
                workersTab: companiesWorkersTabWorkers,
                teamMembersTab: companiesTabTeamMembersReducer,
                companiesDelete: companiesDeleteReducer,
                teamTab: combineReducers({
                    team: companiesTeamMembersFetchReducer,
                    remove: companiesTabTeamMembersReducer
                }),
                updateTitle: companiesTeamMembersUpdateTitle,
                assignBMK: companiesAssignBMKReducer,
                assignOrderNotificationReciever: companiesAssignRequestRecieverReducer,
                switchDepartament: switchDepartamentRreducer,
                editParent: combineReducers({
                    account: companiesParentEditAccountReducer,
                    avatar: companiesParentEditProfileUploadsReducer,
                    categories: companiesParentEditCategoriesReducer,
                    about: companiesParentEditAboutReducer,
                    nda: companyParentEditNDAReducer,
                    breaks: companiesParentEditBreaksReducer,
                    contracts: companiesParentEditContractReducer,
                    salary: companiesParentEditAddSalaryConditionReducer
                })
            }),

            departments: combineReducers({
                single: departmentSaga,
                categories: departmentCategoriesSaga,
                new: addNewDepartmentSaga,
                companyDepartments: getCompanyDepartmentsReducer,
                departmentMembers
            }),

            department: combineReducers({
                data: departmentReducer,
                // department settings
                settings: combineReducers({
                    basicInfo: departmentBasicInfoReducer,
                    editBasicInfoStatus: editDepartmentInformationsReducer,
                    patients: patientInformationReducer,
                    courses: combineReducers({
                        registered: coursesInformationReducer,
                        all: allDepartmentCoursesReducer
                    }),
                    skills: combineReducers({
                        registered: departmentSkillsReducer,
                        all: allDepartmentSkillsReducer
                    })
                })
            }),

            // notifications
            notifications: combineReducers({
                page: notificationsPageReducer,
                switch: notificationSwitchReducer,
                data: notificationsReducer
            }),

            // orders
            orders: combineReducers({
                activeOrders: activeOrdersReducer,
                completedOrders: completedOrdersReducer,
                ongoingOrders: ongoingOrdersReducer,
                companies: getCompaniesReducer,
                new: newOrderReducer,
                templates: orderTemplatesReducer,
                tempNewOrder: tempNewOrderReducer,
                order: orderReducer,
                notify: notifyOrderReducer,
                orderShift: orderShiftReducer,
                temporary: temporaryOrderReducer,
                filteredWorkers: filterOrderWorkersReducer,
                selectedWorker: selectedOrderWorkersReducer,
                resendConfirmation: resendOrderConfirmationReducer,
                edit: editOrderReducer,
                filters: combineReducers({
                    workers: orderFilterWorkersReducer,
                    companies: orderFilterCompaniesReducer,
                    notifiableCount: orderNotifiableCountReducer
                }),
                activityLogs: orderActivityLogReducer,
                renotifedByChannel: renotifyOrderParticipantsByChannelWatcher,
                syncSalaryOrder: syncSalaryOrderReducer,
                directOrdersTypeViolation: directOrdersTypeViolationReducer
            }),

            // timesheet reducers
            timesheet: combineReducers({
                shiftTypes,
                timesheet,
                companies: timesheetCompanies,
                absenceRequest,
                workers: timesheetWorkersReducer,
                orderShift
            }),

            // workers
            workers: combineReducers({
                simpleSearchData: workersSimpleSearchReducer,
                data: workersReducer,
                locations: workersLocationsReducer,
                filters: workerFiltersReducer,
                profile: workersProfileReducer,
                selectWorkers: selectWorkersReducer,
                selectedDepartmentWorkers: selectedDepartmentWorkersReducer,
                mapLocation: workerMapFilterReducer,
                workerAccess: workerAccessReducer,
                admins: workerAdminsReducer,
                assignAdmin: workerAssignAdminReducer,
                workerCourses: workerFilterCoursesReducer,
                workerActivity: workerActivityReducer,
                renotifyWorkers: renotifyWorkersReducer,
                workerReadyStageChecker: workerReadyStageCheckerReducer,
                workerSearchDepartments: workerSearchDepartmentsReducer,
                workerFetchTeamMembers: workerFetchTeamMembersReducer,
                editAccess: combineReducers({
                    education: workerCRUDEducationReducer,
                    work: workerCRUDExperienceReducer,
                    notes: workerNotesReducer,
                    interviews: workerInterviewsReducer,
                    references: workerReferenceReducer,
                    personalInformation: workerPersonalInformationReducer,
                    accountSettings: workerAccountSettingsReducer,
                    uploads: workerProfileUploadReducer,
                    payments: workerPaymentReducer,
                    shifts: workerShiftDistanceReducer,
                    export: workerExportReducer,
                    salary: workerSalaryReducer,
                    attachments: workerAttachmentReducer,
                    gcc: workerGCCReducer,
                    screenings: workerScreeningsReducer,
                    skills: workerSkillsReducer,
                    courses: workerCoursesReducer,
                    notifications: workerNotificationsEditReducer,
                    advancedSettings: combineReducers({
                        tags: workerTagsReducer,
                        agreements: workerAgreementAndPrecentageReducer,
                        badges: workerBadgesReducer,
                        categories: workerCategoriesReducer,
                        blockedCompaniesRelations: workerBlockedCompaniesRelationsReducer
                    })
                }),
                utilities: combineReducers({
                    zipCodes: workerZipCodeReducers,
                    titles: workerTitleReducer,
                    categories: workerGCCCategoriesReducer,
                    interviewTemplates: workerInterviewTemplatesReducer,
                    interviewMappingTemplates: workerInterviewMappingTemplatesReducer,
                    referenceTemplates: workerReferenceTemplatesReducer,
                    parentCompanies: workerFetchParentCompaniesReducer
                }),

                presentation: combineReducers({
                    check: workerCheckPresentationReducer,
                    update: workerUpdatePresentationReducer
                })
            }),

            // recommendations
            recommendations: combineReducers({
                filter: recommendationsFilterReducer,
                workers: recommendedWorkersReducer,
                companies: recommendedCompaniesReducer,
                departmentUsers: recommendedDepartmentUsersReducer,
                admins: recommendedAdminsReducer,
                deleteRecommendation: deleteRecommendationReducer,
                newTemporaryRecommendation: newTemporaryRecommendationReducer,
                newRecommendation: newRecommendationReducer
            }),

            // administration
            administration: combineReducers({
                guidelines: guidelinesReducer,
                companies: companiesWithoutGuidelines,
                courses: coursesReducer,
                skills: administrationSkillsReducer,
                titles: administrationTitlesReducer,
                tags: administrationTagsReducer,
                badges: administrationBadgesReducer,
                emailLogs: combineReducers({
                    list: administrationEmailLogsReducer,
                    single: administrationEmailReducer
                })
            }),

            zipCodes: zipcodeReducer,
            cities: cityReducer,

            // alert notification
            alert: alertReducer
        }),
        ...injectedReducers
    });

    // Wrap the root reducer and return a new root reducer with router state
    const mergeWithRouterState = connectRouter(history);
    return mergeWithRouterState(rootReducer);
};
export default createReducer;
