const getUserSettingsFromLocalStorage = (userId) => {
    const settings = localStorage.getItem(`user.settings.${userId}`);

    if (settings) {
        return JSON.parse(settings);
    }

    return null;
};

export default getUserSettingsFromLocalStorage;
