import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/orders/selectworkerssaga/REQUEST';
export const SUCCESS = '@app/orders/selectworkerssaga/SUCCESS';
export const FAILURE = '@app/orders/selectworkerssaga/FAILURE';
export const SET_LOADING = '@app/orders/selectworkerssaga/SET_LOADING';
export const SET_ITEMS = '@app/orders/selectworkerssaga/SET_ITEMS';
export const CLEAR = '@app/orders/selectworkerssaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    data: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.data = payload.data;
                draft.success = true;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.data = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload: { id, workers } }) {
        yield put(actions.clear());
        yield put(creator(SET_LOADING, true));
        try {
            const response = yield axios.put(`/orders/workers/${id}`, {
                workers
            });
            yield put(actions.success(response.data));

            yield put(push(`/orders/view/${id}`));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
