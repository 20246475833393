import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';
import format from 'utils/customTimeFormat';
import { parseISO } from 'date-fns';

/**
 * External Ations
 */
import { actions as orderActions } from './orderSaga';

/**
 * Constants
 */
export const REQUEST = '@app/workers/editordershiftsaga/REQUEST';
export const INIT_REQUEST = '@app/workers/editordershiftsaga/INIT_REQUEST';
export const SUCCESS = '@app/workers/editordershiftsaga/SUCCESS';
export const FAILURE = '@app/workers/editordershiftsaga/FAILURE';
export const SET_LOADING = '@app/workers/editordershiftsaga/SET_LOADING';
export const SET_ITEMS = '@@app/workers/editordershiftsaga/SET_ITEMS';
export const CLEAR = '@app/workers/editordershiftsaga/CLEAR';
export const SET_CLOSE_MODAL = '@app/workers/editordershiftsaga/SET_CLOSE_MODAL';
export const SET_FORM_VALUES = '@app/workers/editordershiftsaga/SET_FORM_VALUES';
export const TRIGGER_MODAL = '@app/workers/editordershiftsaga/TRIGGER_MODAL';
export const DELETE_SHIFT_REQUEST = '@app/workers/editordershiftsaga/DELETE_SHIFT_REQUEST';
export const DELETE_SHIFT_SUCCESS = '@app/workers/editordershiftsaga/DELETE_SHIFT_SUCCESS';
export const DELETE_SHIFT_FAILURE = '@app/workers/editordershiftsaga/DELETE_SHIFT_FAILURE';

/**
 * Init Form Data
 */
const initFormData = {
    from: new Date().toISOString(),
    to: new Date().toISOString(),
    id: 0,
    sum_hours: 0,
    orderId: 0
};

/**
 * Initial state
 */
const initState = {
    loading: false,
    closeModal: false,
    modal: false,
    errorStatus: '',
    formValues: initFormData
};
/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.closeModal = true;
                break;
            case DELETE_SHIFT_REQUEST:
                draft.loading = true;
                break;
            case DELETE_SHIFT_SUCCESS:
                draft.loading = false;
                break;
            case DELETE_SHIFT_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case SET_CLOSE_MODAL:
                draft.closeModal = payload;
                break;
            case SET_FORM_VALUES:
                draft.formValues = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                break;
            case TRIGGER_MODAL:
                draft.modal = payload.modal;
                draft.formValues = payload.data || initFormData;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    delete_request: (data) => creator(DELETE_SHIFT_REQUEST, data),
    delete_success: (data) => creator(DELETE_SHIFT_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_SHIFT_FAILURE, data),
    clear: () => creator(CLEAR),
    setCloseModal: (data) => creator(SET_CLOSE_MODAL, data),
    init: (data) => creator(INIT_REQUEST, data),
    trigger_modal: (data) => creator(TRIGGER_MODAL, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            const response = yield axios.put(`/orders/order-shifts/${payload.id}`, {
                from: format(parseISO(payload.from), 'YYYY-MM-DD HH:mm:ss'),
                to: format(parseISO(payload.to), 'YYYY-MM-DD HH:mm:ss'),
                sum_hours: payload.sum_hours,
                rest_time_comment: payload.rest_time_comment
            });
            yield put(actions.success(response.data));
            yield put(orderActions.request(payload.order_id));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    },
    *delete_request({ payload }) {
        try {
            yield axios.delete(`/orders/order-shifts/${payload.order_shift_id}`);
            yield put(orderActions.request(payload.order_id));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(DELETE_SHIFT_REQUEST, sagas.delete_request);
};
