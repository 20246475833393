import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/workers/workersprofilesaga/REQUEST';
export const SUCCESS = '@app/workers/workersprofilesaga/SUCCESS';
export const FAILURE = '@app/workers/workersprofilesaga/FAILURE';
export const UPDATE_SINGLE_PARAM = '@app/workers/workersprofilesaga/UPDATE_SINGLE_PARAM';
export const CLEAR = '@app/workers/workersprofilesaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    data: null,
    isLoading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.isLoading = true;
                break;
            case SUCCESS:
                draft.data = payload.data;
                draft.isLoading = false;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.isLoading = false;
                break;
            case UPDATE_SINGLE_PARAM:
                draft.data = { ...draft.data, [payload.key]: payload.value };
                break;
            case CLEAR:
                draft.data = null;
                draft.isLoading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    updateSingleParam: (data) => creator(UPDATE_SINGLE_PARAM, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(`/workers/show/${payload}`);
            yield put(actions.success(response.data));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
