import produce from 'immer';
import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

import { actions as companyActions } from 'sagas/app/companies/companiesPageSaga';
import { actions as alertActions } from 'sagas/app/alertSaga';

/**
 * Constants
 */
const STEP_ONE_REQUEST = '@app/company/add/newcompany/STEP_ONE_REQUEST';
const STEP_ONE_SUCCESS = '@app/company/add/newcompany/STEP_ONE_SUCCESS';
const STEP_ONE_FAILURE = '@app/company/add/newcompany/STEP_ONE_FAILURE';

const STEP_TWO_REQUEST = '@app/company/add/newcompany/STEP_TWO_REQUEST';
const STEP_TWO_FAILURE = '@app/company/add/newcompany/STEP_TWO_FAILURE';
const STEP_TWO_SUCCESS = '@app/company/add/newcompany/STEP_TWO_SUCCESS';

const TRIGGER_MODAL = '@app/company/add/newcompany/TRIGGER_MODAL';
const COLAPSE_MODAL = '@app/company/add/newcompany/COLAPSE_MODAL';
const COLAPSE_STEP_TWO = '@app/company/add/newcompany/COLAPSE_STEP_TWO';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    step: 1,
    team: [],
    modal: false,
    id: null,
    user_id: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case STEP_ONE_REQUEST:
            case STEP_TWO_REQUEST:
                draft.loading = true;
                break;
            case STEP_ONE_FAILURE:
            case STEP_TWO_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case STEP_ONE_SUCCESS:
                draft.loading = false;
                draft.step = 2;
                draft.id = payload.id;
                draft.user_id = payload.user_id;
                draft.errorStatus = '';
                break;
            case STEP_TWO_SUCCESS:
                draft.loading = false;
                draft.team = payload.team;
                break;
            case TRIGGER_MODAL:
                draft.modal = true;
                break;
            case COLAPSE_MODAL:
                draft.modal = false;
                draft.step = 1;
                draft.loading = false;
                draft.errorStatus = '';
                draft.team = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request_step_one: (data) => creator(STEP_ONE_REQUEST, data),
    success_step_one: (data) => creator(STEP_ONE_SUCCESS, data),
    failure_step_one: (data) => creator(STEP_ONE_FAILURE, data),

    request_step_two: (data) => creator(STEP_TWO_REQUEST, data),
    failure_step_two: (data) => creator(STEP_TWO_FAILURE, data),
    success_step_two: (data) => creator(STEP_TWO_SUCCESS, data),

    trigger_modal: () => creator(TRIGGER_MODAL),
    colapse_modal: () => creator(COLAPSE_MODAL),
    colapse_step_two: () => creator(COLAPSE_STEP_TWO)
};

/**
 * Saga functions
 */
export const sagas = {
    *request_step_one({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('file', payload.file, payload.file.name);
            data.append('name', payload.name);
            data.append('place', payload.place);
            data.append('category_id', payload.category_id);
            data.append('intro', payload.intro);
            data.append('address', payload.address);
            data.append('lat', payload.lat);
            data.append('lng', payload.lng);
            data.append('country', payload.country);
            data.append('web', payload.web);
            data.append('zip_code', payload.zip_code);

            const response = yield axios.post(`/companies`, data, settings);

            yield put(actions.success_step_one(response.data.data));
            yield put(alertActions.success({ message: payload.message }));
            yield put(push(`/companies/show/${response?.data?.data?.id}/description`));
        } catch (e) {
            yield put(actions.failure_step_one(errorMessageGenerator(e)));
        }
    },

    *request_step_two({ payload }) {
        try {
            // add new worker
            yield axios.post(`/companies/users`, payload);

            // fetch all workers after success
            const { company_id } = payload;

            // delay a bit then fetch
            yield delay(300);
            const response = yield axios.get(`/companies/show/${company_id}`);
            yield put(actions.success_step_two(response.data.data));
        } catch (e) {
            yield put(actions.failure_step_two(errorMessageGenerator(e)));
        }
    },
    *colapse_step_two() {
        const provisinalPayload = { page: 1 };
        yield put(actions.colapse_modal());
        yield put(companyActions.request(provisinalPayload));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(STEP_ONE_REQUEST, sagas.request_step_one);
    yield takeLatest(STEP_TWO_REQUEST, sagas.request_step_two);
    yield takeLatest(COLAPSE_STEP_TWO, sagas.colapse_step_two);
};
