import _navigation from './navigation/navigation';
import _settingsNavigation from './navigation/settingsNavigation';
import _companySettingsNavigation from './navigation/companySettingsNavigation';
import _uworkerSettingsNavigation from './navigation/uworkerSettingsNavigation';
import _companyEditSettingsNavigation from './navigation/companyEditAccessNavigation';
import _departmentSettingsNavigation from './navigation/departmentSettingsNavigation';
import mapStyles from './mapStyles';

// Google Map
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_MARKERCLUSTER_SCRIPT =
    'https://cdnjs.cloudflare.com/ajax/libs/js-marker-clusterer/1.0.0/markerclusterer.js';
export const GOOGLE_MAPS_SCRIPT = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

// Export imports
export const navigation = _navigation;
export const settingsNavigation = _settingsNavigation;
export const companySettingsNavigation = _companySettingsNavigation;
export const uworkerSettingsNavigation = _uworkerSettingsNavigation;
export const companyEditAccessNavigation = _companyEditSettingsNavigation;
export const departmentSettingsNavigation = _departmentSettingsNavigation;
export const MAP_STYLES = mapStyles;

// Constants
export const API_URL = process.env.REACT_APP_API_URL;
export const ADMIN_SERVICE_URL = process.env.REACT_APP_ADMIN_SERVICE_URL;
export const NAVBAR_SIDEBAR_BREAKPOINT = 992;
export const NAVBAR_SIDEBAR_CLOSED_BREAKPOINT = 1230;
export const MOBILE_SCREEN_WIDTH = 768;
export const TIMEZONE = 'Europe/Oslo';
export const LOCALE = 'nb-NO';

// Regular Expresions
export const PHONE_REG_EXP = /^[+\d]?(?:[\d-.\s()]*)$/;
export const ZIP_CODE_REG_EXP = /^\d{4}$/;

// Public uworker profile
export const PUBLIC_PROFILE_URL = process.env.REACT_APP_PUBLIC_WORKER_PROFILE_URL;
