import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */

// ORDER Types
const REQUEST = '@app/orders/order/REQUEST';
const SUCCESS = '@app/orders/order/SUCCESS';
const SET_LOADING = '@app/orders/order/SET_LOADING';
const FAILURE = '@app/orders/order/FAILURE';
const CLEAR = '@app/orders/order/CLEAR';

// APPLY/REVOKE_TO_ORDER Types
const APPLY_REQUEST = '@app/orders/order/APPLY_REQUEST';
const REVOKE_REQUEST = '@app/orders/order/REVOKE_REQUEST';
const APPLICATION_PENDING = '@app/orders/order/APPLICATION_PENDING';

/**
 * Initial state
 */
const initState = {
    loading: true,
    item: {},
    notifiedWorkers: [],
    applicationPending: false,
    favoriteWorkers: [],
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.loading = true;
                draft.item = {};
                draft.notifiedWorkers = [];
                draft.applicationPending = false;
                draft.favoriteWorkers = [];
                draft.errorStatus = '';
                break;
            case SUCCESS:
                draft.item = payload.data;
                draft.notifiedWorkers = payload.data.notified_workers;
                draft.favoriteWorkers = payload.data.favourite_workers;
                draft.loading = false;
                break;
            case SET_LOADING:
                draft.loading = payload;
                draft.errorStatus = '';
                break;
            case APPLICATION_PENDING:
                draft.applicationPending = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),

    // apply actions
    apply: (data) => creator(APPLY_REQUEST, data),
    revoke: (data) => creator(REVOKE_REQUEST, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            yield put(creator(CLEAR));
            const orderResponse = yield axios.get(`/orders/${payload}`);
            yield put(actions.success(orderResponse.data));
        } catch (e) {
            console.error(e);
            yield put(actions.failure('Failed to fetch data, please contact support!'));
        }
        yield put(creator(SET_LOADING, false));
    },

    *applyRequest({ payload }) {
        try {
            yield put(creator(CLEAR));
            yield put(creator(APPLICATION_PENDING, true));
            yield axios.post(`/orders/notify/${payload}`);
            yield put(actions.request(payload));
        } catch (e) {
            console.error(e);
            yield put(actions.failure('Failed to fetch data, please contact support!'));
        }
    },

    *revokeRequest({ payload }) {
        try {
            yield put(creator(CLEAR));
            yield put(creator(APPLICATION_PENDING, true));
            yield axios.post(`/orders/order-requests/revoke/${payload}`);
            yield put(actions.request(payload));
        } catch (e) {
            console.error(e);
            yield put(actions.failure('Failed to fetch data, please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(APPLY_REQUEST, sagas.applyRequest);
    yield takeLatest(REVOKE_REQUEST, sagas.revokeRequest);
};
