import produce from 'immer';
// import { takeEvery, put } from '@redux-saga/core/effects';
// import { push } from 'react-router-redux';

/**
 * Import Utils
 */
import { creator as createAction } from 'uwork-app-core';

/**
 * Import Config
 */
import {
    navigation as navItems,
    settingsNavigation as settingsNavItems,
    uworkerSettingsNavigation as uworkerSettingsNavItems,
    companyEditAccessNavigation as companyEditAccessNavItems,
    departmentSettingsNavigation as departmentSettingsNavItems
} from 'config';

// action types
const SET_FIELD = 'app/ui/SET_FIELD';
const TOGGLE_MOBILE_VIEW = 'app/ui/TOGGLE_MOBILE_VIEW_REQUEST';
const CHANGE_NAVIGATION_TO_MAIN = 'app/ui/CHANGE_NAVIGATION_TO_MAIN';
const CHANGE_NAVIGATION_TO_SETTINGS = 'app/ui/CHANGE_NAVIGATION_TO_SETTINGS';
const CHANGE_NAVIGATION_TO_UWORKER_SETTINGS = 'app/ui/CHANGE_NAVIGATION_TO_UWORKER_SETTINGS';
const CHANGE_NAVIGATION_TO_COMPANY_EDIT_SETTINGS =
    'app/ui/CHANGE_NAVIGATION_TO_COMPANY_EDIT_SETTINGS';
const CHANGE_NAVIGATION_TO_DEPARTMENT_SETTINGS = 'app/ui/CHANGE_NAVIGATION_TO_DEPARTMENT_SETTINGS';

// initial reducer state
const initState = {
    breadcrumbs: [],
    headerTitle: '',
    mobileView: false,
    active: 'main',
    navItems
};

// Reducer
const reducer = (state = initState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_FIELD:
                draft[action.payload.name] = action.payload.value;
                break;
            case TOGGLE_MOBILE_VIEW:
                draft.mobileView = action.payload || !state.mobileView;
                break;
            case CHANGE_NAVIGATION_TO_MAIN:
                if (draft.active !== 'main') {
                    draft.active = 'main';
                    draft.navItems = navItems;
                }
                break;
            case CHANGE_NAVIGATION_TO_SETTINGS:
                if (draft.active !== 'settings') {
                    draft.active = 'settings';
                    draft.navItems = settingsNavItems;
                }
                break;
            case CHANGE_NAVIGATION_TO_UWORKER_SETTINGS:
                if (draft.active !== 'usettings') {
                    draft.active = 'usettings';
                    draft.navItems = navRoutesIdMapper(action.payload, uworkerSettingsNavItems);
                }
                break;
            case CHANGE_NAVIGATION_TO_COMPANY_EDIT_SETTINGS:
                if (draft.active !== 'csettings') {
                    draft.active = 'csettings';
                    draft.navItems = navRoutesIdMapper(action.payload, companyEditAccessNavItems);
                }
                break;
            case CHANGE_NAVIGATION_TO_DEPARTMENT_SETTINGS:
                if (draft.active !== 'dsettings') {
                    draft.active = 'dsettings';
                    draft.navItems = navRoutesIdMapper(action.payload, departmentSettingsNavItems);
                }
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    setField: (payload) => createAction(SET_FIELD, payload),
    toggleMobileView: (payload) => createAction(TOGGLE_MOBILE_VIEW, payload),
    changeNavigationToMain: () => createAction(CHANGE_NAVIGATION_TO_MAIN),
    changeNavigationToSettings: () => createAction(CHANGE_NAVIGATION_TO_SETTINGS),
    changeNavigationToUworkerSettings: (data) =>
        createAction(CHANGE_NAVIGATION_TO_UWORKER_SETTINGS, data),
    changeNavigationToCompanyEditSettings: (data) =>
        createAction(CHANGE_NAVIGATION_TO_COMPANY_EDIT_SETTINGS, data),
    changeNavigationToDepartmentSettings: (data) =>
        createAction(CHANGE_NAVIGATION_TO_DEPARTMENT_SETTINGS, data)
};

/**
 * Utils
 */
function navRoutesIdMapper({ id, t }, navRoutes) {
    // accepts id param from reducer generates nav routes with ids
    return {
        ...navRoutes,
        en: (t) => navRoutes.en(t).map((route) => ({ ...route, to: `${route.to}/${id}` }))
    };
}
