import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * External Actions and Consts
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';
import { RESET, SUCCESS, FAILURE } from 'sagas/app/workers/advancedSettingsSaga';

/**
 * Constants
 */
const POST_REQUEST =
    '@app/workers/workersprofile/accountSettings/advanced/agreementAndPrecentage/update/REQUEST';

/**
 * Initial state
 */
const initState = {
    loading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case POST_REQUEST:
                draft.loading = true;
                break;
            case FAILURE:
                draft.loading = false;
                break;
            case SUCCESS:
                draft.loading = false;
                break;
            case RESET:
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(POST_REQUEST, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { worker_id } = payload;
            yield axios.post(`/workers/agreement/${worker_id} `, payload);
            yield put(creator(SUCCESS, 'admin.advancedWorkerSettings.agreementsSuccessMsg'));
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(
                creator(FAILURE, {
                    key: 'admin.advancedWorkerSettings.agreementTitle',
                    message: errorMessageGenerator(e)
                })
            );
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(POST_REQUEST, sagas.request);
};
