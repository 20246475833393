import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { push } from 'react-router-redux';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/workers/renotifyWorkersSaga/REQUEST';
export const SUCCESS = '@app/workers/renotifyWorkersSaga/SUCCESS';
export const FAILURE = '@app/workers/renotifyWorkersSaga/FAILURE';

export const RENOTIFY_REQUEST = '@app/workers/renotifyWorkersSaga/RENOTIFY_REQUEST';
export const RENOTIFY_SUCCESS = '@app/workers/renotifyWorkersSaga/RENOTIFY_SUCCESS';
export const RENOTIFY_FAILURE = '@app/workers/renotifyWorkersSaga/RENOTIFY_FAILURE';
export const CLEAR = '@app/workers/renotifyWorkersSaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: [],
    loading: false,
    notifyLoading: false,
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case RENOTIFY_SUCCESS:
                draft.notifyLoading = false;
                draft.errorStatus = '';
                break;
            case SUCCESS:
                draft.items = payload.items;
                draft.loading = false;
                break;
            case RENOTIFY_FAILURE:
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                draft.notifyLoading = false;
                break;
            case RENOTIFY_REQUEST:
                draft.notifyLoading = true;
                draft.errorStatus = '';
                break;
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case CLEAR:
                draft.items = [];
                draft.notifyLoading = false;
                draft.loading = false;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),

    renotify_request: (data) => creator(RENOTIFY_REQUEST, data),
    renotify_success: () => creator(RENOTIFY_SUCCESS),
    renotify_failure: (data) => creator(RENOTIFY_FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const searchParam = new URLSearchParams(payload.search);
            if (searchParam.has('query')) {
                let _query = searchParam.get('query');
                if (_query) {
                    _query = _query.replace('+', '%20');
                    searchParam.set('query', _query);
                } else {
                    searchParam.delete('query');
                }
            }

            const queryString = searchParam.toString().replace('+', '%20');
            let responseId = {};
            responseId = yield axios.get(`/workers/fetch-worker-ids?${queryString}`);
            yield put(actions.success({ items: responseId.data }));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },
    *renotify_request({ payload }) {
        try {
            yield axios.post(`/orders/renotify-workers/${payload.order_id}`, {
                workers: payload.workers
            });
            yield put(actions.renotify_success());
            yield put(actions.clear());
            yield put(push(`/orders/view/${payload.order_id}`));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.renotify_failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(RENOTIFY_REQUEST, sagas.renotify_request);
};
