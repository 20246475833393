import produce from 'immer';
import { put, takeLatest, delay } from 'redux-saga/effects';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
const VIDEO_REQUEST = '@app/workers/workersprofile/profileUploads/VIDEO_REQUEST';
const VIDEO_SUCCESS = '@app/workers/workersprofile/profileUploads/VIDEO_SUCCESS';

const DESCRIPTION_REQUEST =
    '@app/workers/workersprofile/profileUploads/description/DESCRIPTION_REQUEST';
const DESCRIPTION_SUCCESS =
    '@app/workers/workersprofile/profileUploads/description/DESCRIPTION_SUCCESS';

const PHOTO_REQUEST = '@app/workers/workersprofile/profileUploads/PHOTO_REQUEST';
const PHOTO_SUCCESS = '@app/workers/workersprofile/profileUploads/PHOTO_SUCCESS';

const PHOTO_DELETE_REQUEST = '@app/workers/workersprofile/profileUploads/PHOTO_DELETE_REQUEST';
const PHOTO_DELETE_SUCCESS = '@app/workers/workersprofile/profileUploads/PHOTO_DELETE_SUCCESS';

const VIDEO_DELETE_REQUEST = '@app/workers/workersprofile/profileUploads/VIDEO_DELETE_REQUEST';
const VIDEO_DELETE_SUCCESS = '@app/workers/workersprofile/profileUploads/VIDEO_DELETE_SUCCESS';

const FAILURE = '@app/workers/workersprofile/profileUploads/FAILURE';

/**
 * Initial state
 */
const initState = {
    currentVideo: '',
    currentPhoto: '',
    loading: false,
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case VIDEO_REQUEST:
            case PHOTO_REQUEST:
            case PHOTO_DELETE_REQUEST:
            case VIDEO_DELETE_REQUEST:
            case DESCRIPTION_REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case PHOTO_DELETE_SUCCESS:
            case VIDEO_DELETE_SUCCESS:
                draft.loading = false;
                break;
            case VIDEO_SUCCESS:
                draft.loading = false;
                draft.currentVideo = payload;
                break;
            case PHOTO_SUCCESS:
                draft.loading = false;
                draft.currentPhoto = payload;
                break;
            case DESCRIPTION_SUCCESS:
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    video_request: (data) => creator(VIDEO_REQUEST, data),
    photo_request: (data) => creator(PHOTO_REQUEST, data),
    description_request: (data) => creator(DESCRIPTION_REQUEST, data),
    video_delete_request: (data) => creator(VIDEO_DELETE_REQUEST, data),
    photo_delete_request: (data) => creator(PHOTO_DELETE_REQUEST, data),

    video_success: (data) => creator(VIDEO_SUCCESS, data),
    photo_success: (data) => creator(PHOTO_SUCCESS, data),
    description_success: (data) => creator(DESCRIPTION_SUCCESS, data),
    video_delete_success: (data) => creator(VIDEO_DELETE_SUCCESS, data),
    photo_delete_success: (data) => creator(PHOTO_DELETE_SUCCESS, data),

    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *video_request({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('video', payload.file, payload.file.name);

            const { user_id, worker_id } = payload;
            const response = yield axios.post(`/workers/video/${user_id}`, data, settings);

            yield put(actions.video_success(response.data));

            //delay before refetching for low networks
            yield delay(300);
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *photo_request({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('avatar', payload.file, payload.file.name);

            const { user_id, worker_id } = payload;
            const response = yield axios.post(`/workers/avatar/${user_id}`, data, settings);

            yield put(actions.photo_success(response.data));

            //delay before refetching for low networks
            yield delay(300);
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *description_request({ payload }) {
        try {
            const { worker_id } = payload;
            const response = yield axios.post(`/workers/description/${worker_id}`, payload);

            yield put(actions.description_success(response.data));

            //delay before refetching for low networks
            yield delay(300);
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *video_delete_request({ payload }) {
        try {
            const { user_id, worker_id } = payload;
            yield axios.delete(`/workers/video/${user_id}`);

            yield put(actions.video_delete_success());
            yield delay(300);
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *photo_delete_request({ payload }) {
        try {
            const { user_id, worker_id } = payload;
            yield axios.delete(`/workers/avatar/${user_id}`);

            yield put(actions.photo_delete_success());
            yield delay(300);
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(VIDEO_REQUEST, sagas.video_request);
    yield takeLatest(PHOTO_REQUEST, sagas.photo_request);
    yield takeLatest(DESCRIPTION_REQUEST, sagas.description_request);
    yield takeLatest(PHOTO_DELETE_REQUEST, sagas.photo_delete_request);
    yield takeLatest(VIDEO_DELETE_REQUEST, sagas.video_delete_request);
};
