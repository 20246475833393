import produce from 'immer';
import { put, takeLatest, select } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Ations
 */
import { actions as timesheetActions } from 'sagas/app/timesheet/timesheetSaga';

/**
 * Constants
 */
export const REQUEST = '@app/timesheet/ordershiftsaga/REQUEST';
export const INIT_REQUEST = '@app/timesheet/ordershiftsaga/INIT_REQUEST';
export const SUCCESS = '@app/timesheet/ordershiftsaga/SUCCESS';
export const FAILURE = '@app/timesheet/ordershiftsaga/FAILURE';
export const SET_LOADING = '@app/timesheet/ordershiftsaga/SET_LOADING';
export const SET_ITEMS = '@@app/timesheet/ordershiftsaga/SET_ITEMS';
export const CLEAR = '@app/timesheet/ordershiftsaga/CLEAR';
export const SET_CLOSE_MODAL = '@app/timesheet/ordershiftsaga/SET_CLOSE_MODAL';
export const SET_FORM_VALUES = '@app/timesheet/ordershiftsaga/SET_FORM_VALUES';
export const TRIGGER_MODAL = '@app/timesheet/ordershiftsaga/TRIGGER_MODAL';

/**
 * Init Form Data
 */
const initFormData = {
    from: new Date().toISOString(),
    to: new Date().toISOString(),
    description: ''
};

/**
 * Initial state
 */
const initState = {
    loading: false,
    closeModal: false,
    modal: false,
    errorStatus: '',
    formValues: initFormData
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.closeModal = true;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case SET_CLOSE_MODAL:
                draft.closeModal = payload;
                break;
            case SET_FORM_VALUES:
                draft.formValues = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                break;
            case TRIGGER_MODAL:
                draft.modal = payload.modal;
                draft.formValues = payload.data || initFormData;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */
export const selectors = {
    getTimesheetItems: (state) => state.app.timesheet.timesheet.items
};

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),
    setCloseModal: (data) => creator(SET_CLOSE_MODAL, data),
    init: (data) => creator(INIT_REQUEST, data),
    trigger_modal: (data) => creator(TRIGGER_MODAL, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));

        try {
            const response = yield axios.put(`/orders/order-shifts/${payload.id}`, {
                from: payload.from,
                to: payload.to,
                description: payload.description
            });
            yield put(actions.success(response.data));
            yield put(timesheetActions.initLastRequest());
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    },

    *init({ payload }) {
        if (!payload) {
            return;
        }

        yield put(actions.clear());

        // get timesheet items from stor
        const items = yield select(selectors.getTimesheetItems);

        // find item by id
        const item = items.find((_item) => _item.id === payload);

        yield put(
            creator(SET_FORM_VALUES, {
                from: item.from,
                to: item.to,
                description: ''
            })
        );
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(INIT_REQUEST, sagas.init);
};
