import { API_URL } from 'config';

const downloadFile = (_url, ignoreBaseUrl = false) => {
    let baseUrl = API_URL;
    if (ignoreBaseUrl) {
        baseUrl = '';
    }
    const url = `${baseUrl}${_url}`;

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = url;
    link.dispatchEvent(new MouseEvent('click'));
};
export default downloadFile;
