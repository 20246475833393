import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekDay from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/nb';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
dayjs.extend(weekDay);
dayjs.extend(isoWeek);
dayjs.extend(weekYear);
dayjs.extend(updateLocale);
dayjs.extend(duration);

// Set Default timezone
dayjs.tz.setDefault('Europe/Oslo');
dayjs.updateLocale('nb', {
    weekStart: 1
});
dayjs.locale('nb');
