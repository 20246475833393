import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/companies/companiespagesaga/REQUEST';
export const SUCCESS = '@app/companies/companiespagesaga/SUCCESS';
export const FAILURE = '@app/companies/companiespagesaga/FAILURE';
export const CLEAR = '@app/companies/companiespagesaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: [],
    meta: {
        current_page: 1,
        last_page: 1,
        total: 0
    }
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case CLEAR:
                draft.items = [];
                draft.meta = {
                    current_page: 1,
                    last_page: 1
                };
                break;
            case SUCCESS:
                draft.items = payload.data;
                draft.meta = payload.meta;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield put(creator(CLEAR));
            const searchParam = new URLSearchParams(payload);
            if (searchParam.has('query')) {
                let _query = searchParam.get('query');
                if (_query) {
                    _query = _query.replace('+', '%20');
                    searchParam.set('query', _query);
                } else {
                    searchParam.delete('query');
                }
            }
            const queryString = searchParam.toString();
            const response = yield axios.get(`/companies?${queryString}`);
            yield put(actions.success(response.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
