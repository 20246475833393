import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const GET_REQUEST = '@app/administration/guidelines/GET_REQUEST';
export const GET_SUCCESS = '@app/administration/guidelines/GET_SUCCESS';
export const GET_FAILURE = '@app/administration/guidelines/GET_FAILURE';
export const POST_REQUEST = '@app/administration/guidelines/POST_REQUEST';
export const POST_SUCCESS = '@app/administration/guidelines/POST_SUCCESS';
export const POST_FAILURE = '@app/administration/guidelines/POST_FAILURE';
export const DELETE_REQUEST = '@app/administration/guidelines/DELETE_REQUEST';
export const DELETE_SUCCESS = '@app/administration/guidelines/DELETE_SUCCESS';
export const DELETE_FAILURE = '@app/administration/guidelines/DELETE_FAILURE';
export const TOGGLE_MODAL = '@app/administration/guidelines/TOGGLE_MODAL';
export const TOGGLE_MODAL_SUCCESS = '@app/administration/guidelines/TOGGLE_MODAL_SUCCESS';
export const CLEAR = '@app/administration/guidelines/CLEAR';

/**
 * Initial State
 */
const initialState = {
    loading: true,
    guidelines: [],
    isModalOpen: false,
    submitting: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_FAILURE:
                draft.getError = payload;
                break;
            case CLEAR:
                draft.guidelines = [];
                break;
            case GET_SUCCESS:
                draft.guidelines = payload;
                draft.loading = false;
                break;
            case POST_REQUEST:
                draft.submitting = true;
                break;
            case POST_SUCCESS:
                draft.isModalOpen = false;
                draft.submitting = false;
                break;
            case POST_FAILURE:
                draft.addError = payload;
                draft.submitting = false;
                break;
            case DELETE_REQUEST:
                draft.loading = true;
                break;
            case DELETE_SUCCESS:
                draft.loading = false;
                draft.guidelines = draft.guidelines.filter((el) => el.id !== payload);
                break;
            case DELETE_FAILURE:
                draft.deleteError = payload;
                draft.loading = false;
                break;
            case TOGGLE_MODAL_SUCCESS:
                draft.isModalOpen = payload;
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    get_request: () => creator(GET_REQUEST),
    get_success: (data) => creator(GET_SUCCESS, data),
    get_failure: (data) => creator(GET_FAILURE, data),
    post_request: (data) => creator(POST_REQUEST, data),
    post_success: () => creator(POST_SUCCESS),
    post_failure: (data) => creator(POST_FAILURE, data),
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),
    toggle_modal: (data) => creator(TOGGLE_MODAL, data),
    toggle_modal_success: (data) => creator(TOGGLE_MODAL_SUCCESS, data),
    clear: () => creator(CLEAR)
};

export const sagas = {
    *get() {
        try {
            yield put(actions.clear());
            const response = yield axios.get('/companies/guidelines');
            yield put(actions.get_success(response.data.data));
        } catch (e) {
            yield put(actions.get_failure('Oops something went wrong'));
        }
    },

    *post({ payload: { file, companies } }) {
        const settings = { headers: { 'content-type': 'multipart/form-data' } };
        const data = new FormData();

        data.append('file', file, file.name);
        data.append('companies', companies);

        try {
            yield actions.post_request();
            yield axios.post('/companies/guidelines', data, settings);
            yield put(actions.post_success());
            yield put(actions.get_request());
        } catch (e) {
            yield put(actions.post_failure(e.response.data.message || 'Oops something went wrong'));
        }
    },

    *delete({ payload }) {
        try {
            yield axios.delete(`/companies/guidelines/${payload}`);
            yield put(actions.delete_success(payload));
        } catch (e) {
            yield put(
                actions.delete_failure(e.response.data.message || 'Oops something went wrong')
            );
        }
    },
    *toggleModal({ payload }) {
        try {
            yield put(actions.toggle_modal_success(payload));
        } catch (e) {
            console.error(e);
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get);
    yield takeLatest(POST_REQUEST, sagas.post);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
    yield takeLatest(TOGGLE_MODAL, sagas.toggleModal);
};
