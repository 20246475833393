import produce from 'immer';
import { put, takeLatest, delay, select } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import format from 'utils/customTimeFormat';

/**
 * External actions
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */

const GET_REQUEST = '@app/workers/workersprofile/interview/get/REQUEST';
const GET_FAILURE = '@app/workers/workersprofile/interview/get/FAILURE';
const GET_SUCCESS = '@app/workers/workersprofile/interview/get/SUCCESS';

const CREATE_REQUEST = '@app/workers/workersprofile/interview/post/REQUEST';
const CREATE_FAILURE = '@app/workers/workersprofile/interview/post/FAILURE';
const CREATE_SUCCESS = '@app/workers/workersprofile/interview/post/SUCCESS';

const EDIT_REQUEST = '@app/workers/workersprofile/interview/put/REQUEST';
const EDIT_FAILURE = '@app/workers/workersprofile/interview/put/FAILURE';
const EDIT_SUCCESS = '@app/workers/workersprofile/interview/put/SUCCESS';

const DELETE_REQUEST = '@app/workers/workersprofile/interview/delete/REQUEST';
const DELETE_FAILURE = '@app/workers/workersprofile/interview/delete/FAILURE';
const DELETE_SUCCESS = '@app/workers/workersprofile/interview/delete/SUCCESS';

const RESET = '@app/workers/workersprofile/interview/RESET';
const OPEN_MODAL = '@app/workers/workersprofile/interview/OPEN_MODAL';
const CLOSE_MODAL = '@app/workers/workersprofile/interview/CLOSE_MODAL';

/**
 * Initial state
 */
const today = new Date(new Date(new Date().setHours(9)).setMinutes(0));
const initModalState = {
    show: false,
    loading: false,
    error: '',
    description: '',
    date_set: today.toISOString(),
    date: today.toISOString(),
    date_to: new Date(today.setMinutes(5)).toISOString(),
    interviewId: null,
    mode: 0 // 0-create , 1-edit
};

const initViewState = {
    loading: false,
    error: '',
    data: []
};

const initState = {
    modal: initModalState,
    view: initViewState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            //get
            case GET_REQUEST:
                draft.view.loading = true;
                draft.view.error = '';
                draft.view.data = [];
                break;
            case GET_SUCCESS:
                draft.view.loading = false;
                draft.view.data = payload;
                break;
            case GET_FAILURE:
                draft.view.loading = false;
                draft.view.error = payload;
                break;

            //put post
            case CREATE_REQUEST:
            case EDIT_REQUEST:
                draft.modal.loading = true;
                draft.modal.error = '';
                break;
            case CREATE_FAILURE:
            case EDIT_FAILURE:
                draft.modal.loading = false;
                draft.modal.error = payload;
                break;
            case CREATE_SUCCESS:
            case EDIT_SUCCESS:
                draft.modal = initModalState;
                break;

            //delete
            case DELETE_REQUEST:
                draft.view.loading = payload.note_id;
                draft.view.error = '';
                break;
            case DELETE_SUCCESS:
                draft.view.loading = false;
                break;
            case DELETE_FAILURE:
                draft.view.loading = false;
                draft.view.error = payload;
                break;

            //modal
            case OPEN_MODAL:
                draft.modal.loading = false;
                draft.modal.error = '';
                draft.modal.show = true;
                draft.modal.description = payload?.description || '';
                draft.modal.mapping = payload?.mapping || '';
                draft.modal.short_summary = payload?.short_summary || '';
                draft.modal.mode = payload?.mode || 0;
                draft.modal.interviewId = payload?.id || null;
                draft.modal.date = payload?.date || initModalState.date;
                draft.modal.date_set = payload?.date || initModalState.date;
                draft.modal.date_to = payload?.date_to || initModalState.date_to;
                break;
            case CLOSE_MODAL:
                draft.modal = initModalState;
                break;

            // reset to initial
            case RESET:
                draft.view = initViewState;
                draft.modal = initModalState;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    get: (data) => creator(GET_REQUEST, data),
    getSuccess: (data) => creator(GET_SUCCESS, data),
    getFailure: (data) => creator(GET_FAILURE, data),

    create: (data) => creator(CREATE_REQUEST, data),
    createSuccess: (data) => creator(CREATE_SUCCESS, data),
    createFailure: (data) => creator(CREATE_FAILURE, data),

    update: (data) => creator(EDIT_REQUEST, data),
    updateSuccess: (data) => creator(EDIT_SUCCESS, data),
    updateFailure: (data) => creator(EDIT_FAILURE, data),

    delete: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    deleteFailure: (data) => creator(DELETE_FAILURE, data),

    reset: () => creator(RESET),
    openModal: (data) => creator(OPEN_MODAL, data),
    closeModal: () => creator(CLOSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *getWorkerInterviews({ payload }) {
        try {
            const response = yield axios.get(`/workers/interviews/${payload}`);
            yield put(actions.getSuccess(response.data.data));
        } catch (e) {
            yield put(actions.getFailure(errorMessageGenerator(e)));
        }
    },

    *createInterview({ payload }) {
        try {
            const { worker_id, date, date_to } = payload;
            const data = {
                ...payload,
                date: format(new Date(date), 'YYYY-MM-DD HH:mm:ss'),
                date_to: format(new Date(date_to), 'YYYY-MM-DD HH:mm:ss')
            };
            yield axios.post(`/workers/interviews`, data);
            yield delay(500);
            yield put(actions.createSuccess());
            const interview = yield select(
                (state) => state.app.workers.editAccess.interviews.view.data
            );
            if (interview.length === 0) {
                yield put(profileActions.request(worker_id));
            }
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.createFailure(errorMessageGenerator(e)));
        }
    },

    *editInterview({ payload }) {
        try {
            const { worker_id, date, date_to, interview_id } = payload;
            const data = {
                ...payload,
                date: format(new Date(date), 'YYYY-MM-DD HH:mm:ss'),
                date_to: format(new Date(date_to), 'YYYY-MM-DD HH:mm:ss')
            };
            yield axios.put(`/workers/interviews/${interview_id}`, data);
            yield delay(500);
            yield put(actions.updateSuccess());
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.updateFailure(errorMessageGenerator(e)));
        }
    },

    *deleteInterview({ payload }) {
        try {
            const { worker_id, interview_id } = payload;
            yield axios.delete(`/workers/interviews/${interview_id}`);
            yield delay(500);
            yield put(actions.deleteSuccess());
            const interview = yield select(
                (state) => state.app.workers.editAccess.interviews.view.data
            );
            if (interview.length === 1) {
                yield put(profileActions.request(worker_id));
            }
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.deleteFailure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.getWorkerInterviews);
    yield takeLatest(CREATE_REQUEST, sagas.createInterview);
    yield takeLatest(EDIT_REQUEST, sagas.editInterview);
    yield takeLatest(DELETE_REQUEST, sagas.deleteInterview);
};
