import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */
import { actions as newTemporaryRecommendationActions } from 'sagas/app/recommendations/newTemporaryRecommendationSaga';
import { actions as alertActions } from 'sagas/app/alertSaga';
/**
 * Constants
 */
export const REQUEST = '@app/workers/recommendUWorker/recommendUWorker/REQUEST';
export const SUCCESS = '@app/workers/recommendUWorker/recommendUWorker/SUCCESS';
export const FAILURE = '@app/workers/recommendUWorker/recommendUWorker/FAILURE';
export const CLEAR = '@app/workers/recommendUWorker/recommendUWorker/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: '',
    success: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.success = false;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.success = true;
                break;
            case FAILURE:
                draft.loading = false;
                draft.success = false;
                draft.error = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.success = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.post('/workers/worker-recommendations', {
                company_users: payload.company_users,
                companies: payload.companies,
                workers: payload.workers
            });
            yield put(actions.success(response.data));
            yield put(newTemporaryRecommendationActions.clear());
            yield put(push('/recommendations?page=1&requested_date=desc'));
            yield put(alertActions.success({ message: payload.message }));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
