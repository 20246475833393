const zipCodes = [
    {
        zipCode: '1001',
        city: 'Oslo'
    },
    {
        zipCode: '1003',
        city: 'Oslo'
    },
    {
        zipCode: '1005',
        city: 'Oslo'
    },
    {
        zipCode: '1006',
        city: 'Oslo'
    },
    {
        zipCode: '1007',
        city: 'Oslo'
    },
    {
        zipCode: '1008',
        city: 'Oslo'
    },
    {
        zipCode: '1009',
        city: 'Oslo'
    },
    {
        zipCode: '1011',
        city: 'Oslo'
    },
    {
        zipCode: '1051',
        city: 'Oslo'
    },
    {
        zipCode: '1052',
        city: 'Oslo'
    },
    {
        zipCode: '1053',
        city: 'Oslo'
    },
    {
        zipCode: '1054',
        city: 'Oslo'
    },
    {
        zipCode: '1055',
        city: 'Oslo'
    },
    {
        zipCode: '1056',
        city: 'Oslo'
    },
    {
        zipCode: '1061',
        city: 'Oslo'
    },
    {
        zipCode: '1062',
        city: 'Oslo'
    },
    {
        zipCode: '1063',
        city: 'Oslo'
    },
    {
        zipCode: '1064',
        city: 'Oslo'
    },
    {
        zipCode: '1065',
        city: 'Oslo'
    },
    {
        zipCode: '1067',
        city: 'Oslo'
    },
    {
        zipCode: '1068',
        city: 'Oslo'
    },
    {
        zipCode: '1069',
        city: 'Oslo'
    },
    {
        zipCode: '1071',
        city: 'Oslo'
    },
    {
        zipCode: '1081',
        city: 'Oslo'
    },
    {
        zipCode: '1083',
        city: 'Oslo'
    },
    {
        zipCode: '1084',
        city: 'Oslo'
    },
    {
        zipCode: '1086',
        city: 'Oslo'
    },
    {
        zipCode: '1087',
        city: 'Oslo'
    },
    {
        zipCode: '1088',
        city: 'Oslo'
    },
    {
        zipCode: '1089',
        city: 'Oslo'
    },
    {
        zipCode: '1101',
        city: 'Oslo'
    },
    {
        zipCode: '1102',
        city: 'Oslo'
    },
    {
        zipCode: '1108',
        city: 'Oslo'
    },
    {
        zipCode: '1109',
        city: 'Oslo'
    },
    {
        zipCode: '1112',
        city: 'Oslo'
    },
    {
        zipCode: '1150',
        city: 'Oslo'
    },
    {
        zipCode: '1151',
        city: 'Oslo'
    },
    {
        zipCode: '1152',
        city: 'Oslo'
    },
    {
        zipCode: '1153',
        city: 'Oslo'
    },
    {
        zipCode: '1154',
        city: 'Oslo'
    },
    {
        zipCode: '1155',
        city: 'Oslo'
    },
    {
        zipCode: '1156',
        city: 'Oslo'
    },
    {
        zipCode: '1157',
        city: 'Oslo'
    },
    {
        zipCode: '1158',
        city: 'Oslo'
    },
    {
        zipCode: '1160',
        city: 'Oslo'
    },
    {
        zipCode: '1161',
        city: 'Oslo'
    },
    {
        zipCode: '1162',
        city: 'Oslo'
    },
    {
        zipCode: '1163',
        city: 'Oslo'
    },
    {
        zipCode: '1164',
        city: 'Oslo'
    },
    {
        zipCode: '1165',
        city: 'Oslo'
    },
    {
        zipCode: '1166',
        city: 'Oslo'
    },
    {
        zipCode: '1167',
        city: 'Oslo'
    },
    {
        zipCode: '1168',
        city: 'Oslo'
    },
    {
        zipCode: '1169',
        city: 'Oslo'
    },
    {
        zipCode: '1170',
        city: 'Oslo'
    },
    {
        zipCode: '1172',
        city: 'Oslo'
    },
    {
        zipCode: '1176',
        city: 'Oslo'
    },
    {
        zipCode: '1177',
        city: 'Oslo'
    },
    {
        zipCode: '1178',
        city: 'Oslo'
    },
    {
        zipCode: '1179',
        city: 'Oslo'
    },
    {
        zipCode: '1181',
        city: 'Oslo'
    },
    {
        zipCode: '1182',
        city: 'Oslo'
    },
    {
        zipCode: '1184',
        city: 'Oslo'
    },
    {
        zipCode: '1185',
        city: 'Oslo'
    },
    {
        zipCode: '1187',
        city: 'Oslo'
    },
    {
        zipCode: '1188',
        city: 'Oslo'
    },
    {
        zipCode: '1189',
        city: 'Oslo'
    },
    {
        zipCode: '1201',
        city: 'Oslo'
    },
    {
        zipCode: '1203',
        city: 'Oslo'
    },
    {
        zipCode: '1204',
        city: 'Oslo'
    },
    {
        zipCode: '1205',
        city: 'Oslo'
    },
    {
        zipCode: '1207',
        city: 'Oslo'
    },
    {
        zipCode: '1214',
        city: 'Oslo'
    },
    {
        zipCode: '1215',
        city: 'Oslo'
    },
    {
        zipCode: '1250',
        city: 'Oslo'
    },
    {
        zipCode: '1251',
        city: 'Oslo'
    },
    {
        zipCode: '1252',
        city: 'Oslo'
    },
    {
        zipCode: '1253',
        city: 'Oslo'
    },
    {
        zipCode: '1254',
        city: 'Oslo'
    },
    {
        zipCode: '1255',
        city: 'Oslo'
    },
    {
        zipCode: '1256',
        city: 'Oslo'
    },
    {
        zipCode: '1257',
        city: 'Oslo'
    },
    {
        zipCode: '1258',
        city: 'Oslo'
    },
    {
        zipCode: '1259',
        city: 'Oslo'
    },
    {
        zipCode: '1262',
        city: 'Oslo'
    },
    {
        zipCode: '1263',
        city: 'Oslo'
    },
    {
        zipCode: '1266',
        city: 'Oslo'
    },
    {
        zipCode: '1270',
        city: 'Oslo'
    },
    {
        zipCode: '1271',
        city: 'Oslo'
    },
    {
        zipCode: '1272',
        city: 'Oslo'
    },
    {
        zipCode: '1273',
        city: 'Oslo'
    },
    {
        zipCode: '1274',
        city: 'Oslo'
    },
    {
        zipCode: '1275',
        city: 'Oslo'
    },
    {
        zipCode: '1278',
        city: 'Oslo'
    },
    {
        zipCode: '1279',
        city: 'Oslo'
    },
    {
        zipCode: '1281',
        city: 'Oslo'
    },
    {
        zipCode: '1283',
        city: 'Oslo'
    },
    {
        zipCode: '1284',
        city: 'Oslo'
    },
    {
        zipCode: '1285',
        city: 'Oslo'
    },
    {
        zipCode: '1286',
        city: 'Oslo'
    },
    {
        zipCode: '1290',
        city: 'Oslo'
    },
    {
        zipCode: '1291',
        city: 'Oslo'
    },
    {
        zipCode: '1294',
        city: 'Oslo'
    },
    {
        zipCode: '1295',
        city: 'Oslo'
    },
    {
        zipCode: '1300',
        city: 'Sandvika'
    },
    {
        zipCode: '1301',
        city: 'Sandvika'
    },
    {
        zipCode: '1302',
        city: 'Sandvika'
    },
    {
        zipCode: '1303',
        city: 'Sandvika'
    },
    {
        zipCode: '1304',
        city: 'Sandvika'
    },
    {
        zipCode: '1305',
        city: 'Haslum'
    },
    {
        zipCode: '1306',
        city: 'Sandvika'
    },
    {
        zipCode: '1307',
        city: 'Fornebu'
    },
    {
        zipCode: '1308',
        city: 'Jar'
    },
    {
        zipCode: '1309',
        city: 'Rud'
    },
    {
        zipCode: '1311',
        city: 'Høvikodden'
    },
    {
        zipCode: '1312',
        city: 'Slependen'
    },
    {
        zipCode: '1313',
        city: 'Vøyenenga'
    },
    {
        zipCode: '1314',
        city: 'Vøyenenga'
    },
    {
        zipCode: '1316',
        city: 'Eiksmarka'
    },
    {
        zipCode: '1317',
        city: 'Bærums verk'
    },
    {
        zipCode: '1318',
        city: 'Bekkestua'
    },
    {
        zipCode: '1319',
        city: 'Bekkestua'
    },
    {
        zipCode: '1321',
        city: 'Stabekk'
    },
    {
        zipCode: '1322',
        city: 'Høvik'
    },
    {
        zipCode: '1323',
        city: 'Høvik'
    },
    {
        zipCode: '1324',
        city: 'Lysaker'
    },
    {
        zipCode: '1325',
        city: 'Lysaker'
    },
    {
        zipCode: '1326',
        city: 'Lysaker'
    },
    {
        zipCode: '1327',
        city: 'Lysaker'
    },
    {
        zipCode: '1328',
        city: 'Høvik'
    },
    {
        zipCode: '1329',
        city: 'Lommedalen'
    },
    {
        zipCode: '1330',
        city: 'Fornebu'
    },
    {
        zipCode: '1331',
        city: 'Fornebu'
    },
    {
        zipCode: '1332',
        city: 'Østerås'
    },
    {
        zipCode: '1333',
        city: 'Kolsås'
    },
    {
        zipCode: '1334',
        city: 'Rykkinn'
    },
    {
        zipCode: '1335',
        city: 'Snarøya'
    },
    {
        zipCode: '1336',
        city: 'Sandvika'
    },
    {
        zipCode: '1337',
        city: 'Sandvika'
    },
    {
        zipCode: '1338',
        city: 'Sandvika'
    },
    {
        zipCode: '1339',
        city: 'Vøyenenga'
    },
    {
        zipCode: '1340',
        city: 'Skui'
    },
    {
        zipCode: '1341',
        city: 'Slependen'
    },
    {
        zipCode: '1342',
        city: 'Gjettum'
    },
    {
        zipCode: '1344',
        city: 'Haslum'
    },
    {
        zipCode: '1346',
        city: 'Gjettum'
    },
    {
        zipCode: '1348',
        city: 'Rykkinn'
    },
    {
        zipCode: '1349',
        city: 'Rykkinn'
    },
    {
        zipCode: '1350',
        city: 'Lommedalen'
    },
    {
        zipCode: '1351',
        city: 'Rud'
    },
    {
        zipCode: '1352',
        city: 'Kolsås'
    },
    {
        zipCode: '1353',
        city: 'Bærums verk'
    },
    {
        zipCode: '1354',
        city: 'Bærums verk'
    },
    {
        zipCode: '1356',
        city: 'Bekkestua'
    },
    {
        zipCode: '1357',
        city: 'Bekkestua'
    },
    {
        zipCode: '1358',
        city: 'Jar'
    },
    {
        zipCode: '1359',
        city: 'Eiksmarka'
    },
    {
        zipCode: '1360',
        city: 'Fornebu'
    },
    {
        zipCode: '1361',
        city: 'Østerås'
    },
    {
        zipCode: '1362',
        city: 'Hosle'
    },
    {
        zipCode: '1363',
        city: 'Høvik'
    },
    {
        zipCode: '1364',
        city: 'Fornebu'
    },
    {
        zipCode: '1365',
        city: 'Blommenholm'
    },
    {
        zipCode: '1366',
        city: 'Lysaker'
    },
    {
        zipCode: '1367',
        city: 'Snarøya'
    },
    {
        zipCode: '1368',
        city: 'Stabekk'
    },
    {
        zipCode: '1369',
        city: 'Stabekk'
    },
    {
        zipCode: '1371',
        city: 'Asker'
    },
    {
        zipCode: '1372',
        city: 'Asker'
    },
    {
        zipCode: '1373',
        city: 'Asker'
    },
    {
        zipCode: '1375',
        city: 'Billingstad'
    },
    {
        zipCode: '1376',
        city: 'Billingstad'
    },
    {
        zipCode: '1377',
        city: 'Billingstad'
    },
    {
        zipCode: '1378',
        city: 'Nesbru'
    },
    {
        zipCode: '1379',
        city: 'Nesbru'
    },
    {
        zipCode: '1380',
        city: 'Heggedal'
    },
    {
        zipCode: '1381',
        city: 'Vettre'
    },
    {
        zipCode: '1383',
        city: 'Asker'
    },
    {
        zipCode: '1384',
        city: 'Asker'
    },
    {
        zipCode: '1385',
        city: 'Asker'
    },
    {
        zipCode: '1386',
        city: 'Asker'
    },
    {
        zipCode: '1387',
        city: 'Asker'
    },
    {
        zipCode: '1388',
        city: 'Borgen'
    },
    {
        zipCode: '1389',
        city: 'Heggedal'
    },
    {
        zipCode: '1390',
        city: 'Vollen'
    },
    {
        zipCode: '1391',
        city: 'Vollen'
    },
    {
        zipCode: '1392',
        city: 'Vettre'
    },
    {
        zipCode: '1393',
        city: 'Vollen'
    },
    {
        zipCode: '1394',
        city: 'Nesbru'
    },
    {
        zipCode: '1395',
        city: 'Hvalstad'
    },
    {
        zipCode: '1396',
        city: 'Billingstad'
    },
    {
        zipCode: '1397',
        city: 'Nesøya'
    },
    {
        zipCode: '1399',
        city: 'Asker'
    },
    {
        zipCode: '1400',
        city: 'Ski'
    },
    {
        zipCode: '1401',
        city: 'Ski'
    },
    {
        zipCode: '1402',
        city: 'Ski'
    },
    {
        zipCode: '1403',
        city: 'Langhus'
    },
    {
        zipCode: '1404',
        city: 'Siggerud'
    },
    {
        zipCode: '1405',
        city: 'Langhus'
    },
    {
        zipCode: '1406',
        city: 'Ski'
    },
    {
        zipCode: '1407',
        city: 'Vinterbro'
    },
    {
        zipCode: '1408',
        city: 'Kråkstad'
    },
    {
        zipCode: '1409',
        city: 'Skotbu'
    },
    {
        zipCode: '1410',
        city: 'Kolbotn'
    },
    {
        zipCode: '1411',
        city: 'Kolbotn'
    },
    {
        zipCode: '1412',
        city: 'Sofiemyr'
    },
    {
        zipCode: '1413',
        city: 'Tårnåsen'
    },
    {
        zipCode: '1414',
        city: 'Trollåsen'
    },
    {
        zipCode: '1415',
        city: 'Oppegård'
    },
    {
        zipCode: '1416',
        city: 'Oppegård'
    },
    {
        zipCode: '1417',
        city: 'Sofiemyr'
    },
    {
        zipCode: '1418',
        city: 'Kolbotn'
    },
    {
        zipCode: '1419',
        city: 'Oppegård'
    },
    {
        zipCode: '1420',
        city: 'Svartskog'
    },
    {
        zipCode: '1421',
        city: 'Trollåsen'
    },
    {
        zipCode: '1422',
        city: 'Siggerud'
    },
    {
        zipCode: '1429',
        city: 'Vinterbro'
    },
    {
        zipCode: '1430',
        city: 'Ås'
    },
    {
        zipCode: '1431',
        city: 'Ås'
    },
    {
        zipCode: '1432',
        city: 'Ås'
    },
    {
        zipCode: '1433',
        city: 'Ås'
    },
    {
        zipCode: '1434',
        city: 'Ås'
    },
    {
        zipCode: '1435',
        city: 'Ås'
    },
    {
        zipCode: '1440',
        city: 'Drøbak'
    },
    {
        zipCode: '1441',
        city: 'Drøbak'
    },
    {
        zipCode: '1442',
        city: 'Drøbak'
    },
    {
        zipCode: '1443',
        city: 'Drøbak'
    },
    {
        zipCode: '1444',
        city: 'Drøbak'
    },
    {
        zipCode: '1445',
        city: 'Drøbak'
    },
    {
        zipCode: '1446',
        city: 'Drøbak'
    },
    {
        zipCode: '1447',
        city: 'Drøbak'
    },
    {
        zipCode: '1448',
        city: 'Drøbak'
    },
    {
        zipCode: '1449',
        city: 'Drøbak'
    },
    {
        zipCode: '1450',
        city: 'Nesoddtangen'
    },
    {
        zipCode: '1451',
        city: 'Nesoddtangen'
    },
    {
        zipCode: '1452',
        city: 'Nesoddtangen'
    },
    {
        zipCode: '1453',
        city: 'Bjørnemyr'
    },
    {
        zipCode: '1454',
        city: 'Fagerstrand'
    },
    {
        zipCode: '1455',
        city: 'Nordre frogn'
    },
    {
        zipCode: '1456',
        city: 'Nesoddtangen'
    },
    {
        zipCode: '1457',
        city: 'Fagerstrand'
    },
    {
        zipCode: '1458',
        city: 'Fjellstrand'
    },
    {
        zipCode: '1459',
        city: 'Nesodden'
    },
    {
        zipCode: '1465',
        city: 'Strømmen'
    },
    {
        zipCode: '1466',
        city: 'Strømmen'
    },
    {
        zipCode: '1467',
        city: 'Strømmen'
    },
    {
        zipCode: '1468',
        city: 'Finstadjordet'
    },
    {
        zipCode: '1469',
        city: 'Rasta'
    },
    {
        zipCode: '1470',
        city: 'Lørenskog'
    },
    {
        zipCode: '1471',
        city: 'Lørenskog'
    },
    {
        zipCode: '1472',
        city: 'Fjellhamar'
    },
    {
        zipCode: '1473',
        city: 'Lørenskog'
    },
    {
        zipCode: '1474',
        city: 'Lørenskog'
    },
    {
        zipCode: '1475',
        city: 'Finstadjordet'
    },
    {
        zipCode: '1476',
        city: 'Rasta'
    },
    {
        zipCode: '1477',
        city: 'Fjellhamar'
    },
    {
        zipCode: '1478',
        city: 'Lørenskog'
    },
    {
        zipCode: '1479',
        city: 'Kurland'
    },
    {
        zipCode: '1480',
        city: 'Slattum'
    },
    {
        zipCode: '1481',
        city: 'Hagan'
    },
    {
        zipCode: '1482',
        city: 'Nittedal'
    },
    {
        zipCode: '1483',
        city: 'Hagan'
    },
    {
        zipCode: '1484',
        city: 'Hakadal'
    },
    {
        zipCode: '1485',
        city: 'Hakadal'
    },
    {
        zipCode: '1486',
        city: 'Nittedal'
    },
    {
        zipCode: '1487',
        city: 'Hakadal'
    },
    {
        zipCode: '1488',
        city: 'Hakadal'
    },
    {
        zipCode: '1501',
        city: 'Moss'
    },
    {
        zipCode: '1502',
        city: 'Moss'
    },
    {
        zipCode: '1503',
        city: 'Moss'
    },
    {
        zipCode: '1504',
        city: 'Moss'
    },
    {
        zipCode: '1506',
        city: 'Moss'
    },
    {
        zipCode: '1508',
        city: 'Moss'
    },
    {
        zipCode: '1509',
        city: 'Moss'
    },
    {
        zipCode: '1510',
        city: 'Moss'
    },
    {
        zipCode: '1511',
        city: 'Moss'
    },
    {
        zipCode: '1512',
        city: 'Moss'
    },
    {
        zipCode: '1513',
        city: 'Moss'
    },
    {
        zipCode: '1514',
        city: 'Moss'
    },
    {
        zipCode: '1515',
        city: 'Moss'
    },
    {
        zipCode: '1516',
        city: 'Moss'
    },
    {
        zipCode: '1517',
        city: 'Moss'
    },
    {
        zipCode: '1518',
        city: 'Moss'
    },
    {
        zipCode: '1519',
        city: 'Moss'
    },
    {
        zipCode: '1520',
        city: 'Moss'
    },
    {
        zipCode: '1521',
        city: 'Moss'
    },
    {
        zipCode: '1522',
        city: 'Moss'
    },
    {
        zipCode: '1523',
        city: 'Moss'
    },
    {
        zipCode: '1524',
        city: 'Moss'
    },
    {
        zipCode: '1525',
        city: 'Moss'
    },
    {
        zipCode: '1526',
        city: 'Moss'
    },
    {
        zipCode: '1528',
        city: 'Moss'
    },
    {
        zipCode: '1529',
        city: 'Moss'
    },
    {
        zipCode: '1530',
        city: 'Moss'
    },
    {
        zipCode: '1531',
        city: 'Moss'
    },
    {
        zipCode: '1532',
        city: 'Moss'
    },
    {
        zipCode: '1533',
        city: 'Moss'
    },
    {
        zipCode: '1534',
        city: 'Moss'
    },
    {
        zipCode: '1535',
        city: 'Moss'
    },
    {
        zipCode: '1536',
        city: 'Moss'
    },
    {
        zipCode: '1537',
        city: 'Moss'
    },
    {
        zipCode: '1538',
        city: 'Moss'
    },
    {
        zipCode: '1539',
        city: 'Moss'
    },
    {
        zipCode: '1540',
        city: 'Vestby'
    },
    {
        zipCode: '1541',
        city: 'Vestby'
    },
    {
        zipCode: '1545',
        city: 'Hvitsten'
    },
    {
        zipCode: '1550',
        city: 'Hølen'
    },
    {
        zipCode: '1555',
        city: 'Son'
    },
    {
        zipCode: '1556',
        city: 'Son'
    },
    {
        zipCode: '1560',
        city: 'Larkollen'
    },
    {
        zipCode: '1561',
        city: 'Larkollen'
    },
    {
        zipCode: '1570',
        city: 'Dilling'
    },
    {
        zipCode: '1580',
        city: 'Rygge'
    },
    {
        zipCode: '1581',
        city: 'Rygge'
    },
    {
        zipCode: '1590',
        city: 'Rygge'
    },
    {
        zipCode: '1591',
        city: 'Sperrebotn'
    },
    {
        zipCode: '1592',
        city: 'Våler i østfold'
    },
    {
        zipCode: '1593',
        city: 'Svinndal'
    },
    {
        zipCode: '1594',
        city: 'Våler i østfold'
    },
    {
        zipCode: '1596',
        city: 'Moss'
    },
    {
        zipCode: '1597',
        city: 'Moss'
    },
    {
        zipCode: '1598',
        city: 'Moss'
    },
    {
        zipCode: '1599',
        city: 'Moss'
    },
    {
        zipCode: '1601',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1602',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1604',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1605',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1606',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1607',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1608',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1609',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1610',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1612',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1613',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1614',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1615',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1616',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1617',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1618',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1619',
        city: 'Fredrikstad'
    },
    {
        zipCode: '1620',
        city: 'Gressvik'
    },
    {
        zipCode: '1621',
        city: 'Gressvik'
    },
    {
        zipCode: '1622',
        city: 'Gressvik'
    },
    {
        zipCode: '1623',
        city: 'Gressvik'
    },
    {
        zipCode: '1624',
        city: 'Gressvik'
    },
    {
        zipCode: '1625',
        city: 'Manstad'
    },
    {
        zipCode: '1626',
        city: 'Manstad'
    },
    {
        zipCode: '1628',
        city: 'Engelsviken'
    },
    {
        zipCode: '1629',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1630',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1632',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1633',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1634',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1636',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1637',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1638',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1639',
        city: 'Gamle fredrikstad'
    },
    {
        zipCode: '1640',
        city: 'Råde'
    },
    {
        zipCode: '1641',
        city: 'Råde'
    },
    {
        zipCode: '1642',
        city: 'Saltnes'
    },
    {
        zipCode: '1650',
        city: 'Sellebakk'
    },
    {
        zipCode: '1651',
        city: 'Sellebakk'
    },
    {
        zipCode: '1653',
        city: 'Sellebakk'
    },
    {
        zipCode: '1654',
        city: 'Sellebakk'
    },
    {
        zipCode: '1655',
        city: 'Sellebakk'
    },
    {
        zipCode: '1657',
        city: 'Torp'
    },
    {
        zipCode: '1658',
        city: 'Torp'
    },
    {
        zipCode: '1659',
        city: 'Torp'
    },
    {
        zipCode: '1661',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1662',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1663',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1664',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1665',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1666',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1667',
        city: 'Rolvsøy'
    },
    {
        zipCode: '1670',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1671',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1672',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1673',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1675',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1676',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1678',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1679',
        city: 'Kråkerøy'
    },
    {
        zipCode: '1680',
        city: 'Skjærhalden'
    },
    {
        zipCode: '1682',
        city: 'Skjærhalden'
    },
    {
        zipCode: '1683',
        city: 'Vesterøy'
    },
    {
        zipCode: '1684',
        city: 'Vesterøy'
    },
    {
        zipCode: '1690',
        city: 'Herføl'
    },
    {
        zipCode: '1692',
        city: 'Nedgården'
    },
    {
        zipCode: '1701',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1702',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1703',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1704',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1705',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1706',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1707',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1708',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1709',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1710',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1711',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1712',
        city: 'Grålum'
    },
    {
        zipCode: '1713',
        city: 'Grålum'
    },
    {
        zipCode: '1714',
        city: 'Grålum'
    },
    {
        zipCode: '1715',
        city: 'Yven'
    },
    {
        zipCode: '1718',
        city: 'Greåker'
    },
    {
        zipCode: '1719',
        city: 'Greåker'
    },
    {
        zipCode: '1720',
        city: 'Greåker'
    },
    {
        zipCode: '1721',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1722',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1723',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1724',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1725',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1726',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1727',
        city: 'Sarpsborg'
    },
    {
        zipCode: '1730',
        city: 'Ise'
    },
    {
        zipCode: '1733',
        city: 'Hafslundsøy'
    },
    {
        zipCode: '1734',
        city: 'Hafslundsøy'
    },
    {
        zipCode: '1735',
        city: 'Varteig'
    },
    {
        zipCode: '1738',
        city: 'Borgenhaugen'
    },
    {
        zipCode: '1739',
        city: 'Borgenhaugen'
    },
    {
        zipCode: '1740',
        city: 'Borgenhaugen'
    },
    {
        zipCode: '1742',
        city: 'Klavestadhaugen'
    },
    {
        zipCode: '1743',
        city: 'Klavestadhaugen'
    },
    {
        zipCode: '1745',
        city: 'Skjeberg'
    },
    {
        zipCode: '1746',
        city: 'Skjeberg'
    },
    {
        zipCode: '1747',
        city: 'Skjeberg'
    },
    {
        zipCode: '1751',
        city: 'Halden'
    },
    {
        zipCode: '1752',
        city: 'Halden'
    },
    {
        zipCode: '1753',
        city: 'Halden'
    },
    {
        zipCode: '1754',
        city: 'Halden'
    },
    {
        zipCode: '1757',
        city: 'Halden'
    },
    {
        zipCode: '1759',
        city: 'Halden'
    },
    {
        zipCode: '1760',
        city: 'Halden'
    },
    {
        zipCode: '1761',
        city: 'Halden'
    },
    {
        zipCode: '1762',
        city: 'Halden'
    },
    {
        zipCode: '1763',
        city: 'Halden'
    },
    {
        zipCode: '1764',
        city: 'Halden'
    },
    {
        zipCode: '1765',
        city: 'Halden'
    },
    {
        zipCode: '1766',
        city: 'Halden'
    },
    {
        zipCode: '1767',
        city: 'Halden'
    },
    {
        zipCode: '1768',
        city: 'Halden'
    },
    {
        zipCode: '1769',
        city: 'Halden'
    },
    {
        zipCode: '1771',
        city: 'Halden'
    },
    {
        zipCode: '1772',
        city: 'Halden'
    },
    {
        zipCode: '1776',
        city: 'Halden'
    },
    {
        zipCode: '1777',
        city: 'Halden'
    },
    {
        zipCode: '1778',
        city: 'Halden'
    },
    {
        zipCode: '1779',
        city: 'Halden'
    },
    {
        zipCode: '1781',
        city: 'Halden'
    },
    {
        zipCode: '1782',
        city: 'Halden'
    },
    {
        zipCode: '1783',
        city: 'Halden'
    },
    {
        zipCode: '1784',
        city: 'Halden'
    },
    {
        zipCode: '1785',
        city: 'Halden'
    },
    {
        zipCode: '1786',
        city: 'Halden'
    },
    {
        zipCode: '1787',
        city: 'Halden'
    },
    {
        zipCode: '1788',
        city: 'Halden'
    },
    {
        zipCode: '1789',
        city: 'Berg i østfold'
    },
    {
        zipCode: '1790',
        city: 'Tistedal'
    },
    {
        zipCode: '1791',
        city: 'Tistedal'
    },
    {
        zipCode: '1792',
        city: 'Tistedal'
    },
    {
        zipCode: '1793',
        city: 'Tistedal'
    },
    {
        zipCode: '1794',
        city: 'Sponvika'
    },
    {
        zipCode: '1796',
        city: 'Kornsjø'
    },
    {
        zipCode: '1798',
        city: 'Aremark'
    },
    {
        zipCode: '1799',
        city: 'Aremark'
    },
    {
        zipCode: '1801',
        city: 'Askim'
    },
    {
        zipCode: '1802',
        city: 'Askim'
    },
    {
        zipCode: '1803',
        city: 'Askim'
    },
    {
        zipCode: '1804',
        city: 'Spydeberg'
    },
    {
        zipCode: '1805',
        city: 'Tomter'
    },
    {
        zipCode: '1806',
        city: 'Skiptvet'
    },
    {
        zipCode: '1807',
        city: 'Askim'
    },
    {
        zipCode: '1808',
        city: 'Askim'
    },
    {
        zipCode: '1809',
        city: 'Askim'
    },
    {
        zipCode: '1811',
        city: 'Askim'
    },
    {
        zipCode: '1812',
        city: 'Askim'
    },
    {
        zipCode: '1813',
        city: 'Askim'
    },
    {
        zipCode: '1814',
        city: 'Askim'
    },
    {
        zipCode: '1815',
        city: 'Askim'
    },
    {
        zipCode: '1816',
        city: 'Skiptvet'
    },
    {
        zipCode: '1820',
        city: 'Spydeberg'
    },
    {
        zipCode: '1821',
        city: 'Spydeberg'
    },
    {
        zipCode: '1823',
        city: 'Knapstad'
    },
    {
        zipCode: '1825',
        city: 'Tomter'
    },
    {
        zipCode: '1827',
        city: 'Hobøl'
    },
    {
        zipCode: '1830',
        city: 'Askim'
    },
    {
        zipCode: '1831',
        city: 'Askim'
    },
    {
        zipCode: '1832',
        city: 'Askim'
    },
    {
        zipCode: '1833',
        city: 'Askim'
    },
    {
        zipCode: '1850',
        city: 'Mysen'
    },
    {
        zipCode: '1851',
        city: 'Mysen'
    },
    {
        zipCode: '1852',
        city: 'Mysen'
    },
    {
        zipCode: '1859',
        city: 'Slitu'
    },
    {
        zipCode: '1860',
        city: 'Trøgstad'
    },
    {
        zipCode: '1861',
        city: 'Trøgstad'
    },
    {
        zipCode: '1866',
        city: 'Båstad'
    },
    {
        zipCode: '1867',
        city: 'Båstad'
    },
    {
        zipCode: '1870',
        city: 'Ørje'
    },
    {
        zipCode: '1871',
        city: 'Ørje'
    },
    {
        zipCode: '1875',
        city: 'Otteid'
    },
    {
        zipCode: '1878',
        city: 'Hærland'
    },
    {
        zipCode: '1880',
        city: 'Eidsberg'
    },
    {
        zipCode: '1890',
        city: 'Rakkestad'
    },
    {
        zipCode: '1891',
        city: 'Rakkestad'
    },
    {
        zipCode: '1892',
        city: 'Degernes'
    },
    {
        zipCode: '1893',
        city: 'Degernes'
    },
    {
        zipCode: '1894',
        city: 'Rakkestad'
    },
    {
        zipCode: '1900',
        city: 'Fetsund'
    },
    {
        zipCode: '1901',
        city: 'Fetsund'
    },
    {
        zipCode: '1903',
        city: 'Gan'
    },
    {
        zipCode: '1910',
        city: 'Enebakkneset'
    },
    {
        zipCode: '1911',
        city: 'Flateby'
    },
    {
        zipCode: '1912',
        city: 'Enebakk'
    },
    {
        zipCode: '1914',
        city: 'Ytre enebakk'
    },
    {
        zipCode: '1916',
        city: 'Flateby'
    },
    {
        zipCode: '1917',
        city: 'Ytre enebakk'
    },
    {
        zipCode: '1920',
        city: 'Sørumsand'
    },
    {
        zipCode: '1921',
        city: 'Sørumsand'
    },
    {
        zipCode: '1923',
        city: 'Sørum'
    },
    {
        zipCode: '1924',
        city: 'Sørum'
    },
    {
        zipCode: '1925',
        city: 'Blaker'
    },
    {
        zipCode: '1926',
        city: 'Blaker'
    },
    {
        zipCode: '1927',
        city: 'Rånåsfoss'
    },
    {
        zipCode: '1928',
        city: 'Auli'
    },
    {
        zipCode: '1929',
        city: 'Auli'
    },
    {
        zipCode: '1930',
        city: 'Aurskog'
    },
    {
        zipCode: '1931',
        city: 'Aurskog'
    },
    {
        zipCode: '1940',
        city: 'Bjørkelangen'
    },
    {
        zipCode: '1941',
        city: 'Bjørkelangen'
    },
    {
        zipCode: '1950',
        city: 'Rømskog'
    },
    {
        zipCode: '1954',
        city: 'Setskog'
    },
    {
        zipCode: '1960',
        city: 'Løken'
    },
    {
        zipCode: '1961',
        city: 'Løken'
    },
    {
        zipCode: '1963',
        city: 'Fosser'
    },
    {
        zipCode: '1970',
        city: 'Hemnes'
    },
    {
        zipCode: '1971',
        city: 'Hemnes'
    },
    {
        zipCode: '2000',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2001',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2003',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2004',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2005',
        city: 'Rælingen'
    },
    {
        zipCode: '2006',
        city: 'Løvenstad'
    },
    {
        zipCode: '2007',
        city: 'Kjeller'
    },
    {
        zipCode: '2008',
        city: 'Fjerdingby'
    },
    {
        zipCode: '2009',
        city: 'Nordby'
    },
    {
        zipCode: '2010',
        city: 'Strømmen'
    },
    {
        zipCode: '2011',
        city: 'Strømmen'
    },
    {
        zipCode: '2012',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2013',
        city: 'Skjetten'
    },
    {
        zipCode: '2014',
        city: 'Blystadlia'
    },
    {
        zipCode: '2015',
        city: 'Leirsund'
    },
    {
        zipCode: '2016',
        city: 'Frogner'
    },
    {
        zipCode: '2017',
        city: 'Frogner'
    },
    {
        zipCode: '2018',
        city: 'Løvenstad'
    },
    {
        zipCode: '2019',
        city: 'Skedsmokorset'
    },
    {
        zipCode: '2020',
        city: 'Skedsmokorset'
    },
    {
        zipCode: '2021',
        city: 'Skedsmokorset'
    },
    {
        zipCode: '2022',
        city: 'Gjerdrum'
    },
    {
        zipCode: '2023',
        city: 'Skedsmokorset'
    },
    {
        zipCode: '2024',
        city: 'Gjerdrum'
    },
    {
        zipCode: '2025',
        city: 'Fjerdingby'
    },
    {
        zipCode: '2026',
        city: 'Skjetten'
    },
    {
        zipCode: '2027',
        city: 'Kjeller'
    },
    {
        zipCode: '2028',
        city: 'Lillestrøm'
    },
    {
        zipCode: '2029',
        city: 'Rælingen'
    },
    {
        zipCode: '2030',
        city: 'Nannestad'
    },
    {
        zipCode: '2031',
        city: 'Nannestad'
    },
    {
        zipCode: '2032',
        city: 'Maura'
    },
    {
        zipCode: '2033',
        city: 'Åsgreina'
    },
    {
        zipCode: '2034',
        city: 'Holter'
    },
    {
        zipCode: '2035',
        city: 'Holter'
    },
    {
        zipCode: '2036',
        city: 'Maura'
    },
    {
        zipCode: '2040',
        city: 'Kløfta'
    },
    {
        zipCode: '2041',
        city: 'Kløfta'
    },
    {
        zipCode: '2050',
        city: 'Jessheim'
    },
    {
        zipCode: '2051',
        city: 'Jessheim'
    },
    {
        zipCode: '2052',
        city: 'Jessheim'
    },
    {
        zipCode: '2053',
        city: 'Jessheim'
    },
    {
        zipCode: '2054',
        city: 'Mogreina'
    },
    {
        zipCode: '2055',
        city: 'Nordkisa'
    },
    {
        zipCode: '2056',
        city: 'Algarheim'
    },
    {
        zipCode: '2057',
        city: 'Jessheim'
    },
    {
        zipCode: '2058',
        city: 'Sessvollmoen'
    },
    {
        zipCode: '2060',
        city: 'Gardermoen'
    },
    {
        zipCode: '2061',
        city: 'Gardermoen'
    },
    {
        zipCode: '2062',
        city: 'Jessheim'
    },
    {
        zipCode: '2063',
        city: 'Jessheim'
    },
    {
        zipCode: '2066',
        city: 'Jessheim'
    },
    {
        zipCode: '2067',
        city: 'Jessheim'
    },
    {
        zipCode: '2068',
        city: 'Jessheim'
    },
    {
        zipCode: '2069',
        city: 'Jessheim'
    },
    {
        zipCode: '2070',
        city: 'Råholt'
    },
    {
        zipCode: '2071',
        city: 'Råholt'
    },
    {
        zipCode: '2072',
        city: 'Dal'
    },
    {
        zipCode: '2073',
        city: 'Bøn'
    },
    {
        zipCode: '2074',
        city: 'Eidsvoll verk'
    },
    {
        zipCode: '2076',
        city: 'Dal'
    },
    {
        zipCode: '2080',
        city: 'Eidsvoll'
    },
    {
        zipCode: '2081',
        city: 'Eidsvoll'
    },
    {
        zipCode: '2090',
        city: 'Hurdal'
    },
    {
        zipCode: '2091',
        city: 'Hurdal'
    },
    {
        zipCode: '2092',
        city: 'Minnesund'
    },
    {
        zipCode: '2093',
        city: 'Feiring'
    },
    {
        zipCode: '2094',
        city: 'Minnesund'
    },
    {
        zipCode: '2100',
        city: 'Skarnes'
    },
    {
        zipCode: '2101',
        city: 'Skarnes'
    },
    {
        zipCode: '2110',
        city: 'Slåstad'
    },
    {
        zipCode: '2114',
        city: 'Disenå'
    },
    {
        zipCode: '2116',
        city: 'Sander'
    },
    {
        zipCode: '2120',
        city: 'Sagstua'
    },
    {
        zipCode: '2121',
        city: 'Sagstua'
    },
    {
        zipCode: '2123',
        city: 'Bruvoll'
    },
    {
        zipCode: '2130',
        city: 'Knapper'
    },
    {
        zipCode: '2132',
        city: 'Gardvik'
    },
    {
        zipCode: '2133',
        city: 'Gardvik'
    },
    {
        zipCode: '2134',
        city: 'Austvatn'
    },
    {
        zipCode: '2150',
        city: 'Årnes'
    },
    {
        zipCode: '2151',
        city: 'Årnes'
    },
    {
        zipCode: '2160',
        city: 'Vormsund'
    },
    {
        zipCode: '2161',
        city: 'Vormsund'
    },
    {
        zipCode: '2162',
        city: 'Brårud'
    },
    {
        zipCode: '2163',
        city: 'Skogbygda'
    },
    {
        zipCode: '2164',
        city: 'Skogbygda'
    },
    {
        zipCode: '2165',
        city: 'Hvam'
    },
    {
        zipCode: '2166',
        city: 'Oppaker'
    },
    {
        zipCode: '2167',
        city: 'Hvam'
    },
    {
        zipCode: '2170',
        city: 'Fenstad'
    },
    {
        zipCode: '2201',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2202',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2203',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2204',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2205',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2206',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2207',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2208',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2209',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2210',
        city: 'Granli'
    },
    {
        zipCode: '2211',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2212',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2213',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2214',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2215',
        city: 'Roverud'
    },
    {
        zipCode: '2216',
        city: 'Roverud'
    },
    {
        zipCode: '2217',
        city: 'Hokkåsen'
    },
    {
        zipCode: '2218',
        city: 'Lundersæter'
    },
    {
        zipCode: '2219',
        city: 'Brandval'
    },
    {
        zipCode: '2220',
        city: 'Åbogen'
    },
    {
        zipCode: '2223',
        city: 'Galterud'
    },
    {
        zipCode: '2224',
        city: 'Austmarka'
    },
    {
        zipCode: '2225',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2226',
        city: 'Kongsvinger'
    },
    {
        zipCode: '2227',
        city: 'Austmarka'
    },
    {
        zipCode: '2230',
        city: 'Skotterud'
    },
    {
        zipCode: '2231',
        city: 'Skotterud'
    },
    {
        zipCode: '2232',
        city: 'Tobøl'
    },
    {
        zipCode: '2233',
        city: 'Vestmarka'
    },
    {
        zipCode: '2235',
        city: 'Matrand'
    },
    {
        zipCode: '2240',
        city: 'Magnor'
    },
    {
        zipCode: '2241',
        city: 'Magnor'
    },
    {
        zipCode: '2251',
        city: 'Grue finnskog'
    },
    {
        zipCode: '2256',
        city: 'Grue finnskog'
    },
    {
        zipCode: '2260',
        city: 'Kirkenær'
    },
    {
        zipCode: '2261',
        city: 'Kirkenær'
    },
    {
        zipCode: '2264',
        city: 'Grinder'
    },
    {
        zipCode: '2265',
        city: 'Namnå'
    },
    {
        zipCode: '2266',
        city: 'Arneberg'
    },
    {
        zipCode: '2270',
        city: 'Flisa'
    },
    {
        zipCode: '2271',
        city: 'Flisa'
    },
    {
        zipCode: '2280',
        city: 'Gjesåsen'
    },
    {
        zipCode: '2283',
        city: 'Åsnes finnskog'
    },
    {
        zipCode: '2301',
        city: 'Hamar'
    },
    {
        zipCode: '2302',
        city: 'Hamar'
    },
    {
        zipCode: '2303',
        city: 'Hamar'
    },
    {
        zipCode: '2304',
        city: 'Hamar'
    },
    {
        zipCode: '2305',
        city: 'Hamar'
    },
    {
        zipCode: '2306',
        city: 'Hamar'
    },
    {
        zipCode: '2307',
        city: 'Hamar'
    },
    {
        zipCode: '2308',
        city: 'Hamar'
    },
    {
        zipCode: '2309',
        city: 'Hamar'
    },
    {
        zipCode: '2311',
        city: 'Hamar'
    },
    {
        zipCode: '2312',
        city: 'Ottestad'
    },
    {
        zipCode: '2313',
        city: 'Ottestad'
    },
    {
        zipCode: '2314',
        city: 'Ottestad'
    },
    {
        zipCode: '2315',
        city: 'Hamar'
    },
    {
        zipCode: '2316',
        city: 'Hamar'
    },
    {
        zipCode: '2317',
        city: 'Hamar'
    },
    {
        zipCode: '2318',
        city: 'Hamar'
    },
    {
        zipCode: '2319',
        city: 'Hamar'
    },
    {
        zipCode: '2320',
        city: 'Furnes'
    },
    {
        zipCode: '2321',
        city: 'Hamar'
    },
    {
        zipCode: '2322',
        city: 'Ridabu'
    },
    {
        zipCode: '2323',
        city: 'Ingeberg'
    },
    {
        zipCode: '2324',
        city: 'Vang på hedmarken'
    },
    {
        zipCode: '2325',
        city: 'Hamar'
    },
    {
        zipCode: '2326',
        city: 'Hamar'
    },
    {
        zipCode: '2327',
        city: 'Furnes'
    },
    {
        zipCode: '2328',
        city: 'Ridabu'
    },
    {
        zipCode: '2329',
        city: 'Vang på hedmarken'
    },
    {
        zipCode: '2330',
        city: 'Vallset'
    },
    {
        zipCode: '2331',
        city: 'Vallset'
    },
    {
        zipCode: '2332',
        city: 'Åsvang'
    },
    {
        zipCode: '2333',
        city: 'Romedal'
    },
    {
        zipCode: '2334',
        city: 'Romedal'
    },
    {
        zipCode: '2335',
        city: 'Stange'
    },
    {
        zipCode: '2336',
        city: 'Stange'
    },
    {
        zipCode: '2337',
        city: 'Tangen'
    },
    {
        zipCode: '2338',
        city: 'Espa'
    },
    {
        zipCode: '2339',
        city: 'Tangen'
    },
    {
        zipCode: '2340',
        city: 'Løten'
    },
    {
        zipCode: '2341',
        city: 'Løten'
    },
    {
        zipCode: '2344',
        city: 'Ilseng'
    },
    {
        zipCode: '2345',
        city: 'Ådalsbruk'
    },
    {
        zipCode: '2346',
        city: 'Ilseng'
    },
    {
        zipCode: '2350',
        city: 'Nes på hedmarken'
    },
    {
        zipCode: '2351',
        city: 'Nes på hedmarken'
    },
    {
        zipCode: '2353',
        city: 'Stavsjø'
    },
    {
        zipCode: '2355',
        city: 'Gaupen'
    },
    {
        zipCode: '2360',
        city: 'Rudshøgda'
    },
    {
        zipCode: '2361',
        city: 'Rudshøgda'
    },
    {
        zipCode: '2364',
        city: 'Næroset'
    },
    {
        zipCode: '2365',
        city: 'Åsmarka'
    },
    {
        zipCode: '2372',
        city: 'Brøttum'
    },
    {
        zipCode: '2373',
        city: 'Brøttum'
    },
    {
        zipCode: '2380',
        city: 'Brumunddal'
    },
    {
        zipCode: '2381',
        city: 'Brumunddal'
    },
    {
        zipCode: '2382',
        city: 'Brumunddal'
    },
    {
        zipCode: '2383',
        city: 'Brumunddal'
    },
    {
        zipCode: '2384',
        city: 'Brumunddal'
    },
    {
        zipCode: '2385',
        city: 'Brumunddal'
    },
    {
        zipCode: '2386',
        city: 'Brumunddal'
    },
    {
        zipCode: '2387',
        city: 'Brumunddal'
    },
    {
        zipCode: '2388',
        city: 'Brumunddal'
    },
    {
        zipCode: '2389',
        city: 'Brumunddal'
    },
    {
        zipCode: '2390',
        city: 'Moelv'
    },
    {
        zipCode: '2391',
        city: 'Moelv'
    },
    {
        zipCode: '2401',
        city: 'Elverum'
    },
    {
        zipCode: '2402',
        city: 'Elverum'
    },
    {
        zipCode: '2403',
        city: 'Elverum'
    },
    {
        zipCode: '2404',
        city: 'Elverum'
    },
    {
        zipCode: '2405',
        city: 'Elverum'
    },
    {
        zipCode: '2406',
        city: 'Elverum'
    },
    {
        zipCode: '2407',
        city: 'Elverum'
    },
    {
        zipCode: '2408',
        city: 'Elverum'
    },
    {
        zipCode: '2409',
        city: 'Elverum'
    },
    {
        zipCode: '2410',
        city: 'Hernes'
    },
    {
        zipCode: '2411',
        city: 'Elverum'
    },
    {
        zipCode: '2412',
        city: 'Sørskogbygda'
    },
    {
        zipCode: '2413',
        city: 'Elverum'
    },
    {
        zipCode: '2414',
        city: 'Elverum'
    },
    {
        zipCode: '2415',
        city: 'Heradsbygd'
    },
    {
        zipCode: '2416',
        city: 'Jømna'
    },
    {
        zipCode: '2417',
        city: 'Elverum'
    },
    {
        zipCode: '2418',
        city: 'Elverum'
    },
    {
        zipCode: '2419',
        city: 'Elverum'
    },
    {
        zipCode: '2420',
        city: 'Trysil'
    },
    {
        zipCode: '2421',
        city: 'Trysil'
    },
    {
        zipCode: '2422',
        city: 'Nybergsund'
    },
    {
        zipCode: '2423',
        city: 'Østby'
    },
    {
        zipCode: '2424',
        city: 'Østby'
    },
    {
        zipCode: '2425',
        city: 'Ljørdalen'
    },
    {
        zipCode: '2426',
        city: 'Ljørdalen'
    },
    {
        zipCode: '2427',
        city: 'Plassen'
    },
    {
        zipCode: '2428',
        city: 'Søre osen'
    },
    {
        zipCode: '2429',
        city: 'Tørberget'
    },
    {
        zipCode: '2430',
        city: 'Jordet'
    },
    {
        zipCode: '2432',
        city: 'Slettås'
    },
    {
        zipCode: '2434',
        city: 'Braskereidfoss'
    },
    {
        zipCode: '2435',
        city: 'Braskereidfoss'
    },
    {
        zipCode: '2436',
        city: 'Våler i solør'
    },
    {
        zipCode: '2437',
        city: 'Haslemoen'
    },
    {
        zipCode: '2438',
        city: 'Gravberget'
    },
    {
        zipCode: '2439',
        city: 'Våler i solør'
    },
    {
        zipCode: '2440',
        city: 'Engerdal'
    },
    {
        zipCode: '2441',
        city: 'Engerdal'
    },
    {
        zipCode: '2442',
        city: 'Heradsbygd'
    },
    {
        zipCode: '2443',
        city: 'Drevsjø'
    },
    {
        zipCode: '2444',
        city: 'Drevsjø'
    },
    {
        zipCode: '2446',
        city: 'Elgå'
    },
    {
        zipCode: '2447',
        city: 'Søre osen'
    },
    {
        zipCode: '2448',
        city: 'Sømådalen'
    },
    {
        zipCode: '2450',
        city: 'Rena'
    },
    {
        zipCode: '2451',
        city: 'Rena'
    },
    {
        zipCode: '2460',
        city: 'Osen'
    },
    {
        zipCode: '2461',
        city: 'Osen'
    },
    {
        zipCode: '2476',
        city: 'Atna'
    },
    {
        zipCode: '2477',
        city: 'Sollia'
    },
    {
        zipCode: '2478',
        city: 'Hanestad'
    },
    {
        zipCode: '2480',
        city: 'Koppang'
    },
    {
        zipCode: '2481',
        city: 'Koppang'
    },
    {
        zipCode: '2484',
        city: 'Rendalen'
    },
    {
        zipCode: '2485',
        city: 'Rendalen'
    },
    {
        zipCode: '2486',
        city: 'Rendalen'
    },
    {
        zipCode: '2487',
        city: 'Rendalen'
    },
    {
        zipCode: '2488',
        city: 'Rendalen'
    },
    {
        zipCode: '2500',
        city: 'Tynset'
    },
    {
        zipCode: '2501',
        city: 'Tynset'
    },
    {
        zipCode: '2510',
        city: 'Tylldalen'
    },
    {
        zipCode: '2512',
        city: 'Kvikne'
    },
    {
        zipCode: '2513',
        city: 'Kvikne'
    },
    {
        zipCode: '2540',
        city: 'Tolga'
    },
    {
        zipCode: '2541',
        city: 'Tolga'
    },
    {
        zipCode: '2542',
        city: 'Vingelen'
    },
    {
        zipCode: '2544',
        city: 'Øversjødalen'
    },
    {
        zipCode: '2550',
        city: 'Os i østerdalen'
    },
    {
        zipCode: '2551',
        city: 'Os i østerdalen'
    },
    {
        zipCode: '2552',
        city: 'Dalsbygda'
    },
    {
        zipCode: '2555',
        city: 'Tufsingdalen'
    },
    {
        zipCode: '2560',
        city: 'Alvdal'
    },
    {
        zipCode: '2561',
        city: 'Alvdal'
    },
    {
        zipCode: '2580',
        city: 'Folldal'
    },
    {
        zipCode: '2581',
        city: 'Folldal'
    },
    {
        zipCode: '2582',
        city: 'Grimsbu'
    },
    {
        zipCode: '2584',
        city: 'Dalholen'
    },
    {
        zipCode: '2601',
        city: 'Lillehammer'
    },
    {
        zipCode: '2602',
        city: 'Lillehammer'
    },
    {
        zipCode: '2603',
        city: 'Lillehammer'
    },
    {
        zipCode: '2604',
        city: 'Lillehammer'
    },
    {
        zipCode: '2605',
        city: 'Lillehammer'
    },
    {
        zipCode: '2606',
        city: 'Lillehammer'
    },
    {
        zipCode: '2607',
        city: 'Vingrom'
    },
    {
        zipCode: '2608',
        city: 'Lillehammer'
    },
    {
        zipCode: '2609',
        city: 'Lillehammer'
    },
    {
        zipCode: '2610',
        city: 'Mesnali'
    },
    {
        zipCode: '2611',
        city: 'Lillehammer'
    },
    {
        zipCode: '2612',
        city: 'Sjusjøen'
    },
    {
        zipCode: '2613',
        city: 'Lillehammer'
    },
    {
        zipCode: '2614',
        city: 'Lillehammer'
    },
    {
        zipCode: '2615',
        city: 'Lillehammer'
    },
    {
        zipCode: '2616',
        city: 'Lismarka'
    },
    {
        zipCode: '2617',
        city: 'Lillehammer'
    },
    {
        zipCode: '2618',
        city: 'Lillehammer'
    },
    {
        zipCode: '2619',
        city: 'Lillehammer'
    },
    {
        zipCode: '2620',
        city: 'Mesnali'
    },
    {
        zipCode: '2621',
        city: 'Vingrom'
    },
    {
        zipCode: '2622',
        city: 'Lillehammer'
    },
    {
        zipCode: '2623',
        city: 'Lillehammer'
    },
    {
        zipCode: '2624',
        city: 'Lillehammer'
    },
    {
        zipCode: '2625',
        city: 'Fåberg'
    },
    {
        zipCode: '2626',
        city: 'Lillehammer'
    },
    {
        zipCode: '2627',
        city: 'Fåberg'
    },
    {
        zipCode: '2628',
        city: 'Sjusjøen'
    },
    {
        zipCode: '2629',
        city: 'Lillehammer'
    },
    {
        zipCode: '2630',
        city: 'Ringebu'
    },
    {
        zipCode: '2631',
        city: 'Ringebu'
    },
    {
        zipCode: '2632',
        city: 'Venabygd'
    },
    {
        zipCode: '2633',
        city: 'Fåvang'
    },
    {
        zipCode: '2634',
        city: 'Fåvang'
    },
    {
        zipCode: '2635',
        city: 'Tretten'
    },
    {
        zipCode: '2636',
        city: 'Øyer'
    },
    {
        zipCode: '2637',
        city: 'Øyer'
    },
    {
        zipCode: '2638',
        city: 'Tretten'
    },
    {
        zipCode: '2639',
        city: 'Vinstra'
    },
    {
        zipCode: '2640',
        city: 'Vinstra'
    },
    {
        zipCode: '2641',
        city: 'Kvam'
    },
    {
        zipCode: '2642',
        city: 'Kvam'
    },
    {
        zipCode: '2643',
        city: 'Skåbu'
    },
    {
        zipCode: '2644',
        city: 'Skåbu'
    },
    {
        zipCode: '2645',
        city: 'Sør-fron'
    },
    {
        zipCode: '2646',
        city: 'Gålå'
    },
    {
        zipCode: '2647',
        city: 'Sør-fron'
    },
    {
        zipCode: '2648',
        city: 'Sør-fron'
    },
    {
        zipCode: '2649',
        city: 'Østre gausdal'
    },
    {
        zipCode: '2651',
        city: 'Østre gausdal'
    },
    {
        zipCode: '2652',
        city: 'Svingvoll'
    },
    {
        zipCode: '2653',
        city: 'Vestre gausdal'
    },
    {
        zipCode: '2654',
        city: 'Vestre gausdal'
    },
    {
        zipCode: '2656',
        city: 'Follebu'
    },
    {
        zipCode: '2657',
        city: 'Svatsum'
    },
    {
        zipCode: '2658',
        city: 'Espedalen'
    },
    {
        zipCode: '2659',
        city: 'Dombås'
    },
    {
        zipCode: '2660',
        city: 'Dombås'
    },
    {
        zipCode: '2661',
        city: 'Hjerkinn'
    },
    {
        zipCode: '2662',
        city: 'Dovre'
    },
    {
        zipCode: '2663',
        city: 'Dovreskogen'
    },
    {
        zipCode: '2664',
        city: 'Dovre'
    },
    {
        zipCode: '2665',
        city: 'Lesja'
    },
    {
        zipCode: '2666',
        city: 'Lora'
    },
    {
        zipCode: '2667',
        city: 'Lesjaverk'
    },
    {
        zipCode: '2668',
        city: 'Lesjaskog'
    },
    {
        zipCode: '2669',
        city: 'Bjorli'
    },
    {
        zipCode: '2670',
        city: 'Otta'
    },
    {
        zipCode: '2671',
        city: 'Lesja'
    },
    {
        zipCode: '2672',
        city: 'Sel'
    },
    {
        zipCode: '2673',
        city: 'Høvringen'
    },
    {
        zipCode: '2674',
        city: 'Mysusæter'
    },
    {
        zipCode: '2675',
        city: 'Otta'
    },
    {
        zipCode: '2676',
        city: 'Heidal'
    },
    {
        zipCode: '2677',
        city: 'Nedre heidal'
    },
    {
        zipCode: '2678',
        city: 'Sel'
    },
    {
        zipCode: '2679',
        city: 'Heidal'
    },
    {
        zipCode: '2680',
        city: 'Vågå'
    },
    {
        zipCode: '2681',
        city: 'Lalm'
    },
    {
        zipCode: '2682',
        city: 'Lalm'
    },
    {
        zipCode: '2683',
        city: 'Tessanden'
    },
    {
        zipCode: '2684',
        city: 'Vågå'
    },
    {
        zipCode: '2685',
        city: 'Garmo'
    },
    {
        zipCode: '2686',
        city: 'Lom'
    },
    {
        zipCode: '2687',
        city: 'Bøverdalen'
    },
    {
        zipCode: '2688',
        city: 'Lom'
    },
    {
        zipCode: '2690',
        city: 'Skjåk'
    },
    {
        zipCode: '2693',
        city: 'Nordberg'
    },
    {
        zipCode: '2694',
        city: 'Skjåk'
    },
    {
        zipCode: '2695',
        city: 'Grotli'
    },
    {
        zipCode: '2711',
        city: 'Gran'
    },
    {
        zipCode: '2712',
        city: 'Brandbu'
    },
    {
        zipCode: '2713',
        city: 'Roa'
    },
    {
        zipCode: '2714',
        city: 'Jaren'
    },
    {
        zipCode: '2715',
        city: 'Lunner'
    },
    {
        zipCode: '2716',
        city: 'Harestua'
    },
    {
        zipCode: '2717',
        city: 'Grua'
    },
    {
        zipCode: '2718',
        city: 'Brandbu'
    },
    {
        zipCode: '2720',
        city: 'Grindvoll'
    },
    {
        zipCode: '2730',
        city: 'Lunner'
    },
    {
        zipCode: '2740',
        city: 'Roa'
    },
    {
        zipCode: '2742',
        city: 'Grua'
    },
    {
        zipCode: '2743',
        city: 'Harestua'
    },
    {
        zipCode: '2750',
        city: 'Gran'
    },
    {
        zipCode: '2760',
        city: 'Brandbu'
    },
    {
        zipCode: '2770',
        city: 'Jaren'
    },
    {
        zipCode: '2801',
        city: 'Gjøvik'
    },
    {
        zipCode: '2802',
        city: 'Gjøvik'
    },
    {
        zipCode: '2803',
        city: 'Gjøvik'
    },
    {
        zipCode: '2804',
        city: 'Gjøvik'
    },
    {
        zipCode: '2805',
        city: 'Gjøvik'
    },
    {
        zipCode: '2806',
        city: 'Gjøvik'
    },
    {
        zipCode: '2807',
        city: 'Hunndalen'
    },
    {
        zipCode: '2808',
        city: 'Gjøvik'
    },
    {
        zipCode: '2809',
        city: 'Gjøvik'
    },
    {
        zipCode: '2810',
        city: 'Gjøvik'
    },
    {
        zipCode: '2811',
        city: 'Hunndalen'
    },
    {
        zipCode: '2812',
        city: 'Gjøvik'
    },
    {
        zipCode: '2815',
        city: 'Gjøvik'
    },
    {
        zipCode: '2816',
        city: 'Gjøvik'
    },
    {
        zipCode: '2817',
        city: 'Gjøvik'
    },
    {
        zipCode: '2818',
        city: 'Gjøvik'
    },
    {
        zipCode: '2819',
        city: 'Gjøvik'
    },
    {
        zipCode: '2820',
        city: 'Nordre toten'
    },
    {
        zipCode: '2821',
        city: 'Gjøvik'
    },
    {
        zipCode: '2822',
        city: 'Bybrua'
    },
    {
        zipCode: '2825',
        city: 'Gjøvik'
    },
    {
        zipCode: '2827',
        city: 'Hunndalen'
    },
    {
        zipCode: '2830',
        city: 'Raufoss'
    },
    {
        zipCode: '2831',
        city: 'Raufoss'
    },
    {
        zipCode: '2832',
        city: 'Biri'
    },
    {
        zipCode: '2833',
        city: 'Raufoss'
    },
    {
        zipCode: '2834',
        city: 'Raufoss'
    },
    {
        zipCode: '2835',
        city: 'Raufoss'
    },
    {
        zipCode: '2836',
        city: 'Biri'
    },
    {
        zipCode: '2837',
        city: 'Biristrand'
    },
    {
        zipCode: '2838',
        city: 'Snertingdal'
    },
    {
        zipCode: '2839',
        city: 'Øvre snertingdal'
    },
    {
        zipCode: '2840',
        city: 'Reinsvoll'
    },
    {
        zipCode: '2841',
        city: 'Snertingdal'
    },
    {
        zipCode: '2843',
        city: 'Eina'
    },
    {
        zipCode: '2844',
        city: 'Kolbu'
    },
    {
        zipCode: '2845',
        city: 'Bøverbru'
    },
    {
        zipCode: '2846',
        city: 'Bøverbru'
    },
    {
        zipCode: '2847',
        city: 'Kolbu'
    },
    {
        zipCode: '2848',
        city: 'Skreia'
    },
    {
        zipCode: '2849',
        city: 'Kapp'
    },
    {
        zipCode: '2850',
        city: 'Lena'
    },
    {
        zipCode: '2851',
        city: 'Lena'
    },
    {
        zipCode: '2853',
        city: 'Reinsvoll'
    },
    {
        zipCode: '2854',
        city: 'Eina'
    },
    {
        zipCode: '2857',
        city: 'Skreia'
    },
    {
        zipCode: '2858',
        city: 'Kapp'
    },
    {
        zipCode: '2860',
        city: 'Hov'
    },
    {
        zipCode: '2861',
        city: 'Landåsbygda'
    },
    {
        zipCode: '2862',
        city: 'Fluberg'
    },
    {
        zipCode: '2864',
        city: 'Fall'
    },
    {
        zipCode: '2866',
        city: 'Enger'
    },
    {
        zipCode: '2867',
        city: 'Hov'
    },
    {
        zipCode: '2870',
        city: 'Dokka'
    },
    {
        zipCode: '2879',
        city: 'Odnes'
    },
    {
        zipCode: '2880',
        city: 'Nord-torpa'
    },
    {
        zipCode: '2881',
        city: 'Aust-torpa'
    },
    {
        zipCode: '2882',
        city: 'Dokka'
    },
    {
        zipCode: '2890',
        city: 'Etnedal'
    },
    {
        zipCode: '2893',
        city: 'Etnedal'
    },
    {
        zipCode: '2900',
        city: 'Fagernes'
    },
    {
        zipCode: '2901',
        city: 'Fagernes'
    },
    {
        zipCode: '2907',
        city: 'Leira i valdres'
    },
    {
        zipCode: '2909',
        city: 'Aurdal'
    },
    {
        zipCode: '2910',
        city: 'Aurdal'
    },
    {
        zipCode: '2917',
        city: 'Skrautvål'
    },
    {
        zipCode: '2918',
        city: 'Ulnes'
    },
    {
        zipCode: '2920',
        city: 'Leira i valdres'
    },
    {
        zipCode: '2923',
        city: 'Tisleidalen'
    },
    {
        zipCode: '2929',
        city: 'Bagn'
    },
    {
        zipCode: '2930',
        city: 'Bagn'
    },
    {
        zipCode: '2933',
        city: 'Reinli'
    },
    {
        zipCode: '2936',
        city: 'Begnadalen'
    },
    {
        zipCode: '2937',
        city: 'Begna'
    },
    {
        zipCode: '2939',
        city: 'Heggenes'
    },
    {
        zipCode: '2940',
        city: 'Heggenes'
    },
    {
        zipCode: '2943',
        city: 'Rogne'
    },
    {
        zipCode: '2950',
        city: 'Skammestein'
    },
    {
        zipCode: '2952',
        city: 'Beito'
    },
    {
        zipCode: '2953',
        city: 'Beitostølen'
    },
    {
        zipCode: '2954',
        city: 'Beitostølen'
    },
    {
        zipCode: '2959',
        city: 'Røn'
    },
    {
        zipCode: '2960',
        city: 'Røn'
    },
    {
        zipCode: '2965',
        city: 'Slidre'
    },
    {
        zipCode: '2966',
        city: 'Slidre'
    },
    {
        zipCode: '2967',
        city: 'Lomen'
    },
    {
        zipCode: '2972',
        city: 'Ryfoss'
    },
    {
        zipCode: '2973',
        city: 'Ryfoss'
    },
    {
        zipCode: '2974',
        city: 'Vang i valdres'
    },
    {
        zipCode: '2975',
        city: 'Vang i valdres'
    },
    {
        zipCode: '2977',
        city: 'Øye'
    },
    {
        zipCode: '2985',
        city: 'Tyinkrysset'
    },
    {
        zipCode: '3001',
        city: 'Drammen'
    },
    {
        zipCode: '3002',
        city: 'Drammen'
    },
    {
        zipCode: '3003',
        city: 'Drammen'
    },
    {
        zipCode: '3004',
        city: 'Drammen'
    },
    {
        zipCode: '3005',
        city: 'Drammen'
    },
    {
        zipCode: '3006',
        city: 'Drammen'
    },
    {
        zipCode: '3007',
        city: 'Drammen'
    },
    {
        zipCode: '3008',
        city: 'Drammen'
    },
    {
        zipCode: '3009',
        city: 'Drammen'
    },
    {
        zipCode: '3010',
        city: 'Drammen'
    },
    {
        zipCode: '3011',
        city: 'Drammen'
    },
    {
        zipCode: '3012',
        city: 'Drammen'
    },
    {
        zipCode: '3013',
        city: 'Drammen'
    },
    {
        zipCode: '3014',
        city: 'Drammen'
    },
    {
        zipCode: '3015',
        city: 'Drammen'
    },
    {
        zipCode: '3016',
        city: 'Drammen'
    },
    {
        zipCode: '3017',
        city: 'Drammen'
    },
    {
        zipCode: '3018',
        city: 'Drammen'
    },
    {
        zipCode: '3019',
        city: 'Drammen'
    },
    {
        zipCode: '3021',
        city: 'Drammen'
    },
    {
        zipCode: '3022',
        city: 'Drammen'
    },
    {
        zipCode: '3023',
        city: 'Drammen'
    },
    {
        zipCode: '3024',
        city: 'Drammen'
    },
    {
        zipCode: '3025',
        city: 'Drammen'
    },
    {
        zipCode: '3026',
        city: 'Drammen'
    },
    {
        zipCode: '3027',
        city: 'Drammen'
    },
    {
        zipCode: '3028',
        city: 'Drammen'
    },
    {
        zipCode: '3029',
        city: 'Drammen'
    },
    {
        zipCode: '3030',
        city: 'Drammen'
    },
    {
        zipCode: '3031',
        city: 'Drammen'
    },
    {
        zipCode: '3032',
        city: 'Drammen'
    },
    {
        zipCode: '3033',
        city: 'Drammen'
    },
    {
        zipCode: '3034',
        city: 'Drammen'
    },
    {
        zipCode: '3035',
        city: 'Drammen'
    },
    {
        zipCode: '3036',
        city: 'Drammen'
    },
    {
        zipCode: '3037',
        city: 'Drammen'
    },
    {
        zipCode: '3038',
        city: 'Drammen'
    },
    {
        zipCode: '3039',
        city: 'Drammen'
    },
    {
        zipCode: '3040',
        city: 'Drammen'
    },
    {
        zipCode: '3041',
        city: 'Drammen'
    },
    {
        zipCode: '3042',
        city: 'Drammen'
    },
    {
        zipCode: '3043',
        city: 'Drammen'
    },
    {
        zipCode: '3044',
        city: 'Drammen'
    },
    {
        zipCode: '3045',
        city: 'Drammen'
    },
    {
        zipCode: '3046',
        city: 'Drammen'
    },
    {
        zipCode: '3047',
        city: 'Drammen'
    },
    {
        zipCode: '3048',
        city: 'Drammen'
    },
    {
        zipCode: '3050',
        city: 'Mjøndalen'
    },
    {
        zipCode: '3051',
        city: 'Mjøndalen'
    },
    {
        zipCode: '3053',
        city: 'Steinberg'
    },
    {
        zipCode: '3054',
        city: 'Krokstadelva'
    },
    {
        zipCode: '3055',
        city: 'Krokstadelva'
    },
    {
        zipCode: '3056',
        city: 'Solbergelva'
    },
    {
        zipCode: '3057',
        city: 'Solbergelva'
    },
    {
        zipCode: '3058',
        city: 'Solbergmoen'
    },
    {
        zipCode: '3060',
        city: 'Svelvik'
    },
    {
        zipCode: '3061',
        city: 'Svelvik'
    },
    {
        zipCode: '3063',
        city: 'Drammen'
    },
    {
        zipCode: '3064',
        city: 'Drammen'
    },
    {
        zipCode: '3065',
        city: 'Drammen'
    },
    {
        zipCode: '3066',
        city: 'Drammen'
    },
    {
        zipCode: '3070',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3071',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3072',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3073',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3074',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3075',
        city: 'Berger'
    },
    {
        zipCode: '3076',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3077',
        city: 'Sande i vestfold'
    },
    {
        zipCode: '3080',
        city: 'Holmestrand'
    },
    {
        zipCode: '3081',
        city: 'Holmestrand'
    },
    {
        zipCode: '3082',
        city: 'Holmestrand'
    },
    {
        zipCode: '3083',
        city: 'Holmestrand'
    },
    {
        zipCode: '3084',
        city: 'Holmestrand'
    },
    {
        zipCode: '3085',
        city: 'Holmestrand'
    },
    {
        zipCode: '3086',
        city: 'Holmestrand'
    },
    {
        zipCode: '3087',
        city: 'Holmestrand'
    },
    {
        zipCode: '3088',
        city: 'Holmestrand'
    },
    {
        zipCode: '3089',
        city: 'Holmestrand'
    },
    {
        zipCode: '3090',
        city: 'Hof'
    },
    {
        zipCode: '3091',
        city: 'Hof'
    },
    {
        zipCode: '3092',
        city: 'Sundbyfoss'
    },
    {
        zipCode: '3095',
        city: 'Eidsfoss'
    },
    {
        zipCode: '3101',
        city: 'Tønsberg'
    },
    {
        zipCode: '3103',
        city: 'Tønsberg'
    },
    {
        zipCode: '3104',
        city: 'Tønsberg'
    },
    {
        zipCode: '3105',
        city: 'Tønsberg'
    },
    {
        zipCode: '3106',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3107',
        city: 'Sem'
    },
    {
        zipCode: '3108',
        city: 'Vear'
    },
    {
        zipCode: '3109',
        city: 'Tønsberg'
    },
    {
        zipCode: '3110',
        city: 'Tønsberg'
    },
    {
        zipCode: '3111',
        city: 'Tønsberg'
    },
    {
        zipCode: '3112',
        city: 'Tønsberg'
    },
    {
        zipCode: '3113',
        city: 'Tønsberg'
    },
    {
        zipCode: '3114',
        city: 'Tønsberg'
    },
    {
        zipCode: '3115',
        city: 'Tønsberg'
    },
    {
        zipCode: '3116',
        city: 'Tønsberg'
    },
    {
        zipCode: '3117',
        city: 'Tønsberg'
    },
    {
        zipCode: '3118',
        city: 'Tønsberg'
    },
    {
        zipCode: '3119',
        city: 'Tønsberg'
    },
    {
        zipCode: '3120',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3121',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3122',
        city: 'Tønsberg'
    },
    {
        zipCode: '3123',
        city: 'Tønsberg'
    },
    {
        zipCode: '3124',
        city: 'Tønsberg'
    },
    {
        zipCode: '3125',
        city: 'Tønsberg'
    },
    {
        zipCode: '3126',
        city: 'Tønsberg'
    },
    {
        zipCode: '3127',
        city: 'Tønsberg'
    },
    {
        zipCode: '3128',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3129',
        city: 'Tønsberg'
    },
    {
        zipCode: '3131',
        city: 'Husøysund'
    },
    {
        zipCode: '3132',
        city: 'Husøysund'
    },
    {
        zipCode: '3133',
        city: 'Duken'
    },
    {
        zipCode: '3134',
        city: 'Tønsberg'
    },
    {
        zipCode: '3135',
        city: 'Torød'
    },
    {
        zipCode: '3137',
        city: 'Torød'
    },
    {
        zipCode: '3138',
        city: 'Skallestad'
    },
    {
        zipCode: '3139',
        city: 'Skallestad'
    },
    {
        zipCode: '3140',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3141',
        city: 'Kjøpmannskjær'
    },
    {
        zipCode: '3142',
        city: 'Vestskogen'
    },
    {
        zipCode: '3143',
        city: 'Kjøpmannskjær'
    },
    {
        zipCode: '3144',
        city: 'Veierland'
    },
    {
        zipCode: '3145',
        city: 'Tjøme'
    },
    {
        zipCode: '3148',
        city: 'Hvasser'
    },
    {
        zipCode: '3150',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3151',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3152',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3153',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3154',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3156',
        city: 'Melsomvik'
    },
    {
        zipCode: '3157',
        city: 'Barkåker'
    },
    {
        zipCode: '3158',
        city: 'Andebu'
    },
    {
        zipCode: '3159',
        city: 'Melsomvik'
    },
    {
        zipCode: '3160',
        city: 'Stokke'
    },
    {
        zipCode: '3161',
        city: 'Stokke'
    },
    {
        zipCode: '3162',
        city: 'Andebu'
    },
    {
        zipCode: '3163',
        city: 'Nøtterøy'
    },
    {
        zipCode: '3164',
        city: 'Revetal'
    },
    {
        zipCode: '3165',
        city: 'Tjøme'
    },
    {
        zipCode: '3166',
        city: 'Tolvsrød'
    },
    {
        zipCode: '3167',
        city: 'Åsgårdstrand'
    },
    {
        zipCode: '3168',
        city: 'Melsomvik'
    },
    {
        zipCode: '3169',
        city: 'Stokke'
    },
    {
        zipCode: '3170',
        city: 'Sem'
    },
    {
        zipCode: '3171',
        city: 'Sem'
    },
    {
        zipCode: '3172',
        city: 'Vear'
    },
    {
        zipCode: '3173',
        city: 'Vear'
    },
    {
        zipCode: '3174',
        city: 'Revetal'
    },
    {
        zipCode: '3175',
        city: 'Ramnes'
    },
    {
        zipCode: '3176',
        city: 'Undrumsdal'
    },
    {
        zipCode: '3177',
        city: 'Våle'
    },
    {
        zipCode: '3178',
        city: 'Våle'
    },
    {
        zipCode: '3179',
        city: 'Åsgårdstrand'
    },
    {
        zipCode: '3180',
        city: 'Nykirke'
    },
    {
        zipCode: '3181',
        city: 'Horten'
    },
    {
        zipCode: '3182',
        city: 'Horten'
    },
    {
        zipCode: '3183',
        city: 'Horten'
    },
    {
        zipCode: '3184',
        city: 'Borre'
    },
    {
        zipCode: '3185',
        city: 'Skoppum'
    },
    {
        zipCode: '3186',
        city: 'Horten'
    },
    {
        zipCode: '3187',
        city: 'Horten'
    },
    {
        zipCode: '3188',
        city: 'Horten'
    },
    {
        zipCode: '3189',
        city: 'Horten'
    },
    {
        zipCode: '3191',
        city: 'Horten'
    },
    {
        zipCode: '3192',
        city: 'Horten'
    },
    {
        zipCode: '3193',
        city: 'Horten'
    },
    {
        zipCode: '3194',
        city: 'Horten'
    },
    {
        zipCode: '3195',
        city: 'Skoppum'
    },
    {
        zipCode: '3196',
        city: 'Horten'
    },
    {
        zipCode: '3197',
        city: 'Nykirke'
    },
    {
        zipCode: '3199',
        city: 'Borre'
    },
    {
        zipCode: '3201',
        city: 'Sandefjord'
    },
    {
        zipCode: '3202',
        city: 'Sandefjord'
    },
    {
        zipCode: '3203',
        city: 'Sandefjord'
    },
    {
        zipCode: '3204',
        city: 'Sandefjord'
    },
    {
        zipCode: '3205',
        city: 'Sandefjord'
    },
    {
        zipCode: '3206',
        city: 'Sandefjord'
    },
    {
        zipCode: '3207',
        city: 'Sandefjord'
    },
    {
        zipCode: '3208',
        city: 'Sandefjord'
    },
    {
        zipCode: '3209',
        city: 'Sandefjord'
    },
    {
        zipCode: '3210',
        city: 'Sandefjord'
    },
    {
        zipCode: '3211',
        city: 'Sandefjord'
    },
    {
        zipCode: '3212',
        city: 'Sandefjord'
    },
    {
        zipCode: '3213',
        city: 'Sandefjord'
    },
    {
        zipCode: '3214',
        city: 'Sandefjord'
    },
    {
        zipCode: '3215',
        city: 'Sandefjord'
    },
    {
        zipCode: '3216',
        city: 'Sandefjord'
    },
    {
        zipCode: '3217',
        city: 'Sandefjord'
    },
    {
        zipCode: '3218',
        city: 'Sandefjord'
    },
    {
        zipCode: '3219',
        city: 'Sandefjord'
    },
    {
        zipCode: '3220',
        city: 'Sandefjord'
    },
    {
        zipCode: '3221',
        city: 'Sandefjord'
    },
    {
        zipCode: '3222',
        city: 'Sandefjord'
    },
    {
        zipCode: '3223',
        city: 'Sandefjord'
    },
    {
        zipCode: '3224',
        city: 'Sandefjord'
    },
    {
        zipCode: '3225',
        city: 'Sandefjord'
    },
    {
        zipCode: '3226',
        city: 'Sandefjord'
    },
    {
        zipCode: '3227',
        city: 'Sandefjord'
    },
    {
        zipCode: '3228',
        city: 'Sandefjord'
    },
    {
        zipCode: '3229',
        city: 'Sandefjord'
    },
    {
        zipCode: '3230',
        city: 'Sandefjord'
    },
    {
        zipCode: '3231',
        city: 'Sandefjord'
    },
    {
        zipCode: '3232',
        city: 'Sandefjord'
    },
    {
        zipCode: '3233',
        city: 'Sandefjord'
    },
    {
        zipCode: '3234',
        city: 'Sandefjord'
    },
    {
        zipCode: '3235',
        city: 'Sandefjord'
    },
    {
        zipCode: '3236',
        city: 'Sandefjord'
    },
    {
        zipCode: '3237',
        city: 'Sandefjord'
    },
    {
        zipCode: '3238',
        city: 'Sandefjord'
    },
    {
        zipCode: '3239',
        city: 'Sandefjord'
    },
    {
        zipCode: '3240',
        city: 'Sandefjord'
    },
    {
        zipCode: '3241',
        city: 'Sandefjord'
    },
    {
        zipCode: '3242',
        city: 'Sandefjord'
    },
    {
        zipCode: '3243',
        city: 'Kodal'
    },
    {
        zipCode: '3244',
        city: 'Sandefjord'
    },
    {
        zipCode: '3245',
        city: 'Kodal'
    },
    {
        zipCode: '3246',
        city: 'Sandefjord'
    },
    {
        zipCode: '3247',
        city: 'Sandefjord'
    },
    {
        zipCode: '3248',
        city: 'Sandefjord'
    },
    {
        zipCode: '3249',
        city: 'Sandefjord'
    },
    {
        zipCode: '3251',
        city: 'Larvik'
    },
    {
        zipCode: '3252',
        city: 'Larvik'
    },
    {
        zipCode: '3253',
        city: 'Larvik'
    },
    {
        zipCode: '3254',
        city: 'Larvik'
    },
    {
        zipCode: '3255',
        city: 'Larvik'
    },
    {
        zipCode: '3256',
        city: 'Larvik'
    },
    {
        zipCode: '3257',
        city: 'Larvik'
    },
    {
        zipCode: '3258',
        city: 'Larvik'
    },
    {
        zipCode: '3259',
        city: 'Larvik'
    },
    {
        zipCode: '3260',
        city: 'Larvik'
    },
    {
        zipCode: '3261',
        city: 'Larvik'
    },
    {
        zipCode: '3262',
        city: 'Larvik'
    },
    {
        zipCode: '3263',
        city: 'Larvik'
    },
    {
        zipCode: '3264',
        city: 'Larvik'
    },
    {
        zipCode: '3265',
        city: 'Larvik'
    },
    {
        zipCode: '3267',
        city: 'Larvik'
    },
    {
        zipCode: '3268',
        city: 'Larvik'
    },
    {
        zipCode: '3269',
        city: 'Larvik'
    },
    {
        zipCode: '3270',
        city: 'Larvik'
    },
    {
        zipCode: '3271',
        city: 'Larvik'
    },
    {
        zipCode: '3274',
        city: 'Larvik'
    },
    {
        zipCode: '3275',
        city: 'Svarstad'
    },
    {
        zipCode: '3276',
        city: 'Svarstad'
    },
    {
        zipCode: '3277',
        city: 'Steinsholt'
    },
    {
        zipCode: '3280',
        city: 'Tjodalyng'
    },
    {
        zipCode: '3281',
        city: 'Tjodalyng'
    },
    {
        zipCode: '3282',
        city: 'Kvelde'
    },
    {
        zipCode: '3284',
        city: 'Kvelde'
    },
    {
        zipCode: '3285',
        city: 'Larvik'
    },
    {
        zipCode: '3290',
        city: 'Stavern'
    },
    {
        zipCode: '3291',
        city: 'Stavern'
    },
    {
        zipCode: '3292',
        city: 'Stavern'
    },
    {
        zipCode: '3294',
        city: 'Stavern'
    },
    {
        zipCode: '3295',
        city: 'Helgeroa'
    },
    {
        zipCode: '3296',
        city: 'Nevlunghavn'
    },
    {
        zipCode: '3297',
        city: 'Helgeroa'
    },
    {
        zipCode: '3300',
        city: 'Hokksund'
    },
    {
        zipCode: '3301',
        city: 'Hokksund'
    },
    {
        zipCode: '3302',
        city: 'Hokksund'
    },
    {
        zipCode: '3303',
        city: 'Hokksund'
    },
    {
        zipCode: '3320',
        city: 'Vestfossen'
    },
    {
        zipCode: '3321',
        city: 'Vestfossen'
    },
    {
        zipCode: '3322',
        city: 'Fiskum'
    },
    {
        zipCode: '3330',
        city: 'Skotselv'
    },
    {
        zipCode: '3331',
        city: 'Skotselv'
    },
    {
        zipCode: '3340',
        city: 'Åmot'
    },
    {
        zipCode: '3341',
        city: 'Åmot'
    },
    {
        zipCode: '3342',
        city: 'Åmot'
    },
    {
        zipCode: '3350',
        city: 'Prestfoss'
    },
    {
        zipCode: '3351',
        city: 'Prestfoss'
    },
    {
        zipCode: '3355',
        city: 'Solumsmoen'
    },
    {
        zipCode: '3357',
        city: 'Eggedal'
    },
    {
        zipCode: '3358',
        city: 'Nedre eggedal'
    },
    {
        zipCode: '3359',
        city: 'Eggedal'
    },
    {
        zipCode: '3360',
        city: 'Geithus'
    },
    {
        zipCode: '3361',
        city: 'Geithus'
    },
    {
        zipCode: '3370',
        city: 'Vikersund'
    },
    {
        zipCode: '3371',
        city: 'Vikersund'
    },
    {
        zipCode: '3401',
        city: 'Lier'
    },
    {
        zipCode: '3402',
        city: 'Lier'
    },
    {
        zipCode: '3403',
        city: 'Lier'
    },
    {
        zipCode: '3404',
        city: 'Lier'
    },
    {
        zipCode: '3405',
        city: 'Lier'
    },
    {
        zipCode: '3406',
        city: 'Tranby'
    },
    {
        zipCode: '3407',
        city: 'Tranby'
    },
    {
        zipCode: '3408',
        city: 'Tranby'
    },
    {
        zipCode: '3409',
        city: 'Tranby'
    },
    {
        zipCode: '3410',
        city: 'Sylling'
    },
    {
        zipCode: '3411',
        city: 'Sylling'
    },
    {
        zipCode: '3412',
        city: 'Lierstranda'
    },
    {
        zipCode: '3413',
        city: 'Lier'
    },
    {
        zipCode: '3414',
        city: 'Lierstranda'
    },
    {
        zipCode: '3420',
        city: 'Lierskogen'
    },
    {
        zipCode: '3421',
        city: 'Lierskogen'
    },
    {
        zipCode: '3425',
        city: 'Reistad'
    },
    {
        zipCode: '3426',
        city: 'Gullaug'
    },
    {
        zipCode: '3427',
        city: 'Gullaug'
    },
    {
        zipCode: '3428',
        city: 'Gullaug'
    },
    {
        zipCode: '3430',
        city: 'Spikkestad'
    },
    {
        zipCode: '3431',
        city: 'Spikkestad'
    },
    {
        zipCode: '3440',
        city: 'Røyken'
    },
    {
        zipCode: '3441',
        city: 'Røyken'
    },
    {
        zipCode: '3442',
        city: 'Hyggen'
    },
    {
        zipCode: '3470',
        city: 'Slemmestad'
    },
    {
        zipCode: '3471',
        city: 'Slemmestad'
    },
    {
        zipCode: '3472',
        city: 'Bødalen'
    },
    {
        zipCode: '3474',
        city: 'Åros'
    },
    {
        zipCode: '3475',
        city: 'Sætre'
    },
    {
        zipCode: '3476',
        city: 'Sætre'
    },
    {
        zipCode: '3477',
        city: 'Båtstø'
    },
    {
        zipCode: '3478',
        city: 'Nærsnes'
    },
    {
        zipCode: '3479',
        city: 'Nærsnes'
    },
    {
        zipCode: '3480',
        city: 'Filtvet'
    },
    {
        zipCode: '3481',
        city: 'Tofte'
    },
    {
        zipCode: '3482',
        city: 'Tofte'
    },
    {
        zipCode: '3483',
        city: 'Kana'
    },
    {
        zipCode: '3484',
        city: 'Holmsbu'
    },
    {
        zipCode: '3485',
        city: 'Filtvet'
    },
    {
        zipCode: '3490',
        city: 'Klokkarstua'
    },
    {
        zipCode: '3501',
        city: 'Hønefoss'
    },
    {
        zipCode: '3502',
        city: 'Hønefoss'
    },
    {
        zipCode: '3503',
        city: 'Hønefoss'
    },
    {
        zipCode: '3504',
        city: 'Hønefoss'
    },
    {
        zipCode: '3507',
        city: 'Hønefoss'
    },
    {
        zipCode: '3510',
        city: 'Hønefoss'
    },
    {
        zipCode: '3511',
        city: 'Hønefoss'
    },
    {
        zipCode: '3512',
        city: 'Hønefoss'
    },
    {
        zipCode: '3513',
        city: 'Hønefoss'
    },
    {
        zipCode: '3514',
        city: 'Hønefoss'
    },
    {
        zipCode: '3515',
        city: 'Hønefoss'
    },
    {
        zipCode: '3516',
        city: 'Hønefoss'
    },
    {
        zipCode: '3517',
        city: 'Hønefoss'
    },
    {
        zipCode: '3518',
        city: 'Hønefoss'
    },
    {
        zipCode: '3519',
        city: 'Hønefoss'
    },
    {
        zipCode: '3520',
        city: 'Jevnaker'
    },
    {
        zipCode: '3521',
        city: 'Jevnaker'
    },
    {
        zipCode: '3522',
        city: 'Bjoneroa'
    },
    {
        zipCode: '3523',
        city: 'Nes i ådal'
    },
    {
        zipCode: '3524',
        city: 'Nes i ådal'
    },
    {
        zipCode: '3525',
        city: 'Hallingby'
    },
    {
        zipCode: '3526',
        city: 'Hallingby'
    },
    {
        zipCode: '3527',
        city: 'Bjoneroa'
    },
    {
        zipCode: '3528',
        city: 'Hedalen'
    },
    {
        zipCode: '3529',
        city: 'Røyse'
    },
    {
        zipCode: '3530',
        city: 'Røyse'
    },
    {
        zipCode: '3531',
        city: 'Krokkleiva'
    },
    {
        zipCode: '3532',
        city: 'Tyristrand'
    },
    {
        zipCode: '3533',
        city: 'Tyristrand'
    },
    {
        zipCode: '3534',
        city: 'Sokna'
    },
    {
        zipCode: '3535',
        city: 'Krøderen'
    },
    {
        zipCode: '3536',
        city: 'Noresund'
    },
    {
        zipCode: '3537',
        city: 'Krøderen'
    },
    {
        zipCode: '3538',
        city: 'Sollihøgda'
    },
    {
        zipCode: '3539',
        city: 'Flå'
    },
    {
        zipCode: '3540',
        city: 'Nesbyen'
    },
    {
        zipCode: '3541',
        city: 'Nesbyen'
    },
    {
        zipCode: '3543',
        city: 'Noresund'
    },
    {
        zipCode: '3544',
        city: 'Tunhovd'
    },
    {
        zipCode: '3545',
        city: 'Flå'
    },
    {
        zipCode: '3550',
        city: 'Gol'
    },
    {
        zipCode: '3551',
        city: 'Gol'
    },
    {
        zipCode: '3560',
        city: 'Hemsedal'
    },
    {
        zipCode: '3561',
        city: 'Hemsedal'
    },
    {
        zipCode: '3570',
        city: 'Ål'
    },
    {
        zipCode: '3571',
        city: 'Ål'
    },
    {
        zipCode: '3575',
        city: 'Hol'
    },
    {
        zipCode: '3576',
        city: 'Hol'
    },
    {
        zipCode: '3577',
        city: 'Hovet'
    },
    {
        zipCode: '3579',
        city: 'Torpo'
    },
    {
        zipCode: '3580',
        city: 'Geilo'
    },
    {
        zipCode: '3581',
        city: 'Geilo'
    },
    {
        zipCode: '3588',
        city: 'Dagali'
    },
    {
        zipCode: '3593',
        city: 'Ustaoset'
    },
    {
        zipCode: '3595',
        city: 'Haugastøl'
    },
    {
        zipCode: '3601',
        city: 'Kongsberg'
    },
    {
        zipCode: '3602',
        city: 'Kongsberg'
    },
    {
        zipCode: '3603',
        city: 'Kongsberg'
    },
    {
        zipCode: '3604',
        city: 'Kongsberg'
    },
    {
        zipCode: '3605',
        city: 'Kongsberg'
    },
    {
        zipCode: '3606',
        city: 'Kongsberg'
    },
    {
        zipCode: '3607',
        city: 'Kongsberg'
    },
    {
        zipCode: '3608',
        city: 'Heistadmoen'
    },
    {
        zipCode: '3609',
        city: 'Kongsberg'
    },
    {
        zipCode: '3610',
        city: 'Kongsberg'
    },
    {
        zipCode: '3611',
        city: 'Kongsberg'
    },
    {
        zipCode: '3612',
        city: 'Kongsberg'
    },
    {
        zipCode: '3613',
        city: 'Kongsberg'
    },
    {
        zipCode: '3614',
        city: 'Kongsberg'
    },
    {
        zipCode: '3615',
        city: 'Kongsberg'
    },
    {
        zipCode: '3616',
        city: 'Kongsberg'
    },
    {
        zipCode: '3617',
        city: 'Kongsberg'
    },
    {
        zipCode: '3618',
        city: 'Skollenborg'
    },
    {
        zipCode: '3619',
        city: 'Skollenborg'
    },
    {
        zipCode: '3620',
        city: 'Flesberg'
    },
    {
        zipCode: '3621',
        city: 'Lampeland'
    },
    {
        zipCode: '3622',
        city: 'Svene'
    },
    {
        zipCode: '3623',
        city: 'Lampeland'
    },
    {
        zipCode: '3624',
        city: 'Lyngdal i numedal'
    },
    {
        zipCode: '3625',
        city: 'Skollenborg'
    },
    {
        zipCode: '3626',
        city: 'Rollag'
    },
    {
        zipCode: '3627',
        city: 'Veggli'
    },
    {
        zipCode: '3628',
        city: 'Veggli'
    },
    {
        zipCode: '3629',
        city: 'Nore'
    },
    {
        zipCode: '3630',
        city: 'Rødberg'
    },
    {
        zipCode: '3631',
        city: 'Rødberg'
    },
    {
        zipCode: '3632',
        city: 'Uvdal'
    },
    {
        zipCode: '3634',
        city: 'Nore'
    },
    {
        zipCode: '3646',
        city: 'Hvittingfoss'
    },
    {
        zipCode: '3647',
        city: 'Hvittingfoss'
    },
    {
        zipCode: '3648',
        city: 'Passebekk'
    },
    {
        zipCode: '3650',
        city: 'Tinn austbygd'
    },
    {
        zipCode: '3652',
        city: 'Hovin i telemark'
    },
    {
        zipCode: '3656',
        city: 'Atrå'
    },
    {
        zipCode: '3658',
        city: 'Miland'
    },
    {
        zipCode: '3660',
        city: 'Rjukan'
    },
    {
        zipCode: '3661',
        city: 'Rjukan'
    },
    {
        zipCode: '3665',
        city: 'Sauland'
    },
    {
        zipCode: '3666',
        city: 'Atrå'
    },
    {
        zipCode: '3671',
        city: 'Notodden'
    },
    {
        zipCode: '3672',
        city: 'Notodden'
    },
    {
        zipCode: '3673',
        city: 'Notodden'
    },
    {
        zipCode: '3674',
        city: 'Notodden'
    },
    {
        zipCode: '3675',
        city: 'Notodden'
    },
    {
        zipCode: '3676',
        city: 'Notodden'
    },
    {
        zipCode: '3677',
        city: 'Notodden'
    },
    {
        zipCode: '3678',
        city: 'Notodden'
    },
    {
        zipCode: '3679',
        city: 'Notodden'
    },
    {
        zipCode: '3680',
        city: 'Notodden'
    },
    {
        zipCode: '3681',
        city: 'Notodden'
    },
    {
        zipCode: '3683',
        city: 'Notodden'
    },
    {
        zipCode: '3684',
        city: 'Notodden'
    },
    {
        zipCode: '3690',
        city: 'Hjartdal'
    },
    {
        zipCode: '3691',
        city: 'Gransherad'
    },
    {
        zipCode: '3692',
        city: 'Sauland'
    },
    {
        zipCode: '3697',
        city: 'Tuddal'
    },
    {
        zipCode: '3701',
        city: 'Skien'
    },
    {
        zipCode: '3702',
        city: 'Skien'
    },
    {
        zipCode: '3703',
        city: 'Skien'
    },
    {
        zipCode: '3704',
        city: 'Skien'
    },
    {
        zipCode: '3705',
        city: 'Skien'
    },
    {
        zipCode: '3707',
        city: 'Skien'
    },
    {
        zipCode: '3710',
        city: 'Skien'
    },
    {
        zipCode: '3711',
        city: 'Skien'
    },
    {
        zipCode: '3712',
        city: 'Skien'
    },
    {
        zipCode: '3713',
        city: 'Skien'
    },
    {
        zipCode: '3714',
        city: 'Skien'
    },
    {
        zipCode: '3715',
        city: 'Skien'
    },
    {
        zipCode: '3716',
        city: 'Skien'
    },
    {
        zipCode: '3717',
        city: 'Skien'
    },
    {
        zipCode: '3718',
        city: 'Skien'
    },
    {
        zipCode: '3719',
        city: 'Skien'
    },
    {
        zipCode: '3720',
        city: 'Skien'
    },
    {
        zipCode: '3721',
        city: 'Skien'
    },
    {
        zipCode: '3722',
        city: 'Skien'
    },
    {
        zipCode: '3723',
        city: 'Skien'
    },
    {
        zipCode: '3724',
        city: 'Skien'
    },
    {
        zipCode: '3725',
        city: 'Skien'
    },
    {
        zipCode: '3726',
        city: 'Skien'
    },
    {
        zipCode: '3727',
        city: 'Skien'
    },
    {
        zipCode: '3728',
        city: 'Skien'
    },
    {
        zipCode: '3729',
        city: 'Skien'
    },
    {
        zipCode: '3730',
        city: 'Skien'
    },
    {
        zipCode: '3731',
        city: 'Skien'
    },
    {
        zipCode: '3732',
        city: 'Skien'
    },
    {
        zipCode: '3733',
        city: 'Skien'
    },
    {
        zipCode: '3734',
        city: 'Skien'
    },
    {
        zipCode: '3735',
        city: 'Skien'
    },
    {
        zipCode: '3736',
        city: 'Skien'
    },
    {
        zipCode: '3737',
        city: 'Skien'
    },
    {
        zipCode: '3738',
        city: 'Skien'
    },
    {
        zipCode: '3739',
        city: 'Skien'
    },
    {
        zipCode: '3740',
        city: 'Skien'
    },
    {
        zipCode: '3741',
        city: 'Skien'
    },
    {
        zipCode: '3742',
        city: 'Skien'
    },
    {
        zipCode: '3743',
        city: 'Skien'
    },
    {
        zipCode: '3744',
        city: 'Skien'
    },
    {
        zipCode: '3746',
        city: 'Skien'
    },
    {
        zipCode: '3747',
        city: 'Skien'
    },
    {
        zipCode: '3748',
        city: 'Siljan'
    },
    {
        zipCode: '3749',
        city: 'Siljan'
    },
    {
        zipCode: '3750',
        city: 'Drangedal'
    },
    {
        zipCode: '3753',
        city: 'Tørdal'
    },
    {
        zipCode: '3760',
        city: 'Neslandsvatn'
    },
    {
        zipCode: '3766',
        city: 'Sannidal'
    },
    {
        zipCode: '3770',
        city: 'Kragerø'
    },
    {
        zipCode: '3772',
        city: 'Kragerø'
    },
    {
        zipCode: '3780',
        city: 'Skåtøy'
    },
    {
        zipCode: '3781',
        city: 'Jomfruland'
    },
    {
        zipCode: '3783',
        city: 'Kragerø skjærgård'
    },
    {
        zipCode: '3785',
        city: 'Skien'
    },
    {
        zipCode: '3787',
        city: 'Skien'
    },
    {
        zipCode: '3788',
        city: 'Stabbestad'
    },
    {
        zipCode: '3789',
        city: 'Kragerø'
    },
    {
        zipCode: '3790',
        city: 'Helle'
    },
    {
        zipCode: '3791',
        city: 'Kragerø'
    },
    {
        zipCode: '3792',
        city: 'Skien'
    },
    {
        zipCode: '3793',
        city: 'Sannidal'
    },
    {
        zipCode: '3794',
        city: 'Helle'
    },
    {
        zipCode: '3795',
        city: 'Drangedal'
    },
    {
        zipCode: '3796',
        city: 'Skien'
    },
    {
        zipCode: '3798',
        city: 'Skien'
    },
    {
        zipCode: '3799',
        city: 'Skien'
    },
    {
        zipCode: '3800',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3801',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3802',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3803',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3804',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3805',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3810',
        city: 'Gvarv'
    },
    {
        zipCode: '3811',
        city: 'Hørte'
    },
    {
        zipCode: '3812',
        city: 'Akkerhaugen'
    },
    {
        zipCode: '3820',
        city: 'Nordagutu'
    },
    {
        zipCode: '3825',
        city: 'Lunde'
    },
    {
        zipCode: '3830',
        city: 'Ulefoss'
    },
    {
        zipCode: '3831',
        city: 'Ulefoss'
    },
    {
        zipCode: '3832',
        city: 'Lunde'
    },
    {
        zipCode: '3833',
        city: 'Bø i telemark'
    },
    {
        zipCode: '3834',
        city: 'Gvarv'
    },
    {
        zipCode: '3835',
        city: 'Seljord'
    },
    {
        zipCode: '3836',
        city: 'Kviteseid'
    },
    {
        zipCode: '3840',
        city: 'Seljord'
    },
    {
        zipCode: '3841',
        city: 'Flatdal'
    },
    {
        zipCode: '3844',
        city: 'Åmotsdal'
    },
    {
        zipCode: '3848',
        city: 'Morgedal'
    },
    {
        zipCode: '3849',
        city: 'Vråliosen'
    },
    {
        zipCode: '3850',
        city: 'Kviteseid'
    },
    {
        zipCode: '3852',
        city: 'Vrådal'
    },
    {
        zipCode: '3853',
        city: 'Vrådal'
    },
    {
        zipCode: '3854',
        city: 'Nissedal'
    },
    {
        zipCode: '3855',
        city: 'Treungen'
    },
    {
        zipCode: '3864',
        city: 'Rauland'
    },
    {
        zipCode: '3870',
        city: 'Fyresdal'
    },
    {
        zipCode: '3880',
        city: 'Dalen'
    },
    {
        zipCode: '3882',
        city: 'Åmdals verk'
    },
    {
        zipCode: '3883',
        city: 'Treungen'
    },
    {
        zipCode: '3884',
        city: 'Rauland'
    },
    {
        zipCode: '3885',
        city: 'Fyresdal'
    },
    {
        zipCode: '3886',
        city: 'Dalen'
    },
    {
        zipCode: '3887',
        city: 'Vinje'
    },
    {
        zipCode: '3888',
        city: 'Edland'
    },
    {
        zipCode: '3890',
        city: 'Vinje'
    },
    {
        zipCode: '3891',
        city: 'Høydalsmo'
    },
    {
        zipCode: '3893',
        city: 'Vinjesvingen'
    },
    {
        zipCode: '3895',
        city: 'Edland'
    },
    {
        zipCode: '3901',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3902',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3903',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3904',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3905',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3906',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3910',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3911',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3912',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3913',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3914',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3915',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3916',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3917',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3918',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3919',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3920',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3921',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3922',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3924',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3925',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3928',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3929',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3930',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3931',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3933',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3936',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3937',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3939',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3940',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3941',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3942',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3943',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3944',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3946',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3947',
        city: 'Langangen'
    },
    {
        zipCode: '3948',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3949',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3950',
        city: 'Brevik'
    },
    {
        zipCode: '3960',
        city: 'Stathelle'
    },
    {
        zipCode: '3961',
        city: 'Stathelle'
    },
    {
        zipCode: '3962',
        city: 'Stathelle'
    },
    {
        zipCode: '3965',
        city: 'Herre'
    },
    {
        zipCode: '3966',
        city: 'Stathelle'
    },
    {
        zipCode: '3967',
        city: 'Stathelle'
    },
    {
        zipCode: '3970',
        city: 'Langesund'
    },
    {
        zipCode: '3991',
        city: 'Brevik'
    },
    {
        zipCode: '3993',
        city: 'Langesund'
    },
    {
        zipCode: '3994',
        city: 'Langesund'
    },
    {
        zipCode: '3995',
        city: 'Stathelle'
    },
    {
        zipCode: '3996',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3997',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3998',
        city: 'Porsgrunn'
    },
    {
        zipCode: '3999',
        city: 'Herre'
    },
    {
        zipCode: '4001',
        city: 'Stavanger'
    },
    {
        zipCode: '4002',
        city: 'Stavanger'
    },
    {
        zipCode: '4003',
        city: 'Stavanger'
    },
    {
        zipCode: '4004',
        city: 'Stavanger'
    },
    {
        zipCode: '4005',
        city: 'Stavanger'
    },
    {
        zipCode: '4006',
        city: 'Stavanger'
    },
    {
        zipCode: '4007',
        city: 'Stavanger'
    },
    {
        zipCode: '4008',
        city: 'Stavanger'
    },
    {
        zipCode: '4009',
        city: 'Stavanger'
    },
    {
        zipCode: '4010',
        city: 'Stavanger'
    },
    {
        zipCode: '4011',
        city: 'Stavanger'
    },
    {
        zipCode: '4012',
        city: 'Stavanger'
    },
    {
        zipCode: '4013',
        city: 'Stavanger'
    },
    {
        zipCode: '4014',
        city: 'Stavanger'
    },
    {
        zipCode: '4015',
        city: 'Stavanger'
    },
    {
        zipCode: '4016',
        city: 'Stavanger'
    },
    {
        zipCode: '4017',
        city: 'Stavanger'
    },
    {
        zipCode: '4018',
        city: 'Stavanger'
    },
    {
        zipCode: '4019',
        city: 'Stavanger'
    },
    {
        zipCode: '4020',
        city: 'Stavanger'
    },
    {
        zipCode: '4021',
        city: 'Stavanger'
    },
    {
        zipCode: '4022',
        city: 'Stavanger'
    },
    {
        zipCode: '4023',
        city: 'Stavanger'
    },
    {
        zipCode: '4024',
        city: 'Stavanger'
    },
    {
        zipCode: '4025',
        city: 'Stavanger'
    },
    {
        zipCode: '4026',
        city: 'Stavanger'
    },
    {
        zipCode: '4027',
        city: 'Stavanger'
    },
    {
        zipCode: '4028',
        city: 'Stavanger'
    },
    {
        zipCode: '4029',
        city: 'Stavanger'
    },
    {
        zipCode: '4031',
        city: 'Stavanger'
    },
    {
        zipCode: '4032',
        city: 'Stavanger'
    },
    {
        zipCode: '4033',
        city: 'Stavanger'
    },
    {
        zipCode: '4034',
        city: 'Stavanger'
    },
    {
        zipCode: '4035',
        city: 'Stavanger'
    },
    {
        zipCode: '4036',
        city: 'Stavanger'
    },
    {
        zipCode: '4041',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4042',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4043',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4044',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4045',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4046',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4047',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4048',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4049',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4050',
        city: 'Sola'
    },
    {
        zipCode: '4051',
        city: 'Sola'
    },
    {
        zipCode: '4052',
        city: 'Røyneberg'
    },
    {
        zipCode: '4053',
        city: 'Ræge'
    },
    {
        zipCode: '4054',
        city: 'Tjelta'
    },
    {
        zipCode: '4055',
        city: 'Sola'
    },
    {
        zipCode: '4056',
        city: 'Tananger'
    },
    {
        zipCode: '4057',
        city: 'Tananger'
    },
    {
        zipCode: '4058',
        city: 'Tananger'
    },
    {
        zipCode: '4059',
        city: 'Røyneberg'
    },
    {
        zipCode: '4063',
        city: 'Tjelta'
    },
    {
        zipCode: '4064',
        city: 'Stavanger'
    },
    {
        zipCode: '4065',
        city: 'Stavanger'
    },
    {
        zipCode: '4066',
        city: 'Stavanger'
    },
    {
        zipCode: '4067',
        city: 'Stavanger'
    },
    {
        zipCode: '4068',
        city: 'Stavanger'
    },
    {
        zipCode: '4069',
        city: 'Stavanger'
    },
    {
        zipCode: '4070',
        city: 'Randaberg'
    },
    {
        zipCode: '4071',
        city: 'Randaberg'
    },
    {
        zipCode: '4072',
        city: 'Randaberg'
    },
    {
        zipCode: '4073',
        city: 'Randaberg'
    },
    {
        zipCode: '4076',
        city: 'Vassøy'
    },
    {
        zipCode: '4077',
        city: 'Hundvåg'
    },
    {
        zipCode: '4078',
        city: 'Stavanger'
    },
    {
        zipCode: '4079',
        city: 'Stavanger'
    },
    {
        zipCode: '4081',
        city: 'Stavanger'
    },
    {
        zipCode: '4082',
        city: 'Stavanger'
    },
    {
        zipCode: '4083',
        city: 'Hundvåg'
    },
    {
        zipCode: '4084',
        city: 'Stavanger'
    },
    {
        zipCode: '4085',
        city: 'Hundvåg'
    },
    {
        zipCode: '4086',
        city: 'Hundvåg'
    },
    {
        zipCode: '4087',
        city: 'Stavanger'
    },
    {
        zipCode: '4088',
        city: 'Stavanger'
    },
    {
        zipCode: '4089',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4090',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4091',
        city: 'Hafrsfjord'
    },
    {
        zipCode: '4092',
        city: 'Stavanger'
    },
    {
        zipCode: '4093',
        city: 'Stavanger'
    },
    {
        zipCode: '4094',
        city: 'Stavanger'
    },
    {
        zipCode: '4095',
        city: 'Stavanger'
    },
    {
        zipCode: '4096',
        city: 'Randaberg'
    },
    {
        zipCode: '4097',
        city: 'Sola'
    },
    {
        zipCode: '4098',
        city: 'Tananger'
    },
    {
        zipCode: '4099',
        city: 'Stavanger'
    },
    {
        zipCode: '4100',
        city: 'Jørpeland'
    },
    {
        zipCode: '4102',
        city: 'Idse'
    },
    {
        zipCode: '4110',
        city: 'Forsand'
    },
    {
        zipCode: '4119',
        city: 'Forsand'
    },
    {
        zipCode: '4120',
        city: 'Tau'
    },
    {
        zipCode: '4123',
        city: 'Sør-hidle'
    },
    {
        zipCode: '4124',
        city: 'Tau'
    },
    {
        zipCode: '4126',
        city: 'Jørpeland'
    },
    {
        zipCode: '4127',
        city: 'Lysebotn'
    },
    {
        zipCode: '4128',
        city: 'Fløyrli'
    },
    {
        zipCode: '4129',
        city: 'Songesand'
    },
    {
        zipCode: '4130',
        city: 'Hjelmeland'
    },
    {
        zipCode: '4134',
        city: 'Jøsenfjorden'
    },
    {
        zipCode: '4137',
        city: 'Årdal i ryfylke'
    },
    {
        zipCode: '4139',
        city: 'Fister'
    },
    {
        zipCode: '4146',
        city: 'Skiftun'
    },
    {
        zipCode: '4148',
        city: 'Hjelmeland'
    },
    {
        zipCode: '4150',
        city: 'Rennesøy'
    },
    {
        zipCode: '4152',
        city: 'Vestre åmøy'
    },
    {
        zipCode: '4153',
        city: 'Brimse'
    },
    {
        zipCode: '4154',
        city: 'Austre åmøy'
    },
    {
        zipCode: '4156',
        city: 'Mosterøy'
    },
    {
        zipCode: '4158',
        city: 'Bru'
    },
    {
        zipCode: '4159',
        city: 'Rennesøy'
    },
    {
        zipCode: '4160',
        city: 'Finnøy'
    },
    {
        zipCode: '4161',
        city: 'Finnøy'
    },
    {
        zipCode: '4163',
        city: 'Talgje'
    },
    {
        zipCode: '4164',
        city: 'Fogn'
    },
    {
        zipCode: '4167',
        city: 'Helgøy i ryfylke'
    },
    {
        zipCode: '4168',
        city: 'Byre'
    },
    {
        zipCode: '4169',
        city: 'Sørbokn'
    },
    {
        zipCode: '4170',
        city: 'Sjernarøy'
    },
    {
        zipCode: '4173',
        city: 'Nord-hidle'
    },
    {
        zipCode: '4174',
        city: 'Sjernarøy'
    },
    {
        zipCode: '4180',
        city: 'Kvitsøy'
    },
    {
        zipCode: '4181',
        city: 'Kvitsøy'
    },
    {
        zipCode: '4182',
        city: 'Skartveit'
    },
    {
        zipCode: '4187',
        city: 'Ombo'
    },
    {
        zipCode: '4198',
        city: 'Foldøy'
    },
    {
        zipCode: '4200',
        city: 'Sauda'
    },
    {
        zipCode: '4201',
        city: 'Sauda'
    },
    {
        zipCode: '4208',
        city: 'Saudasjøen'
    },
    {
        zipCode: '4209',
        city: 'Vanvik'
    },
    {
        zipCode: '4230',
        city: 'Sand'
    },
    {
        zipCode: '4233',
        city: 'Erfjord'
    },
    {
        zipCode: '4234',
        city: 'Jelsa'
    },
    {
        zipCode: '4235',
        city: 'Hebnes'
    },
    {
        zipCode: '4237',
        city: 'Suldalsosen'
    },
    {
        zipCode: '4239',
        city: 'Sand'
    },
    {
        zipCode: '4240',
        city: 'Suldalsosen'
    },
    {
        zipCode: '4244',
        city: 'Nesflaten'
    },
    {
        zipCode: '4250',
        city: 'Kopervik'
    },
    {
        zipCode: '4260',
        city: 'Torvastad'
    },
    {
        zipCode: '4262',
        city: 'Avaldsnes'
    },
    {
        zipCode: '4264',
        city: 'Kvalavåg'
    },
    {
        zipCode: '4265',
        city: 'Håvik'
    },
    {
        zipCode: '4270',
        city: 'Åkrehamn'
    },
    {
        zipCode: '4272',
        city: 'Sandve'
    },
    {
        zipCode: '4274',
        city: 'Stol'
    },
    {
        zipCode: '4275',
        city: 'Sævelandsvik'
    },
    {
        zipCode: '4276',
        city: 'Veavågen'
    },
    {
        zipCode: '4280',
        city: 'Skudeneshavn'
    },
    {
        zipCode: '4291',
        city: 'Kopervik'
    },
    {
        zipCode: '4294',
        city: 'Kopervik'
    },
    {
        zipCode: '4295',
        city: 'Veavågen'
    },
    {
        zipCode: '4296',
        city: 'Åkrehamn'
    },
    {
        zipCode: '4297',
        city: 'Skudeneshavn'
    },
    {
        zipCode: '4298',
        city: 'Torvastad'
    },
    {
        zipCode: '4299',
        city: 'Avaldsnes'
    },
    {
        zipCode: '4301',
        city: 'Sandnes'
    },
    {
        zipCode: '4302',
        city: 'Sandnes'
    },
    {
        zipCode: '4306',
        city: 'Sandnes'
    },
    {
        zipCode: '4307',
        city: 'Sandnes'
    },
    {
        zipCode: '4308',
        city: 'Sandnes'
    },
    {
        zipCode: '4309',
        city: 'Sandnes'
    },
    {
        zipCode: '4310',
        city: 'Hommersåk'
    },
    {
        zipCode: '4311',
        city: 'Hommersåk'
    },
    {
        zipCode: '4312',
        city: 'Sandnes'
    },
    {
        zipCode: '4313',
        city: 'Sandnes'
    },
    {
        zipCode: '4314',
        city: 'Sandnes'
    },
    {
        zipCode: '4315',
        city: 'Sandnes'
    },
    {
        zipCode: '4316',
        city: 'Sandnes'
    },
    {
        zipCode: '4317',
        city: 'Sandnes'
    },
    {
        zipCode: '4318',
        city: 'Sandnes'
    },
    {
        zipCode: '4319',
        city: 'Sandnes'
    },
    {
        zipCode: '4320',
        city: 'Sandnes'
    },
    {
        zipCode: '4321',
        city: 'Sandnes'
    },
    {
        zipCode: '4322',
        city: 'Sandnes'
    },
    {
        zipCode: '4323',
        city: 'Sandnes'
    },
    {
        zipCode: '4324',
        city: 'Sandnes'
    },
    {
        zipCode: '4325',
        city: 'Sandnes'
    },
    {
        zipCode: '4326',
        city: 'Sandnes'
    },
    {
        zipCode: '4327',
        city: 'Sandnes'
    },
    {
        zipCode: '4328',
        city: 'Sandnes'
    },
    {
        zipCode: '4329',
        city: 'Sandnes'
    },
    {
        zipCode: '4330',
        city: 'Ålgård'
    },
    {
        zipCode: '4332',
        city: 'Figgjo'
    },
    {
        zipCode: '4333',
        city: 'Oltedal'
    },
    {
        zipCode: '4335',
        city: 'Dirdal'
    },
    {
        zipCode: '4336',
        city: 'Sandnes'
    },
    {
        zipCode: '4337',
        city: 'Sandnes'
    },
    {
        zipCode: '4338',
        city: 'Sandnes'
    },
    {
        zipCode: '4339',
        city: 'Ålgård'
    },
    {
        zipCode: '4340',
        city: 'Bryne'
    },
    {
        zipCode: '4341',
        city: 'Bryne'
    },
    {
        zipCode: '4342',
        city: 'Undheim'
    },
    {
        zipCode: '4343',
        city: 'Orre'
    },
    {
        zipCode: '4344',
        city: 'Bryne'
    },
    {
        zipCode: '4345',
        city: 'Bryne'
    },
    {
        zipCode: '4346',
        city: 'Bryne'
    },
    {
        zipCode: '4347',
        city: 'Lye'
    },
    {
        zipCode: '4348',
        city: 'Lye'
    },
    {
        zipCode: '4349',
        city: 'Bryne'
    },
    {
        zipCode: '4352',
        city: 'Kleppe'
    },
    {
        zipCode: '4353',
        city: 'Klepp stasjon'
    },
    {
        zipCode: '4354',
        city: 'Voll'
    },
    {
        zipCode: '4355',
        city: 'Kvernaland'
    },
    {
        zipCode: '4356',
        city: 'Kvernaland'
    },
    {
        zipCode: '4357',
        city: 'Klepp stasjon'
    },
    {
        zipCode: '4358',
        city: 'Kleppe'
    },
    {
        zipCode: '4360',
        city: 'Varhaug'
    },
    {
        zipCode: '4361',
        city: 'Sirevåg'
    },
    {
        zipCode: '4362',
        city: 'Vigrestad'
    },
    {
        zipCode: '4363',
        city: 'Brusand'
    },
    {
        zipCode: '4364',
        city: 'Sirevåg'
    },
    {
        zipCode: '4365',
        city: 'Nærbø'
    },
    {
        zipCode: '4367',
        city: 'Nærbø'
    },
    {
        zipCode: '4368',
        city: 'Varhaug'
    },
    {
        zipCode: '4369',
        city: 'Vigrestad'
    },
    {
        zipCode: '4370',
        city: 'Egersund'
    },
    {
        zipCode: '4371',
        city: 'Egersund'
    },
    {
        zipCode: '4372',
        city: 'Egersund'
    },
    {
        zipCode: '4373',
        city: 'Egersund'
    },
    {
        zipCode: '4374',
        city: 'Egersund'
    },
    {
        zipCode: '4375',
        city: 'Hellvik'
    },
    {
        zipCode: '4376',
        city: 'Helleland'
    },
    {
        zipCode: '4378',
        city: 'Egersund'
    },
    {
        zipCode: '4379',
        city: 'Egersund'
    },
    {
        zipCode: '4380',
        city: 'Hauge i dalane'
    },
    {
        zipCode: '4381',
        city: 'Hauge i dalane'
    },
    {
        zipCode: '4384',
        city: 'Vikeså'
    },
    {
        zipCode: '4385',
        city: 'Helleland'
    },
    {
        zipCode: '4387',
        city: 'Bjerkreim'
    },
    {
        zipCode: '4389',
        city: 'Vikeså'
    },
    {
        zipCode: '4390',
        city: 'Oltedal'
    },
    {
        zipCode: '4391',
        city: 'Sandnes'
    },
    {
        zipCode: '4392',
        city: 'Sandnes'
    },
    {
        zipCode: '4393',
        city: 'Sandnes'
    },
    {
        zipCode: '4394',
        city: 'Sandnes'
    },
    {
        zipCode: '4395',
        city: 'Hommersåk'
    },
    {
        zipCode: '4396',
        city: 'Sandnes'
    },
    {
        zipCode: '4397',
        city: 'Sandnes'
    },
    {
        zipCode: '4398',
        city: 'Sandnes'
    },
    {
        zipCode: '4399',
        city: 'Sandnes'
    },
    {
        zipCode: '4400',
        city: 'Flekkefjord'
    },
    {
        zipCode: '4401',
        city: 'Flekkefjord'
    },
    {
        zipCode: '4402',
        city: 'Flekkefjord'
    },
    {
        zipCode: '4403',
        city: 'Flekkefjord'
    },
    {
        zipCode: '4420',
        city: 'Åna-sira'
    },
    {
        zipCode: '4432',
        city: 'Hidrasund'
    },
    {
        zipCode: '4434',
        city: 'Andabeløy'
    },
    {
        zipCode: '4436',
        city: 'Gyland'
    },
    {
        zipCode: '4438',
        city: 'Sira'
    },
    {
        zipCode: '4439',
        city: 'Sira'
    },
    {
        zipCode: '4440',
        city: 'Tonstad'
    },
    {
        zipCode: '4441',
        city: 'Tonstad'
    },
    {
        zipCode: '4443',
        city: 'Tjørhom'
    },
    {
        zipCode: '4460',
        city: 'Moi'
    },
    {
        zipCode: '4462',
        city: 'Hovsherad'
    },
    {
        zipCode: '4463',
        city: 'Ualand'
    },
    {
        zipCode: '4465',
        city: 'Moi'
    },
    {
        zipCode: '4473',
        city: 'Kvinlog'
    },
    {
        zipCode: '4480',
        city: 'Kvinesdal'
    },
    {
        zipCode: '4484',
        city: 'Øyestranda'
    },
    {
        zipCode: '4485',
        city: 'Feda'
    },
    {
        zipCode: '4490',
        city: 'Kvinesdal'
    },
    {
        zipCode: '4491',
        city: 'Kvinesdal'
    },
    {
        zipCode: '4492',
        city: 'Kvinesdal'
    },
    {
        zipCode: '4501',
        city: 'Mandal'
    },
    {
        zipCode: '4502',
        city: 'Mandal'
    },
    {
        zipCode: '4503',
        city: 'Mandal'
    },
    {
        zipCode: '4504',
        city: 'Mandal'
    },
    {
        zipCode: '4507',
        city: 'Mandal'
    },
    {
        zipCode: '4508',
        city: 'Mandal'
    },
    {
        zipCode: '4509',
        city: 'Mandal'
    },
    {
        zipCode: '4513',
        city: 'Mandal'
    },
    {
        zipCode: '4514',
        city: 'Mandal'
    },
    {
        zipCode: '4515',
        city: 'Mandal'
    },
    {
        zipCode: '4516',
        city: 'Mandal'
    },
    {
        zipCode: '4517',
        city: 'Mandal'
    },
    {
        zipCode: '4519',
        city: 'Holum'
    },
    {
        zipCode: '4520',
        city: 'Lindesnes'
    },
    {
        zipCode: '4521',
        city: 'Lindesnes'
    },
    {
        zipCode: '4522',
        city: 'Lindesnes'
    },
    {
        zipCode: '4523',
        city: 'Lindesnes'
    },
    {
        zipCode: '4524',
        city: 'Lindesnes'
    },
    {
        zipCode: '4525',
        city: 'Konsmo'
    },
    {
        zipCode: '4526',
        city: 'Konsmo'
    },
    {
        zipCode: '4528',
        city: 'Kollungtveit'
    },
    {
        zipCode: '4529',
        city: 'Byremo'
    },
    {
        zipCode: '4532',
        city: 'Øyslebø'
    },
    {
        zipCode: '4534',
        city: 'Marnardal'
    },
    {
        zipCode: '4535',
        city: 'Marnardal'
    },
    {
        zipCode: '4536',
        city: 'Bjelland'
    },
    {
        zipCode: '4540',
        city: 'Åseral'
    },
    {
        zipCode: '4541',
        city: 'Åseral'
    },
    {
        zipCode: '4544',
        city: 'Fossdal'
    },
    {
        zipCode: '4550',
        city: 'Farsund'
    },
    {
        zipCode: '4551',
        city: 'Farsund'
    },
    {
        zipCode: '4552',
        city: 'Farsund'
    },
    {
        zipCode: '4553',
        city: 'Farsund'
    },
    {
        zipCode: '4554',
        city: 'Farsund'
    },
    {
        zipCode: '4557',
        city: 'Vanse'
    },
    {
        zipCode: '4558',
        city: 'Vanse'
    },
    {
        zipCode: '4560',
        city: 'Vanse'
    },
    {
        zipCode: '4563',
        city: 'Borhaug'
    },
    {
        zipCode: '4575',
        city: 'Lyngdal'
    },
    {
        zipCode: '4576',
        city: 'Lyngdal'
    },
    {
        zipCode: '4577',
        city: 'Lyngdal'
    },
    {
        zipCode: '4579',
        city: 'Lyngdal'
    },
    {
        zipCode: '4580',
        city: 'Lyngdal'
    },
    {
        zipCode: '4586',
        city: 'Korshamn'
    },
    {
        zipCode: '4588',
        city: 'Kvås'
    },
    {
        zipCode: '4590',
        city: 'Snartemo'
    },
    {
        zipCode: '4595',
        city: 'Tingvatn'
    },
    {
        zipCode: '4596',
        city: 'Eiken'
    },
    {
        zipCode: '4597',
        city: 'Eiken'
    },
    {
        zipCode: '4604',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4605',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4606',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4608',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4609',
        city: 'Kardemomme by'
    },
    {
        zipCode: '4610',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4611',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4612',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4613',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4614',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4615',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4616',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4617',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4618',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4619',
        city: 'Mosby'
    },
    {
        zipCode: '4620',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4621',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4622',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4623',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4624',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4625',
        city: 'Flekkerøy'
    },
    {
        zipCode: '4626',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4628',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4629',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4630',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4631',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4632',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4633',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4634',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4635',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4636',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4637',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4638',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4639',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4640',
        city: 'Søgne'
    },
    {
        zipCode: '4641',
        city: 'Søgne'
    },
    {
        zipCode: '4642',
        city: 'Søgne'
    },
    {
        zipCode: '4643',
        city: 'Søgne'
    },
    {
        zipCode: '4644',
        city: 'Søgne'
    },
    {
        zipCode: '4645',
        city: 'Nodeland'
    },
    {
        zipCode: '4646',
        city: 'Finsland'
    },
    {
        zipCode: '4647',
        city: 'Brennåsen'
    },
    {
        zipCode: '4649',
        city: 'Finsland'
    },
    {
        zipCode: '4656',
        city: 'Hamresanden'
    },
    {
        zipCode: '4657',
        city: 'Kjevik'
    },
    {
        zipCode: '4658',
        city: 'Tveit'
    },
    {
        zipCode: '4661',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4662',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4663',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4664',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4665',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4666',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4670',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4671',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4672',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4673',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4674',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4675',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4676',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4677',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4678',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4679',
        city: 'Flekkerøy'
    },
    {
        zipCode: '4681',
        city: 'Søgne'
    },
    {
        zipCode: '4682',
        city: 'Søgne'
    },
    {
        zipCode: '4683',
        city: 'Søgne'
    },
    {
        zipCode: '4684',
        city: 'Brennåsen'
    },
    {
        zipCode: '4685',
        city: 'Nodeland'
    },
    {
        zipCode: '4686',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4687',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4688',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4689',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4691',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4693',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4694',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4695',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4696',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4697',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4698',
        city: 'Kristiansand s'
    },
    {
        zipCode: '4699',
        city: 'Tveit'
    },
    {
        zipCode: '4700',
        city: 'Vennesla'
    },
    {
        zipCode: '4701',
        city: 'Vennesla'
    },
    {
        zipCode: '4702',
        city: 'Vennesla'
    },
    {
        zipCode: '4703',
        city: 'Vennesla'
    },
    {
        zipCode: '4705',
        city: 'Øvrebø'
    },
    {
        zipCode: '4706',
        city: 'Vennesla'
    },
    {
        zipCode: '4707',
        city: 'Vennesla'
    },
    {
        zipCode: '4708',
        city: 'Vennesla'
    },
    {
        zipCode: '4715',
        city: 'Øvrebø'
    },
    {
        zipCode: '4720',
        city: 'Hægeland'
    },
    {
        zipCode: '4721',
        city: 'Hægeland'
    },
    {
        zipCode: '4724',
        city: 'Iveland'
    },
    {
        zipCode: '4725',
        city: 'Iveland'
    },
    {
        zipCode: '4730',
        city: 'Vatnestrøm'
    },
    {
        zipCode: '4733',
        city: 'Evje'
    },
    {
        zipCode: '4734',
        city: 'Evje'
    },
    {
        zipCode: '4735',
        city: 'Evje'
    },
    {
        zipCode: '4737',
        city: 'Hornnes'
    },
    {
        zipCode: '4741',
        city: 'Byglandsfjord'
    },
    {
        zipCode: '4742',
        city: 'Grendi'
    },
    {
        zipCode: '4744',
        city: 'Bygland'
    },
    {
        zipCode: '4745',
        city: 'Bygland'
    },
    {
        zipCode: '4746',
        city: 'Valle'
    },
    {
        zipCode: '4747',
        city: 'Valle'
    },
    {
        zipCode: '4748',
        city: 'Rysstad'
    },
    {
        zipCode: '4749',
        city: 'Rysstad'
    },
    {
        zipCode: '4754',
        city: 'Bykle'
    },
    {
        zipCode: '4755',
        city: 'Hovden i setesdal'
    },
    {
        zipCode: '4756',
        city: 'Hovden i setesdal'
    },
    {
        zipCode: '4760',
        city: 'Birkeland'
    },
    {
        zipCode: '4766',
        city: 'Herefoss'
    },
    {
        zipCode: '4768',
        city: 'Engesland'
    },
    {
        zipCode: '4770',
        city: 'Høvåg'
    },
    {
        zipCode: '4780',
        city: 'Brekkestø'
    },
    {
        zipCode: '4790',
        city: 'Lillesand'
    },
    {
        zipCode: '4791',
        city: 'Lillesand'
    },
    {
        zipCode: '4792',
        city: 'Lillesand'
    },
    {
        zipCode: '4793',
        city: 'Høvåg'
    },
    {
        zipCode: '4794',
        city: 'Lillesand'
    },
    {
        zipCode: '4795',
        city: 'Birkeland'
    },
    {
        zipCode: '4801',
        city: 'Arendal'
    },
    {
        zipCode: '4802',
        city: 'Arendal'
    },
    {
        zipCode: '4803',
        city: 'Arendal'
    },
    {
        zipCode: '4804',
        city: 'Arendal'
    },
    {
        zipCode: '4808',
        city: 'Arendal'
    },
    {
        zipCode: '4809',
        city: 'Arendal'
    },
    {
        zipCode: '4810',
        city: 'Eydehavn'
    },
    {
        zipCode: '4812',
        city: 'Kongshavn'
    },
    {
        zipCode: '4815',
        city: 'Saltrød'
    },
    {
        zipCode: '4816',
        city: 'Kolbjørnsvik'
    },
    {
        zipCode: '4817',
        city: 'His'
    },
    {
        zipCode: '4818',
        city: 'Færvik'
    },
    {
        zipCode: '4820',
        city: 'Froland'
    },
    {
        zipCode: '4821',
        city: 'Rykene'
    },
    {
        zipCode: '4822',
        city: 'Rykene'
    },
    {
        zipCode: '4823',
        city: 'Nedenes'
    },
    {
        zipCode: '4824',
        city: 'Bjorbekk'
    },
    {
        zipCode: '4825',
        city: 'Arendal'
    },
    {
        zipCode: '4827',
        city: 'Frolands verk'
    },
    {
        zipCode: '4828',
        city: 'Mjåvatn'
    },
    {
        zipCode: '4830',
        city: 'Hynnekleiv'
    },
    {
        zipCode: '4832',
        city: 'Mykland'
    },
    {
        zipCode: '4834',
        city: 'Risdal'
    },
    {
        zipCode: '4836',
        city: 'Arendal'
    },
    {
        zipCode: '4838',
        city: 'Arendal'
    },
    {
        zipCode: '4839',
        city: 'Arendal'
    },
    {
        zipCode: '4841',
        city: 'Arendal'
    },
    {
        zipCode: '4842',
        city: 'Arendal'
    },
    {
        zipCode: '4843',
        city: 'Arendal'
    },
    {
        zipCode: '4844',
        city: 'Arendal'
    },
    {
        zipCode: '4846',
        city: 'Arendal'
    },
    {
        zipCode: '4847',
        city: 'Arendal'
    },
    {
        zipCode: '4848',
        city: 'Arendal'
    },
    {
        zipCode: '4849',
        city: 'Arendal'
    },
    {
        zipCode: '4851',
        city: 'Saltrød'
    },
    {
        zipCode: '4852',
        city: 'Færvik'
    },
    {
        zipCode: '4853',
        city: 'His'
    },
    {
        zipCode: '4854',
        city: 'Nedenes'
    },
    {
        zipCode: '4855',
        city: 'Froland'
    },
    {
        zipCode: '4856',
        city: 'Arendal'
    },
    {
        zipCode: '4857',
        city: 'Arendal'
    },
    {
        zipCode: '4858',
        city: 'Arendal'
    },
    {
        zipCode: '4859',
        city: 'Arendal'
    },
    {
        zipCode: '4862',
        city: 'Eydehavn'
    },
    {
        zipCode: '4863',
        city: 'Nelaug'
    },
    {
        zipCode: '4864',
        city: 'Åmli'
    },
    {
        zipCode: '4865',
        city: 'Åmli'
    },
    {
        zipCode: '4868',
        city: 'Selåsvatn'
    },
    {
        zipCode: '4869',
        city: 'Dølemo'
    },
    {
        zipCode: '4870',
        city: 'Fevik'
    },
    {
        zipCode: '4876',
        city: 'Grimstad'
    },
    {
        zipCode: '4877',
        city: 'Grimstad'
    },
    {
        zipCode: '4878',
        city: 'Grimstad'
    },
    {
        zipCode: '4879',
        city: 'Grimstad'
    },
    {
        zipCode: '4884',
        city: 'Grimstad'
    },
    {
        zipCode: '4885',
        city: 'Grimstad'
    },
    {
        zipCode: '4886',
        city: 'Grimstad'
    },
    {
        zipCode: '4887',
        city: 'Grimstad'
    },
    {
        zipCode: '4888',
        city: 'Homborsund'
    },
    {
        zipCode: '4889',
        city: 'Fevik'
    },
    {
        zipCode: '4891',
        city: 'Grimstad'
    },
    {
        zipCode: '4892',
        city: 'Grimstad'
    },
    {
        zipCode: '4893',
        city: 'Grimstad'
    },
    {
        zipCode: '4894',
        city: 'Grimstad'
    },
    {
        zipCode: '4896',
        city: 'Grimstad'
    },
    {
        zipCode: '4898',
        city: 'Grimstad'
    },
    {
        zipCode: '4900',
        city: 'Tvedestrand'
    },
    {
        zipCode: '4901',
        city: 'Tvedestrand'
    },
    {
        zipCode: '4902',
        city: 'Tvedestrand'
    },
    {
        zipCode: '4909',
        city: 'Songe'
    },
    {
        zipCode: '4910',
        city: 'Lyngør'
    },
    {
        zipCode: '4912',
        city: 'Gjeving'
    },
    {
        zipCode: '4915',
        city: 'Vestre sandøya'
    },
    {
        zipCode: '4916',
        city: 'Borøy'
    },
    {
        zipCode: '4920',
        city: 'Staubø'
    },
    {
        zipCode: '4921',
        city: 'Staubø'
    },
    {
        zipCode: '4934',
        city: 'Nes verk'
    },
    {
        zipCode: '4950',
        city: 'Risør'
    },
    {
        zipCode: '4951',
        city: 'Risør'
    },
    {
        zipCode: '4952',
        city: 'Risør'
    },
    {
        zipCode: '4953',
        city: 'Risør'
    },
    {
        zipCode: '4955',
        city: 'Risør'
    },
    {
        zipCode: '4956',
        city: 'Risør'
    },
    {
        zipCode: '4957',
        city: 'Risør'
    },
    {
        zipCode: '4971',
        city: 'Sundebru'
    },
    {
        zipCode: '4972',
        city: 'Gjerstad'
    },
    {
        zipCode: '4973',
        city: 'Vegårshei'
    },
    {
        zipCode: '4974',
        city: 'Søndeled'
    },
    {
        zipCode: '4980',
        city: 'Gjerstad'
    },
    {
        zipCode: '4985',
        city: 'Vegårshei'
    },
    {
        zipCode: '4990',
        city: 'Søndeled'
    },
    {
        zipCode: '4993',
        city: 'Sundebru'
    },
    {
        zipCode: '4994',
        city: 'Akland'
    },
    {
        zipCode: '5003',
        city: 'Bergen'
    },
    {
        zipCode: '5004',
        city: 'Bergen'
    },
    {
        zipCode: '5005',
        city: 'Bergen'
    },
    {
        zipCode: '5006',
        city: 'Bergen'
    },
    {
        zipCode: '5007',
        city: 'Bergen'
    },
    {
        zipCode: '5008',
        city: 'Bergen'
    },
    {
        zipCode: '5009',
        city: 'Bergen'
    },
    {
        zipCode: '5010',
        city: 'Bergen'
    },
    {
        zipCode: '5011',
        city: 'Bergen'
    },
    {
        zipCode: '5012',
        city: 'Bergen'
    },
    {
        zipCode: '5013',
        city: 'Bergen'
    },
    {
        zipCode: '5014',
        city: 'Bergen'
    },
    {
        zipCode: '5015',
        city: 'Bergen'
    },
    {
        zipCode: '5016',
        city: 'Bergen'
    },
    {
        zipCode: '5017',
        city: 'Bergen'
    },
    {
        zipCode: '5018',
        city: 'Bergen'
    },
    {
        zipCode: '5019',
        city: 'Bergen'
    },
    {
        zipCode: '5020',
        city: 'Bergen'
    },
    {
        zipCode: '5021',
        city: 'Bergen'
    },
    {
        zipCode: '5022',
        city: 'Bergen'
    },
    {
        zipCode: '5031',
        city: 'Bergen'
    },
    {
        zipCode: '5032',
        city: 'Bergen'
    },
    {
        zipCode: '5033',
        city: 'Bergen'
    },
    {
        zipCode: '5034',
        city: 'Bergen'
    },
    {
        zipCode: '5035',
        city: 'Bergen'
    },
    {
        zipCode: '5036',
        city: 'Bergen'
    },
    {
        zipCode: '5037',
        city: 'Bergen'
    },
    {
        zipCode: '5038',
        city: 'Bergen'
    },
    {
        zipCode: '5039',
        city: 'Bergen'
    },
    {
        zipCode: '5041',
        city: 'Bergen'
    },
    {
        zipCode: '5042',
        city: 'Bergen'
    },
    {
        zipCode: '5043',
        city: 'Bergen'
    },
    {
        zipCode: '5045',
        city: 'Bergen'
    },
    {
        zipCode: '5052',
        city: 'Bergen'
    },
    {
        zipCode: '5053',
        city: 'Bergen'
    },
    {
        zipCode: '5054',
        city: 'Bergen'
    },
    {
        zipCode: '5055',
        city: 'Bergen'
    },
    {
        zipCode: '5056',
        city: 'Bergen'
    },
    {
        zipCode: '5057',
        city: 'Bergen'
    },
    {
        zipCode: '5058',
        city: 'Bergen'
    },
    {
        zipCode: '5059',
        city: 'Bergen'
    },
    {
        zipCode: '5063',
        city: 'Bergen'
    },
    {
        zipCode: '5067',
        city: 'Bergen'
    },
    {
        zipCode: '5068',
        city: 'Bergen'
    },
    {
        zipCode: '5072',
        city: 'Bergen'
    },
    {
        zipCode: '5073',
        city: 'Bergen'
    },
    {
        zipCode: '5075',
        city: 'Bergen'
    },
    {
        zipCode: '5081',
        city: 'Bergen'
    },
    {
        zipCode: '5082',
        city: 'Bergen'
    },
    {
        zipCode: '5089',
        city: 'Bergen'
    },
    {
        zipCode: '5093',
        city: 'Bergen'
    },
    {
        zipCode: '5094',
        city: 'Bergen'
    },
    {
        zipCode: '5096',
        city: 'Bergen'
    },
    {
        zipCode: '5097',
        city: 'Bergen'
    },
    {
        zipCode: '5098',
        city: 'Bergen'
    },
    {
        zipCode: '5099',
        city: 'Bergen'
    },
    {
        zipCode: '5101',
        city: 'Eidsvågneset'
    },
    {
        zipCode: '5104',
        city: 'Eidsvåg i åsane'
    },
    {
        zipCode: '5105',
        city: 'Eidsvåg i åsane'
    },
    {
        zipCode: '5106',
        city: 'Øvre ervik'
    },
    {
        zipCode: '5107',
        city: 'Salhus'
    },
    {
        zipCode: '5108',
        city: 'Hordvik'
    },
    {
        zipCode: '5109',
        city: 'Hylkje'
    },
    {
        zipCode: '5111',
        city: 'Breistein'
    },
    {
        zipCode: '5113',
        city: 'Tertnes'
    },
    {
        zipCode: '5114',
        city: 'Tertnes'
    },
    {
        zipCode: '5115',
        city: 'Ulset'
    },
    {
        zipCode: '5116',
        city: 'Ulset'
    },
    {
        zipCode: '5117',
        city: 'Ulset'
    },
    {
        zipCode: '5118',
        city: 'Ulset'
    },
    {
        zipCode: '5119',
        city: 'Ulset'
    },
    {
        zipCode: '5121',
        city: 'Ulset'
    },
    {
        zipCode: '5122',
        city: 'Morvik'
    },
    {
        zipCode: '5124',
        city: 'Morvik'
    },
    {
        zipCode: '5130',
        city: 'Nyborg'
    },
    {
        zipCode: '5131',
        city: 'Nyborg'
    },
    {
        zipCode: '5132',
        city: 'Nyborg'
    },
    {
        zipCode: '5134',
        city: 'Flaktveit'
    },
    {
        zipCode: '5135',
        city: 'Flaktveit'
    },
    {
        zipCode: '5136',
        city: 'Mjølkeråen'
    },
    {
        zipCode: '5137',
        city: 'Mjølkeråen'
    },
    {
        zipCode: '5141',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5142',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5143',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5144',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5145',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5146',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5147',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5148',
        city: 'Fyllingsdalen'
    },
    {
        zipCode: '5151',
        city: 'Straumsgrend'
    },
    {
        zipCode: '5152',
        city: 'Bønes'
    },
    {
        zipCode: '5153',
        city: 'Bønes'
    },
    {
        zipCode: '5154',
        city: 'Bønes'
    },
    {
        zipCode: '5155',
        city: 'Bønes'
    },
    {
        zipCode: '5160',
        city: 'Laksevåg'
    },
    {
        zipCode: '5161',
        city: 'Laksevåg'
    },
    {
        zipCode: '5162',
        city: 'Laksevåg'
    },
    {
        zipCode: '5163',
        city: 'Laksevåg'
    },
    {
        zipCode: '5164',
        city: 'Laksevåg'
    },
    {
        zipCode: '5165',
        city: 'Laksevåg'
    },
    {
        zipCode: '5170',
        city: 'Bjørndalstræ'
    },
    {
        zipCode: '5171',
        city: 'Loddefjord'
    },
    {
        zipCode: '5172',
        city: 'Loddefjord'
    },
    {
        zipCode: '5173',
        city: 'Loddefjord'
    },
    {
        zipCode: '5174',
        city: 'Mathopen'
    },
    {
        zipCode: '5176',
        city: 'Loddefjord'
    },
    {
        zipCode: '5177',
        city: 'Bjørøyhamn'
    },
    {
        zipCode: '5178',
        city: 'Loddefjord'
    },
    {
        zipCode: '5179',
        city: 'Godvik'
    },
    {
        zipCode: '5183',
        city: 'Olsvik'
    },
    {
        zipCode: '5184',
        city: 'Olsvik'
    },
    {
        zipCode: '5200',
        city: 'Os'
    },
    {
        zipCode: '5201',
        city: 'Os'
    },
    {
        zipCode: '5202',
        city: 'Os'
    },
    {
        zipCode: '5203',
        city: 'Os'
    },
    {
        zipCode: '5206',
        city: 'Os'
    },
    {
        zipCode: '5207',
        city: 'Søfteland'
    },
    {
        zipCode: '5208',
        city: 'Os'
    },
    {
        zipCode: '5209',
        city: 'Os'
    },
    {
        zipCode: '5210',
        city: 'Os'
    },
    {
        zipCode: '5211',
        city: 'Os'
    },
    {
        zipCode: '5212',
        city: 'Søfteland'
    },
    {
        zipCode: '5213',
        city: 'Lepsøy'
    },
    {
        zipCode: '5214',
        city: 'Lysekloster'
    },
    {
        zipCode: '5215',
        city: 'Lysekloster'
    },
    {
        zipCode: '5216',
        city: 'Lepsøy'
    },
    {
        zipCode: '5217',
        city: 'Hagavik'
    },
    {
        zipCode: '5218',
        city: 'Nordstrøno'
    },
    {
        zipCode: '5221',
        city: 'Nesttun'
    },
    {
        zipCode: '5222',
        city: 'Nesttun'
    },
    {
        zipCode: '5223',
        city: 'Nesttun'
    },
    {
        zipCode: '5224',
        city: 'Nesttun'
    },
    {
        zipCode: '5225',
        city: 'Nesttun'
    },
    {
        zipCode: '5226',
        city: 'Nesttun'
    },
    {
        zipCode: '5227',
        city: 'Nesttun'
    },
    {
        zipCode: '5228',
        city: 'Nesttun'
    },
    {
        zipCode: '5229',
        city: 'Kalandseidet'
    },
    {
        zipCode: '5230',
        city: 'Paradis'
    },
    {
        zipCode: '5231',
        city: 'Paradis'
    },
    {
        zipCode: '5232',
        city: 'Paradis'
    },
    {
        zipCode: '5235',
        city: 'Rådal'
    },
    {
        zipCode: '5236',
        city: 'Rådal'
    },
    {
        zipCode: '5237',
        city: 'Rådal'
    },
    {
        zipCode: '5238',
        city: 'Rådal'
    },
    {
        zipCode: '5239',
        city: 'Rådal'
    },
    {
        zipCode: '5243',
        city: 'Fana'
    },
    {
        zipCode: '5244',
        city: 'Fana'
    },
    {
        zipCode: '5251',
        city: 'Søreidgrend'
    },
    {
        zipCode: '5252',
        city: 'Søreidgrend'
    },
    {
        zipCode: '5253',
        city: 'Sandsli'
    },
    {
        zipCode: '5254',
        city: 'Sandsli'
    },
    {
        zipCode: '5257',
        city: 'Kokstad'
    },
    {
        zipCode: '5258',
        city: 'Blomsterdalen'
    },
    {
        zipCode: '5259',
        city: 'Hjellestad'
    },
    {
        zipCode: '5260',
        city: 'Indre arna'
    },
    {
        zipCode: '5261',
        city: 'Indre arna'
    },
    {
        zipCode: '5262',
        city: 'Arnatveit'
    },
    {
        zipCode: '5263',
        city: 'Trengereid'
    },
    {
        zipCode: '5264',
        city: 'Garnes'
    },
    {
        zipCode: '5265',
        city: 'Ytre arna'
    },
    {
        zipCode: '5267',
        city: 'Espeland'
    },
    {
        zipCode: '5268',
        city: 'Haukeland'
    },
    {
        zipCode: '5281',
        city: 'Valestrandsfossen'
    },
    {
        zipCode: '5282',
        city: 'Lonevåg'
    },
    {
        zipCode: '5283',
        city: 'Fotlandsvåg'
    },
    {
        zipCode: '5284',
        city: 'Tyssebotnen'
    },
    {
        zipCode: '5285',
        city: 'Bruvik'
    },
    {
        zipCode: '5286',
        city: 'Haus'
    },
    {
        zipCode: '5291',
        city: 'Valestrandsfossen'
    },
    {
        zipCode: '5293',
        city: 'Lonevåg'
    },
    {
        zipCode: '5299',
        city: 'Haus'
    },
    {
        zipCode: '5300',
        city: 'Kleppestø'
    },
    {
        zipCode: '5301',
        city: 'Kleppestø'
    },
    {
        zipCode: '5302',
        city: 'Strusshamn'
    },
    {
        zipCode: '5303',
        city: 'Follese'
    },
    {
        zipCode: '5304',
        city: 'Hetlevik'
    },
    {
        zipCode: '5305',
        city: 'Florvåg'
    },
    {
        zipCode: '5306',
        city: 'Erdal'
    },
    {
        zipCode: '5307',
        city: 'Ask'
    },
    {
        zipCode: '5308',
        city: 'Kleppestø'
    },
    {
        zipCode: '5309',
        city: 'Kleppestø'
    },
    {
        zipCode: '5310',
        city: 'Hauglandshella'
    },
    {
        zipCode: '5311',
        city: 'Kjerrgarden'
    },
    {
        zipCode: '5314',
        city: 'Kjerrgarden'
    },
    {
        zipCode: '5315',
        city: 'Herdla'
    },
    {
        zipCode: '5318',
        city: 'Strusshamn'
    },
    {
        zipCode: '5319',
        city: 'Kleppestø'
    },
    {
        zipCode: '5321',
        city: 'Kleppestø'
    },
    {
        zipCode: '5322',
        city: 'Kleppestø'
    },
    {
        zipCode: '5323',
        city: 'Kleppestø'
    },
    {
        zipCode: '5325',
        city: 'Follese'
    },
    {
        zipCode: '5326',
        city: 'Ask'
    },
    {
        zipCode: '5327',
        city: 'Hauglandshella'
    },
    {
        zipCode: '5329',
        city: 'Florvåg'
    },
    {
        zipCode: '5331',
        city: 'Rong'
    },
    {
        zipCode: '5333',
        city: 'Tjeldstø'
    },
    {
        zipCode: '5334',
        city: 'Hellesøy'
    },
    {
        zipCode: '5335',
        city: 'Hernar'
    },
    {
        zipCode: '5336',
        city: 'Tjeldstø'
    },
    {
        zipCode: '5337',
        city: 'Rong'
    },
    {
        zipCode: '5341',
        city: 'Straume'
    },
    {
        zipCode: '5342',
        city: 'Straume'
    },
    {
        zipCode: '5343',
        city: 'Straume'
    },
    {
        zipCode: '5345',
        city: 'Knarrevik'
    },
    {
        zipCode: '5346',
        city: 'Ågotnes'
    },
    {
        zipCode: '5347',
        city: 'Ågotnes'
    },
    {
        zipCode: '5350',
        city: 'Brattholmen'
    },
    {
        zipCode: '5353',
        city: 'Straume'
    },
    {
        zipCode: '5354',
        city: 'Straume'
    },
    {
        zipCode: '5355',
        city: 'Knarrevik'
    },
    {
        zipCode: '5357',
        city: 'Fjell'
    },
    {
        zipCode: '5358',
        city: 'Fjell'
    },
    {
        zipCode: '5360',
        city: 'Kolltveit'
    },
    {
        zipCode: '5363',
        city: 'Ågotnes'
    },
    {
        zipCode: '5365',
        city: 'Turøy'
    },
    {
        zipCode: '5366',
        city: 'Misje'
    },
    {
        zipCode: '5371',
        city: 'Skogsvåg'
    },
    {
        zipCode: '5374',
        city: 'Steinsland'
    },
    {
        zipCode: '5378',
        city: 'Klokkarvik'
    },
    {
        zipCode: '5379',
        city: 'Steinsland'
    },
    {
        zipCode: '5380',
        city: 'Tælavåg'
    },
    {
        zipCode: '5381',
        city: 'Glesvær'
    },
    {
        zipCode: '5382',
        city: 'Skogsvåg'
    },
    {
        zipCode: '5384',
        city: 'Torangsvåg'
    },
    {
        zipCode: '5385',
        city: 'Bakkasund'
    },
    {
        zipCode: '5387',
        city: 'Møkster'
    },
    {
        zipCode: '5388',
        city: 'Litlakalsøy'
    },
    {
        zipCode: '5392',
        city: 'Storebø'
    },
    {
        zipCode: '5393',
        city: 'Storebø'
    },
    {
        zipCode: '5394',
        city: 'Kolbeinsvik'
    },
    {
        zipCode: '5396',
        city: 'Vestre vinnesvåg'
    },
    {
        zipCode: '5397',
        city: 'Bekkjarvik'
    },
    {
        zipCode: '5398',
        city: 'Stolmen'
    },
    {
        zipCode: '5399',
        city: 'Bekkjarvik'
    },
    {
        zipCode: '5401',
        city: 'Stord'
    },
    {
        zipCode: '5402',
        city: 'Stord'
    },
    {
        zipCode: '5403',
        city: 'Stord'
    },
    {
        zipCode: '5404',
        city: 'Stord'
    },
    {
        zipCode: '5406',
        city: 'Stord'
    },
    {
        zipCode: '5407',
        city: 'Stord'
    },
    {
        zipCode: '5408',
        city: 'Sagvåg'
    },
    {
        zipCode: '5409',
        city: 'Stord'
    },
    {
        zipCode: '5410',
        city: 'Sagvåg'
    },
    {
        zipCode: '5411',
        city: 'Stord'
    },
    {
        zipCode: '5412',
        city: 'Stord'
    },
    {
        zipCode: '5413',
        city: 'Huglo'
    },
    {
        zipCode: '5414',
        city: 'Stord'
    },
    {
        zipCode: '5415',
        city: 'Stord'
    },
    {
        zipCode: '5416',
        city: 'Stord'
    },
    {
        zipCode: '5417',
        city: 'Stord'
    },
    {
        zipCode: '5418',
        city: 'Fitjar'
    },
    {
        zipCode: '5419',
        city: 'Fitjar'
    },
    {
        zipCode: '5420',
        city: 'Rubbestadneset'
    },
    {
        zipCode: '5423',
        city: 'Brandasund'
    },
    {
        zipCode: '5427',
        city: 'Urangsvåg'
    },
    {
        zipCode: '5428',
        city: 'Foldrøyhamn'
    },
    {
        zipCode: '5430',
        city: 'Bremnes'
    },
    {
        zipCode: '5437',
        city: 'Finnås'
    },
    {
        zipCode: '5440',
        city: 'Mosterhamn'
    },
    {
        zipCode: '5443',
        city: 'Bømlo'
    },
    {
        zipCode: '5444',
        city: 'Espevær'
    },
    {
        zipCode: '5445',
        city: 'Bremnes'
    },
    {
        zipCode: '5447',
        city: 'Mosterhamn'
    },
    {
        zipCode: '5449',
        city: 'Bømlo'
    },
    {
        zipCode: '5450',
        city: 'Sunde i sunnhordland'
    },
    {
        zipCode: '5451',
        city: 'Valen'
    },
    {
        zipCode: '5452',
        city: 'Sandvoll'
    },
    {
        zipCode: '5453',
        city: 'Utåker'
    },
    {
        zipCode: '5454',
        city: 'Sæbøvik'
    },
    {
        zipCode: '5455',
        city: 'Halsnøy kloster'
    },
    {
        zipCode: '5457',
        city: 'Høylandsbygd'
    },
    {
        zipCode: '5458',
        city: 'Arnavik'
    },
    {
        zipCode: '5459',
        city: 'Fjelberg'
    },
    {
        zipCode: '5460',
        city: 'Husnes'
    },
    {
        zipCode: '5462',
        city: 'Herøysundet'
    },
    {
        zipCode: '5463',
        city: 'Uskedalen'
    },
    {
        zipCode: '5464',
        city: 'Dimmelsvik'
    },
    {
        zipCode: '5465',
        city: 'Uskedalen'
    },
    {
        zipCode: '5470',
        city: 'Rosendal'
    },
    {
        zipCode: '5472',
        city: 'Seimsfoss'
    },
    {
        zipCode: '5473',
        city: 'Snilstveitøy'
    },
    {
        zipCode: '5474',
        city: 'Løfallstrand'
    },
    {
        zipCode: '5475',
        city: 'Ænes'
    },
    {
        zipCode: '5476',
        city: 'Mauranger'
    },
    {
        zipCode: '5480',
        city: 'Husnes'
    },
    {
        zipCode: '5484',
        city: 'Sæbøvik'
    },
    {
        zipCode: '5486',
        city: 'Rosendal'
    },
    {
        zipCode: '5498',
        city: 'Matre'
    },
    {
        zipCode: '5499',
        city: 'Åkra'
    },
    {
        zipCode: '5501',
        city: 'Haugesund'
    },
    {
        zipCode: '5502',
        city: 'Haugesund'
    },
    {
        zipCode: '5503',
        city: 'Haugesund'
    },
    {
        zipCode: '5504',
        city: 'Haugesund'
    },
    {
        zipCode: '5505',
        city: 'Haugesund'
    },
    {
        zipCode: '5506',
        city: 'Haugesund'
    },
    {
        zipCode: '5507',
        city: 'Haugesund'
    },
    {
        zipCode: '5508',
        city: 'Karmsund'
    },
    {
        zipCode: '5509',
        city: 'Haugesund'
    },
    {
        zipCode: '5511',
        city: 'Haugesund'
    },
    {
        zipCode: '5512',
        city: 'Haugesund'
    },
    {
        zipCode: '5514',
        city: 'Haugesund'
    },
    {
        zipCode: '5515',
        city: 'Haugesund'
    },
    {
        zipCode: '5516',
        city: 'Haugesund'
    },
    {
        zipCode: '5517',
        city: 'Haugesund'
    },
    {
        zipCode: '5518',
        city: 'Haugesund'
    },
    {
        zipCode: '5519',
        city: 'Haugesund'
    },
    {
        zipCode: '5521',
        city: 'Haugesund'
    },
    {
        zipCode: '5522',
        city: 'Haugesund'
    },
    {
        zipCode: '5523',
        city: 'Haugesund'
    },
    {
        zipCode: '5525',
        city: 'Haugesund'
    },
    {
        zipCode: '5527',
        city: 'Haugesund'
    },
    {
        zipCode: '5528',
        city: 'Haugesund'
    },
    {
        zipCode: '5529',
        city: 'Haugesund'
    },
    {
        zipCode: '5531',
        city: 'Haugesund'
    },
    {
        zipCode: '5532',
        city: 'Haugesund'
    },
    {
        zipCode: '5533',
        city: 'Haugesund'
    },
    {
        zipCode: '5534',
        city: 'Haugesund'
    },
    {
        zipCode: '5535',
        city: 'Haugesund'
    },
    {
        zipCode: '5536',
        city: 'Haugesund'
    },
    {
        zipCode: '5537',
        city: 'Haugesund'
    },
    {
        zipCode: '5538',
        city: 'Haugesund'
    },
    {
        zipCode: '5541',
        city: 'Kolnes'
    },
    {
        zipCode: '5542',
        city: 'Karmsund'
    },
    {
        zipCode: '5544',
        city: 'Vormedal'
    },
    {
        zipCode: '5545',
        city: 'Vormedal'
    },
    {
        zipCode: '5546',
        city: 'Røyksund'
    },
    {
        zipCode: '5547',
        city: 'Utsira'
    },
    {
        zipCode: '5548',
        city: 'Feøy'
    },
    {
        zipCode: '5549',
        city: 'Røvær'
    },
    {
        zipCode: '5550',
        city: 'Sveio'
    },
    {
        zipCode: '5551',
        city: 'Auklandshamn'
    },
    {
        zipCode: '5554',
        city: 'Valevåg'
    },
    {
        zipCode: '5555',
        city: 'Førde i hordaland'
    },
    {
        zipCode: '5556',
        city: 'Førde i hordaland'
    },
    {
        zipCode: '5559',
        city: 'Sveio'
    },
    {
        zipCode: '5560',
        city: 'Nedstrand'
    },
    {
        zipCode: '5561',
        city: 'Bokn'
    },
    {
        zipCode: '5562',
        city: 'Nedstrand'
    },
    {
        zipCode: '5563',
        city: 'Førresfjorden'
    },
    {
        zipCode: '5565',
        city: 'Tysværvåg'
    },
    {
        zipCode: '5566',
        city: 'Hervik'
    },
    {
        zipCode: '5567',
        city: 'Skjoldastraumen'
    },
    {
        zipCode: '5568',
        city: 'Vikebygd'
    },
    {
        zipCode: '5569',
        city: 'Bokn'
    },
    {
        zipCode: '5570',
        city: 'Aksdal'
    },
    {
        zipCode: '5574',
        city: 'Skjold'
    },
    {
        zipCode: '5575',
        city: 'Aksdal'
    },
    {
        zipCode: '5576',
        city: 'Øvre vats'
    },
    {
        zipCode: '5578',
        city: 'Nedre vats'
    },
    {
        zipCode: '5580',
        city: 'Ølen'
    },
    {
        zipCode: '5582',
        city: 'Ølensvåg'
    },
    {
        zipCode: '5583',
        city: 'Vikedal'
    },
    {
        zipCode: '5584',
        city: 'Bjoa'
    },
    {
        zipCode: '5585',
        city: 'Sandeid'
    },
    {
        zipCode: '5586',
        city: 'Vikedal'
    },
    {
        zipCode: '5588',
        city: 'Ølen'
    },
    {
        zipCode: '5589',
        city: 'Sandeid'
    },
    {
        zipCode: '5590',
        city: 'Etne'
    },
    {
        zipCode: '5591',
        city: 'Etne'
    },
    {
        zipCode: '5593',
        city: 'Skånevik'
    },
    {
        zipCode: '5594',
        city: 'Skånevik'
    },
    {
        zipCode: '5595',
        city: 'Førresfjorden'
    },
    {
        zipCode: '5596',
        city: 'Markhus'
    },
    {
        zipCode: '5598',
        city: 'Fjæra'
    },
    {
        zipCode: '5600',
        city: 'Norheimsund'
    },
    {
        zipCode: '5601',
        city: 'Norheimsund'
    },
    {
        zipCode: '5602',
        city: 'Norheimsund'
    },
    {
        zipCode: '5604',
        city: 'Øystese'
    },
    {
        zipCode: '5605',
        city: 'Ålvik'
    },
    {
        zipCode: '5610',
        city: 'Øystese'
    },
    {
        zipCode: '5612',
        city: 'Steinstø'
    },
    {
        zipCode: '5614',
        city: 'Ålvik'
    },
    {
        zipCode: '5620',
        city: 'Tørvikbygd'
    },
    {
        zipCode: '5626',
        city: 'Kysnesstrand'
    },
    {
        zipCode: '5627',
        city: 'Jondal'
    },
    {
        zipCode: '5628',
        city: 'Herand'
    },
    {
        zipCode: '5629',
        city: 'Jondal'
    },
    {
        zipCode: '5630',
        city: 'Strandebarm'
    },
    {
        zipCode: '5631',
        city: 'Strandebarm'
    },
    {
        zipCode: '5632',
        city: 'Omastrand'
    },
    {
        zipCode: '5633',
        city: 'Omastrand'
    },
    {
        zipCode: '5635',
        city: 'Hatlestrand'
    },
    {
        zipCode: '5636',
        city: 'Varaldsøy'
    },
    {
        zipCode: '5637',
        city: 'Ølve'
    },
    {
        zipCode: '5640',
        city: 'Eikelandsosen'
    },
    {
        zipCode: '5641',
        city: 'Fusa'
    },
    {
        zipCode: '5642',
        city: 'Holmefjord'
    },
    {
        zipCode: '5643',
        city: 'Strandvik'
    },
    {
        zipCode: '5644',
        city: 'Sævareid'
    },
    {
        zipCode: '5645',
        city: 'Sævareid'
    },
    {
        zipCode: '5646',
        city: 'Nordtveitgrend'
    },
    {
        zipCode: '5647',
        city: 'Baldersheim'
    },
    {
        zipCode: '5648',
        city: 'Fusa'
    },
    {
        zipCode: '5649',
        city: 'Eikelandsosen'
    },
    {
        zipCode: '5650',
        city: 'Tysse'
    },
    {
        zipCode: '5651',
        city: 'Tysse'
    },
    {
        zipCode: '5652',
        city: 'Årland'
    },
    {
        zipCode: '5653',
        city: 'Årland'
    },
    {
        zipCode: '5680',
        city: 'Tysnes'
    },
    {
        zipCode: '5683',
        city: 'Reksteren'
    },
    {
        zipCode: '5685',
        city: 'Uggdal'
    },
    {
        zipCode: '5687',
        city: 'Flatråker'
    },
    {
        zipCode: '5690',
        city: 'Lundegrend'
    },
    {
        zipCode: '5693',
        city: 'Årbakka'
    },
    {
        zipCode: '5694',
        city: 'Onarheim'
    },
    {
        zipCode: '5695',
        city: 'Uggdal'
    },
    {
        zipCode: '5696',
        city: 'Tysnes'
    },
    {
        zipCode: '5700',
        city: 'Voss'
    },
    {
        zipCode: '5701',
        city: 'Voss'
    },
    {
        zipCode: '5702',
        city: 'Voss'
    },
    {
        zipCode: '5703',
        city: 'Voss'
    },
    {
        zipCode: '5704',
        city: 'Voss'
    },
    {
        zipCode: '5705',
        city: 'Voss'
    },
    {
        zipCode: '5706',
        city: 'Voss'
    },
    {
        zipCode: '5707',
        city: 'Evanger'
    },
    {
        zipCode: '5708',
        city: 'Voss'
    },
    {
        zipCode: '5709',
        city: 'Voss'
    },
    {
        zipCode: '5710',
        city: 'Skulestadmo'
    },
    {
        zipCode: '5711',
        city: 'Skulestadmo'
    },
    {
        zipCode: '5712',
        city: 'Vossestrand'
    },
    {
        zipCode: '5713',
        city: 'Vossestrand'
    },
    {
        zipCode: '5714',
        city: 'Voss'
    },
    {
        zipCode: '5715',
        city: 'Stalheim'
    },
    {
        zipCode: '5718',
        city: 'Myrdal'
    },
    {
        zipCode: '5719',
        city: 'Finse'
    },
    {
        zipCode: '5720',
        city: 'Stanghelle'
    },
    {
        zipCode: '5721',
        city: 'Dalekvam'
    },
    {
        zipCode: '5722',
        city: 'Dalekvam'
    },
    {
        zipCode: '5723',
        city: 'Bolstadøyri'
    },
    {
        zipCode: '5724',
        city: 'Stanghelle'
    },
    {
        zipCode: '5725',
        city: 'Vaksdal'
    },
    {
        zipCode: '5726',
        city: 'Vaksdal'
    },
    {
        zipCode: '5727',
        city: 'Stamnes'
    },
    {
        zipCode: '5728',
        city: 'Eidslandet'
    },
    {
        zipCode: '5729',
        city: 'Modalen'
    },
    {
        zipCode: '5730',
        city: 'Ulvik'
    },
    {
        zipCode: '5731',
        city: 'Ulvik'
    },
    {
        zipCode: '5732',
        city: 'Modalen'
    },
    {
        zipCode: '5733',
        city: 'Granvin'
    },
    {
        zipCode: '5734',
        city: 'Vallavik'
    },
    {
        zipCode: '5736',
        city: 'Granvin'
    },
    {
        zipCode: '5741',
        city: 'Aurland'
    },
    {
        zipCode: '5742',
        city: 'Flåm'
    },
    {
        zipCode: '5743',
        city: 'Flåm'
    },
    {
        zipCode: '5745',
        city: 'Aurland'
    },
    {
        zipCode: '5746',
        city: 'Undredal'
    },
    {
        zipCode: '5747',
        city: 'Gudvangen'
    },
    {
        zipCode: '5748',
        city: 'Styvi'
    },
    {
        zipCode: '5750',
        city: 'Odda'
    },
    {
        zipCode: '5751',
        city: 'Odda'
    },
    {
        zipCode: '5752',
        city: 'Odda'
    },
    {
        zipCode: '5760',
        city: 'Røldal'
    },
    {
        zipCode: '5763',
        city: 'Skare'
    },
    {
        zipCode: '5770',
        city: 'Tyssedal'
    },
    {
        zipCode: '5773',
        city: 'Hovland'
    },
    {
        zipCode: '5775',
        city: 'Nå'
    },
    {
        zipCode: '5776',
        city: 'Nå'
    },
    {
        zipCode: '5777',
        city: 'Grimo'
    },
    {
        zipCode: '5778',
        city: 'Utne'
    },
    {
        zipCode: '5779',
        city: 'Utne'
    },
    {
        zipCode: '5780',
        city: 'Kinsarvik'
    },
    {
        zipCode: '5781',
        city: 'Lofthus'
    },
    {
        zipCode: '5782',
        city: 'Kinsarvik'
    },
    {
        zipCode: '5783',
        city: 'Eidfjord'
    },
    {
        zipCode: '5784',
        city: 'Øvre eidfjord'
    },
    {
        zipCode: '5785',
        city: 'Vøringsfoss'
    },
    {
        zipCode: '5786',
        city: 'Eidfjord'
    },
    {
        zipCode: '5787',
        city: 'Lofthus'
    },
    {
        zipCode: '5788',
        city: 'Kinsarvik'
    },
    {
        zipCode: '5802',
        city: 'Bergen'
    },
    {
        zipCode: '5803',
        city: 'Bergen'
    },
    {
        zipCode: '5804',
        city: 'Bergen'
    },
    {
        zipCode: '5805',
        city: 'Bergen'
    },
    {
        zipCode: '5806',
        city: 'Bergen'
    },
    {
        zipCode: '5807',
        city: 'Bergen'
    },
    {
        zipCode: '5808',
        city: 'Bergen'
    },
    {
        zipCode: '5809',
        city: 'Bergen'
    },
    {
        zipCode: '5810',
        city: 'Bergen'
    },
    {
        zipCode: '5811',
        city: 'Bergen'
    },
    {
        zipCode: '5812',
        city: 'Bergen'
    },
    {
        zipCode: '5813',
        city: 'Bergen'
    },
    {
        zipCode: '5814',
        city: 'Bergen'
    },
    {
        zipCode: '5815',
        city: 'Bergen'
    },
    {
        zipCode: '5816',
        city: 'Bergen'
    },
    {
        zipCode: '5817',
        city: 'Bergen'
    },
    {
        zipCode: '5818',
        city: 'Bergen'
    },
    {
        zipCode: '5819',
        city: 'Bergen'
    },
    {
        zipCode: '5820',
        city: 'Bergen'
    },
    {
        zipCode: '5821',
        city: 'Bergen'
    },
    {
        zipCode: '5822',
        city: 'Bergen'
    },
    {
        zipCode: '5823',
        city: 'Bergen'
    },
    {
        zipCode: '5824',
        city: 'Bergen'
    },
    {
        zipCode: '5825',
        city: 'Bergen'
    },
    {
        zipCode: '5826',
        city: 'Bergen'
    },
    {
        zipCode: '5827',
        city: 'Bergen'
    },
    {
        zipCode: '5828',
        city: 'Bergen'
    },
    {
        zipCode: '5829',
        city: 'Bergen'
    },
    {
        zipCode: '5830',
        city: 'Bergen'
    },
    {
        zipCode: '5831',
        city: 'Bergen'
    },
    {
        zipCode: '5832',
        city: 'Bergen'
    },
    {
        zipCode: '5833',
        city: 'Bergen'
    },
    {
        zipCode: '5834',
        city: 'Bergen'
    },
    {
        zipCode: '5835',
        city: 'Bergen'
    },
    {
        zipCode: '5836',
        city: 'Bergen'
    },
    {
        zipCode: '5837',
        city: 'Bergen'
    },
    {
        zipCode: '5838',
        city: 'Bergen'
    },
    {
        zipCode: '5841',
        city: 'Bergen'
    },
    {
        zipCode: '5843',
        city: 'Bergen'
    },
    {
        zipCode: '5844',
        city: 'Bergen'
    },
    {
        zipCode: '5845',
        city: 'Bergen'
    },
    {
        zipCode: '5847',
        city: 'Bergen'
    },
    {
        zipCode: '5848',
        city: 'Bergen'
    },
    {
        zipCode: '5849',
        city: 'Bergen'
    },
    {
        zipCode: '5851',
        city: 'Bergen'
    },
    {
        zipCode: '5852',
        city: 'Bergen'
    },
    {
        zipCode: '5853',
        city: 'Bergen'
    },
    {
        zipCode: '5854',
        city: 'Bergen'
    },
    {
        zipCode: '5855',
        city: 'Bergen'
    },
    {
        zipCode: '5857',
        city: 'Bergen'
    },
    {
        zipCode: '5858',
        city: 'Bergen'
    },
    {
        zipCode: '5859',
        city: 'Bergen'
    },
    {
        zipCode: '5861',
        city: 'Bergen'
    },
    {
        zipCode: '5862',
        city: 'Bergen'
    },
    {
        zipCode: '5863',
        city: 'Bergen'
    },
    {
        zipCode: '5864',
        city: 'Bergen'
    },
    {
        zipCode: '5865',
        city: 'Bergen'
    },
    {
        zipCode: '5866',
        city: 'Bergen'
    },
    {
        zipCode: '5867',
        city: 'Bergen'
    },
    {
        zipCode: '5868',
        city: 'Bergen'
    },
    {
        zipCode: '5869',
        city: 'Bergen'
    },
    {
        zipCode: '5872',
        city: 'Bergen'
    },
    {
        zipCode: '5873',
        city: 'Bergen'
    },
    {
        zipCode: '5876',
        city: 'Bergen'
    },
    {
        zipCode: '5877',
        city: 'Bergen'
    },
    {
        zipCode: '5878',
        city: 'Bergen'
    },
    {
        zipCode: '5879',
        city: 'Bergen'
    },
    {
        zipCode: '5881',
        city: 'Bergen'
    },
    {
        zipCode: '5884',
        city: 'Bergen'
    },
    {
        zipCode: '5886',
        city: 'Bergen'
    },
    {
        zipCode: '5887',
        city: 'Bergen'
    },
    {
        zipCode: '5888',
        city: 'Bergen'
    },
    {
        zipCode: '5889',
        city: 'Bergen'
    },
    {
        zipCode: '5892',
        city: 'Bergen'
    },
    {
        zipCode: '5893',
        city: 'Bergen'
    },
    {
        zipCode: '5895',
        city: 'Bergen'
    },
    {
        zipCode: '5896',
        city: 'Bergen'
    },
    {
        zipCode: '5899',
        city: 'Bergen'
    },
    {
        zipCode: '5902',
        city: 'Isdalstø'
    },
    {
        zipCode: '5903',
        city: 'Isdalstø'
    },
    {
        zipCode: '5904',
        city: 'Isdalstø'
    },
    {
        zipCode: '5906',
        city: 'Frekhaug'
    },
    {
        zipCode: '5907',
        city: 'Alversund'
    },
    {
        zipCode: '5908',
        city: 'Isdalstø'
    },
    {
        zipCode: '5911',
        city: 'Alversund'
    },
    {
        zipCode: '5912',
        city: 'Seim'
    },
    {
        zipCode: '5913',
        city: 'Eikangervåg'
    },
    {
        zipCode: '5914',
        city: 'Isdalstø'
    },
    {
        zipCode: '5915',
        city: 'Hjelmås'
    },
    {
        zipCode: '5916',
        city: 'Isdalstø'
    },
    {
        zipCode: '5917',
        city: 'Rossland'
    },
    {
        zipCode: '5918',
        city: 'Frekhaug'
    },
    {
        zipCode: '5919',
        city: 'Frekhaug'
    },
    {
        zipCode: '5931',
        city: 'Manger'
    },
    {
        zipCode: '5935',
        city: 'Bøvågen'
    },
    {
        zipCode: '5936',
        city: 'Manger'
    },
    {
        zipCode: '5937',
        city: 'Bøvågen'
    },
    {
        zipCode: '5938',
        city: 'Sæbøvågen'
    },
    {
        zipCode: '5939',
        city: 'Sletta'
    },
    {
        zipCode: '5941',
        city: 'Austrheim'
    },
    {
        zipCode: '5943',
        city: 'Austrheim'
    },
    {
        zipCode: '5947',
        city: 'Fedje'
    },
    {
        zipCode: '5948',
        city: 'Fedje'
    },
    {
        zipCode: '5951',
        city: 'Lindås'
    },
    {
        zipCode: '5952',
        city: 'Fonnes'
    },
    {
        zipCode: '5953',
        city: 'Fonnes'
    },
    {
        zipCode: '5954',
        city: 'Mongstad'
    },
    {
        zipCode: '5955',
        city: 'Lindås'
    },
    {
        zipCode: '5956',
        city: 'Hundvin'
    },
    {
        zipCode: '5957',
        city: 'Myking'
    },
    {
        zipCode: '5960',
        city: 'Dalsøyra'
    },
    {
        zipCode: '5961',
        city: 'Brekke'
    },
    {
        zipCode: '5962',
        city: 'Bjordal'
    },
    {
        zipCode: '5963',
        city: 'Dalsøyra'
    },
    {
        zipCode: '5964',
        city: 'Brekke'
    },
    {
        zipCode: '5965',
        city: 'Bjordal'
    },
    {
        zipCode: '5966',
        city: 'Eivindvik'
    },
    {
        zipCode: '5967',
        city: 'Eivindvik'
    },
    {
        zipCode: '5970',
        city: 'Byrknesøy'
    },
    {
        zipCode: '5977',
        city: 'Ånneland'
    },
    {
        zipCode: '5978',
        city: 'Mjømna'
    },
    {
        zipCode: '5979',
        city: 'Byrknesøy'
    },
    {
        zipCode: '5981',
        city: 'Masfjordnes'
    },
    {
        zipCode: '5982',
        city: 'Masfjordnes'
    },
    {
        zipCode: '5983',
        city: 'Haugsvær'
    },
    {
        zipCode: '5984',
        city: 'Matredal'
    },
    {
        zipCode: '5985',
        city: 'Haugsvær'
    },
    {
        zipCode: '5986',
        city: 'Hosteland'
    },
    {
        zipCode: '5987',
        city: 'Hosteland'
    },
    {
        zipCode: '5991',
        city: 'Ostereidet'
    },
    {
        zipCode: '5993',
        city: 'Ostereidet'
    },
    {
        zipCode: '5994',
        city: 'Vikanes'
    },
    {
        zipCode: '6001',
        city: 'Ålesund'
    },
    {
        zipCode: '6002',
        city: 'Ålesund'
    },
    {
        zipCode: '6003',
        city: 'Ålesund'
    },
    {
        zipCode: '6004',
        city: 'Ålesund'
    },
    {
        zipCode: '6005',
        city: 'Ålesund'
    },
    {
        zipCode: '6006',
        city: 'Ålesund'
    },
    {
        zipCode: '6007',
        city: 'Ålesund'
    },
    {
        zipCode: '6008',
        city: 'Ålesund'
    },
    {
        zipCode: '6009',
        city: 'Ålesund'
    },
    {
        zipCode: '6010',
        city: 'Ålesund'
    },
    {
        zipCode: '6011',
        city: 'Ålesund'
    },
    {
        zipCode: '6012',
        city: 'Ålesund'
    },
    {
        zipCode: '6013',
        city: 'Ålesund'
    },
    {
        zipCode: '6014',
        city: 'Ålesund'
    },
    {
        zipCode: '6015',
        city: 'Ålesund'
    },
    {
        zipCode: '6016',
        city: 'Ålesund'
    },
    {
        zipCode: '6017',
        city: 'Ålesund'
    },
    {
        zipCode: '6018',
        city: 'Ålesund'
    },
    {
        zipCode: '6019',
        city: 'Ålesund'
    },
    {
        zipCode: '6020',
        city: 'Ålesund'
    },
    {
        zipCode: '6021',
        city: 'Ålesund'
    },
    {
        zipCode: '6022',
        city: 'Ålesund'
    },
    {
        zipCode: '6023',
        city: 'Ålesund'
    },
    {
        zipCode: '6024',
        city: 'Ålesund'
    },
    {
        zipCode: '6025',
        city: 'Ålesund'
    },
    {
        zipCode: '6026',
        city: 'Ålesund'
    },
    {
        zipCode: '6028',
        city: 'Ålesund'
    },
    {
        zipCode: '6030',
        city: 'Langevåg'
    },
    {
        zipCode: '6034',
        city: 'Eidsnes'
    },
    {
        zipCode: '6035',
        city: 'Fiskarstrand'
    },
    {
        zipCode: '6036',
        city: 'Mauseidvåg'
    },
    {
        zipCode: '6037',
        city: 'Eidsnes'
    },
    {
        zipCode: '6038',
        city: 'Fiskarstrand'
    },
    {
        zipCode: '6039',
        city: 'Langevåg'
    },
    {
        zipCode: '6040',
        city: 'Vigra'
    },
    {
        zipCode: '6044',
        city: 'Ålesund'
    },
    {
        zipCode: '6045',
        city: 'Ålesund'
    },
    {
        zipCode: '6046',
        city: 'Ålesund'
    },
    {
        zipCode: '6047',
        city: 'Ålesund'
    },
    {
        zipCode: '6048',
        city: 'Ålesund'
    },
    {
        zipCode: '6050',
        city: 'Valderøya'
    },
    {
        zipCode: '6051',
        city: 'Valderøya'
    },
    {
        zipCode: '6052',
        city: 'Giske'
    },
    {
        zipCode: '6054',
        city: 'Godøya'
    },
    {
        zipCode: '6055',
        city: 'Godøya'
    },
    {
        zipCode: '6057',
        city: 'Ellingsøy'
    },
    {
        zipCode: '6058',
        city: 'Valderøya'
    },
    {
        zipCode: '6059',
        city: 'Vigra'
    },
    {
        zipCode: '6060',
        city: 'Hareid'
    },
    {
        zipCode: '6062',
        city: 'Brandal'
    },
    {
        zipCode: '6063',
        city: 'Hjørungavåg'
    },
    {
        zipCode: '6064',
        city: 'Haddal'
    },
    {
        zipCode: '6065',
        city: 'Ulsteinvik'
    },
    {
        zipCode: '6067',
        city: 'Ulsteinvik'
    },
    {
        zipCode: '6068',
        city: 'Eiksund'
    },
    {
        zipCode: '6069',
        city: 'Hareid'
    },
    {
        zipCode: '6070',
        city: 'Tjørvåg'
    },
    {
        zipCode: '6075',
        city: 'Moltustranda'
    },
    {
        zipCode: '6076',
        city: 'Moltustranda'
    },
    {
        zipCode: '6078',
        city: 'Gjerdsvika'
    },
    {
        zipCode: '6079',
        city: 'Gurskøy'
    },
    {
        zipCode: '6080',
        city: 'Gurskøy'
    },
    {
        zipCode: '6082',
        city: 'Gursken'
    },
    {
        zipCode: '6083',
        city: 'Gjerdsvika'
    },
    {
        zipCode: '6084',
        city: 'Larsnes'
    },
    {
        zipCode: '6085',
        city: 'Larsnes'
    },
    {
        zipCode: '6086',
        city: 'Kvamsøy'
    },
    {
        zipCode: '6087',
        city: 'Kvamsøy'
    },
    {
        zipCode: '6088',
        city: 'Sandshamn'
    },
    {
        zipCode: '6089',
        city: 'Sandshamn'
    },
    {
        zipCode: '6090',
        city: 'Fosnavåg'
    },
    {
        zipCode: '6091',
        city: 'Fosnavåg'
    },
    {
        zipCode: '6092',
        city: 'Fosnavåg'
    },
    {
        zipCode: '6094',
        city: 'Leinøy'
    },
    {
        zipCode: '6095',
        city: 'Bølandet'
    },
    {
        zipCode: '6096',
        city: 'Runde'
    },
    {
        zipCode: '6098',
        city: 'Nerlandsøy'
    },
    {
        zipCode: '6099',
        city: 'Fosnavåg'
    },
    {
        zipCode: '6100',
        city: 'Volda'
    },
    {
        zipCode: '6101',
        city: 'Volda'
    },
    {
        zipCode: '6102',
        city: 'Volda'
    },
    {
        zipCode: '6103',
        city: 'Volda'
    },
    {
        zipCode: '6104',
        city: 'Volda'
    },
    {
        zipCode: '6105',
        city: 'Volda'
    },
    {
        zipCode: '6106',
        city: 'Volda'
    },
    {
        zipCode: '6110',
        city: 'Austefjorden'
    },
    {
        zipCode: '6120',
        city: 'Folkestad'
    },
    {
        zipCode: '6133',
        city: 'Lauvstad'
    },
    {
        zipCode: '6134',
        city: 'Lauvstad'
    },
    {
        zipCode: '6138',
        city: 'Syvde'
    },
    {
        zipCode: '6139',
        city: 'Fiskå'
    },
    {
        zipCode: '6140',
        city: 'Syvde'
    },
    {
        zipCode: '6141',
        city: 'Rovde'
    },
    {
        zipCode: '6142',
        city: 'Eidså'
    },
    {
        zipCode: '6143',
        city: 'Fiskå'
    },
    {
        zipCode: '6144',
        city: 'Sylte'
    },
    {
        zipCode: '6146',
        city: 'Åheim'
    },
    {
        zipCode: '6147',
        city: 'Åheim'
    },
    {
        zipCode: '6149',
        city: 'Åram'
    },
    {
        zipCode: '6150',
        city: 'Ørsta'
    },
    {
        zipCode: '6151',
        city: 'Ørsta'
    },
    {
        zipCode: '6152',
        city: 'Ørsta'
    },
    {
        zipCode: '6153',
        city: 'Ørsta'
    },
    {
        zipCode: '6154',
        city: 'Ørsta'
    },
    {
        zipCode: '6155',
        city: 'Ørsta'
    },
    {
        zipCode: '6156',
        city: 'Ørsta'
    },
    {
        zipCode: '6160',
        city: 'Hovdebygda'
    },
    {
        zipCode: '6161',
        city: 'Hovdebygda'
    },
    {
        zipCode: '6165',
        city: 'Sæbø'
    },
    {
        zipCode: '6166',
        city: 'Sæbø'
    },
    {
        zipCode: '6170',
        city: 'Vartdal'
    },
    {
        zipCode: '6171',
        city: 'Vartdal'
    },
    {
        zipCode: '6174',
        city: 'Barstadvik'
    },
    {
        zipCode: '6183',
        city: 'Trandal'
    },
    {
        zipCode: '6184',
        city: 'Storestandal'
    },
    {
        zipCode: '6190',
        city: 'Bjørke'
    },
    {
        zipCode: '6196',
        city: 'Norangsfjorden'
    },
    {
        zipCode: '6200',
        city: 'Stranda'
    },
    {
        zipCode: '6201',
        city: 'Stranda'
    },
    {
        zipCode: '6210',
        city: 'Valldal'
    },
    {
        zipCode: '6211',
        city: 'Valldal'
    },
    {
        zipCode: '6212',
        city: 'Liabygda'
    },
    {
        zipCode: '6213',
        city: 'Tafjord'
    },
    {
        zipCode: '6214',
        city: 'Norddal'
    },
    {
        zipCode: '6215',
        city: 'Eidsdal'
    },
    {
        zipCode: '6216',
        city: 'Geiranger'
    },
    {
        zipCode: '6217',
        city: 'Geiranger'
    },
    {
        zipCode: '6218',
        city: 'Hellesylt'
    },
    {
        zipCode: '6219',
        city: 'Hellesylt'
    },
    {
        zipCode: '6220',
        city: 'Straumgjerde'
    },
    {
        zipCode: '6222',
        city: 'Ikornnes'
    },
    {
        zipCode: '6223',
        city: 'Ikornnes'
    },
    {
        zipCode: '6224',
        city: 'Hundeidvik'
    },
    {
        zipCode: '6230',
        city: 'Sykkylven'
    },
    {
        zipCode: '6238',
        city: 'Straumgjerde'
    },
    {
        zipCode: '6239',
        city: 'Sykkylven'
    },
    {
        zipCode: '6240',
        city: 'Ørskog'
    },
    {
        zipCode: '6249',
        city: 'Ørskog'
    },
    {
        zipCode: '6250',
        city: 'Stordal'
    },
    {
        zipCode: '6255',
        city: 'Eidsdal'
    },
    {
        zipCode: '6259',
        city: 'Stordal'
    },
    {
        zipCode: '6260',
        city: 'Skodje'
    },
    {
        zipCode: '6263',
        city: 'Skodje'
    },
    {
        zipCode: '6264',
        city: 'Tennfjord'
    },
    {
        zipCode: '6265',
        city: 'Vatne'
    },
    {
        zipCode: '6270',
        city: 'Brattvåg'
    },
    {
        zipCode: '6272',
        city: 'Hildre'
    },
    {
        zipCode: '6280',
        city: 'Søvik'
    },
    {
        zipCode: '6281',
        city: 'Søvik'
    },
    {
        zipCode: '6282',
        city: 'Brattvåg'
    },
    {
        zipCode: '6283',
        city: 'Vatne'
    },
    {
        zipCode: '6285',
        city: 'Storekalvøy'
    },
    {
        zipCode: '6290',
        city: 'Haramsøy'
    },
    {
        zipCode: '6291',
        city: 'Haramsøy'
    },
    {
        zipCode: '6292',
        city: 'Kjerstad'
    },
    {
        zipCode: '6293',
        city: 'Longva'
    },
    {
        zipCode: '6294',
        city: 'Fjørtoft'
    },
    {
        zipCode: '6300',
        city: 'Åndalsnes'
    },
    {
        zipCode: '6301',
        city: 'Åndalsnes'
    },
    {
        zipCode: '6310',
        city: 'Veblungsnes'
    },
    {
        zipCode: '6315',
        city: 'Innfjorden'
    },
    {
        zipCode: '6320',
        city: 'Isfjorden'
    },
    {
        zipCode: '6330',
        city: 'Verma'
    },
    {
        zipCode: '6331',
        city: 'Verma'
    },
    {
        zipCode: '6339',
        city: 'Isfjorden'
    },
    {
        zipCode: '6350',
        city: 'Eidsbygda'
    },
    {
        zipCode: '6360',
        city: 'Åfarnes'
    },
    {
        zipCode: '6361',
        city: 'Åfarnes'
    },
    {
        zipCode: '6363',
        city: 'Mittet'
    },
    {
        zipCode: '6364',
        city: 'Vistdal'
    },
    {
        zipCode: '6365',
        city: 'Vistdal'
    },
    {
        zipCode: '6385',
        city: 'Måndalen'
    },
    {
        zipCode: '6386',
        city: 'Måndalen'
    },
    {
        zipCode: '6387',
        city: 'Vågstranda'
    },
    {
        zipCode: '6388',
        city: 'Vågstranda'
    },
    {
        zipCode: '6389',
        city: 'Fiksdal'
    },
    {
        zipCode: '6390',
        city: 'Vestnes'
    },
    {
        zipCode: '6391',
        city: 'Tresfjord'
    },
    {
        zipCode: '6392',
        city: 'Vikebukt'
    },
    {
        zipCode: '6393',
        city: 'Tomrefjord'
    },
    {
        zipCode: '6394',
        city: 'Fiksdal'
    },
    {
        zipCode: '6395',
        city: 'Rekdal'
    },
    {
        zipCode: '6396',
        city: 'Vikebukt'
    },
    {
        zipCode: '6397',
        city: 'Tresfjord'
    },
    {
        zipCode: '6398',
        city: 'Tomrefjord'
    },
    {
        zipCode: '6399',
        city: 'Vestnes'
    },
    {
        zipCode: '6401',
        city: 'Molde'
    },
    {
        zipCode: '6402',
        city: 'Molde'
    },
    {
        zipCode: '6403',
        city: 'Molde'
    },
    {
        zipCode: '6404',
        city: 'Molde'
    },
    {
        zipCode: '6405',
        city: 'Molde'
    },
    {
        zipCode: '6407',
        city: 'Molde'
    },
    {
        zipCode: '6408',
        city: 'Aureosen'
    },
    {
        zipCode: '6409',
        city: 'Molde'
    },
    {
        zipCode: '6410',
        city: 'Molde'
    },
    {
        zipCode: '6411',
        city: 'Molde'
    },
    {
        zipCode: '6412',
        city: 'Molde'
    },
    {
        zipCode: '6413',
        city: 'Molde'
    },
    {
        zipCode: '6414',
        city: 'Molde'
    },
    {
        zipCode: '6415',
        city: 'Molde'
    },
    {
        zipCode: '6416',
        city: 'Molde'
    },
    {
        zipCode: '6418',
        city: 'Sekken'
    },
    {
        zipCode: '6419',
        city: 'Molde'
    },
    {
        zipCode: '6421',
        city: 'Molde'
    },
    {
        zipCode: '6422',
        city: 'Molde'
    },
    {
        zipCode: '6423',
        city: 'Molde'
    },
    {
        zipCode: '6425',
        city: 'Molde'
    },
    {
        zipCode: '6429',
        city: 'Molde'
    },
    {
        zipCode: '6430',
        city: 'Bud'
    },
    {
        zipCode: '6431',
        city: 'Bud'
    },
    {
        zipCode: '6433',
        city: 'Hustad'
    },
    {
        zipCode: '6434',
        city: 'Molde'
    },
    {
        zipCode: '6435',
        city: 'Molde'
    },
    {
        zipCode: '6436',
        city: 'Molde'
    },
    {
        zipCode: '6440',
        city: 'Elnesvågen'
    },
    {
        zipCode: '6443',
        city: 'Tornes i romsdal'
    },
    {
        zipCode: '6444',
        city: 'Farstad'
    },
    {
        zipCode: '6445',
        city: 'Malmefjorden'
    },
    {
        zipCode: '6446',
        city: 'Farstad'
    },
    {
        zipCode: '6447',
        city: 'Elnesvågen'
    },
    {
        zipCode: '6450',
        city: 'Hjelset'
    },
    {
        zipCode: '6452',
        city: 'Kleive'
    },
    {
        zipCode: '6453',
        city: 'Kleive'
    },
    {
        zipCode: '6454',
        city: 'Hjelset'
    },
    {
        zipCode: '6455',
        city: 'Kortgarden'
    },
    {
        zipCode: '6456',
        city: 'Skåla'
    },
    {
        zipCode: '6457',
        city: 'Bolsøya'
    },
    {
        zipCode: '6458',
        city: 'Skåla'
    },
    {
        zipCode: '6460',
        city: 'Eidsvåg i romsdal'
    },
    {
        zipCode: '6461',
        city: 'Eidsvåg i romsdal'
    },
    {
        zipCode: '6462',
        city: 'Raudsand'
    },
    {
        zipCode: '6470',
        city: 'Eresfjord'
    },
    {
        zipCode: '6471',
        city: 'Eresfjord'
    },
    {
        zipCode: '6472',
        city: 'Eikesdal'
    },
    {
        zipCode: '6475',
        city: 'Midsund'
    },
    {
        zipCode: '6476',
        city: 'Midsund'
    },
    {
        zipCode: '6480',
        city: 'Aukra'
    },
    {
        zipCode: '6481',
        city: 'Aukra'
    },
    {
        zipCode: '6483',
        city: 'Ona'
    },
    {
        zipCode: '6484',
        city: 'Sandøy'
    },
    {
        zipCode: '6485',
        city: 'Harøy'
    },
    {
        zipCode: '6486',
        city: 'Orten'
    },
    {
        zipCode: '6487',
        city: 'Harøy'
    },
    {
        zipCode: '6488',
        city: 'Myklebost'
    },
    {
        zipCode: '6490',
        city: 'Eide'
    },
    {
        zipCode: '6493',
        city: 'Lyngstad'
    },
    {
        zipCode: '6494',
        city: 'Vevang'
    },
    {
        zipCode: '6499',
        city: 'Eide'
    },
    {
        zipCode: '6501',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6502',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6503',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6504',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6506',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6507',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6508',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6509',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6510',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6511',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6512',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6514',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6515',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6516',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6517',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6518',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6520',
        city: 'Frei'
    },
    {
        zipCode: '6521',
        city: 'Frei'
    },
    {
        zipCode: '6522',
        city: 'Frei'
    },
    {
        zipCode: '6523',
        city: 'Frei'
    },
    {
        zipCode: '6524',
        city: 'Frei'
    },
    {
        zipCode: '6525',
        city: 'Frei'
    },
    {
        zipCode: '6527',
        city: 'Frei'
    },
    {
        zipCode: '6528',
        city: 'Frei'
    },
    {
        zipCode: '6529',
        city: 'Frei'
    },
    {
        zipCode: '6530',
        city: 'Averøy'
    },
    {
        zipCode: '6531',
        city: 'Averøy'
    },
    {
        zipCode: '6532',
        city: 'Averøy'
    },
    {
        zipCode: '6533',
        city: 'Averøy'
    },
    {
        zipCode: '6538',
        city: 'Averøy'
    },
    {
        zipCode: '6539',
        city: 'Averøy'
    },
    {
        zipCode: '6546',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6547',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6548',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6549',
        city: 'Kristiansund n'
    },
    {
        zipCode: '6570',
        city: 'Smøla'
    },
    {
        zipCode: '6571',
        city: 'Smøla'
    },
    {
        zipCode: '6590',
        city: 'Tustna'
    },
    {
        zipCode: '6591',
        city: 'Tustna'
    },
    {
        zipCode: '6600',
        city: 'Sunndalsøra'
    },
    {
        zipCode: '6601',
        city: 'Sunndalsøra'
    },
    {
        zipCode: '6610',
        city: 'Øksendal'
    },
    {
        zipCode: '6611',
        city: 'Furugrenda'
    },
    {
        zipCode: '6612',
        city: 'Grøa'
    },
    {
        zipCode: '6613',
        city: 'Gjøra'
    },
    {
        zipCode: '6614',
        city: 'Gjøra'
    },
    {
        zipCode: '6620',
        city: 'Ålvundeid'
    },
    {
        zipCode: '6622',
        city: 'Ålvundfjord'
    },
    {
        zipCode: '6623',
        city: 'Ålvundfjord'
    },
    {
        zipCode: '6627',
        city: 'Tingvoll'
    },
    {
        zipCode: '6628',
        city: 'Meisingset'
    },
    {
        zipCode: '6629',
        city: 'Torjulvågen'
    },
    {
        zipCode: '6630',
        city: 'Tingvoll'
    },
    {
        zipCode: '6631',
        city: 'Batnfjordsøra'
    },
    {
        zipCode: '6632',
        city: 'Batnfjordsøra'
    },
    {
        zipCode: '6633',
        city: 'Gjemnes'
    },
    {
        zipCode: '6636',
        city: 'Angvik'
    },
    {
        zipCode: '6637',
        city: 'Flemma'
    },
    {
        zipCode: '6638',
        city: 'Osmarka'
    },
    {
        zipCode: '6639',
        city: 'Torvikbukt'
    },
    {
        zipCode: '6640',
        city: 'Kvanne'
    },
    {
        zipCode: '6641',
        city: 'Torvikbukt'
    },
    {
        zipCode: '6642',
        city: 'Stangvik'
    },
    {
        zipCode: '6643',
        city: 'Bøfjorden'
    },
    {
        zipCode: '6644',
        city: 'Bæverfjord'
    },
    {
        zipCode: '6645',
        city: 'Todalen'
    },
    {
        zipCode: '6650',
        city: 'Surnadal'
    },
    {
        zipCode: '6652',
        city: 'Surnadal'
    },
    {
        zipCode: '6653',
        city: 'Øvre surnadal'
    },
    {
        zipCode: '6655',
        city: 'Vindøla'
    },
    {
        zipCode: '6656',
        city: 'Surnadal'
    },
    {
        zipCode: '6657',
        city: 'Rindal'
    },
    {
        zipCode: '6658',
        city: 'Rindalsskogen'
    },
    {
        zipCode: '6659',
        city: 'Rindal'
    },
    {
        zipCode: '6670',
        city: 'Øydegard'
    },
    {
        zipCode: '6671',
        city: 'Øydegard'
    },
    {
        zipCode: '6674',
        city: 'Kvisvik'
    },
    {
        zipCode: '6680',
        city: 'Halsanaustan'
    },
    {
        zipCode: '6683',
        city: 'Vågland'
    },
    {
        zipCode: '6686',
        city: 'Valsøybotn'
    },
    {
        zipCode: '6687',
        city: 'Valsøyfjord'
    },
    {
        zipCode: '6688',
        city: 'Vågland'
    },
    {
        zipCode: '6689',
        city: 'Aure'
    },
    {
        zipCode: '6690',
        city: 'Aure'
    },
    {
        zipCode: '6693',
        city: 'Mjosundet'
    },
    {
        zipCode: '6694',
        city: 'Foldfjorden'
    },
    {
        zipCode: '6697',
        city: 'Vihals'
    },
    {
        zipCode: '6698',
        city: 'Lesund'
    },
    {
        zipCode: '6699',
        city: 'Kjørsvikbugen'
    },
    {
        zipCode: '6700',
        city: 'Måløy'
    },
    {
        zipCode: '6701',
        city: 'Måløy'
    },
    {
        zipCode: '6702',
        city: 'Måløy'
    },
    {
        zipCode: '6703',
        city: 'Måløy'
    },
    {
        zipCode: '6704',
        city: 'Deknepollen'
    },
    {
        zipCode: '6707',
        city: 'Raudeberg'
    },
    {
        zipCode: '6708',
        city: 'Bryggja'
    },
    {
        zipCode: '6710',
        city: 'Raudeberg'
    },
    {
        zipCode: '6711',
        city: 'Bryggja'
    },
    {
        zipCode: '6713',
        city: 'Almenningen'
    },
    {
        zipCode: '6714',
        city: 'Silda'
    },
    {
        zipCode: '6715',
        city: 'Barmen'
    },
    {
        zipCode: '6716',
        city: 'Husevåg'
    },
    {
        zipCode: '6717',
        city: 'Flatraket'
    },
    {
        zipCode: '6718',
        city: 'Deknepollen'
    },
    {
        zipCode: '6719',
        city: 'Skatestraumen'
    },
    {
        zipCode: '6721',
        city: 'Svelgen'
    },
    {
        zipCode: '6723',
        city: 'Svelgen'
    },
    {
        zipCode: '6726',
        city: 'Bremanger'
    },
    {
        zipCode: '6727',
        city: 'Bremanger'
    },
    {
        zipCode: '6728',
        city: 'Kalvåg'
    },
    {
        zipCode: '6729',
        city: 'Kalvåg'
    },
    {
        zipCode: '6730',
        city: 'Davik'
    },
    {
        zipCode: '6734',
        city: 'Rugsund'
    },
    {
        zipCode: '6737',
        city: 'Ålfoten'
    },
    {
        zipCode: '6740',
        city: 'Selje'
    },
    {
        zipCode: '6741',
        city: 'Selje'
    },
    {
        zipCode: '6750',
        city: 'Stadlandet'
    },
    {
        zipCode: '6751',
        city: 'Stadlandet'
    },
    {
        zipCode: '6761',
        city: 'Hornindal'
    },
    {
        zipCode: '6763',
        city: 'Hornindal'
    },
    {
        zipCode: '6770',
        city: 'Nordfjordeid'
    },
    {
        zipCode: '6771',
        city: 'Nordfjordeid'
    },
    {
        zipCode: '6772',
        city: 'Nordfjordeid'
    },
    {
        zipCode: '6773',
        city: 'Nordfjordeid'
    },
    {
        zipCode: '6774',
        city: 'Nordfjordeid'
    },
    {
        zipCode: '6776',
        city: 'Kjølsdalen'
    },
    {
        zipCode: '6777',
        city: 'Stårheim'
    },
    {
        zipCode: '6778',
        city: 'Lote'
    },
    {
        zipCode: '6779',
        city: 'Holmøyane'
    },
    {
        zipCode: '6781',
        city: 'Stryn'
    },
    {
        zipCode: '6782',
        city: 'Stryn'
    },
    {
        zipCode: '6783',
        city: 'Stryn'
    },
    {
        zipCode: '6784',
        city: 'Olden'
    },
    {
        zipCode: '6788',
        city: 'Olden'
    },
    {
        zipCode: '6789',
        city: 'Loen'
    },
    {
        zipCode: '6790',
        city: 'Loen'
    },
    {
        zipCode: '6791',
        city: 'Oldedalen'
    },
    {
        zipCode: '6792',
        city: 'Briksdalsbre'
    },
    {
        zipCode: '6793',
        city: 'Innvik'
    },
    {
        zipCode: '6794',
        city: 'Innvik'
    },
    {
        zipCode: '6795',
        city: 'Blaksæter'
    },
    {
        zipCode: '6796',
        city: 'Hopland'
    },
    {
        zipCode: '6797',
        city: 'Utvik'
    },
    {
        zipCode: '6798',
        city: 'Hjelledalen'
    },
    {
        zipCode: '6799',
        city: 'Oppstryn'
    },
    {
        zipCode: '6800',
        city: 'Førde'
    },
    {
        zipCode: '6801',
        city: 'Førde'
    },
    {
        zipCode: '6802',
        city: 'Førde'
    },
    {
        zipCode: '6803',
        city: 'Førde'
    },
    {
        zipCode: '6804',
        city: 'Førde'
    },
    {
        zipCode: '6805',
        city: 'Førde'
    },
    {
        zipCode: '6806',
        city: 'Naustdal'
    },
    {
        zipCode: '6807',
        city: 'Førde'
    },
    {
        zipCode: '6808',
        city: 'Førde'
    },
    {
        zipCode: '6809',
        city: 'Førde'
    },
    {
        zipCode: '6810',
        city: 'Førde'
    },
    {
        zipCode: '6811',
        city: 'Førde'
    },
    {
        zipCode: '6812',
        city: 'Førde'
    },
    {
        zipCode: '6813',
        city: 'Førde'
    },
    {
        zipCode: '6814',
        city: 'Førde'
    },
    {
        zipCode: '6815',
        city: 'Førde'
    },
    {
        zipCode: '6817',
        city: 'Naustdal'
    },
    {
        zipCode: '6818',
        city: 'Haukedalen'
    },
    {
        zipCode: '6819',
        city: 'Førde'
    },
    {
        zipCode: '6820',
        city: 'Førde'
    },
    {
        zipCode: '6821',
        city: 'Sandane'
    },
    {
        zipCode: '6822',
        city: 'Sandane'
    },
    {
        zipCode: '6823',
        city: 'Sandane'
    },
    {
        zipCode: '6826',
        city: 'Byrkjelo'
    },
    {
        zipCode: '6827',
        city: 'Breim'
    },
    {
        zipCode: '6828',
        city: 'Hestenesøyra'
    },
    {
        zipCode: '6829',
        city: 'Hyen'
    },
    {
        zipCode: '6830',
        city: 'Byrkjelo'
    },
    {
        zipCode: '6831',
        city: 'Hyen'
    },
    {
        zipCode: '6841',
        city: 'Skei i jølster'
    },
    {
        zipCode: '6843',
        city: 'Skei i jølster'
    },
    {
        zipCode: '6844',
        city: 'Vassenden'
    },
    {
        zipCode: '6845',
        city: 'Fjærland'
    },
    {
        zipCode: '6847',
        city: 'Vassenden'
    },
    {
        zipCode: '6848',
        city: 'Fjærland'
    },
    {
        zipCode: '6849',
        city: 'Kaupanger'
    },
    {
        zipCode: '6851',
        city: 'Sogndal'
    },
    {
        zipCode: '6852',
        city: 'Sogndal'
    },
    {
        zipCode: '6853',
        city: 'Sogndal'
    },
    {
        zipCode: '6854',
        city: 'Kaupanger'
    },
    {
        zipCode: '6855',
        city: 'Frønningen'
    },
    {
        zipCode: '6856',
        city: 'Sogndal'
    },
    {
        zipCode: '6858',
        city: 'Fardal'
    },
    {
        zipCode: '6859',
        city: 'Slinde'
    },
    {
        zipCode: '6861',
        city: 'Leikanger'
    },
    {
        zipCode: '6863',
        city: 'Leikanger'
    },
    {
        zipCode: '6866',
        city: 'Gaupne'
    },
    {
        zipCode: '6867',
        city: 'Hafslo'
    },
    {
        zipCode: '6868',
        city: 'Gaupne'
    },
    {
        zipCode: '6869',
        city: 'Hafslo'
    },
    {
        zipCode: '6870',
        city: 'Ornes'
    },
    {
        zipCode: '6871',
        city: 'Jostedal'
    },
    {
        zipCode: '6872',
        city: 'Luster'
    },
    {
        zipCode: '6873',
        city: 'Marifjøra'
    },
    {
        zipCode: '6874',
        city: 'Luster'
    },
    {
        zipCode: '6875',
        city: 'Høyheimsvik'
    },
    {
        zipCode: '6876',
        city: 'Skjolden'
    },
    {
        zipCode: '6877',
        city: 'Fortun'
    },
    {
        zipCode: '6878',
        city: 'Veitastrond'
    },
    {
        zipCode: '6879',
        city: 'Solvorn'
    },
    {
        zipCode: '6881',
        city: 'Årdalstangen'
    },
    {
        zipCode: '6882',
        city: 'Øvre årdal'
    },
    {
        zipCode: '6884',
        city: 'Øvre årdal'
    },
    {
        zipCode: '6885',
        city: 'Årdalstangen'
    },
    {
        zipCode: '6886',
        city: 'Lærdal'
    },
    {
        zipCode: '6887',
        city: 'Lærdal'
    },
    {
        zipCode: '6888',
        city: 'Borgund'
    },
    {
        zipCode: '6891',
        city: 'Vik i sogn'
    },
    {
        zipCode: '6893',
        city: 'Vik i sogn'
    },
    {
        zipCode: '6894',
        city: 'Vangsnes'
    },
    {
        zipCode: '6895',
        city: 'Feios'
    },
    {
        zipCode: '6896',
        city: 'Fresvik'
    },
    {
        zipCode: '6898',
        city: 'Balestrand'
    },
    {
        zipCode: '6899',
        city: 'Balestrand'
    },
    {
        zipCode: '6900',
        city: 'Florø'
    },
    {
        zipCode: '6901',
        city: 'Florø'
    },
    {
        zipCode: '6902',
        city: 'Florø'
    },
    {
        zipCode: '6903',
        city: 'Florø'
    },
    {
        zipCode: '6905',
        city: 'Florø'
    },
    {
        zipCode: '6906',
        city: 'Florø'
    },
    {
        zipCode: '6907',
        city: 'Florø'
    },
    {
        zipCode: '6908',
        city: 'Florø'
    },
    {
        zipCode: '6909',
        city: 'Florø'
    },
    {
        zipCode: '6910',
        city: 'Florø'
    },
    {
        zipCode: '6912',
        city: 'Kinn'
    },
    {
        zipCode: '6913',
        city: 'Florø'
    },
    {
        zipCode: '6914',
        city: 'Svanøybukt'
    },
    {
        zipCode: '6915',
        city: 'Rognaldsvåg'
    },
    {
        zipCode: '6916',
        city: 'Barekstad'
    },
    {
        zipCode: '6917',
        city: 'Batalden'
    },
    {
        zipCode: '6918',
        city: 'Sør-skorpa'
    },
    {
        zipCode: '6919',
        city: 'Tansøy'
    },
    {
        zipCode: '6921',
        city: 'Hardbakke'
    },
    {
        zipCode: '6924',
        city: 'Hardbakke'
    },
    {
        zipCode: '6926',
        city: 'Krakhella'
    },
    {
        zipCode: '6927',
        city: 'Ytrøygrend'
    },
    {
        zipCode: '6928',
        city: 'Kolgrov'
    },
    {
        zipCode: '6929',
        city: 'Hersvikbygda'
    },
    {
        zipCode: '6940',
        city: 'Eikefjord'
    },
    {
        zipCode: '6941',
        city: 'Eikefjord'
    },
    {
        zipCode: '6942',
        city: 'Svortevik'
    },
    {
        zipCode: '6944',
        city: 'Stavang'
    },
    {
        zipCode: '6946',
        city: 'Lavik'
    },
    {
        zipCode: '6947',
        city: 'Lavik'
    },
    {
        zipCode: '6951',
        city: 'Leirvik i sogn'
    },
    {
        zipCode: '6953',
        city: 'Leirvik i sogn'
    },
    {
        zipCode: '6957',
        city: 'Hyllestad'
    },
    {
        zipCode: '6958',
        city: 'Sørbøvåg'
    },
    {
        zipCode: '6959',
        city: 'Sørbøvåg'
    },
    {
        zipCode: '6961',
        city: 'Dale i sunnfjord'
    },
    {
        zipCode: '6963',
        city: 'Dale i sunnfjord'
    },
    {
        zipCode: '6964',
        city: 'Korssund'
    },
    {
        zipCode: '6966',
        city: 'Guddal'
    },
    {
        zipCode: '6967',
        city: 'Hellevik i fjaler'
    },
    {
        zipCode: '6968',
        city: 'Flekke'
    },
    {
        zipCode: '6969',
        city: 'Straumsnes'
    },
    {
        zipCode: '6971',
        city: 'Sande i sunnfjord'
    },
    {
        zipCode: '6973',
        city: 'Sande i sunnfjord'
    },
    {
        zipCode: '6975',
        city: 'Skilbrei'
    },
    {
        zipCode: '6976',
        city: 'Bygstad'
    },
    {
        zipCode: '6977',
        city: 'Bygstad'
    },
    {
        zipCode: '6978',
        city: 'Viksdalen'
    },
    {
        zipCode: '6980',
        city: 'Askvoll'
    },
    {
        zipCode: '6982',
        city: 'Holmedal'
    },
    {
        zipCode: '6983',
        city: 'Kvammen'
    },
    {
        zipCode: '6984',
        city: 'Stongfjorden'
    },
    {
        zipCode: '6985',
        city: 'Atløy'
    },
    {
        zipCode: '6986',
        city: 'Værlandet'
    },
    {
        zipCode: '6987',
        city: 'Bulandet'
    },
    {
        zipCode: '6988',
        city: 'Askvoll'
    },
    {
        zipCode: '6991',
        city: 'Høyanger'
    },
    {
        zipCode: '6993',
        city: 'Høyanger'
    },
    {
        zipCode: '6995',
        city: 'Kyrkjebø'
    },
    {
        zipCode: '6996',
        city: 'Vadheim'
    },
    {
        zipCode: '6997',
        city: 'Vadheim'
    },
    {
        zipCode: '7003',
        city: 'Trondheim'
    },
    {
        zipCode: '7004',
        city: 'Trondheim'
    },
    {
        zipCode: '7005',
        city: 'Trondheim'
    },
    {
        zipCode: '7006',
        city: 'Trondheim'
    },
    {
        zipCode: '7010',
        city: 'Trondheim'
    },
    {
        zipCode: '7011',
        city: 'Trondheim'
    },
    {
        zipCode: '7012',
        city: 'Trondheim'
    },
    {
        zipCode: '7013',
        city: 'Trondheim'
    },
    {
        zipCode: '7014',
        city: 'Trondheim'
    },
    {
        zipCode: '7015',
        city: 'Trondheim'
    },
    {
        zipCode: '7016',
        city: 'Trondheim'
    },
    {
        zipCode: '7017',
        city: 'Trondheim'
    },
    {
        zipCode: '7018',
        city: 'Trondheim'
    },
    {
        zipCode: '7019',
        city: 'Trondheim'
    },
    {
        zipCode: '7020',
        city: 'Trondheim'
    },
    {
        zipCode: '7021',
        city: 'Trondheim'
    },
    {
        zipCode: '7022',
        city: 'Trondheim'
    },
    {
        zipCode: '7023',
        city: 'Trondheim'
    },
    {
        zipCode: '7024',
        city: 'Trondheim'
    },
    {
        zipCode: '7025',
        city: 'Trondheim'
    },
    {
        zipCode: '7026',
        city: 'Trondheim'
    },
    {
        zipCode: '7027',
        city: 'Trondheim'
    },
    {
        zipCode: '7028',
        city: 'Trondheim'
    },
    {
        zipCode: '7029',
        city: 'Trondheim'
    },
    {
        zipCode: '7030',
        city: 'Trondheim'
    },
    {
        zipCode: '7031',
        city: 'Trondheim'
    },
    {
        zipCode: '7032',
        city: 'Trondheim'
    },
    {
        zipCode: '7033',
        city: 'Trondheim'
    },
    {
        zipCode: '7034',
        city: 'Trondheim'
    },
    {
        zipCode: '7035',
        city: 'Trondheim'
    },
    {
        zipCode: '7036',
        city: 'Trondheim'
    },
    {
        zipCode: '7037',
        city: 'Trondheim'
    },
    {
        zipCode: '7038',
        city: 'Trondheim'
    },
    {
        zipCode: '7039',
        city: 'Trondheim'
    },
    {
        zipCode: '7040',
        city: 'Trondheim'
    },
    {
        zipCode: '7041',
        city: 'Trondheim'
    },
    {
        zipCode: '7042',
        city: 'Trondheim'
    },
    {
        zipCode: '7043',
        city: 'Trondheim'
    },
    {
        zipCode: '7044',
        city: 'Trondheim'
    },
    {
        zipCode: '7045',
        city: 'Trondheim'
    },
    {
        zipCode: '7046',
        city: 'Trondheim'
    },
    {
        zipCode: '7047',
        city: 'Trondheim'
    },
    {
        zipCode: '7048',
        city: 'Trondheim'
    },
    {
        zipCode: '7049',
        city: 'Trondheim'
    },
    {
        zipCode: '7050',
        city: 'Trondheim'
    },
    {
        zipCode: '7051',
        city: 'Trondheim'
    },
    {
        zipCode: '7052',
        city: 'Trondheim'
    },
    {
        zipCode: '7053',
        city: 'Ranheim'
    },
    {
        zipCode: '7054',
        city: 'Ranheim'
    },
    {
        zipCode: '7055',
        city: 'Ranheim'
    },
    {
        zipCode: '7056',
        city: 'Ranheim'
    },
    {
        zipCode: '7057',
        city: 'Jonsvatnet'
    },
    {
        zipCode: '7058',
        city: 'Jakobsli'
    },
    {
        zipCode: '7059',
        city: 'Jakobsli'
    },
    {
        zipCode: '7066',
        city: 'Trondheim'
    },
    {
        zipCode: '7067',
        city: 'Trondheim'
    },
    {
        zipCode: '7068',
        city: 'Trondheim'
    },
    {
        zipCode: '7069',
        city: 'Trondheim'
    },
    {
        zipCode: '7070',
        city: 'Bosberg'
    },
    {
        zipCode: '7071',
        city: 'Trondheim'
    },
    {
        zipCode: '7072',
        city: 'Heimdal'
    },
    {
        zipCode: '7074',
        city: 'Spongdal'
    },
    {
        zipCode: '7075',
        city: 'Tiller'
    },
    {
        zipCode: '7078',
        city: 'Saupstad'
    },
    {
        zipCode: '7079',
        city: 'Flatåsen'
    },
    {
        zipCode: '7080',
        city: 'Heimdal'
    },
    {
        zipCode: '7081',
        city: 'Sjetnemarka'
    },
    {
        zipCode: '7082',
        city: 'Kattem'
    },
    {
        zipCode: '7083',
        city: 'Leinstrand'
    },
    {
        zipCode: '7088',
        city: 'Heimdal'
    },
    {
        zipCode: '7089',
        city: 'Heimdal'
    },
    {
        zipCode: '7091',
        city: 'Tiller'
    },
    {
        zipCode: '7092',
        city: 'Tiller'
    },
    {
        zipCode: '7093',
        city: 'Tiller'
    },
    {
        zipCode: '7097',
        city: 'Saupstad'
    },
    {
        zipCode: '7098',
        city: 'Saupstad'
    },
    {
        zipCode: '7099',
        city: 'Flatåsen'
    },
    {
        zipCode: '7100',
        city: 'Rissa'
    },
    {
        zipCode: '7101',
        city: 'Rissa'
    },
    {
        zipCode: '7105',
        city: 'Stadsbygd'
    },
    {
        zipCode: '7110',
        city: 'Fevåg'
    },
    {
        zipCode: '7111',
        city: 'Hasselvika'
    },
    {
        zipCode: '7112',
        city: 'Hasselvika'
    },
    {
        zipCode: '7113',
        city: 'Husbysjøen'
    },
    {
        zipCode: '7114',
        city: 'Råkvåg'
    },
    {
        zipCode: '7115',
        city: 'Husbysjøen'
    },
    {
        zipCode: '7116',
        city: 'Råkvåg'
    },
    {
        zipCode: '7119',
        city: 'Stadsbygd'
    },
    {
        zipCode: '7120',
        city: 'Leksvik'
    },
    {
        zipCode: '7121',
        city: 'Leksvik'
    },
    {
        zipCode: '7125',
        city: 'Vanvikan'
    },
    {
        zipCode: '7126',
        city: 'Vanvikan'
    },
    {
        zipCode: '7127',
        city: 'Opphaug'
    },
    {
        zipCode: '7129',
        city: 'Brekstad'
    },
    {
        zipCode: '7130',
        city: 'Brekstad'
    },
    {
        zipCode: '7140',
        city: 'Opphaug'
    },
    {
        zipCode: '7142',
        city: 'Uthaug'
    },
    {
        zipCode: '7150',
        city: 'Storfosna'
    },
    {
        zipCode: '7151',
        city: 'Storfosna'
    },
    {
        zipCode: '7152',
        city: 'Kråkvåg'
    },
    {
        zipCode: '7153',
        city: 'Garten'
    },
    {
        zipCode: '7156',
        city: 'Leksa'
    },
    {
        zipCode: '7159',
        city: 'Bjugn'
    },
    {
        zipCode: '7160',
        city: 'Bjugn'
    },
    {
        zipCode: '7164',
        city: 'Lysøysundet'
    },
    {
        zipCode: '7165',
        city: 'Oksvoll'
    },
    {
        zipCode: '7166',
        city: 'Tarva'
    },
    {
        zipCode: '7167',
        city: 'Vallersund'
    },
    {
        zipCode: '7168',
        city: 'Lysøysundet'
    },
    {
        zipCode: '7169',
        city: 'Åfjord'
    },
    {
        zipCode: '7170',
        city: 'Åfjord'
    },
    {
        zipCode: '7174',
        city: 'Revsnes'
    },
    {
        zipCode: '7175',
        city: 'Stokkøy'
    },
    {
        zipCode: '7176',
        city: 'Linesøya'
    },
    {
        zipCode: '7177',
        city: 'Revsnes'
    },
    {
        zipCode: '7178',
        city: 'Stokkøy'
    },
    {
        zipCode: '7180',
        city: 'Roan'
    },
    {
        zipCode: '7181',
        city: 'Roan'
    },
    {
        zipCode: '7190',
        city: 'Bessaker'
    },
    {
        zipCode: '7194',
        city: 'Brandsfjord'
    },
    {
        zipCode: '7200',
        city: 'Kyrksæterøra'
    },
    {
        zipCode: '7201',
        city: 'Kyrksæterøra'
    },
    {
        zipCode: '7203',
        city: 'Vinjeøra'
    },
    {
        zipCode: '7206',
        city: 'Hellandsjøen'
    },
    {
        zipCode: '7211',
        city: 'Korsvegen'
    },
    {
        zipCode: '7212',
        city: 'Korsvegen'
    },
    {
        zipCode: '7213',
        city: 'Gåsbakken'
    },
    {
        zipCode: '7221',
        city: 'Melhus'
    },
    {
        zipCode: '7223',
        city: 'Melhus'
    },
    {
        zipCode: '7224',
        city: 'Melhus'
    },
    {
        zipCode: '7227',
        city: 'Gimse'
    },
    {
        zipCode: '7228',
        city: 'Kvål'
    },
    {
        zipCode: '7231',
        city: 'Lundamo'
    },
    {
        zipCode: '7232',
        city: 'Lundamo'
    },
    {
        zipCode: '7234',
        city: 'Ler'
    },
    {
        zipCode: '7235',
        city: 'Ler'
    },
    {
        zipCode: '7236',
        city: 'Hovin i gauldal'
    },
    {
        zipCode: '7238',
        city: 'Hovin i gauldal'
    },
    {
        zipCode: '7239',
        city: 'Hitra'
    },
    {
        zipCode: '7240',
        city: 'Hitra'
    },
    {
        zipCode: '7241',
        city: 'Ansnes'
    },
    {
        zipCode: '7242',
        city: 'Knarrlagsund'
    },
    {
        zipCode: '7243',
        city: 'Kvenvær'
    },
    {
        zipCode: '7244',
        city: 'Knarrlagsund'
    },
    {
        zipCode: '7245',
        city: 'Kvenvær'
    },
    {
        zipCode: '7246',
        city: 'Sandstad'
    },
    {
        zipCode: '7247',
        city: 'Hestvika'
    },
    {
        zipCode: '7250',
        city: 'Melandsjø'
    },
    {
        zipCode: '7252',
        city: 'Dolmøy'
    },
    {
        zipCode: '7255',
        city: 'Sundlandet'
    },
    {
        zipCode: '7256',
        city: 'Hemnskjela'
    },
    {
        zipCode: '7257',
        city: 'Snillfjord'
    },
    {
        zipCode: '7259',
        city: 'Snillfjord'
    },
    {
        zipCode: '7260',
        city: 'Sistranda'
    },
    {
        zipCode: '7261',
        city: 'Sistranda'
    },
    {
        zipCode: '7263',
        city: 'Hamarvik'
    },
    {
        zipCode: '7264',
        city: 'Hamarvik'
    },
    {
        zipCode: '7266',
        city: 'Kverva'
    },
    {
        zipCode: '7267',
        city: 'Kverva'
    },
    {
        zipCode: '7268',
        city: 'Titran'
    },
    {
        zipCode: '7270',
        city: 'Dyrvik'
    },
    {
        zipCode: '7273',
        city: 'Norddyrøy'
    },
    {
        zipCode: '7274',
        city: 'Norddyrøy'
    },
    {
        zipCode: '7280',
        city: 'Sula'
    },
    {
        zipCode: '7282',
        city: 'Bogøyvær'
    },
    {
        zipCode: '7284',
        city: 'Mausund'
    },
    {
        zipCode: '7285',
        city: 'Gjæsingen'
    },
    {
        zipCode: '7286',
        city: 'Sørburøy'
    },
    {
        zipCode: '7287',
        city: 'Sauøy'
    },
    {
        zipCode: '7288',
        city: 'Soknedal'
    },
    {
        zipCode: '7289',
        city: 'Soknedal'
    },
    {
        zipCode: '7290',
        city: 'Støren'
    },
    {
        zipCode: '7291',
        city: 'Støren'
    },
    {
        zipCode: '7295',
        city: 'Rognes'
    },
    {
        zipCode: '7298',
        city: 'Budalen'
    },
    {
        zipCode: '7300',
        city: 'Orkanger'
    },
    {
        zipCode: '7301',
        city: 'Orkanger'
    },
    {
        zipCode: '7302',
        city: 'Orkanger'
    },
    {
        zipCode: '7310',
        city: 'Gjølme'
    },
    {
        zipCode: '7315',
        city: 'Lensvik'
    },
    {
        zipCode: '7316',
        city: 'Lensvik'
    },
    {
        zipCode: '7318',
        city: 'Agdenes'
    },
    {
        zipCode: '7319',
        city: 'Agdenes'
    },
    {
        zipCode: '7320',
        city: 'Fannrem'
    },
    {
        zipCode: '7321',
        city: 'Fannrem'
    },
    {
        zipCode: '7327',
        city: 'Svorkmo'
    },
    {
        zipCode: '7329',
        city: 'Svorkmo'
    },
    {
        zipCode: '7331',
        city: 'Løkken verk'
    },
    {
        zipCode: '7332',
        city: 'Løkken verk'
    },
    {
        zipCode: '7333',
        city: 'Storås'
    },
    {
        zipCode: '7334',
        city: 'Storås'
    },
    {
        zipCode: '7335',
        city: 'Jerpstad'
    },
    {
        zipCode: '7336',
        city: 'Meldal'
    },
    {
        zipCode: '7338',
        city: 'Meldal'
    },
    {
        zipCode: '7340',
        city: 'Oppdal'
    },
    {
        zipCode: '7341',
        city: 'Oppdal'
    },
    {
        zipCode: '7342',
        city: 'Lønset'
    },
    {
        zipCode: '7343',
        city: 'Vognill'
    },
    {
        zipCode: '7345',
        city: 'Driva'
    },
    {
        zipCode: '7350',
        city: 'Buvika'
    },
    {
        zipCode: '7351',
        city: 'Buvika'
    },
    {
        zipCode: '7353',
        city: 'Børsa'
    },
    {
        zipCode: '7354',
        city: 'Viggja'
    },
    {
        zipCode: '7355',
        city: 'Eggkleiva'
    },
    {
        zipCode: '7356',
        city: 'Skaun'
    },
    {
        zipCode: '7357',
        city: 'Skaun'
    },
    {
        zipCode: '7358',
        city: 'Børsa'
    },
    {
        zipCode: '7361',
        city: 'Røros'
    },
    {
        zipCode: '7370',
        city: 'Brekkebygd'
    },
    {
        zipCode: '7372',
        city: 'Glåmos'
    },
    {
        zipCode: '7374',
        city: 'Røros'
    },
    {
        zipCode: '7380',
        city: 'Ålen'
    },
    {
        zipCode: '7383',
        city: 'Haltdalen'
    },
    {
        zipCode: '7384',
        city: 'Ålen'
    },
    {
        zipCode: '7386',
        city: 'Singsås'
    },
    {
        zipCode: '7387',
        city: 'Singsås'
    },
    {
        zipCode: '7388',
        city: 'Singsås'
    },
    {
        zipCode: '7391',
        city: 'Rennebu'
    },
    {
        zipCode: '7392',
        city: 'Rennebu'
    },
    {
        zipCode: '7393',
        city: 'Rennebu'
    },
    {
        zipCode: '7397',
        city: 'Rennebu'
    },
    {
        zipCode: '7398',
        city: 'Rennebu'
    },
    {
        zipCode: '7399',
        city: 'Rennebu'
    },
    {
        zipCode: '7400',
        city: 'Trondheim'
    },
    {
        zipCode: '7401',
        city: 'Trondheim'
    },
    {
        zipCode: '7402',
        city: 'Trondheim'
    },
    {
        zipCode: '7403',
        city: 'Trondheim'
    },
    {
        zipCode: '7404',
        city: 'Trondheim'
    },
    {
        zipCode: '7405',
        city: 'Trondheim'
    },
    {
        zipCode: '7406',
        city: 'Trondheim'
    },
    {
        zipCode: '7407',
        city: 'Trondheim'
    },
    {
        zipCode: '7408',
        city: 'Trondheim'
    },
    {
        zipCode: '7409',
        city: 'Trondheim'
    },
    {
        zipCode: '7410',
        city: 'Trondheim'
    },
    {
        zipCode: '7411',
        city: 'Trondheim'
    },
    {
        zipCode: '7412',
        city: 'Trondheim'
    },
    {
        zipCode: '7413',
        city: 'Trondheim'
    },
    {
        zipCode: '7414',
        city: 'Trondheim'
    },
    {
        zipCode: '7415',
        city: 'Trondheim'
    },
    {
        zipCode: '7416',
        city: 'Trondheim'
    },
    {
        zipCode: '7417',
        city: 'Trondheim'
    },
    {
        zipCode: '7418',
        city: 'Trondheim'
    },
    {
        zipCode: '7419',
        city: 'Trondheim'
    },
    {
        zipCode: '7420',
        city: 'Trondheim'
    },
    {
        zipCode: '7421',
        city: 'Trondheim'
    },
    {
        zipCode: '7422',
        city: 'Trondheim'
    },
    {
        zipCode: '7424',
        city: 'Trondheim'
    },
    {
        zipCode: '7425',
        city: 'Trondheim'
    },
    {
        zipCode: '7426',
        city: 'Trondheim'
    },
    {
        zipCode: '7427',
        city: 'Trondheim'
    },
    {
        zipCode: '7428',
        city: 'Trondheim'
    },
    {
        zipCode: '7429',
        city: 'Trondheim'
    },
    {
        zipCode: '7430',
        city: 'Trondheim'
    },
    {
        zipCode: '7431',
        city: 'Trondheim'
    },
    {
        zipCode: '7432',
        city: 'Trondheim'
    },
    {
        zipCode: '7433',
        city: 'Trondheim'
    },
    {
        zipCode: '7434',
        city: 'Trondheim'
    },
    {
        zipCode: '7435',
        city: 'Trondheim'
    },
    {
        zipCode: '7436',
        city: 'Trondheim'
    },
    {
        zipCode: '7437',
        city: 'Trondheim'
    },
    {
        zipCode: '7438',
        city: 'Trondheim'
    },
    {
        zipCode: '7439',
        city: 'Trondheim'
    },
    {
        zipCode: '7440',
        city: 'Trondheim'
    },
    {
        zipCode: '7441',
        city: 'Trondheim'
    },
    {
        zipCode: '7442',
        city: 'Trondheim'
    },
    {
        zipCode: '7443',
        city: 'Trondheim'
    },
    {
        zipCode: '7444',
        city: 'Trondheim'
    },
    {
        zipCode: '7445',
        city: 'Trondheim'
    },
    {
        zipCode: '7446',
        city: 'Trondheim'
    },
    {
        zipCode: '7447',
        city: 'Trondheim'
    },
    {
        zipCode: '7448',
        city: 'Trondheim'
    },
    {
        zipCode: '7449',
        city: 'Trondheim'
    },
    {
        zipCode: '7450',
        city: 'Trondheim'
    },
    {
        zipCode: '7451',
        city: 'Trondheim'
    },
    {
        zipCode: '7452',
        city: 'Trondheim'
    },
    {
        zipCode: '7453',
        city: 'Trondheim'
    },
    {
        zipCode: '7454',
        city: 'Trondheim'
    },
    {
        zipCode: '7455',
        city: 'Trondheim'
    },
    {
        zipCode: '7456',
        city: 'Trondheim'
    },
    {
        zipCode: '7457',
        city: 'Trondheim'
    },
    {
        zipCode: '7458',
        city: 'Trondheim'
    },
    {
        zipCode: '7459',
        city: 'Trondheim'
    },
    {
        zipCode: '7462',
        city: 'Trondheim'
    },
    {
        zipCode: '7463',
        city: 'Trondheim'
    },
    {
        zipCode: '7464',
        city: 'Trondheim'
    },
    {
        zipCode: '7465',
        city: 'Trondheim'
    },
    {
        zipCode: '7466',
        city: 'Trondheim'
    },
    {
        zipCode: '7467',
        city: 'Trondheim'
    },
    {
        zipCode: '7468',
        city: 'Trondheim'
    },
    {
        zipCode: '7469',
        city: 'Trondheim'
    },
    {
        zipCode: '7470',
        city: 'Trondheim'
    },
    {
        zipCode: '7471',
        city: 'Trondheim'
    },
    {
        zipCode: '7472',
        city: 'Trondheim'
    },
    {
        zipCode: '7473',
        city: 'Trondheim'
    },
    {
        zipCode: '7474',
        city: 'Trondheim'
    },
    {
        zipCode: '7475',
        city: 'Trondheim'
    },
    {
        zipCode: '7476',
        city: 'Trondheim'
    },
    {
        zipCode: '7477',
        city: 'Trondheim'
    },
    {
        zipCode: '7478',
        city: 'Trondheim'
    },
    {
        zipCode: '7479',
        city: 'Trondheim'
    },
    {
        zipCode: '7480',
        city: 'Trondheim'
    },
    {
        zipCode: '7481',
        city: 'Trondheim'
    },
    {
        zipCode: '7482',
        city: 'Trondheim'
    },
    {
        zipCode: '7483',
        city: 'Trondheim'
    },
    {
        zipCode: '7484',
        city: 'Trondheim'
    },
    {
        zipCode: '7485',
        city: 'Trondheim'
    },
    {
        zipCode: '7486',
        city: 'Trondheim'
    },
    {
        zipCode: '7487',
        city: 'Trondheim'
    },
    {
        zipCode: '7488',
        city: 'Trondheim'
    },
    {
        zipCode: '7489',
        city: 'Trondheim'
    },
    {
        zipCode: '7490',
        city: 'Trondheim'
    },
    {
        zipCode: '7491',
        city: 'Trondheim'
    },
    {
        zipCode: '7492',
        city: 'Trondheim'
    },
    {
        zipCode: '7493',
        city: 'Trondheim'
    },
    {
        zipCode: '7494',
        city: 'Trondheim'
    },
    {
        zipCode: '7495',
        city: 'Trondheim'
    },
    {
        zipCode: '7496',
        city: 'Trondheim'
    },
    {
        zipCode: '7497',
        city: 'Trondheim'
    },
    {
        zipCode: '7498',
        city: 'Trondheim'
    },
    {
        zipCode: '7500',
        city: 'Stjørdal'
    },
    {
        zipCode: '7501',
        city: 'Stjørdal'
    },
    {
        zipCode: '7502',
        city: 'Stjørdal'
    },
    {
        zipCode: '7503',
        city: 'Stjørdal'
    },
    {
        zipCode: '7504',
        city: 'Stjørdal'
    },
    {
        zipCode: '7505',
        city: 'Stjørdal'
    },
    {
        zipCode: '7506',
        city: 'Stjørdal'
    },
    {
        zipCode: '7507',
        city: 'Stjørdal'
    },
    {
        zipCode: '7508',
        city: 'Stjørdal'
    },
    {
        zipCode: '7509',
        city: 'Stjørdal'
    },
    {
        zipCode: '7510',
        city: 'Skatval'
    },
    {
        zipCode: '7511',
        city: 'Skatval'
    },
    {
        zipCode: '7512',
        city: 'Stjørdal'
    },
    {
        zipCode: '7513',
        city: 'Stjørdal'
    },
    {
        zipCode: '7514',
        city: 'Stjørdal'
    },
    {
        zipCode: '7517',
        city: 'Hell'
    },
    {
        zipCode: '7519',
        city: 'Elvarli'
    },
    {
        zipCode: '7520',
        city: 'Hegra'
    },
    {
        zipCode: '7525',
        city: 'Flornes'
    },
    {
        zipCode: '7529',
        city: 'Hegra'
    },
    {
        zipCode: '7530',
        city: 'Meråker'
    },
    {
        zipCode: '7531',
        city: 'Meråker'
    },
    {
        zipCode: '7533',
        city: 'Kopperå'
    },
    {
        zipCode: '7540',
        city: 'Klæbu'
    },
    {
        zipCode: '7541',
        city: 'Klæbu'
    },
    {
        zipCode: '7549',
        city: 'Tanem'
    },
    {
        zipCode: '7550',
        city: 'Hommelvik'
    },
    {
        zipCode: '7551',
        city: 'Hommelvik'
    },
    {
        zipCode: '7560',
        city: 'Vikhammer'
    },
    {
        zipCode: '7562',
        city: 'Saksvik'
    },
    {
        zipCode: '7563',
        city: 'Malvik'
    },
    {
        zipCode: '7566',
        city: 'Vikhammer'
    },
    {
        zipCode: '7570',
        city: 'Hell'
    },
    {
        zipCode: '7580',
        city: 'Selbu'
    },
    {
        zipCode: '7581',
        city: 'Selbu'
    },
    {
        zipCode: '7583',
        city: 'Selbu'
    },
    {
        zipCode: '7584',
        city: 'Selbustrand'
    },
    {
        zipCode: '7590',
        city: 'Tydal'
    },
    {
        zipCode: '7591',
        city: 'Tydal'
    },
    {
        zipCode: '7596',
        city: 'Flaknan'
    },
    {
        zipCode: '7600',
        city: 'Levanger'
    },
    {
        zipCode: '7601',
        city: 'Levanger'
    },
    {
        zipCode: '7602',
        city: 'Levanger'
    },
    {
        zipCode: '7603',
        city: 'Levanger'
    },
    {
        zipCode: '7604',
        city: 'Levanger'
    },
    {
        zipCode: '7605',
        city: 'Levanger'
    },
    {
        zipCode: '7606',
        city: 'Levanger'
    },
    {
        zipCode: '7607',
        city: 'Levanger'
    },
    {
        zipCode: '7608',
        city: 'Levanger'
    },
    {
        zipCode: '7609',
        city: 'Levanger'
    },
    {
        zipCode: '7610',
        city: 'Levanger'
    },
    {
        zipCode: '7619',
        city: 'Skogn'
    },
    {
        zipCode: '7620',
        city: 'Skogn'
    },
    {
        zipCode: '7622',
        city: 'Markabygda'
    },
    {
        zipCode: '7623',
        city: 'Ronglan'
    },
    {
        zipCode: '7624',
        city: 'Ekne'
    },
    {
        zipCode: '7629',
        city: 'Ytterøy'
    },
    {
        zipCode: '7630',
        city: 'Åsen'
    },
    {
        zipCode: '7631',
        city: 'Åsen'
    },
    {
        zipCode: '7632',
        city: 'Åsenfjord'
    },
    {
        zipCode: '7633',
        city: 'Frosta'
    },
    {
        zipCode: '7634',
        city: 'Frosta'
    },
    {
        zipCode: '7650',
        city: 'Verdal'
    },
    {
        zipCode: '7651',
        city: 'Verdal'
    },
    {
        zipCode: '7652',
        city: 'Verdal'
    },
    {
        zipCode: '7653',
        city: 'Verdal'
    },
    {
        zipCode: '7654',
        city: 'Verdal'
    },
    {
        zipCode: '7655',
        city: 'Verdal'
    },
    {
        zipCode: '7656',
        city: 'Verdal'
    },
    {
        zipCode: '7657',
        city: 'Verdal'
    },
    {
        zipCode: '7658',
        city: 'Verdal'
    },
    {
        zipCode: '7660',
        city: 'Vuku'
    },
    {
        zipCode: '7661',
        city: 'Vuku'
    },
    {
        zipCode: '7670',
        city: 'Inderøy'
    },
    {
        zipCode: '7671',
        city: 'Inderøy'
    },
    {
        zipCode: '7672',
        city: 'Inderøy'
    },
    {
        zipCode: '7690',
        city: 'Mosvik'
    },
    {
        zipCode: '7691',
        city: 'Mosvik'
    },
    {
        zipCode: '7701',
        city: 'Steinkjer'
    },
    {
        zipCode: '7702',
        city: 'Steinkjer'
    },
    {
        zipCode: '7703',
        city: 'Steinkjer'
    },
    {
        zipCode: '7704',
        city: 'Steinkjer'
    },
    {
        zipCode: '7705',
        city: 'Steinkjer'
    },
    {
        zipCode: '7707',
        city: 'Steinkjer'
    },
    {
        zipCode: '7708',
        city: 'Steinkjer'
    },
    {
        zipCode: '7709',
        city: 'Steinkjer'
    },
    {
        zipCode: '7710',
        city: 'Sparbu'
    },
    {
        zipCode: '7711',
        city: 'Steinkjer'
    },
    {
        zipCode: '7712',
        city: 'Steinkjer'
    },
    {
        zipCode: '7713',
        city: 'Steinkjer'
    },
    {
        zipCode: '7714',
        city: 'Steinkjer'
    },
    {
        zipCode: '7715',
        city: 'Steinkjer'
    },
    {
        zipCode: '7716',
        city: 'Steinkjer'
    },
    {
        zipCode: '7717',
        city: 'Steinkjer'
    },
    {
        zipCode: '7718',
        city: 'Steinkjer'
    },
    {
        zipCode: '7724',
        city: 'Steinkjer'
    },
    {
        zipCode: '7725',
        city: 'Steinkjer'
    },
    {
        zipCode: '7726',
        city: 'Steinkjer'
    },
    {
        zipCode: '7729',
        city: 'Steinkjer'
    },
    {
        zipCode: '7730',
        city: 'Beitstad'
    },
    {
        zipCode: '7732',
        city: 'Steinkjer'
    },
    {
        zipCode: '7733',
        city: 'Sparbu'
    },
    {
        zipCode: '7734',
        city: 'Steinkjer'
    },
    {
        zipCode: '7735',
        city: 'Steinkjer'
    },
    {
        zipCode: '7736',
        city: 'Steinkjer'
    },
    {
        zipCode: '7737',
        city: 'Steinkjer'
    },
    {
        zipCode: '7738',
        city: 'Steinkjer'
    },
    {
        zipCode: '7739',
        city: 'Beitstad'
    },
    {
        zipCode: '7740',
        city: 'Steinsdalen'
    },
    {
        zipCode: '7741',
        city: 'Steinsdalen'
    },
    {
        zipCode: '7742',
        city: 'Yttervåg'
    },
    {
        zipCode: '7744',
        city: 'Hepsøy'
    },
    {
        zipCode: '7745',
        city: 'Oppland'
    },
    {
        zipCode: '7746',
        city: 'Hasvåg'
    },
    {
        zipCode: '7748',
        city: 'Sætervik'
    },
    {
        zipCode: '7750',
        city: 'Namdalseid'
    },
    {
        zipCode: '7751',
        city: 'Namdalseid'
    },
    {
        zipCode: '7760',
        city: 'Snåsa'
    },
    {
        zipCode: '7761',
        city: 'Snåsa'
    },
    {
        zipCode: '7770',
        city: 'Flatanger'
    },
    {
        zipCode: '7771',
        city: 'Flatanger'
    },
    {
        zipCode: '7777',
        city: 'Nord-statland'
    },
    {
        zipCode: '7790',
        city: 'Malm'
    },
    {
        zipCode: '7791',
        city: 'Malm'
    },
    {
        zipCode: '7795',
        city: 'Follafoss'
    },
    {
        zipCode: '7796',
        city: 'Follafoss'
    },
    {
        zipCode: '7797',
        city: 'Verrabotn'
    },
    {
        zipCode: '7800',
        city: 'Namsos'
    },
    {
        zipCode: '7801',
        city: 'Namsos'
    },
    {
        zipCode: '7802',
        city: 'Namsos'
    },
    {
        zipCode: '7803',
        city: 'Namsos'
    },
    {
        zipCode: '7804',
        city: 'Namsos'
    },
    {
        zipCode: '7805',
        city: 'Namsos'
    },
    {
        zipCode: '7808',
        city: 'Namsos'
    },
    {
        zipCode: '7810',
        city: 'Namsos'
    },
    {
        zipCode: '7817',
        city: 'Salsnes'
    },
    {
        zipCode: '7818',
        city: 'Lund'
    },
    {
        zipCode: '7819',
        city: 'Fosslandsosen'
    },
    {
        zipCode: '7820',
        city: 'Spillum'
    },
    {
        zipCode: '7821',
        city: 'Spillum'
    },
    {
        zipCode: '7822',
        city: 'Bangsund'
    },
    {
        zipCode: '7823',
        city: 'Bangsund'
    },
    {
        zipCode: '7856',
        city: 'Jøa'
    },
    {
        zipCode: '7860',
        city: 'Skage i namdalen'
    },
    {
        zipCode: '7863',
        city: 'Overhalla'
    },
    {
        zipCode: '7864',
        city: 'Overhalla'
    },
    {
        zipCode: '7869',
        city: 'Skage i namdalen'
    },
    {
        zipCode: '7870',
        city: 'Grong'
    },
    {
        zipCode: '7871',
        city: 'Grong'
    },
    {
        zipCode: '7873',
        city: 'Harran'
    },
    {
        zipCode: '7874',
        city: 'Harran'
    },
    {
        zipCode: '7876',
        city: 'Kongsmoen'
    },
    {
        zipCode: '7877',
        city: 'Høylandet'
    },
    {
        zipCode: '7878',
        city: 'Høylandet'
    },
    {
        zipCode: '7881',
        city: 'Nordli'
    },
    {
        zipCode: '7882',
        city: 'Nordli'
    },
    {
        zipCode: '7884',
        city: 'Sørli'
    },
    {
        zipCode: '7885',
        city: 'Sørli'
    },
    {
        zipCode: '7890',
        city: 'Namsskogan'
    },
    {
        zipCode: '7891',
        city: 'Namsskogan'
    },
    {
        zipCode: '7892',
        city: 'Trones'
    },
    {
        zipCode: '7893',
        city: 'Skorovatn'
    },
    {
        zipCode: '7896',
        city: 'Brekkvasselv'
    },
    {
        zipCode: '7897',
        city: 'Limingen'
    },
    {
        zipCode: '7898',
        city: 'Limingen'
    },
    {
        zipCode: '7900',
        city: 'Rørvik'
    },
    {
        zipCode: '7901',
        city: 'Rørvik'
    },
    {
        zipCode: '7902',
        city: 'Rørvik'
    },
    {
        zipCode: '7940',
        city: 'Ottersøy'
    },
    {
        zipCode: '7941',
        city: 'Ottersøy'
    },
    {
        zipCode: '7944',
        city: 'Indre nærøy'
    },
    {
        zipCode: '7950',
        city: 'Abelvær'
    },
    {
        zipCode: '7960',
        city: 'Salsbruket'
    },
    {
        zipCode: '7970',
        city: 'Kolvereid'
    },
    {
        zipCode: '7971',
        city: 'Kolvereid'
    },
    {
        zipCode: '7973',
        city: 'Gjerdinga'
    },
    {
        zipCode: '7979',
        city: 'Terråk'
    },
    {
        zipCode: '7980',
        city: 'Terråk'
    },
    {
        zipCode: '7981',
        city: 'Harangsfjord'
    },
    {
        zipCode: '7982',
        city: 'Bindalseidet'
    },
    {
        zipCode: '7983',
        city: 'Bindalseidet'
    },
    {
        zipCode: '7985',
        city: 'Foldereid'
    },
    {
        zipCode: '7986',
        city: 'Foldereid'
    },
    {
        zipCode: '7990',
        city: 'Naustbukta'
    },
    {
        zipCode: '7993',
        city: 'Gutvik'
    },
    {
        zipCode: '7994',
        city: 'Leka'
    },
    {
        zipCode: '7995',
        city: 'Leka'
    },
    {
        zipCode: '8001',
        city: 'Bodø'
    },
    {
        zipCode: '8002',
        city: 'Bodø'
    },
    {
        zipCode: '8003',
        city: 'Bodø'
    },
    {
        zipCode: '8004',
        city: 'Bodø'
    },
    {
        zipCode: '8005',
        city: 'Bodø'
    },
    {
        zipCode: '8006',
        city: 'Bodø'
    },
    {
        zipCode: '8007',
        city: 'Bodø'
    },
    {
        zipCode: '8008',
        city: 'Bodø'
    },
    {
        zipCode: '8009',
        city: 'Bodø'
    },
    {
        zipCode: '8010',
        city: 'Bodø'
    },
    {
        zipCode: '8011',
        city: 'Bodø'
    },
    {
        zipCode: '8012',
        city: 'Bodø'
    },
    {
        zipCode: '8013',
        city: 'Bodø'
    },
    {
        zipCode: '8014',
        city: 'Bodø'
    },
    {
        zipCode: '8015',
        city: 'Bodø'
    },
    {
        zipCode: '8016',
        city: 'Bodø'
    },
    {
        zipCode: '8019',
        city: 'Bodø'
    },
    {
        zipCode: '8020',
        city: 'Bodø'
    },
    {
        zipCode: '8021',
        city: 'Bodø'
    },
    {
        zipCode: '8022',
        city: 'Bodø'
    },
    {
        zipCode: '8023',
        city: 'Bodø'
    },
    {
        zipCode: '8026',
        city: 'Bodø'
    },
    {
        zipCode: '8027',
        city: 'Bodø'
    },
    {
        zipCode: '8028',
        city: 'Bodø'
    },
    {
        zipCode: '8029',
        city: 'Bodø'
    },
    {
        zipCode: '8030',
        city: 'Bodø'
    },
    {
        zipCode: '8031',
        city: 'Bodø'
    },
    {
        zipCode: '8037',
        city: 'Bodø'
    },
    {
        zipCode: '8038',
        city: 'Bodø'
    },
    {
        zipCode: '8041',
        city: 'Bodø'
    },
    {
        zipCode: '8047',
        city: 'Bodø'
    },
    {
        zipCode: '8048',
        city: 'Bodø'
    },
    {
        zipCode: '8049',
        city: 'Bodø'
    },
    {
        zipCode: '8050',
        city: 'Tverlandet'
    },
    {
        zipCode: '8056',
        city: 'Saltstraumen'
    },
    {
        zipCode: '8057',
        city: 'Saltstraumen'
    },
    {
        zipCode: '8058',
        city: 'Tverlandet'
    },
    {
        zipCode: '8062',
        city: 'Værøy'
    },
    {
        zipCode: '8063',
        city: 'Værøy'
    },
    {
        zipCode: '8064',
        city: 'Røst'
    },
    {
        zipCode: '8065',
        city: 'Røst'
    },
    {
        zipCode: '8070',
        city: 'Bodø'
    },
    {
        zipCode: '8071',
        city: 'Bodø'
    },
    {
        zipCode: '8072',
        city: 'Bodø'
    },
    {
        zipCode: '8073',
        city: 'Bodø'
    },
    {
        zipCode: '8074',
        city: 'Bodø'
    },
    {
        zipCode: '8075',
        city: 'Bodø'
    },
    {
        zipCode: '8076',
        city: 'Bodø'
    },
    {
        zipCode: '8079',
        city: 'Bodø'
    },
    {
        zipCode: '8084',
        city: 'Bodø'
    },
    {
        zipCode: '8086',
        city: 'Bodø'
    },
    {
        zipCode: '8087',
        city: 'Bodø'
    },
    {
        zipCode: '8088',
        city: 'Bodø'
    },
    {
        zipCode: '8089',
        city: 'Bodø'
    },
    {
        zipCode: '8091',
        city: 'Bodø'
    },
    {
        zipCode: '8092',
        city: 'Bodø'
    },
    {
        zipCode: '8093',
        city: 'Kjerringøy'
    },
    {
        zipCode: '8094',
        city: 'Fleinvær'
    },
    {
        zipCode: '8095',
        city: 'Helligvær'
    },
    {
        zipCode: '8096',
        city: 'Bliksvær'
    },
    {
        zipCode: '8097',
        city: 'Givær'
    },
    {
        zipCode: '8098',
        city: 'Landegode'
    },
    {
        zipCode: '8099',
        city: 'Jan mayen'
    },
    {
        zipCode: '8100',
        city: 'Misvær'
    },
    {
        zipCode: '8102',
        city: 'Skjerstad'
    },
    {
        zipCode: '8103',
        city: 'Breivik i salten'
    },
    {
        zipCode: '8108',
        city: 'Misvær'
    },
    {
        zipCode: '8110',
        city: 'Moldjord'
    },
    {
        zipCode: '8114',
        city: 'Tollå'
    },
    {
        zipCode: '8118',
        city: 'Moldjord'
    },
    {
        zipCode: '8120',
        city: 'Nygårdsjøen'
    },
    {
        zipCode: '8128',
        city: 'Ytre beiarn'
    },
    {
        zipCode: '8130',
        city: 'Sandhornøy'
    },
    {
        zipCode: '8134',
        city: 'Sørarnøy'
    },
    {
        zipCode: '8135',
        city: 'Sørarnøy'
    },
    {
        zipCode: '8136',
        city: 'Nordarnøy'
    },
    {
        zipCode: '8138',
        city: 'Inndyr'
    },
    {
        zipCode: '8140',
        city: 'Inndyr'
    },
    {
        zipCode: '8145',
        city: 'Storvik'
    },
    {
        zipCode: '8146',
        city: 'Reipå'
    },
    {
        zipCode: '8149',
        city: 'Neverdal'
    },
    {
        zipCode: '8150',
        city: 'Ørnes'
    },
    {
        zipCode: '8151',
        city: 'Ørnes'
    },
    {
        zipCode: '8157',
        city: 'Meløy'
    },
    {
        zipCode: '8158',
        city: 'Bolga'
    },
    {
        zipCode: '8159',
        city: 'Støtt'
    },
    {
        zipCode: '8160',
        city: 'Glomfjord'
    },
    {
        zipCode: '8161',
        city: 'Glomfjord'
    },
    {
        zipCode: '8168',
        city: 'Engavågen'
    },
    {
        zipCode: '8170',
        city: 'Engavågen'
    },
    {
        zipCode: '8178',
        city: 'Halsa'
    },
    {
        zipCode: '8179',
        city: 'Halsa'
    },
    {
        zipCode: '8181',
        city: 'Myken'
    },
    {
        zipCode: '8182',
        city: 'Melfjordbotn'
    },
    {
        zipCode: '8183',
        city: 'Vågaholmen'
    },
    {
        zipCode: '8184',
        city: 'Ågskardet'
    },
    {
        zipCode: '8185',
        city: 'Vågaholmen'
    },
    {
        zipCode: '8186',
        city: 'Tjongsfjorden'
    },
    {
        zipCode: '8187',
        city: 'Jektvik'
    },
    {
        zipCode: '8188',
        city: 'Nordvernes'
    },
    {
        zipCode: '8189',
        city: 'Gjersvikgrenda'
    },
    {
        zipCode: '8190',
        city: 'Sørfjorden'
    },
    {
        zipCode: '8193',
        city: 'Rødøy'
    },
    {
        zipCode: '8195',
        city: 'Gjerøy'
    },
    {
        zipCode: '8196',
        city: 'Selsøyvik'
    },
    {
        zipCode: '8197',
        city: 'Storselsøy'
    },
    {
        zipCode: '8198',
        city: 'Nordnesøy'
    },
    {
        zipCode: '8200',
        city: 'Fauske'
    },
    {
        zipCode: '8201',
        city: 'Fauske'
    },
    {
        zipCode: '8202',
        city: 'Fauske'
    },
    {
        zipCode: '8203',
        city: 'Fauske'
    },
    {
        zipCode: '8205',
        city: 'Fauske'
    },
    {
        zipCode: '8206',
        city: 'Fauske'
    },
    {
        zipCode: '8207',
        city: 'Fauske'
    },
    {
        zipCode: '8208',
        city: 'Fauske'
    },
    {
        zipCode: '8209',
        city: 'Fauske'
    },
    {
        zipCode: '8210',
        city: 'Fauske'
    },
    {
        zipCode: '8211',
        city: 'Fauske'
    },
    {
        zipCode: '8214',
        city: 'Fauske'
    },
    {
        zipCode: '8215',
        city: 'Valnesfjord'
    },
    {
        zipCode: '8218',
        city: 'Fauske'
    },
    {
        zipCode: '8219',
        city: 'Fauske'
    },
    {
        zipCode: '8220',
        city: 'Røsvik'
    },
    {
        zipCode: '8226',
        city: 'Straumen'
    },
    {
        zipCode: '8230',
        city: 'Sulitjelma'
    },
    {
        zipCode: '8231',
        city: 'Sulitjelma'
    },
    {
        zipCode: '8232',
        city: 'Straumen'
    },
    {
        zipCode: '8233',
        city: 'Valnesfjord'
    },
    {
        zipCode: '8250',
        city: 'Rognan'
    },
    {
        zipCode: '8251',
        city: 'Rognan'
    },
    {
        zipCode: '8255',
        city: 'Røkland'
    },
    {
        zipCode: '8256',
        city: 'Røkland'
    },
    {
        zipCode: '8260',
        city: 'Innhavet'
    },
    {
        zipCode: '8261',
        city: 'Innhavet'
    },
    {
        zipCode: '8264',
        city: 'Engan'
    },
    {
        zipCode: '8266',
        city: 'Mørsvikbotn'
    },
    {
        zipCode: '8270',
        city: 'Drag'
    },
    {
        zipCode: '8271',
        city: 'Drag'
    },
    {
        zipCode: '8273',
        city: 'Nevervik'
    },
    {
        zipCode: '8274',
        city: 'Musken'
    },
    {
        zipCode: '8275',
        city: 'Storjord i tysfjord'
    },
    {
        zipCode: '8276',
        city: 'Ulvsvåg'
    },
    {
        zipCode: '8278',
        city: 'Storå'
    },
    {
        zipCode: '8281',
        city: 'Leinesfjord'
    },
    {
        zipCode: '8283',
        city: 'Leinesfjord'
    },
    {
        zipCode: '8285',
        city: 'Leines'
    },
    {
        zipCode: '8286',
        city: 'Nordfold'
    },
    {
        zipCode: '8287',
        city: 'Engeløya'
    },
    {
        zipCode: '8288',
        city: 'Bogøy'
    },
    {
        zipCode: '8289',
        city: 'Engeløya'
    },
    {
        zipCode: '8290',
        city: 'Skutvik'
    },
    {
        zipCode: '8294',
        city: 'Hamarøy'
    },
    {
        zipCode: '8297',
        city: 'Tranøy'
    },
    {
        zipCode: '8298',
        city: 'Hamarøy'
    },
    {
        zipCode: '8300',
        city: 'Svolvær'
    },
    {
        zipCode: '8301',
        city: 'Svolvær'
    },
    {
        zipCode: '8305',
        city: 'Svolvær'
    },
    {
        zipCode: '8309',
        city: 'Kabelvåg'
    },
    {
        zipCode: '8310',
        city: 'Kabelvåg'
    },
    {
        zipCode: '8311',
        city: 'Henningsvær'
    },
    {
        zipCode: '8312',
        city: 'Henningsvær'
    },
    {
        zipCode: '8313',
        city: 'Kleppstad'
    },
    {
        zipCode: '8314',
        city: 'Gimsøysand'
    },
    {
        zipCode: '8315',
        city: 'Laukvik'
    },
    {
        zipCode: '8316',
        city: 'Laupstad'
    },
    {
        zipCode: '8317',
        city: 'Strønstad'
    },
    {
        zipCode: '8320',
        city: 'Skrova'
    },
    {
        zipCode: '8322',
        city: 'Brettesnes'
    },
    {
        zipCode: '8323',
        city: 'Storfjell'
    },
    {
        zipCode: '8324',
        city: 'Digermulen'
    },
    {
        zipCode: '8325',
        city: 'Tengelfjord'
    },
    {
        zipCode: '8326',
        city: 'Myrland'
    },
    {
        zipCode: '8328',
        city: 'Stormolla'
    },
    {
        zipCode: '8340',
        city: 'Stamsund'
    },
    {
        zipCode: '8352',
        city: 'Sennesvik'
    },
    {
        zipCode: '8357',
        city: 'Valberg'
    },
    {
        zipCode: '8360',
        city: 'Bøstad'
    },
    {
        zipCode: '8361',
        city: 'Bøstad'
    },
    {
        zipCode: '8370',
        city: 'Leknes'
    },
    {
        zipCode: '8372',
        city: 'Gravdal'
    },
    {
        zipCode: '8373',
        city: 'Ballstad'
    },
    {
        zipCode: '8374',
        city: 'Ballstad'
    },
    {
        zipCode: '8376',
        city: 'Leknes'
    },
    {
        zipCode: '8377',
        city: 'Gravdal'
    },
    {
        zipCode: '8378',
        city: 'Stamsund'
    },
    {
        zipCode: '8380',
        city: 'Ramberg'
    },
    {
        zipCode: '8382',
        city: 'Napp'
    },
    {
        zipCode: '8384',
        city: 'Sund i lofoten'
    },
    {
        zipCode: '8387',
        city: 'Fredvang'
    },
    {
        zipCode: '8388',
        city: 'Ramberg'
    },
    {
        zipCode: '8390',
        city: 'Reine'
    },
    {
        zipCode: '8392',
        city: 'Sørvågen'
    },
    {
        zipCode: '8393',
        city: 'Sørvågen'
    },
    {
        zipCode: '8398',
        city: 'Reine'
    },
    {
        zipCode: '8400',
        city: 'Sortland'
    },
    {
        zipCode: '8401',
        city: 'Sortland'
    },
    {
        zipCode: '8402',
        city: 'Sortland'
    },
    {
        zipCode: '8403',
        city: 'Sortland'
    },
    {
        zipCode: '8404',
        city: 'Sortland'
    },
    {
        zipCode: '8405',
        city: 'Sortland'
    },
    {
        zipCode: '8406',
        city: 'Sortland'
    },
    {
        zipCode: '8407',
        city: 'Sortland'
    },
    {
        zipCode: '8408',
        city: 'Sortland'
    },
    {
        zipCode: '8409',
        city: 'Gullesfjord'
    },
    {
        zipCode: '8410',
        city: 'Lødingen'
    },
    {
        zipCode: '8411',
        city: 'Lødingen'
    },
    {
        zipCode: '8412',
        city: 'Vestbygd'
    },
    {
        zipCode: '8413',
        city: 'Kvitnes'
    },
    {
        zipCode: '8414',
        city: 'Hennes'
    },
    {
        zipCode: '8415',
        city: 'Sortland'
    },
    {
        zipCode: '8416',
        city: 'Sortland'
    },
    {
        zipCode: '8426',
        city: 'Barkestad'
    },
    {
        zipCode: '8428',
        city: 'Tunstad'
    },
    {
        zipCode: '8430',
        city: 'Myre'
    },
    {
        zipCode: '8432',
        city: 'Alsvåg'
    },
    {
        zipCode: '8438',
        city: 'Stø'
    },
    {
        zipCode: '8439',
        city: 'Myre'
    },
    {
        zipCode: '8445',
        city: 'Melbu'
    },
    {
        zipCode: '8447',
        city: 'Lonkan'
    },
    {
        zipCode: '8450',
        city: 'Stokmarknes'
    },
    {
        zipCode: '8455',
        city: 'Stokmarknes'
    },
    {
        zipCode: '8459',
        city: 'Melbu'
    },
    {
        zipCode: '8465',
        city: 'Straumsjøen'
    },
    {
        zipCode: '8469',
        city: 'Bø i vesterålen'
    },
    {
        zipCode: '8470',
        city: 'Bø i vesterålen'
    },
    {
        zipCode: '8475',
        city: 'Straumsjøen'
    },
    {
        zipCode: '8480',
        city: 'Andenes'
    },
    {
        zipCode: '8481',
        city: 'Bleik'
    },
    {
        zipCode: '8483',
        city: 'Andenes'
    },
    {
        zipCode: '8484',
        city: 'Risøyhamn'
    },
    {
        zipCode: '8485',
        city: 'Dverberg'
    },
    {
        zipCode: '8488',
        city: 'Nøss'
    },
    {
        zipCode: '8489',
        city: 'Nordmela'
    },
    {
        zipCode: '8493',
        city: 'Risøyhamn'
    },
    {
        zipCode: '8501',
        city: 'Narvik'
    },
    {
        zipCode: '8502',
        city: 'Narvik'
    },
    {
        zipCode: '8503',
        city: 'Narvik'
    },
    {
        zipCode: '8504',
        city: 'Narvik'
    },
    {
        zipCode: '8505',
        city: 'Narvik'
    },
    {
        zipCode: '8506',
        city: 'Narvik'
    },
    {
        zipCode: '8507',
        city: 'Narvik'
    },
    {
        zipCode: '8508',
        city: 'Narvik'
    },
    {
        zipCode: '8509',
        city: 'Narvik'
    },
    {
        zipCode: '8510',
        city: 'Narvik'
    },
    {
        zipCode: '8512',
        city: 'Narvik'
    },
    {
        zipCode: '8513',
        city: 'Ankenes'
    },
    {
        zipCode: '8514',
        city: 'Narvik'
    },
    {
        zipCode: '8515',
        city: 'Narvik'
    },
    {
        zipCode: '8516',
        city: 'Narvik'
    },
    {
        zipCode: '8517',
        city: 'Narvik'
    },
    {
        zipCode: '8518',
        city: 'Narvik'
    },
    {
        zipCode: '8520',
        city: 'Ankenes'
    },
    {
        zipCode: '8522',
        city: 'Beisfjord'
    },
    {
        zipCode: '8523',
        city: 'Elvegård'
    },
    {
        zipCode: '8530',
        city: 'Bjerkvik'
    },
    {
        zipCode: '8531',
        city: 'Bjerkvik'
    },
    {
        zipCode: '8533',
        city: 'Bogen i ofoten'
    },
    {
        zipCode: '8534',
        city: 'Liland'
    },
    {
        zipCode: '8535',
        city: 'Tårstad'
    },
    {
        zipCode: '8536',
        city: 'Evenes'
    },
    {
        zipCode: '8539',
        city: 'Bogen i ofoten'
    },
    {
        zipCode: '8540',
        city: 'Ballangen'
    },
    {
        zipCode: '8543',
        city: 'Kjeldebotn'
    },
    {
        zipCode: '8546',
        city: 'Ballangen'
    },
    {
        zipCode: '8590',
        city: 'Kjøpsvik'
    },
    {
        zipCode: '8591',
        city: 'Kjøpsvik'
    },
    {
        zipCode: '8601',
        city: 'Mo i rana'
    },
    {
        zipCode: '8602',
        city: 'Mo i rana'
    },
    {
        zipCode: '8603',
        city: 'Mo i rana'
    },
    {
        zipCode: '8604',
        city: 'Mo i rana'
    },
    {
        zipCode: '8607',
        city: 'Mo i rana'
    },
    {
        zipCode: '8608',
        city: 'Mo i rana'
    },
    {
        zipCode: '8609',
        city: 'Mo i rana'
    },
    {
        zipCode: '8610',
        city: 'Mo i rana'
    },
    {
        zipCode: '8613',
        city: 'Mo i rana'
    },
    {
        zipCode: '8614',
        city: 'Mo i rana'
    },
    {
        zipCode: '8615',
        city: 'Skonseng'
    },
    {
        zipCode: '8616',
        city: 'Mo i rana'
    },
    {
        zipCode: '8617',
        city: 'Dalsgrenda'
    },
    {
        zipCode: '8618',
        city: 'Mo i rana'
    },
    {
        zipCode: '8619',
        city: 'Mo i rana'
    },
    {
        zipCode: '8622',
        city: 'Mo i rana'
    },
    {
        zipCode: '8624',
        city: 'Mo i rana'
    },
    {
        zipCode: '8626',
        city: 'Mo i rana'
    },
    {
        zipCode: '8630',
        city: 'Storforshei'
    },
    {
        zipCode: '8634',
        city: 'Mo i rana'
    },
    {
        zipCode: '8638',
        city: 'Storforshei'
    },
    {
        zipCode: '8640',
        city: 'Hemnesberget'
    },
    {
        zipCode: '8641',
        city: 'Hemnesberget'
    },
    {
        zipCode: '8642',
        city: 'Finneidfjord'
    },
    {
        zipCode: '8643',
        city: 'Bjerka'
    },
    {
        zipCode: '8644',
        city: 'Bjerka'
    },
    {
        zipCode: '8646',
        city: 'Korgen'
    },
    {
        zipCode: '8647',
        city: 'Bleikvasslia'
    },
    {
        zipCode: '8648',
        city: 'Korgen'
    },
    {
        zipCode: '8651',
        city: 'Mosjøen'
    },
    {
        zipCode: '8652',
        city: 'Mosjøen'
    },
    {
        zipCode: '8654',
        city: 'Mosjøen'
    },
    {
        zipCode: '8655',
        city: 'Mosjøen'
    },
    {
        zipCode: '8656',
        city: 'Mosjøen'
    },
    {
        zipCode: '8657',
        city: 'Mosjøen'
    },
    {
        zipCode: '8658',
        city: 'Mosjøen'
    },
    {
        zipCode: '8659',
        city: 'Mosjøen'
    },
    {
        zipCode: '8660',
        city: 'Mosjøen'
    },
    {
        zipCode: '8661',
        city: 'Mosjøen'
    },
    {
        zipCode: '8663',
        city: 'Mosjøen'
    },
    {
        zipCode: '8664',
        city: 'Mosjøen'
    },
    {
        zipCode: '8665',
        city: 'Mosjøen'
    },
    {
        zipCode: '8666',
        city: 'Mosjøen'
    },
    {
        zipCode: '8672',
        city: 'Elsfjord'
    },
    {
        zipCode: '8680',
        city: 'Trofors'
    },
    {
        zipCode: '8681',
        city: 'Trofors'
    },
    {
        zipCode: '8690',
        city: 'Hattfjelldal'
    },
    {
        zipCode: '8691',
        city: 'Hattfjelldal'
    },
    {
        zipCode: '8700',
        city: 'Nesna'
    },
    {
        zipCode: '8701',
        city: 'Nesna'
    },
    {
        zipCode: '8720',
        city: 'Vikholmen'
    },
    {
        zipCode: '8723',
        city: 'Husby'
    },
    {
        zipCode: '8724',
        city: 'Saura'
    },
    {
        zipCode: '8725',
        city: 'Utskarpen'
    },
    {
        zipCode: '8730',
        city: 'Bratland'
    },
    {
        zipCode: '8732',
        city: 'Aldra'
    },
    {
        zipCode: '8733',
        city: 'Stuvland'
    },
    {
        zipCode: '8735',
        city: 'Stokkvågen'
    },
    {
        zipCode: '8740',
        city: 'Nord-solvær'
    },
    {
        zipCode: '8742',
        city: 'Selvær'
    },
    {
        zipCode: '8743',
        city: 'Indre kvarøy'
    },
    {
        zipCode: '8750',
        city: 'Tonnes'
    },
    {
        zipCode: '8752',
        city: 'Konsvikosen'
    },
    {
        zipCode: '8753',
        city: 'Konsvikosen'
    },
    {
        zipCode: '8754',
        city: 'Øresvik'
    },
    {
        zipCode: '8762',
        city: 'Sleneset'
    },
    {
        zipCode: '8764',
        city: 'Lovund'
    },
    {
        zipCode: '8766',
        city: 'Lurøy'
    },
    {
        zipCode: '8767',
        city: 'Lurøy'
    },
    {
        zipCode: '8770',
        city: 'Træna'
    },
    {
        zipCode: '8800',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8801',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8802',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8803',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8804',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8805',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8809',
        city: 'Sandnessjøen'
    },
    {
        zipCode: '8813',
        city: 'Løkta'
    },
    {
        zipCode: '8820',
        city: 'Dønna'
    },
    {
        zipCode: '8827',
        city: 'Dønna'
    },
    {
        zipCode: '8830',
        city: 'Vandve'
    },
    {
        zipCode: '8842',
        city: 'Brasøy'
    },
    {
        zipCode: '8844',
        city: 'Sandvær'
    },
    {
        zipCode: '8850',
        city: 'Herøy'
    },
    {
        zipCode: '8851',
        city: 'Herøy'
    },
    {
        zipCode: '8852',
        city: 'Herøy'
    },
    {
        zipCode: '8854',
        city: 'Austbø'
    },
    {
        zipCode: '8860',
        city: 'Tjøtta'
    },
    {
        zipCode: '8861',
        city: 'Tjøtta'
    },
    {
        zipCode: '8865',
        city: 'Tro'
    },
    {
        zipCode: '8870',
        city: 'Visthus'
    },
    {
        zipCode: '8880',
        city: 'Bærøyvågen'
    },
    {
        zipCode: '8890',
        city: 'Leirfjord'
    },
    {
        zipCode: '8891',
        city: 'Leirfjord'
    },
    {
        zipCode: '8892',
        city: 'Sundøy'
    },
    {
        zipCode: '8897',
        city: 'Bardal'
    },
    {
        zipCode: '8900',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8901',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8902',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8904',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8905',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8906',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8907',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8908',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8909',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8910',
        city: 'Brønnøysund'
    },
    {
        zipCode: '8920',
        city: 'Sømna'
    },
    {
        zipCode: '8921',
        city: 'Sømna'
    },
    {
        zipCode: '8922',
        city: 'Sømna'
    },
    {
        zipCode: '8960',
        city: 'Velfjord'
    },
    {
        zipCode: '8961',
        city: 'Velfjord'
    },
    {
        zipCode: '8976',
        city: 'Vevelstad'
    },
    {
        zipCode: '8977',
        city: 'Vevelstad'
    },
    {
        zipCode: '8980',
        city: 'Vega'
    },
    {
        zipCode: '8981',
        city: 'Vega'
    },
    {
        zipCode: '8985',
        city: 'Ylvingen'
    },
    {
        zipCode: '9006',
        city: 'Tromsø'
    },
    {
        zipCode: '9007',
        city: 'Tromsø'
    },
    {
        zipCode: '9008',
        city: 'Tromsø'
    },
    {
        zipCode: '9009',
        city: 'Tromsø'
    },
    {
        zipCode: '9010',
        city: 'Tromsø'
    },
    {
        zipCode: '9011',
        city: 'Tromsø'
    },
    {
        zipCode: '9012',
        city: 'Tromsø'
    },
    {
        zipCode: '9013',
        city: 'Tromsø'
    },
    {
        zipCode: '9014',
        city: 'Tromsø'
    },
    {
        zipCode: '9015',
        city: 'Tromsø'
    },
    {
        zipCode: '9016',
        city: 'Tromsø'
    },
    {
        zipCode: '9017',
        city: 'Tromsø'
    },
    {
        zipCode: '9018',
        city: 'Tromsø'
    },
    {
        zipCode: '9019',
        city: 'Tromsø'
    },
    {
        zipCode: '9020',
        city: 'Tromsdalen'
    },
    {
        zipCode: '9021',
        city: 'Tromsdalen'
    },
    {
        zipCode: '9022',
        city: 'Krokelvdalen'
    },
    {
        zipCode: '9023',
        city: 'Krokelvdalen'
    },
    {
        zipCode: '9024',
        city: 'Tomasjord'
    },
    {
        zipCode: '9027',
        city: 'Ramfjordbotn'
    },
    {
        zipCode: '9029',
        city: 'Tromsdalen'
    },
    {
        zipCode: '9030',
        city: 'Sjursnes'
    },
    {
        zipCode: '9034',
        city: 'Oldervik'
    },
    {
        zipCode: '9037',
        city: 'Tromsø'
    },
    {
        zipCode: '9038',
        city: 'Tromsø'
    },
    {
        zipCode: '9040',
        city: 'Nordkjosbotn'
    },
    {
        zipCode: '9042',
        city: 'Laksvatn'
    },
    {
        zipCode: '9043',
        city: 'Jøvik'
    },
    {
        zipCode: '9046',
        city: 'Oteren'
    },
    {
        zipCode: '9049',
        city: 'Nordkjosbotn'
    },
    {
        zipCode: '9050',
        city: 'Storsteinnes'
    },
    {
        zipCode: '9055',
        city: 'Meistervik'
    },
    {
        zipCode: '9056',
        city: 'Mortenhals'
    },
    {
        zipCode: '9057',
        city: 'Vikran'
    },
    {
        zipCode: '9059',
        city: 'Storsteinnes'
    },
    {
        zipCode: '9060',
        city: 'Lyngseidet'
    },
    {
        zipCode: '9062',
        city: 'Furuflaten'
    },
    {
        zipCode: '9064',
        city: 'Svensby'
    },
    {
        zipCode: '9068',
        city: 'Nord-lenangen'
    },
    {
        zipCode: '9069',
        city: 'Lyngseidet'
    },
    {
        zipCode: '9100',
        city: 'Kvaløysletta'
    },
    {
        zipCode: '9101',
        city: 'Kvaløysletta'
    },
    {
        zipCode: '9102',
        city: 'Kvaløysletta'
    },
    {
        zipCode: '9103',
        city: 'Kvaløya'
    },
    {
        zipCode: '9104',
        city: 'Kvaløya'
    },
    {
        zipCode: '9105',
        city: 'Kvaløya'
    },
    {
        zipCode: '9106',
        city: 'Straumsbukta'
    },
    {
        zipCode: '9107',
        city: 'Kvaløya'
    },
    {
        zipCode: '9108',
        city: 'Kvaløya'
    },
    {
        zipCode: '9110',
        city: 'Sommarøy'
    },
    {
        zipCode: '9118',
        city: 'Brensholmen'
    },
    {
        zipCode: '9119',
        city: 'Sommarøy'
    },
    {
        zipCode: '9120',
        city: 'Vengsøy'
    },
    {
        zipCode: '9128',
        city: 'Tussøy'
    },
    {
        zipCode: '9130',
        city: 'Hansnes'
    },
    {
        zipCode: '9131',
        city: 'Kårvik'
    },
    {
        zipCode: '9132',
        city: 'Stakkvik'
    },
    {
        zipCode: '9134',
        city: 'Hansnes'
    },
    {
        zipCode: '9135',
        city: 'Vannvåg'
    },
    {
        zipCode: '9136',
        city: 'Vannareid'
    },
    {
        zipCode: '9137',
        city: 'Vannvåg'
    },
    {
        zipCode: '9138',
        city: 'Karlsøy'
    },
    {
        zipCode: '9140',
        city: 'Rebbenes'
    },
    {
        zipCode: '9141',
        city: 'Mjølvik'
    },
    {
        zipCode: '9142',
        city: 'Skibotn'
    },
    {
        zipCode: '9143',
        city: 'Skibotn'
    },
    {
        zipCode: '9144',
        city: 'Samuelsberg'
    },
    {
        zipCode: '9145',
        city: 'Samuelsberg'
    },
    {
        zipCode: '9146',
        city: 'Olderdalen'
    },
    {
        zipCode: '9147',
        city: 'Birtavarre'
    },
    {
        zipCode: '9148',
        city: 'Olderdalen'
    },
    {
        zipCode: '9149',
        city: 'Birtavarre'
    },
    {
        zipCode: '9151',
        city: 'Storslett'
    },
    {
        zipCode: '9152',
        city: 'Sørkjosen'
    },
    {
        zipCode: '9153',
        city: 'Rotsund'
    },
    {
        zipCode: '9155',
        city: 'Sørkjosen'
    },
    {
        zipCode: '9156',
        city: 'Storslett'
    },
    {
        zipCode: '9159',
        city: 'Havnnes'
    },
    {
        zipCode: '9161',
        city: 'Burfjord'
    },
    {
        zipCode: '9162',
        city: 'Sørstraumen'
    },
    {
        zipCode: '9163',
        city: 'Jøkelfjord'
    },
    {
        zipCode: '9169',
        city: 'Burfjord'
    },
    {
        zipCode: '9170',
        city: 'Longyearbyen'
    },
    {
        zipCode: '9171',
        city: 'Longyearbyen'
    },
    {
        zipCode: '9173',
        city: 'Ny-ålesund'
    },
    {
        zipCode: '9174',
        city: 'Hopen'
    },
    {
        zipCode: '9175',
        city: 'Sveagruva'
    },
    {
        zipCode: '9176',
        city: 'Bjørnøya'
    },
    {
        zipCode: '9178',
        city: 'Barentsburg'
    },
    {
        zipCode: '9180',
        city: 'Skjervøy'
    },
    {
        zipCode: '9181',
        city: 'Hamneidet'
    },
    {
        zipCode: '9182',
        city: 'Seglvik'
    },
    {
        zipCode: '9184',
        city: 'Reinfjord'
    },
    {
        zipCode: '9185',
        city: 'Spildra'
    },
    {
        zipCode: '9186',
        city: 'Andsnes'
    },
    {
        zipCode: '9187',
        city: 'Valanhamn'
    },
    {
        zipCode: '9189',
        city: 'Skjervøy'
    },
    {
        zipCode: '9190',
        city: 'Akkarvik'
    },
    {
        zipCode: '9192',
        city: 'Arnøyhamn'
    },
    {
        zipCode: '9193',
        city: 'Nikkeby'
    },
    {
        zipCode: '9194',
        city: 'Lauksletta'
    },
    {
        zipCode: '9195',
        city: 'Årviksand'
    },
    {
        zipCode: '9197',
        city: 'Uløybukt'
    },
    {
        zipCode: '9240',
        city: 'Tromsø'
    },
    {
        zipCode: '9251',
        city: 'Tromsø'
    },
    {
        zipCode: '9252',
        city: 'Tromsø'
    },
    {
        zipCode: '9253',
        city: 'Tromsø'
    },
    {
        zipCode: '9254',
        city: 'Tromsø'
    },
    {
        zipCode: '9255',
        city: 'Tromsø'
    },
    {
        zipCode: '9256',
        city: 'Tromsø'
    },
    {
        zipCode: '9257',
        city: 'Tromsø'
    },
    {
        zipCode: '9258',
        city: 'Tromsø'
    },
    {
        zipCode: '9259',
        city: 'Tromsø'
    },
    {
        zipCode: '9260',
        city: 'Tromsø'
    },
    {
        zipCode: '9261',
        city: 'Tromsø'
    },
    {
        zipCode: '9262',
        city: 'Tromsø'
    },
    {
        zipCode: '9263',
        city: 'Tromsø'
    },
    {
        zipCode: '9265',
        city: 'Tromsø'
    },
    {
        zipCode: '9266',
        city: 'Tromsø'
    },
    {
        zipCode: '9267',
        city: 'Tromsø'
    },
    {
        zipCode: '9268',
        city: 'Tromsø'
    },
    {
        zipCode: '9269',
        city: 'Tromsø'
    },
    {
        zipCode: '9270',
        city: 'Tromsø'
    },
    {
        zipCode: '9271',
        city: 'Tromsø'
    },
    {
        zipCode: '9272',
        city: 'Tromsø'
    },
    {
        zipCode: '9273',
        city: 'Tromsø'
    },
    {
        zipCode: '9274',
        city: 'Tromsø'
    },
    {
        zipCode: '9275',
        city: 'Tromsø'
    },
    {
        zipCode: '9276',
        city: 'Tromsø'
    },
    {
        zipCode: '9277',
        city: 'Tromsø'
    },
    {
        zipCode: '9278',
        city: 'Tromsø'
    },
    {
        zipCode: '9279',
        city: 'Tromsø'
    },
    {
        zipCode: '9280',
        city: 'Tromsø'
    },
    {
        zipCode: '9281',
        city: 'Tromsø'
    },
    {
        zipCode: '9282',
        city: 'Tromsø'
    },
    {
        zipCode: '9283',
        city: 'Tromsø'
    },
    {
        zipCode: '9284',
        city: 'Tromsø'
    },
    {
        zipCode: '9285',
        city: 'Tromsø'
    },
    {
        zipCode: '9286',
        city: 'Tromsø'
    },
    {
        zipCode: '9287',
        city: 'Tromsø'
    },
    {
        zipCode: '9288',
        city: 'Tromsø'
    },
    {
        zipCode: '9290',
        city: 'Tromsø'
    },
    {
        zipCode: '9291',
        city: 'Tromsø'
    },
    {
        zipCode: '9292',
        city: 'Tromsø'
    },
    {
        zipCode: '9293',
        city: 'Tromsø'
    },
    {
        zipCode: '9294',
        city: 'Tromsø'
    },
    {
        zipCode: '9296',
        city: 'Tromsø'
    },
    {
        zipCode: '9298',
        city: 'Tromsø'
    },
    {
        zipCode: '9299',
        city: 'Tromsø'
    },
    {
        zipCode: '9300',
        city: 'Finnsnes'
    },
    {
        zipCode: '9302',
        city: 'Rossfjordstraumen'
    },
    {
        zipCode: '9303',
        city: 'Silsand'
    },
    {
        zipCode: '9304',
        city: 'Vangsvik'
    },
    {
        zipCode: '9305',
        city: 'Finnsnes'
    },
    {
        zipCode: '9306',
        city: 'Finnsnes'
    },
    {
        zipCode: '9307',
        city: 'Finnsnes'
    },
    {
        zipCode: '9308',
        city: 'Finnsnes'
    },
    {
        zipCode: '9309',
        city: 'Finnsnes'
    },
    {
        zipCode: '9310',
        city: 'Sørreisa'
    },
    {
        zipCode: '9311',
        city: 'Brøstadbotn'
    },
    {
        zipCode: '9315',
        city: 'Sørreisa'
    },
    {
        zipCode: '9316',
        city: 'Brøstadbotn'
    },
    {
        zipCode: '9321',
        city: 'Moen'
    },
    {
        zipCode: '9322',
        city: 'Karlstad'
    },
    {
        zipCode: '9325',
        city: 'Bardufoss'
    },
    {
        zipCode: '9326',
        city: 'Bardufoss'
    },
    {
        zipCode: '9329',
        city: 'Moen'
    },
    {
        zipCode: '9334',
        city: 'Øverbygd'
    },
    {
        zipCode: '9335',
        city: 'Øverbygd'
    },
    {
        zipCode: '9336',
        city: 'Rundhaug'
    },
    {
        zipCode: '9350',
        city: 'Sjøvegan'
    },
    {
        zipCode: '9355',
        city: 'Sjøvegan'
    },
    {
        zipCode: '9357',
        city: 'Tennevoll'
    },
    {
        zipCode: '9358',
        city: 'Tennevoll'
    },
    {
        zipCode: '9360',
        city: 'Bardu'
    },
    {
        zipCode: '9365',
        city: 'Bardu'
    },
    {
        zipCode: '9370',
        city: 'Silsand'
    },
    {
        zipCode: '9372',
        city: 'Gibostad'
    },
    {
        zipCode: '9373',
        city: 'Botnhamn'
    },
    {
        zipCode: '9376',
        city: 'Skatvik'
    },
    {
        zipCode: '9379',
        city: 'Gryllefjord'
    },
    {
        zipCode: '9380',
        city: 'Gryllefjord'
    },
    {
        zipCode: '9381',
        city: 'Torsken'
    },
    {
        zipCode: '9382',
        city: 'Gibostad'
    },
    {
        zipCode: '9384',
        city: 'Skaland'
    },
    {
        zipCode: '9385',
        city: 'Skaland'
    },
    {
        zipCode: '9386',
        city: 'Senjahopen'
    },
    {
        zipCode: '9387',
        city: 'Senjahopen'
    },
    {
        zipCode: '9388',
        city: 'Fjordgard'
    },
    {
        zipCode: '9389',
        city: 'Husøy i senja'
    },
    {
        zipCode: '9391',
        city: 'Stonglandseidet'
    },
    {
        zipCode: '9392',
        city: 'Stonglandseidet'
    },
    {
        zipCode: '9393',
        city: 'Flakstadvåg'
    },
    {
        zipCode: '9395',
        city: 'Kaldfarnes'
    },
    {
        zipCode: '9402',
        city: 'Harstad'
    },
    {
        zipCode: '9403',
        city: 'Harstad'
    },
    {
        zipCode: '9404',
        city: 'Harstad'
    },
    {
        zipCode: '9405',
        city: 'Harstad'
    },
    {
        zipCode: '9406',
        city: 'Harstad'
    },
    {
        zipCode: '9407',
        city: 'Harstad'
    },
    {
        zipCode: '9408',
        city: 'Harstad'
    },
    {
        zipCode: '9409',
        city: 'Harstad'
    },
    {
        zipCode: '9411',
        city: 'Harstad'
    },
    {
        zipCode: '9414',
        city: 'Harstad'
    },
    {
        zipCode: '9415',
        city: 'Harstad'
    },
    {
        zipCode: '9416',
        city: 'Harstad'
    },
    {
        zipCode: '9419',
        city: 'Sørvik'
    },
    {
        zipCode: '9420',
        city: 'Lundenes'
    },
    {
        zipCode: '9423',
        city: 'Grøtavær'
    },
    {
        zipCode: '9424',
        city: 'Kjøtta'
    },
    {
        zipCode: '9425',
        city: 'Sandsøy'
    },
    {
        zipCode: '9426',
        city: 'Bjarkøy'
    },
    {
        zipCode: '9427',
        city: 'Meløyvær'
    },
    {
        zipCode: '9430',
        city: 'Sandtorg'
    },
    {
        zipCode: '9436',
        city: 'Kongsvik'
    },
    {
        zipCode: '9439',
        city: 'Evenskjer'
    },
    {
        zipCode: '9440',
        city: 'Evenskjer'
    },
    {
        zipCode: '9441',
        city: 'Fjelldal'
    },
    {
        zipCode: '9442',
        city: 'Ramsund'
    },
    {
        zipCode: '9443',
        city: 'Myklebostad'
    },
    {
        zipCode: '9444',
        city: 'Hol i tjeldsund'
    },
    {
        zipCode: '9445',
        city: 'Tovik'
    },
    {
        zipCode: '9446',
        city: 'Grovfjord'
    },
    {
        zipCode: '9447',
        city: 'Grovfjord'
    },
    {
        zipCode: '9448',
        city: 'Ramsund'
    },
    {
        zipCode: '9450',
        city: 'Hamnvik'
    },
    {
        zipCode: '9451',
        city: 'Hamnvik'
    },
    {
        zipCode: '9453',
        city: 'Kråkrøhamn'
    },
    {
        zipCode: '9454',
        city: 'Ånstad'
    },
    {
        zipCode: '9455',
        city: 'Engenes'
    },
    {
        zipCode: '9456',
        city: 'Engenes'
    },
    {
        zipCode: '9470',
        city: 'Gratangen'
    },
    {
        zipCode: '9471',
        city: 'Gratangen'
    },
    {
        zipCode: '9475',
        city: 'Borkenes'
    },
    {
        zipCode: '9476',
        city: 'Borkenes'
    },
    {
        zipCode: '9479',
        city: 'Harstad'
    },
    {
        zipCode: '9480',
        city: 'Harstad'
    },
    {
        zipCode: '9481',
        city: 'Harstad'
    },
    {
        zipCode: '9482',
        city: 'Harstad'
    },
    {
        zipCode: '9483',
        city: 'Harstad'
    },
    {
        zipCode: '9484',
        city: 'Harstad'
    },
    {
        zipCode: '9485',
        city: 'Harstad'
    },
    {
        zipCode: '9486',
        city: 'Harstad'
    },
    {
        zipCode: '9487',
        city: 'Harstad'
    },
    {
        zipCode: '9488',
        city: 'Harstad'
    },
    {
        zipCode: '9489',
        city: 'Harstad'
    },
    {
        zipCode: '9496',
        city: 'Harstad'
    },
    {
        zipCode: '9497',
        city: 'Harstad'
    },
    {
        zipCode: '9498',
        city: 'Harstad'
    },
    {
        zipCode: '9501',
        city: 'Alta'
    },
    {
        zipCode: '9502',
        city: 'Alta'
    },
    {
        zipCode: '9503',
        city: 'Alta'
    },
    {
        zipCode: '9504',
        city: 'Alta'
    },
    {
        zipCode: '9505',
        city: 'Alta'
    },
    {
        zipCode: '9506',
        city: 'Alta'
    },
    {
        zipCode: '9507',
        city: 'Alta'
    },
    {
        zipCode: '9508',
        city: 'Alta'
    },
    {
        zipCode: '9509',
        city: 'Alta'
    },
    {
        zipCode: '9510',
        city: 'Alta'
    },
    {
        zipCode: '9511',
        city: 'Alta'
    },
    {
        zipCode: '9512',
        city: 'Alta'
    },
    {
        zipCode: '9513',
        city: 'Alta'
    },
    {
        zipCode: '9514',
        city: 'Alta'
    },
    {
        zipCode: '9515',
        city: 'Alta'
    },
    {
        zipCode: '9516',
        city: 'Alta'
    },
    {
        zipCode: '9517',
        city: 'Alta'
    },
    {
        zipCode: '9518',
        city: 'Alta'
    },
    {
        zipCode: '9519',
        city: 'Kviby'
    },
    {
        zipCode: '9520',
        city: 'Kautokeino'
    },
    {
        zipCode: '9521',
        city: 'Kautokeino'
    },
    {
        zipCode: '9525',
        city: 'Maze'
    },
    {
        zipCode: '9531',
        city: 'Kvalfjord'
    },
    {
        zipCode: '9532',
        city: 'Hakkstabben'
    },
    {
        zipCode: '9533',
        city: 'Kongshus'
    },
    {
        zipCode: '9536',
        city: 'Korsfjorden'
    },
    {
        zipCode: '9540',
        city: 'Talvik'
    },
    {
        zipCode: '9545',
        city: 'Langfjordbotn'
    },
    {
        zipCode: '9550',
        city: 'Øksfjord'
    },
    {
        zipCode: '9580',
        city: 'Bergsfjord'
    },
    {
        zipCode: '9582',
        city: 'Nuvsvåg'
    },
    {
        zipCode: '9583',
        city: 'Langfjordhamn'
    },
    {
        zipCode: '9584',
        city: 'Sør-tverrfjord'
    },
    {
        zipCode: '9585',
        city: 'Sandland'
    },
    {
        zipCode: '9586',
        city: 'Loppa'
    },
    {
        zipCode: '9587',
        city: 'Skavnakk'
    },
    {
        zipCode: '9590',
        city: 'Hasvik'
    },
    {
        zipCode: '9591',
        city: 'Hasvik'
    },
    {
        zipCode: '9593',
        city: 'Breivikbotn'
    },
    {
        zipCode: '9595',
        city: 'Sørvær'
    },
    {
        zipCode: '9600',
        city: 'Hammerfest'
    },
    {
        zipCode: '9601',
        city: 'Hammerfest'
    },
    {
        zipCode: '9602',
        city: 'Hammerfest'
    },
    {
        zipCode: '9603',
        city: 'Hammerfest'
    },
    {
        zipCode: '9609',
        city: 'Nordre seiland'
    },
    {
        zipCode: '9610',
        city: 'Rypefjord'
    },
    {
        zipCode: '9611',
        city: 'Rypefjord'
    },
    {
        zipCode: '9612',
        city: 'Forsøl'
    },
    {
        zipCode: '9615',
        city: 'Hammerfest'
    },
    {
        zipCode: '9616',
        city: 'Hammerfest'
    },
    {
        zipCode: '9620',
        city: 'Kvalsund'
    },
    {
        zipCode: '9621',
        city: 'Kvalsund'
    },
    {
        zipCode: '9624',
        city: 'Revsneshamn'
    },
    {
        zipCode: '9650',
        city: 'Akkarfjord'
    },
    {
        zipCode: '9651',
        city: 'Langstrand'
    },
    {
        zipCode: '9657',
        city: 'Kårhamn'
    },
    {
        zipCode: '9664',
        city: 'Sandøybotn'
    },
    {
        zipCode: '9670',
        city: 'Tufjord'
    },
    {
        zipCode: '9672',
        city: 'Ingøy'
    },
    {
        zipCode: '9690',
        city: 'Havøysund'
    },
    {
        zipCode: '9691',
        city: 'Havøysund'
    },
    {
        zipCode: '9692',
        city: 'Måsøy'
    },
    {
        zipCode: '9700',
        city: 'Lakselv'
    },
    {
        zipCode: '9709',
        city: 'Porsangmoen'
    },
    {
        zipCode: '9710',
        city: 'Indre billefjord'
    },
    {
        zipCode: '9711',
        city: 'Lakselv'
    },
    {
        zipCode: '9712',
        city: 'Lakselv'
    },
    {
        zipCode: '9713',
        city: 'Russenes'
    },
    {
        zipCode: '9714',
        city: 'Snefjord'
    },
    {
        zipCode: '9715',
        city: 'Kokelv'
    },
    {
        zipCode: '9716',
        city: 'Børselv'
    },
    {
        zipCode: '9717',
        city: 'Veidnesklubben'
    },
    {
        zipCode: '9722',
        city: 'Skoganvarre'
    },
    {
        zipCode: '9730',
        city: 'Karasjok'
    },
    {
        zipCode: '9735',
        city: 'Karasjok'
    },
    {
        zipCode: '9740',
        city: 'Lebesby'
    },
    {
        zipCode: '9742',
        city: 'Kunes'
    },
    {
        zipCode: '9750',
        city: 'Honningsvåg'
    },
    {
        zipCode: '9751',
        city: 'Honningsvåg'
    },
    {
        zipCode: '9760',
        city: 'Nordvågen'
    },
    {
        zipCode: '9763',
        city: 'Skarsvåg'
    },
    {
        zipCode: '9764',
        city: 'Nordkapp'
    },
    {
        zipCode: '9765',
        city: 'Gjesvær'
    },
    {
        zipCode: '9768',
        city: 'Repvåg'
    },
    {
        zipCode: '9770',
        city: 'Mehamn'
    },
    {
        zipCode: '9771',
        city: 'Skjånes'
    },
    {
        zipCode: '9772',
        city: 'Langfjordnes'
    },
    {
        zipCode: '9773',
        city: 'Nervei'
    },
    {
        zipCode: '9775',
        city: 'Gamvik'
    },
    {
        zipCode: '9782',
        city: 'Dyfjord'
    },
    {
        zipCode: '9790',
        city: 'Kjøllefjord'
    },
    {
        zipCode: '9800',
        city: 'Vadsø'
    },
    {
        zipCode: '9802',
        city: 'Vestre jakobselv'
    },
    {
        zipCode: '9810',
        city: 'Vestre jakobselv'
    },
    {
        zipCode: '9811',
        city: 'Vadsø'
    },
    {
        zipCode: '9815',
        city: 'Vadsø'
    },
    {
        zipCode: '9820',
        city: 'Varangerbotn'
    },
    {
        zipCode: '9826',
        city: 'Sirma'
    },
    {
        zipCode: '9840',
        city: 'Varangerbotn'
    },
    {
        zipCode: '9845',
        city: 'Tana'
    },
    {
        zipCode: '9846',
        city: 'Tana'
    },
    {
        zipCode: '9900',
        city: 'Kirkenes'
    },
    {
        zipCode: '9910',
        city: 'Bjørnevatn'
    },
    {
        zipCode: '9912',
        city: 'Hesseng'
    },
    {
        zipCode: '9914',
        city: 'Bjørnevatn'
    },
    {
        zipCode: '9915',
        city: 'Kirkenes'
    },
    {
        zipCode: '9916',
        city: 'Hesseng'
    },
    {
        zipCode: '9917',
        city: 'Kirkenes'
    },
    {
        zipCode: '9925',
        city: 'Svanvik'
    },
    {
        zipCode: '9930',
        city: 'Neiden'
    },
    {
        zipCode: '9935',
        city: 'Bugøynes'
    },
    {
        zipCode: '9950',
        city: 'Vardø'
    },
    {
        zipCode: '9951',
        city: 'Vardø'
    },
    {
        zipCode: '9960',
        city: 'Kiberg'
    },
    {
        zipCode: '9980',
        city: 'Berlevåg'
    },
    {
        zipCode: '9981',
        city: 'Berlevåg'
    },
    {
        zipCode: '9982',
        city: 'Kongsfjord'
    },
    {
        zipCode: '9990',
        city: 'Båtsfjord'
    },
    {
        zipCode: '9991',
        city: 'Båtsfjord'
    },
    {
        zipCode: '0001',
        city: 'Oslo'
    },
    {
        zipCode: '0010',
        city: 'Oslo'
    },
    {
        zipCode: '0015',
        city: 'Oslo'
    },
    {
        zipCode: '0018',
        city: 'Oslo'
    },
    {
        zipCode: '0021',
        city: 'Oslo'
    },
    {
        zipCode: '0024',
        city: 'Oslo'
    },
    {
        zipCode: '0026',
        city: 'Oslo'
    },
    {
        zipCode: '0028',
        city: 'Oslo'
    },
    {
        zipCode: '0030',
        city: 'Oslo'
    },
    {
        zipCode: '0031',
        city: 'Oslo'
    },
    {
        zipCode: '0032',
        city: 'Oslo'
    },
    {
        zipCode: '0033',
        city: 'Oslo'
    },
    {
        zipCode: '0034',
        city: 'Oslo'
    },
    {
        zipCode: '0037',
        city: 'Oslo'
    },
    {
        zipCode: '0040',
        city: 'Oslo'
    },
    {
        zipCode: '0045',
        city: 'Oslo'
    },
    {
        zipCode: '0046',
        city: 'Oslo'
    },
    {
        zipCode: '0047',
        city: 'Oslo'
    },
    {
        zipCode: '0048',
        city: 'Oslo'
    },
    {
        zipCode: '0050',
        city: 'Oslo'
    },
    {
        zipCode: '0055',
        city: 'Oslo'
    },
    {
        zipCode: '0060',
        city: 'Oslo'
    },
    {
        zipCode: '0081',
        city: 'Oslo'
    },
    {
        zipCode: '0101',
        city: 'Oslo'
    },
    {
        zipCode: '0102',
        city: 'Oslo'
    },
    {
        zipCode: '0103',
        city: 'Oslo'
    },
    {
        zipCode: '0104',
        city: 'Oslo'
    },
    {
        zipCode: '0105',
        city: 'Oslo'
    },
    {
        zipCode: '0106',
        city: 'Oslo'
    },
    {
        zipCode: '0107',
        city: 'Oslo'
    },
    {
        zipCode: '0109',
        city: 'Oslo'
    },
    {
        zipCode: '0110',
        city: 'Oslo'
    },
    {
        zipCode: '0111',
        city: 'Oslo'
    },
    {
        zipCode: '0112',
        city: 'Oslo'
    },
    {
        zipCode: '0113',
        city: 'Oslo'
    },
    {
        zipCode: '0114',
        city: 'Oslo'
    },
    {
        zipCode: '0115',
        city: 'Oslo'
    },
    {
        zipCode: '0116',
        city: 'Oslo'
    },
    {
        zipCode: '0117',
        city: 'Oslo'
    },
    {
        zipCode: '0118',
        city: 'Oslo'
    },
    {
        zipCode: '0119',
        city: 'Oslo'
    },
    {
        zipCode: '0120',
        city: 'Oslo'
    },
    {
        zipCode: '0121',
        city: 'Oslo'
    },
    {
        zipCode: '0122',
        city: 'Oslo'
    },
    {
        zipCode: '0123',
        city: 'Oslo'
    },
    {
        zipCode: '0124',
        city: 'Oslo'
    },
    {
        zipCode: '0125',
        city: 'Oslo'
    },
    {
        zipCode: '0128',
        city: 'Oslo'
    },
    {
        zipCode: '0129',
        city: 'Oslo'
    },
    {
        zipCode: '0130',
        city: 'Oslo'
    },
    {
        zipCode: '0131',
        city: 'Oslo'
    },
    {
        zipCode: '0132',
        city: 'Oslo'
    },
    {
        zipCode: '0133',
        city: 'Oslo'
    },
    {
        zipCode: '0134',
        city: 'Oslo'
    },
    {
        zipCode: '0135',
        city: 'Oslo'
    },
    {
        zipCode: '0136',
        city: 'Oslo'
    },
    {
        zipCode: '0138',
        city: 'Oslo'
    },
    {
        zipCode: '0139',
        city: 'Oslo'
    },
    {
        zipCode: '0140',
        city: 'Oslo'
    },
    {
        zipCode: '0150',
        city: 'Oslo'
    },
    {
        zipCode: '0151',
        city: 'Oslo'
    },
    {
        zipCode: '0152',
        city: 'Oslo'
    },
    {
        zipCode: '0153',
        city: 'Oslo'
    },
    {
        zipCode: '0154',
        city: 'Oslo'
    },
    {
        zipCode: '0155',
        city: 'Oslo'
    },
    {
        zipCode: '0157',
        city: 'Oslo'
    },
    {
        zipCode: '0158',
        city: 'Oslo'
    },
    {
        zipCode: '0159',
        city: 'Oslo'
    },
    {
        zipCode: '0160',
        city: 'Oslo'
    },
    {
        zipCode: '0161',
        city: 'Oslo'
    },
    {
        zipCode: '0162',
        city: 'Oslo'
    },
    {
        zipCode: '0164',
        city: 'Oslo'
    },
    {
        zipCode: '0165',
        city: 'Oslo'
    },
    {
        zipCode: '0166',
        city: 'Oslo'
    },
    {
        zipCode: '0167',
        city: 'Oslo'
    },
    {
        zipCode: '0168',
        city: 'Oslo'
    },
    {
        zipCode: '0169',
        city: 'Oslo'
    },
    {
        zipCode: '0170',
        city: 'Oslo'
    },
    {
        zipCode: '0171',
        city: 'Oslo'
    },
    {
        zipCode: '0172',
        city: 'Oslo'
    },
    {
        zipCode: '0173',
        city: 'Oslo'
    },
    {
        zipCode: '0174',
        city: 'Oslo'
    },
    {
        zipCode: '0175',
        city: 'Oslo'
    },
    {
        zipCode: '0176',
        city: 'Oslo'
    },
    {
        zipCode: '0177',
        city: 'Oslo'
    },
    {
        zipCode: '0178',
        city: 'Oslo'
    },
    {
        zipCode: '0179',
        city: 'Oslo'
    },
    {
        zipCode: '0180',
        city: 'Oslo'
    },
    {
        zipCode: '0181',
        city: 'Oslo'
    },
    {
        zipCode: '0182',
        city: 'Oslo'
    },
    {
        zipCode: '0183',
        city: 'Oslo'
    },
    {
        zipCode: '0184',
        city: 'Oslo'
    },
    {
        zipCode: '0185',
        city: 'Oslo'
    },
    {
        zipCode: '0186',
        city: 'Oslo'
    },
    {
        zipCode: '0187',
        city: 'Oslo'
    },
    {
        zipCode: '0188',
        city: 'Oslo'
    },
    {
        zipCode: '0190',
        city: 'Oslo'
    },
    {
        zipCode: '0191',
        city: 'Oslo'
    },
    {
        zipCode: '0192',
        city: 'Oslo'
    },
    {
        zipCode: '0193',
        city: 'Oslo'
    },
    {
        zipCode: '0194',
        city: 'Oslo'
    },
    {
        zipCode: '0195',
        city: 'Oslo'
    },
    {
        zipCode: '0196',
        city: 'Oslo'
    },
    {
        zipCode: '0198',
        city: 'Oslo'
    },
    {
        zipCode: '0201',
        city: 'Oslo'
    },
    {
        zipCode: '0202',
        city: 'Oslo'
    },
    {
        zipCode: '0203',
        city: 'Oslo'
    },
    {
        zipCode: '0204',
        city: 'Oslo'
    },
    {
        zipCode: '0207',
        city: 'Oslo'
    },
    {
        zipCode: '0208',
        city: 'Oslo'
    },
    {
        zipCode: '0211',
        city: 'Oslo'
    },
    {
        zipCode: '0212',
        city: 'Oslo'
    },
    {
        zipCode: '0213',
        city: 'Oslo'
    },
    {
        zipCode: '0214',
        city: 'Oslo'
    },
    {
        zipCode: '0215',
        city: 'Oslo'
    },
    {
        zipCode: '0216',
        city: 'Oslo'
    },
    {
        zipCode: '0217',
        city: 'Oslo'
    },
    {
        zipCode: '0218',
        city: 'Oslo'
    },
    {
        zipCode: '0230',
        city: 'Oslo'
    },
    {
        zipCode: '0240',
        city: 'Oslo'
    },
    {
        zipCode: '0244',
        city: 'Oslo'
    },
    {
        zipCode: '0247',
        city: 'Oslo'
    },
    {
        zipCode: '0250',
        city: 'Oslo'
    },
    {
        zipCode: '0251',
        city: 'Oslo'
    },
    {
        zipCode: '0252',
        city: 'Oslo'
    },
    {
        zipCode: '0253',
        city: 'Oslo'
    },
    {
        zipCode: '0254',
        city: 'Oslo'
    },
    {
        zipCode: '0255',
        city: 'Oslo'
    },
    {
        zipCode: '0256',
        city: 'Oslo'
    },
    {
        zipCode: '0257',
        city: 'Oslo'
    },
    {
        zipCode: '0258',
        city: 'Oslo'
    },
    {
        zipCode: '0259',
        city: 'Oslo'
    },
    {
        zipCode: '0260',
        city: 'Oslo'
    },
    {
        zipCode: '0262',
        city: 'Oslo'
    },
    {
        zipCode: '0263',
        city: 'Oslo'
    },
    {
        zipCode: '0264',
        city: 'Oslo'
    },
    {
        zipCode: '0265',
        city: 'Oslo'
    },
    {
        zipCode: '0266',
        city: 'Oslo'
    },
    {
        zipCode: '0267',
        city: 'Oslo'
    },
    {
        zipCode: '0268',
        city: 'Oslo'
    },
    {
        zipCode: '0270',
        city: 'Oslo'
    },
    {
        zipCode: '0271',
        city: 'Oslo'
    },
    {
        zipCode: '0272',
        city: 'Oslo'
    },
    {
        zipCode: '0273',
        city: 'Oslo'
    },
    {
        zipCode: '0274',
        city: 'Oslo'
    },
    {
        zipCode: '0275',
        city: 'Oslo'
    },
    {
        zipCode: '0276',
        city: 'Oslo'
    },
    {
        zipCode: '0277',
        city: 'Oslo'
    },
    {
        zipCode: '0278',
        city: 'Oslo'
    },
    {
        zipCode: '0279',
        city: 'Oslo'
    },
    {
        zipCode: '0280',
        city: 'Oslo'
    },
    {
        zipCode: '0281',
        city: 'Oslo'
    },
    {
        zipCode: '0282',
        city: 'Oslo'
    },
    {
        zipCode: '0283',
        city: 'Oslo'
    },
    {
        zipCode: '0284',
        city: 'Oslo'
    },
    {
        zipCode: '0286',
        city: 'Oslo'
    },
    {
        zipCode: '0287',
        city: 'Oslo'
    },
    {
        zipCode: '0301',
        city: 'Oslo'
    },
    {
        zipCode: '0302',
        city: 'Oslo'
    },
    {
        zipCode: '0303',
        city: 'Oslo'
    },
    {
        zipCode: '0304',
        city: 'Oslo'
    },
    {
        zipCode: '0305',
        city: 'Oslo'
    },
    {
        zipCode: '0306',
        city: 'Oslo'
    },
    {
        zipCode: '0307',
        city: 'Oslo'
    },
    {
        zipCode: '0308',
        city: 'Oslo'
    },
    {
        zipCode: '0309',
        city: 'Oslo'
    },
    {
        zipCode: '0311',
        city: 'Oslo'
    },
    {
        zipCode: '0313',
        city: 'Oslo'
    },
    {
        zipCode: '0314',
        city: 'Oslo'
    },
    {
        zipCode: '0315',
        city: 'Oslo'
    },
    {
        zipCode: '0316',
        city: 'Oslo'
    },
    {
        zipCode: '0317',
        city: 'Oslo'
    },
    {
        zipCode: '0318',
        city: 'Oslo'
    },
    {
        zipCode: '0319',
        city: 'Oslo'
    },
    {
        zipCode: '0323',
        city: 'Oslo'
    },
    {
        zipCode: '0330',
        city: 'Oslo'
    },
    {
        zipCode: '0340',
        city: 'Oslo'
    },
    {
        zipCode: '0349',
        city: 'Oslo'
    },
    {
        zipCode: '0350',
        city: 'Oslo'
    },
    {
        zipCode: '0351',
        city: 'Oslo'
    },
    {
        zipCode: '0352',
        city: 'Oslo'
    },
    {
        zipCode: '0353',
        city: 'Oslo'
    },
    {
        zipCode: '0354',
        city: 'Oslo'
    },
    {
        zipCode: '0355',
        city: 'Oslo'
    },
    {
        zipCode: '0356',
        city: 'Oslo'
    },
    {
        zipCode: '0357',
        city: 'Oslo'
    },
    {
        zipCode: '0358',
        city: 'Oslo'
    },
    {
        zipCode: '0359',
        city: 'Oslo'
    },
    {
        zipCode: '0360',
        city: 'Oslo'
    },
    {
        zipCode: '0361',
        city: 'Oslo'
    },
    {
        zipCode: '0362',
        city: 'Oslo'
    },
    {
        zipCode: '0363',
        city: 'Oslo'
    },
    {
        zipCode: '0364',
        city: 'Oslo'
    },
    {
        zipCode: '0365',
        city: 'Oslo'
    },
    {
        zipCode: '0366',
        city: 'Oslo'
    },
    {
        zipCode: '0367',
        city: 'Oslo'
    },
    {
        zipCode: '0368',
        city: 'Oslo'
    },
    {
        zipCode: '0369',
        city: 'Oslo'
    },
    {
        zipCode: '0370',
        city: 'Oslo'
    },
    {
        zipCode: '0371',
        city: 'Oslo'
    },
    {
        zipCode: '0372',
        city: 'Oslo'
    },
    {
        zipCode: '0373',
        city: 'Oslo'
    },
    {
        zipCode: '0374',
        city: 'Oslo'
    },
    {
        zipCode: '0375',
        city: 'Oslo'
    },
    {
        zipCode: '0376',
        city: 'Oslo'
    },
    {
        zipCode: '0377',
        city: 'Oslo'
    },
    {
        zipCode: '0378',
        city: 'Oslo'
    },
    {
        zipCode: '0379',
        city: 'Oslo'
    },
    {
        zipCode: '0380',
        city: 'Oslo'
    },
    {
        zipCode: '0381',
        city: 'Oslo'
    },
    {
        zipCode: '0382',
        city: 'Oslo'
    },
    {
        zipCode: '0383',
        city: 'Oslo'
    },
    {
        zipCode: '0401',
        city: 'Oslo'
    },
    {
        zipCode: '0402',
        city: 'Oslo'
    },
    {
        zipCode: '0403',
        city: 'Oslo'
    },
    {
        zipCode: '0404',
        city: 'Oslo'
    },
    {
        zipCode: '0405',
        city: 'Oslo'
    },
    {
        zipCode: '0406',
        city: 'Oslo'
    },
    {
        zipCode: '0409',
        city: 'Oslo'
    },
    {
        zipCode: '0410',
        city: 'Oslo'
    },
    {
        zipCode: '0411',
        city: 'Oslo'
    },
    {
        zipCode: '0412',
        city: 'Oslo'
    },
    {
        zipCode: '0413',
        city: 'Oslo'
    },
    {
        zipCode: '0415',
        city: 'Oslo'
    },
    {
        zipCode: '0421',
        city: 'Oslo'
    },
    {
        zipCode: '0422',
        city: 'Oslo'
    },
    {
        zipCode: '0423',
        city: 'Oslo'
    },
    {
        zipCode: '0424',
        city: 'Oslo'
    },
    {
        zipCode: '0440',
        city: 'Oslo'
    },
    {
        zipCode: '0441',
        city: 'Oslo'
    },
    {
        zipCode: '0442',
        city: 'Oslo'
    },
    {
        zipCode: '0445',
        city: 'Oslo'
    },
    {
        zipCode: '0450',
        city: 'Oslo'
    },
    {
        zipCode: '0451',
        city: 'Oslo'
    },
    {
        zipCode: '0452',
        city: 'Oslo'
    },
    {
        zipCode: '0454',
        city: 'Oslo'
    },
    {
        zipCode: '0455',
        city: 'Oslo'
    },
    {
        zipCode: '0456',
        city: 'Oslo'
    },
    {
        zipCode: '0457',
        city: 'Oslo'
    },
    {
        zipCode: '0458',
        city: 'Oslo'
    },
    {
        zipCode: '0459',
        city: 'Oslo'
    },
    {
        zipCode: '0460',
        city: 'Oslo'
    },
    {
        zipCode: '0461',
        city: 'Oslo'
    },
    {
        zipCode: '0462',
        city: 'Oslo'
    },
    {
        zipCode: '0463',
        city: 'Oslo'
    },
    {
        zipCode: '0464',
        city: 'Oslo'
    },
    {
        zipCode: '0465',
        city: 'Oslo'
    },
    {
        zipCode: '0467',
        city: 'Oslo'
    },
    {
        zipCode: '0468',
        city: 'Oslo'
    },
    {
        zipCode: '0469',
        city: 'Oslo'
    },
    {
        zipCode: '0470',
        city: 'Oslo'
    },
    {
        zipCode: '0472',
        city: 'Oslo'
    },
    {
        zipCode: '0473',
        city: 'Oslo'
    },
    {
        zipCode: '0474',
        city: 'Oslo'
    },
    {
        zipCode: '0475',
        city: 'Oslo'
    },
    {
        zipCode: '0476',
        city: 'Oslo'
    },
    {
        zipCode: '0477',
        city: 'Oslo'
    },
    {
        zipCode: '0478',
        city: 'Oslo'
    },
    {
        zipCode: '0479',
        city: 'Oslo'
    },
    {
        zipCode: '0480',
        city: 'Oslo'
    },
    {
        zipCode: '0481',
        city: 'Oslo'
    },
    {
        zipCode: '0482',
        city: 'Oslo'
    },
    {
        zipCode: '0483',
        city: 'Oslo'
    },
    {
        zipCode: '0484',
        city: 'Oslo'
    },
    {
        zipCode: '0485',
        city: 'Oslo'
    },
    {
        zipCode: '0486',
        city: 'Oslo'
    },
    {
        zipCode: '0487',
        city: 'Oslo'
    },
    {
        zipCode: '0488',
        city: 'Oslo'
    },
    {
        zipCode: '0489',
        city: 'Oslo'
    },
    {
        zipCode: '0490',
        city: 'Oslo'
    },
    {
        zipCode: '0491',
        city: 'Oslo'
    },
    {
        zipCode: '0492',
        city: 'Oslo'
    },
    {
        zipCode: '0493',
        city: 'Oslo'
    },
    {
        zipCode: '0494',
        city: 'Oslo'
    },
    {
        zipCode: '0495',
        city: 'Oslo'
    },
    {
        zipCode: '0496',
        city: 'Oslo'
    },
    {
        zipCode: '0501',
        city: 'Oslo'
    },
    {
        zipCode: '0502',
        city: 'Oslo'
    },
    {
        zipCode: '0503',
        city: 'Oslo'
    },
    {
        zipCode: '0504',
        city: 'Oslo'
    },
    {
        zipCode: '0505',
        city: 'Oslo'
    },
    {
        zipCode: '0506',
        city: 'Oslo'
    },
    {
        zipCode: '0507',
        city: 'Oslo'
    },
    {
        zipCode: '0508',
        city: 'Oslo'
    },
    {
        zipCode: '0509',
        city: 'Oslo'
    },
    {
        zipCode: '0510',
        city: 'Oslo'
    },
    {
        zipCode: '0511',
        city: 'Oslo'
    },
    {
        zipCode: '0512',
        city: 'Oslo'
    },
    {
        zipCode: '0513',
        city: 'Oslo'
    },
    {
        zipCode: '0515',
        city: 'Oslo'
    },
    {
        zipCode: '0516',
        city: 'Oslo'
    },
    {
        zipCode: '0517',
        city: 'Oslo'
    },
    {
        zipCode: '0518',
        city: 'Oslo'
    },
    {
        zipCode: '0520',
        city: 'Oslo'
    },
    {
        zipCode: '0540',
        city: 'Oslo'
    },
    {
        zipCode: '0550',
        city: 'Oslo'
    },
    {
        zipCode: '0551',
        city: 'Oslo'
    },
    {
        zipCode: '0552',
        city: 'Oslo'
    },
    {
        zipCode: '0553',
        city: 'Oslo'
    },
    {
        zipCode: '0554',
        city: 'Oslo'
    },
    {
        zipCode: '0555',
        city: 'Oslo'
    },
    {
        zipCode: '0556',
        city: 'Oslo'
    },
    {
        zipCode: '0557',
        city: 'Oslo'
    },
    {
        zipCode: '0558',
        city: 'Oslo'
    },
    {
        zipCode: '0559',
        city: 'Oslo'
    },
    {
        zipCode: '0560',
        city: 'Oslo'
    },
    {
        zipCode: '0561',
        city: 'Oslo'
    },
    {
        zipCode: '0562',
        city: 'Oslo'
    },
    {
        zipCode: '0563',
        city: 'Oslo'
    },
    {
        zipCode: '0564',
        city: 'Oslo'
    },
    {
        zipCode: '0565',
        city: 'Oslo'
    },
    {
        zipCode: '0566',
        city: 'Oslo'
    },
    {
        zipCode: '0567',
        city: 'Oslo'
    },
    {
        zipCode: '0568',
        city: 'Oslo'
    },
    {
        zipCode: '0569',
        city: 'Oslo'
    },
    {
        zipCode: '0570',
        city: 'Oslo'
    },
    {
        zipCode: '0571',
        city: 'Oslo'
    },
    {
        zipCode: '0572',
        city: 'Oslo'
    },
    {
        zipCode: '0573',
        city: 'Oslo'
    },
    {
        zipCode: '0574',
        city: 'Oslo'
    },
    {
        zipCode: '0575',
        city: 'Oslo'
    },
    {
        zipCode: '0576',
        city: 'Oslo'
    },
    {
        zipCode: '0577',
        city: 'Oslo'
    },
    {
        zipCode: '0578',
        city: 'Oslo'
    },
    {
        zipCode: '0579',
        city: 'Oslo'
    },
    {
        zipCode: '0580',
        city: 'Oslo'
    },
    {
        zipCode: '0581',
        city: 'Oslo'
    },
    {
        zipCode: '0582',
        city: 'Oslo'
    },
    {
        zipCode: '0583',
        city: 'Oslo'
    },
    {
        zipCode: '0584',
        city: 'Oslo'
    },
    {
        zipCode: '0585',
        city: 'Oslo'
    },
    {
        zipCode: '0586',
        city: 'Oslo'
    },
    {
        zipCode: '0587',
        city: 'Oslo'
    },
    {
        zipCode: '0588',
        city: 'Oslo'
    },
    {
        zipCode: '0589',
        city: 'Oslo'
    },
    {
        zipCode: '0590',
        city: 'Oslo'
    },
    {
        zipCode: '0591',
        city: 'Oslo'
    },
    {
        zipCode: '0592',
        city: 'Oslo'
    },
    {
        zipCode: '0593',
        city: 'Oslo'
    },
    {
        zipCode: '0594',
        city: 'Oslo'
    },
    {
        zipCode: '0595',
        city: 'Oslo'
    },
    {
        zipCode: '0596',
        city: 'Oslo'
    },
    {
        zipCode: '0597',
        city: 'Oslo'
    },
    {
        zipCode: '0598',
        city: 'Oslo'
    },
    {
        zipCode: '0601',
        city: 'Oslo'
    },
    {
        zipCode: '0602',
        city: 'Oslo'
    },
    {
        zipCode: '0603',
        city: 'Oslo'
    },
    {
        zipCode: '0604',
        city: 'Oslo'
    },
    {
        zipCode: '0605',
        city: 'Oslo'
    },
    {
        zipCode: '0606',
        city: 'Oslo'
    },
    {
        zipCode: '0607',
        city: 'Oslo'
    },
    {
        zipCode: '0608',
        city: 'Oslo'
    },
    {
        zipCode: '0609',
        city: 'Oslo'
    },
    {
        zipCode: '0611',
        city: 'Oslo'
    },
    {
        zipCode: '0612',
        city: 'Oslo'
    },
    {
        zipCode: '0613',
        city: 'Oslo'
    },
    {
        zipCode: '0614',
        city: 'Oslo'
    },
    {
        zipCode: '0615',
        city: 'Oslo'
    },
    {
        zipCode: '0616',
        city: 'Oslo'
    },
    {
        zipCode: '0617',
        city: 'Oslo'
    },
    {
        zipCode: '0618',
        city: 'Oslo'
    },
    {
        zipCode: '0619',
        city: 'Oslo'
    },
    {
        zipCode: '0620',
        city: 'Oslo'
    },
    {
        zipCode: '0621',
        city: 'Oslo'
    },
    {
        zipCode: '0622',
        city: 'Oslo'
    },
    {
        zipCode: '0623',
        city: 'Oslo'
    },
    {
        zipCode: '0624',
        city: 'Oslo'
    },
    {
        zipCode: '0626',
        city: 'Oslo'
    },
    {
        zipCode: '0650',
        city: 'Oslo'
    },
    {
        zipCode: '0651',
        city: 'Oslo'
    },
    {
        zipCode: '0652',
        city: 'Oslo'
    },
    {
        zipCode: '0653',
        city: 'Oslo'
    },
    {
        zipCode: '0654',
        city: 'Oslo'
    },
    {
        zipCode: '0655',
        city: 'Oslo'
    },
    {
        zipCode: '0656',
        city: 'Oslo'
    },
    {
        zipCode: '0657',
        city: 'Oslo'
    },
    {
        zipCode: '0658',
        city: 'Oslo'
    },
    {
        zipCode: '0659',
        city: 'Oslo'
    },
    {
        zipCode: '0660',
        city: 'Oslo'
    },
    {
        zipCode: '0661',
        city: 'Oslo'
    },
    {
        zipCode: '0662',
        city: 'Oslo'
    },
    {
        zipCode: '0663',
        city: 'Oslo'
    },
    {
        zipCode: '0664',
        city: 'Oslo'
    },
    {
        zipCode: '0665',
        city: 'Oslo'
    },
    {
        zipCode: '0666',
        city: 'Oslo'
    },
    {
        zipCode: '0667',
        city: 'Oslo'
    },
    {
        zipCode: '0668',
        city: 'Oslo'
    },
    {
        zipCode: '0669',
        city: 'Oslo'
    },
    {
        zipCode: '0670',
        city: 'Oslo'
    },
    {
        zipCode: '0671',
        city: 'Oslo'
    },
    {
        zipCode: '0672',
        city: 'Oslo'
    },
    {
        zipCode: '0673',
        city: 'Oslo'
    },
    {
        zipCode: '0674',
        city: 'Oslo'
    },
    {
        zipCode: '0675',
        city: 'Oslo'
    },
    {
        zipCode: '0676',
        city: 'Oslo'
    },
    {
        zipCode: '0677',
        city: 'Oslo'
    },
    {
        zipCode: '0678',
        city: 'Oslo'
    },
    {
        zipCode: '0679',
        city: 'Oslo'
    },
    {
        zipCode: '0680',
        city: 'Oslo'
    },
    {
        zipCode: '0681',
        city: 'Oslo'
    },
    {
        zipCode: '0682',
        city: 'Oslo'
    },
    {
        zipCode: '0683',
        city: 'Oslo'
    },
    {
        zipCode: '0684',
        city: 'Oslo'
    },
    {
        zipCode: '0685',
        city: 'Oslo'
    },
    {
        zipCode: '0686',
        city: 'Oslo'
    },
    {
        zipCode: '0687',
        city: 'Oslo'
    },
    {
        zipCode: '0688',
        city: 'Oslo'
    },
    {
        zipCode: '0689',
        city: 'Oslo'
    },
    {
        zipCode: '0690',
        city: 'Oslo'
    },
    {
        zipCode: '0691',
        city: 'Oslo'
    },
    {
        zipCode: '0692',
        city: 'Oslo'
    },
    {
        zipCode: '0693',
        city: 'Oslo'
    },
    {
        zipCode: '0694',
        city: 'Oslo'
    },
    {
        zipCode: '0701',
        city: 'Oslo'
    },
    {
        zipCode: '0702',
        city: 'Oslo'
    },
    {
        zipCode: '0705',
        city: 'Oslo'
    },
    {
        zipCode: '0710',
        city: 'Oslo'
    },
    {
        zipCode: '0712',
        city: 'Oslo'
    },
    {
        zipCode: '0750',
        city: 'Oslo'
    },
    {
        zipCode: '0751',
        city: 'Oslo'
    },
    {
        zipCode: '0752',
        city: 'Oslo'
    },
    {
        zipCode: '0753',
        city: 'Oslo'
    },
    {
        zipCode: '0754',
        city: 'Oslo'
    },
    {
        zipCode: '0755',
        city: 'Oslo'
    },
    {
        zipCode: '0756',
        city: 'Oslo'
    },
    {
        zipCode: '0757',
        city: 'Oslo'
    },
    {
        zipCode: '0758',
        city: 'Oslo'
    },
    {
        zipCode: '0760',
        city: 'Oslo'
    },
    {
        zipCode: '0763',
        city: 'Oslo'
    },
    {
        zipCode: '0764',
        city: 'Oslo'
    },
    {
        zipCode: '0765',
        city: 'Oslo'
    },
    {
        zipCode: '0766',
        city: 'Oslo'
    },
    {
        zipCode: '0767',
        city: 'Oslo'
    },
    {
        zipCode: '0768',
        city: 'Oslo'
    },
    {
        zipCode: '0770',
        city: 'Oslo'
    },
    {
        zipCode: '0771',
        city: 'Oslo'
    },
    {
        zipCode: '0772',
        city: 'Oslo'
    },
    {
        zipCode: '0773',
        city: 'Oslo'
    },
    {
        zipCode: '0774',
        city: 'Oslo'
    },
    {
        zipCode: '0775',
        city: 'Oslo'
    },
    {
        zipCode: '0776',
        city: 'Oslo'
    },
    {
        zipCode: '0777',
        city: 'Oslo'
    },
    {
        zipCode: '0778',
        city: 'Oslo'
    },
    {
        zipCode: '0779',
        city: 'Oslo'
    },
    {
        zipCode: '0781',
        city: 'Oslo'
    },
    {
        zipCode: '0782',
        city: 'Oslo'
    },
    {
        zipCode: '0783',
        city: 'Oslo'
    },
    {
        zipCode: '0784',
        city: 'Oslo'
    },
    {
        zipCode: '0785',
        city: 'Oslo'
    },
    {
        zipCode: '0786',
        city: 'Oslo'
    },
    {
        zipCode: '0787',
        city: 'Oslo'
    },
    {
        zipCode: '0788',
        city: 'Oslo'
    },
    {
        zipCode: '0789',
        city: 'Oslo'
    },
    {
        zipCode: '0790',
        city: 'Oslo'
    },
    {
        zipCode: '0791',
        city: 'Oslo'
    },
    {
        zipCode: '0801',
        city: 'Oslo'
    },
    {
        zipCode: '0805',
        city: 'Oslo'
    },
    {
        zipCode: '0806',
        city: 'Oslo'
    },
    {
        zipCode: '0807',
        city: 'Oslo'
    },
    {
        zipCode: '0840',
        city: 'Oslo'
    },
    {
        zipCode: '0850',
        city: 'Oslo'
    },
    {
        zipCode: '0851',
        city: 'Oslo'
    },
    {
        zipCode: '0852',
        city: 'Oslo'
    },
    {
        zipCode: '0853',
        city: 'Oslo'
    },
    {
        zipCode: '0854',
        city: 'Oslo'
    },
    {
        zipCode: '0855',
        city: 'Oslo'
    },
    {
        zipCode: '0856',
        city: 'Oslo'
    },
    {
        zipCode: '0857',
        city: 'Oslo'
    },
    {
        zipCode: '0858',
        city: 'Oslo'
    },
    {
        zipCode: '0860',
        city: 'Oslo'
    },
    {
        zipCode: '0861',
        city: 'Oslo'
    },
    {
        zipCode: '0862',
        city: 'Oslo'
    },
    {
        zipCode: '0863',
        city: 'Oslo'
    },
    {
        zipCode: '0864',
        city: 'Oslo'
    },
    {
        zipCode: '0870',
        city: 'Oslo'
    },
    {
        zipCode: '0871',
        city: 'Oslo'
    },
    {
        zipCode: '0872',
        city: 'Oslo'
    },
    {
        zipCode: '0873',
        city: 'Oslo'
    },
    {
        zipCode: '0874',
        city: 'Oslo'
    },
    {
        zipCode: '0875',
        city: 'Oslo'
    },
    {
        zipCode: '0876',
        city: 'Oslo'
    },
    {
        zipCode: '0877',
        city: 'Oslo'
    },
    {
        zipCode: '0880',
        city: 'Oslo'
    },
    {
        zipCode: '0881',
        city: 'Oslo'
    },
    {
        zipCode: '0882',
        city: 'Oslo'
    },
    {
        zipCode: '0883',
        city: 'Oslo'
    },
    {
        zipCode: '0884',
        city: 'Oslo'
    },
    {
        zipCode: '0890',
        city: 'Oslo'
    },
    {
        zipCode: '0891',
        city: 'Oslo'
    },
    {
        zipCode: '0901',
        city: 'Oslo'
    },
    {
        zipCode: '0902',
        city: 'Oslo'
    },
    {
        zipCode: '0903',
        city: 'Oslo'
    },
    {
        zipCode: '0904',
        city: 'Oslo'
    },
    {
        zipCode: '0905',
        city: 'Oslo'
    },
    {
        zipCode: '0907',
        city: 'Oslo'
    },
    {
        zipCode: '0908',
        city: 'Oslo'
    },
    {
        zipCode: '0913',
        city: 'Oslo'
    },
    {
        zipCode: '0914',
        city: 'Oslo'
    },
    {
        zipCode: '0915',
        city: 'Oslo'
    },
    {
        zipCode: '0950',
        city: 'Oslo'
    },
    {
        zipCode: '0951',
        city: 'Oslo'
    },
    {
        zipCode: '0952',
        city: 'Oslo'
    },
    {
        zipCode: '0953',
        city: 'Oslo'
    },
    {
        zipCode: '0954',
        city: 'Oslo'
    },
    {
        zipCode: '0955',
        city: 'Oslo'
    },
    {
        zipCode: '0956',
        city: 'Oslo'
    },
    {
        zipCode: '0957',
        city: 'Oslo'
    },
    {
        zipCode: '0958',
        city: 'Oslo'
    },
    {
        zipCode: '0959',
        city: 'Oslo'
    },
    {
        zipCode: '0960',
        city: 'Oslo'
    },
    {
        zipCode: '0962',
        city: 'Oslo'
    },
    {
        zipCode: '0963',
        city: 'Oslo'
    },
    {
        zipCode: '0964',
        city: 'Oslo'
    },
    {
        zipCode: '0968',
        city: 'Oslo'
    },
    {
        zipCode: '0969',
        city: 'Oslo'
    },
    {
        zipCode: '0970',
        city: 'Oslo'
    },
    {
        zipCode: '0971',
        city: 'Oslo'
    },
    {
        zipCode: '0972',
        city: 'Oslo'
    },
    {
        zipCode: '0973',
        city: 'Oslo'
    },
    {
        zipCode: '0975',
        city: 'Oslo'
    },
    {
        zipCode: '0976',
        city: 'Oslo'
    },
    {
        zipCode: '0977',
        city: 'Oslo'
    },
    {
        zipCode: '0978',
        city: 'Oslo'
    },
    {
        zipCode: '0979',
        city: 'Oslo'
    },
    {
        zipCode: '0980',
        city: 'Oslo'
    },
    {
        zipCode: '0981',
        city: 'Oslo'
    },
    {
        zipCode: '0982',
        city: 'Oslo'
    },
    {
        zipCode: '0983',
        city: 'Oslo'
    },
    {
        zipCode: '0984',
        city: 'Oslo'
    },
    {
        zipCode: '0985',
        city: 'Oslo'
    },
    {
        zipCode: '0986',
        city: 'Oslo'
    },
    {
        zipCode: '0987',
        city: 'Oslo'
    },
    {
        zipCode: '0988',
        city: 'Oslo'
    }
];

export default zipCodes;
