import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * External Actions
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
const GET_REQUEST = '@app/workers/workersprofile/accountSettings/skills/get/REQUEST';
const GET_SUCCESS = '@app/workers/workersprofile/accountSettings/skills/get/SUCCESS';

const POST_REQUEST = '@app/workers/workersprofile/accountSettings/skills/post/REQUEST';
const POST_SUCCESS = '@app/workers/workersprofile/accountSettings/skills/post/SUCCESS';

const DELETE_REQUEST = '@app/workers/workersprofile/accountSettings/skills/delete/REQUEST';
const DELETE_SUCCESS = '@app/workers/workersprofile/accountSettings/skills/delete/SUCCESS';

const FAILURE = '@app/workers/workersprofile/accountSettings/skills/FAILURE';
const RESET = '@app/workers/workersprofile/accountSettings/skills/RESET';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: '',
    data: [],
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
                draft.loading = true;
                draft.error = '';
                draft.data = [];
                draft.success = null;
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case GET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            case POST_REQUEST:
            case DELETE_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case POST_SUCCESS:
            case DELETE_SUCCESS:
                draft.loading = false;
                draft.success = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.error = '';
                draft.data = [];
                draft.success = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: () => creator(GET_REQUEST),
    success: (data) => creator(GET_SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    update: (data) => creator(POST_REQUEST, data),
    updateSuccess: (data) => creator(POST_SUCCESS, data),
    delete: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *get() {
        try {
            const response = yield axios.get(`/workers/skills?limit=1000`);
            yield put(actions.success(response.data.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *post({ payload }) {
        try {
            const { worker_id, user_id } = payload;
            yield axios.post(`/workers/skills/${user_id} `, payload);
            yield put(actions.updateSuccess('admin.advancedWorkerSettings.skillUpdateSuccessMsg'));
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *delete({ payload }) {
        try {
            const { worker_id, user_id, skill_id } = payload;
            yield axios.delete(`/workers/skills/${skill_id}/${user_id}`, payload);
            yield put(actions.deleteSuccess('admin.advancedWorkerSettings.skillDeleteSuccessMsg'));
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get);
    yield takeLatest(POST_REQUEST, sagas.post);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
};
