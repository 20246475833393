import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Actions import
 */
import { actions as companyActions } from 'sagas/app/companies/companySaga';

import { actions as departmentActions } from 'sagas/app/departments/departmentSaga';

/**
 * Constants
 */
export const REQUEST = '@app/workers/addworkerssaga/REQUEST';
export const SUCCESS = '@app/workers/addworkerssaga/SUCCESS';
export const FAILURE = '@app/workers/addworkerssaga/FAILURE';
export const CLEAR = '@app/workers/addworkerssaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload: { companyID, workerID, isParent } }) {
        try {
            const response = yield axios.post(`/companies/${companyID}/${workerID}`);
            yield put(actions.success(response.data));
            if (isParent) {
                yield put(companyActions.request(companyID));
            } else {
                yield put(departmentActions.request(companyID));
            }
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
