import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Actions
 */
import { actions as companyActions } from 'sagas/app/companies/companySaga';

/**
 * Constants
 */
const REQUEST = '@app/company/edit/parent/contracts/REQUEST';
const SUCCESS = '@app/company/edit/parent/contracts/SUCCESS';
const FAILURE = '@app/company/edit/parent/contracts/FAILURE';
const RESET = '@app/company/edit/parent/contracts/RESET';

/**
 * Initial state
 */
const initState = {
    submitLoading: false,
    errorStatus: '',
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.submitLoading = true;
                draft.errorStatus = '';
                draft.success = null;
                break;
            case SUCCESS:
                draft.submitLoading = false;
                draft.errorStatus = '';
                draft.success = true;
                break;
            case FAILURE:
                draft.submitLoading = false;
                draft.success = false;
                draft.errorStatus = payload;
                break;
            case RESET:
                draft.submitLoading = false;
                draft.success = null;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield axios.put(`/companies/contracts/${payload.company_id}`, payload);
            yield put(actions.success());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
