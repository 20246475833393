import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External actions
 */
import { actions as getTeamMemberActions } from 'sagas/app/companies/fetchTeamMembersSaga';

/**
 * Constants
 */
export const REQUEST = '@app/company/tabs/team/delete/REQUEST';
export const SUCCESS = '@app/company/tabs/team/delete/SUCCESS';
export const FAILURE = '@app/company/tabs/team/delete/FAILURE';
export const CLEAR = '@app/company/tabs/team/delete/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case SUCCESS:
                draft.loading = false;
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    remove: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { companyID, user_id } = payload;
            yield axios.delete(`/companies/users/${companyID}/${user_id}`);
            yield put(actions.success());
            yield put(getTeamMemberActions.request(payload.companyID));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
