import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';
import { push } from 'react-router-redux';

/**
 * External Actions
 */
import { actions as companiesPageActions } from 'sagas/app/companies/companiesPageSaga';

/**
 * Constants
 */
const REQUEST = '@app/company/delete/parent/REQUEST';
const SUCCESS = '@app/company/delete/parent/SUCCESS';
const FAILURE = '@app/company/delete/parent/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: ''
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case SUCCESS:
                draft.loading = false;
                break;
            case FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: () => creator(SUCCESS),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield axios.delete(`/companies/${payload.company_id}`);
            yield put(actions.success());
            if (payload.redirect) {
                yield put(push('/companies'));
            } else {
                yield put(companiesPageActions.request(''));
            }
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
