import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const GET_REQUEST = '@app/administration/badges/GET_REQUEST';
export const GET_SUCCESS = '@app/administration/badges/GET_SUCCESS';
export const GET_FAILURE = '@app/administration/badges/GET_FAILURE';

export const ADD_REQUEST = '@app/administration/badges/ADD_REQUEST';
export const ADD_SUCCESS = '@app/administration/badges/ADD_SUCCESS';
export const ADD_FAILURE = '@app/administration/badges/ADD_FAILURE';

export const EDIT_REQUEST = '@app/administration/badges/EDIT_REQUEST';
export const EDIT_SUCCESS = '@app/administration/badges/EDIT_SUCCESS';
export const EDIT_FAILURE = '@app/administration/badges/EDIT_FAILURE';

export const DELETE_REQUEST = '@app/administration/badges/DELETE_REQUEST';
export const DELETE_SUCCESS = '@app/administration/badges/DELETE_SUCCESS';
export const DELETE_FAILURE = '@app/administration/badges/DELETE_FAILURE';

export const TRIGGER_ADD_MODAL = '@app/administration/badges/TRIGGER_ADD_MODAL';
export const TRIGGER_EDIT_MODAL = '@app/administration/badges/TRIGGER_EDIT_MODAL';
export const COLLAPSE_MODAL = '@app/administration/badges/COLLAPSE_MODAL';
export const RESET = '@app/administration/badges/RESET';

/**
 * Initial State
 */
const initialFormValues = {
    title: '',
    description: '',
    icon: ''
};

const initialModalValues = {
    open: false,
    newBadge: true,
    values: initialFormValues,
    isLoading: false,
    errorStatus: ''
};

const initialViewValues = {
    data: [],
    last: 1,
    errorStatus: '',
    loading: false,
    deleteLoading: null,
    deleteSuccess: false
};

const initialState = {
    modal: initialModalValues,
    view: initialViewValues
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get management
            case GET_REQUEST:
                draft.view.loading = true;
                draft.view.errorStatus = '';
                draft.view.deleteLoading = false;
                draft.view.deleteSuccess = null;
                break;
            case GET_SUCCESS:
                draft.view.loading = false;
                draft.view.data = [...draft.view.data, ...payload.data];
                draft.view.last = payload.last;
                break;
            case GET_FAILURE:
                draft.view.loading = false;
                draft.view.errorStatus = payload;
                break;

            // delete management
            case DELETE_REQUEST:
                draft.view.deleteLoading = payload;
                draft.view.deleteSuccess = false;
                break;
            case DELETE_FAILURE:
                draft.view.deleteLoading = null;
                draft.view.errorStatus = payload;
                break;
            case DELETE_SUCCESS:
                draft.view.deleteLoading = null;
                draft.view.deleteSuccess = true;
                draft.view.data = draft.view.data.filter((el) => el.id !== payload);
                break;

            // add/edit management
            case ADD_REQUEST:
            case EDIT_REQUEST:
                draft.modal.isLoading = true;
                draft.modal.errorStatus = '';
                break;
            case ADD_FAILURE:
            case EDIT_FAILURE:
                draft.modal.isLoading = false;
                draft.modal.errorStatus = payload;
                break;
            case ADD_SUCCESS:
                draft.view.data = [payload, ...draft.view.data];
                break;
            case EDIT_SUCCESS:
                const data = draft.view.data.map((el) => {
                    if (el.id === payload.id) {
                        return payload;
                    }
                    return el;
                });
                draft.view.data = data;
                break;

            // modal management
            case TRIGGER_ADD_MODAL:
                draft.modal.values = initialFormValues;
                draft.modal.newBadge = true;
                draft.modal.open = true;
                draft.modal.isLoading = false;
                draft.modal.errorStatus = '';
                break;
            case TRIGGER_EDIT_MODAL:
                draft.modal.values = payload;
                draft.modal.newBadge = false;
                draft.modal.open = true;
                draft.modal.isLoading = false;
                draft.modal.errorStatus = '';
                break;
            case COLLAPSE_MODAL:
                draft.modal = initialModalValues;
                break;

            // RESET
            case RESET:
                draft.modal = initialModalValues;
                draft.view = initialViewValues;
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    openAddModal: () => creator(TRIGGER_ADD_MODAL),
    openEditModal: (data) => creator(TRIGGER_EDIT_MODAL, data),
    collapseModal: () => creator(COLLAPSE_MODAL),

    getBadges: (data) => creator(GET_REQUEST, data),
    getFailure: (data) => creator(GET_FAILURE, data),
    getSuccess: (data) => creator(GET_SUCCESS, data),

    deleteBadge: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    deleteFailure: (data) => creator(DELETE_FAILURE, data),

    addBadge: (data) => creator(ADD_REQUEST, data),
    addSuccess: (data) => creator(ADD_SUCCESS, data),
    addFailure: (data) => creator(ADD_FAILURE, data),

    editBadge: (data) => creator(EDIT_REQUEST, data),
    editSuccess: (data) => creator(EDIT_SUCCESS, data),
    editFailure: (data) => creator(EDIT_FAILURE, data),

    reset: () => creator(RESET)
};

export const sagas = {
    *get({ payload }) {
        try {
            const response = yield axios.get(`/user/badges?page=${payload}`);
            yield put(
                actions.getSuccess({
                    data: response.data.data,
                    last: response.data.meta.last_page
                })
            );
        } catch (e) {
            yield put(actions.getFailure(errorMessageGenerator(e)));
        }
    },

    *add({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('icon', payload.icon, payload.icon.name);
            data.append('title', payload.title);
            data.append('description', payload.description);

            const response = yield axios.post('/user/badges', data, settings);
            yield put(actions.addSuccess(response.data.data));
            yield put(actions.collapseModal());
        } catch (e) {
            yield put(actions.addFailure(errorMessageGenerator(e)));
        }
    },

    *edit({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            if (payload.icon && Object.keys(payload.icon).length !== 1) {
                // icon didnt change - same as original sample
                data.append('icon', payload.icon, payload.icon.name);
            }

            data.append('title', payload.title);
            data.append('description', payload.description);
            data.append('id', payload.id);

            const response = yield axios.post(`/user/badges/${payload.id}`, data, settings);
            yield put(actions.editSuccess(response.data.data));
            yield put(actions.collapseModal());
        } catch (e) {
            yield put(actions.editFailure(errorMessageGenerator(e)));
        }
    },

    *delete({ payload }) {
        try {
            yield axios.delete(`/user/badges/${payload}`);
            yield put(actions.deleteSuccess(payload));
        } catch (e) {
            yield put(actions.deleteFailure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get);
    yield takeLatest(ADD_REQUEST, sagas.add);
    yield takeLatest(EDIT_REQUEST, sagas.edit);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
};
