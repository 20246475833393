import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
const POST_REQUEST = '@app/workers/workersprofile/gcc/POST_REQUEST';
const POST_FAILURE = '@app/workers/workersprofile/gcc/POST_FAILURE';

const DELETE_REQUEST = '@app/workers/workersprofile/gcc/DELETE_REQUEST';
const DELETE_SUCCESS = '@app/workers/workersprofile/gcc/DELETE_SUCCESS';
const DELETE_FAILURE = '@app/workers/workersprofile/gcc/DELETE_FAILURE';

const APPROVE_REQUEST = '@app/workers/workersprofile/gcc/APPROVE_REQUEST';
const APPROVE_SUCCESS = '@app/workers/workersprofile/gcc/APPROVE_SUCCESS';
const APPROVE_FAILURE = '@app/workers/workersprofile/gcc/APPROVE_FAILURE';

const TRIGGER_MODAL = '@app/workers/workersprofile/gcc/TRIGGER_MODAL';
const COLAPSE_MODAL = '@app/workers/workersprofile/gcc/COLAPSE_MODAL';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    modal: false,
    modalLoading: false,
    modalError: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case DELETE_REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case DELETE_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case DELETE_SUCCESS:
                draft.loading = false;
                break;

            case APPROVE_REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case APPROVE_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case APPROVE_SUCCESS:
                draft.loading = false;
                break;

            case POST_REQUEST:
                draft.modalLoading = true;
                draft.modalError = '';
                break;
            case POST_FAILURE:
                draft.modalLoading = false;
                draft.modalError = payload;
                break;

            case TRIGGER_MODAL:
                draft.modal = true;
                break;

            case COLAPSE_MODAL:
                draft.modal = false;
                draft.modalLoading = false;
                draft.modalError = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    post_request: (data) => creator(POST_REQUEST, data),
    post_failure: (data) => creator(POST_FAILURE, data),

    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: () => creator(DELETE_SUCCESS),
    delete_failure: (data) => creator(DELETE_FAILURE, data),

    approve_request: (data) => creator(APPROVE_REQUEST, data),
    approve_success: () => creator(APPROVE_SUCCESS),
    approve_failure: (data) => creator(APPROVE_FAILURE, data),

    trigger_modal: () => creator(TRIGGER_MODAL),
    colapse_modal: () => creator(COLAPSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *post_request({ payload }) {
        try {
            const { worker_id, user_id } = payload;

            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('file', payload.file, payload.file.name);
            data.append('issued_at', payload.issued_at);
            data.append('category_id', payload.category_id);
            data.append('pcc_reference', payload.pcc_reference);

            yield axios.post(`/workers/gcc/${user_id}`, data, settings);

            yield put(actions.colapse_modal());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.post_failure('Submit failed, please contact support!'));
        }
    },
    *delete_request({ payload }) {
        try {
            const { id, worker_id } = payload;
            yield axios.delete(`/workers/gcc/${id}`);
            yield put(actions.delete_success());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.delete_failure('Submit failed, please contact support!'));
        }
    },
    *approve_request({ payload }) {
        try {
            const { id, worker_id } = payload;
            yield axios.post(`/workers/gcc/approve/${id}`);
            yield put(actions.approve_success());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.approve_failure('Could not approve PCC!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(POST_REQUEST, sagas.post_request);
    yield takeLatest(DELETE_REQUEST, sagas.delete_request);
    yield takeLatest(APPROVE_REQUEST, sagas.approve_request);
};
