import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/addnewdepartmentsaga/REQUEST';
export const SUCCESS = '@app/addnewdepartmentsaga/SUCCESS';
export const FAILURE = '@app/addnewdepartmentsaga/FAILURE';
export const CLEAR = '@app/addnewdepartmentsaga/CLEAR';

/**
 * Initial state
 */

const initState = {
    success: false,
    data: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                break;
            case SUCCESS:
                draft.departmentId = payload;
                draft.loading = false;
                draft.success = true;
                break;
            case FAILURE:
                draft.error = payload;
                break;
            case CLEAR:
                draft.data = null;
                draft.success = false;
                draft.loading = false;
                draft.errorStatus = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload: { companyId, values } }) {
        try {
            const response = yield axios.post(`/companies/${companyId}`, values);
            yield put(actions.success(response?.data?.data.id));
            yield put(actions.clear());
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
