import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

function AnimatedSwtch({ exitBeforeEnter, initial, children }) {
    const location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
            <Switch location={location} key={location.pathname}>
                {children}
            </Switch>
        </AnimatePresence>
    );
}

AnimatedSwtch.defaultProps = {
    exitBeforeEnter: true,
    initial: false
};

AnimatedSwtch.propTypes = {
    exitBeforeEnter: PropTypes.bool,
    initial: PropTypes.bool
};

export default AnimatedSwtch;
