import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * Utils
 */
import { CURRENT_YEAR, CURRENT_MONTH } from 'utils/selectOptionsUtils';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/companiessaga/REQUEST';
export const INIT_REUQEST = '@app/companiessaga/INIT_REUQEST';
export const SUCCESS = '@app/companiessaga/SUCCESS';
export const FAILURE = '@app/companiessaga/FAILURE';
export const SET_LOADING = '@app/companiessaga/SET_LOADING';
export const SET_ITEMS = '@app/companiessaga/SET_ITEMS';
export const CLEAR = '@app/companiessaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    initRequest: () => creator(INIT_REUQEST),
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    /**
     * Request Saga (payload shoud be as query string)
     */
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            const response = yield axios.get(`/orders/timesheets/companies?${payload}`);
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    },

    *initRequest() {
        yield put(creator(SET_LOADING, true));

        try {
            const response = yield axios.get(
                `/orders/timesheets/companies?month=${CURRENT_MONTH}&year=${CURRENT_YEAR}`
            );
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(INIT_REUQEST, sagas.initRequest);
};
