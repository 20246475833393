import produce from 'immer';
import { put, takeLatest, take } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */
import {
    actions as notificationsActions,
    SUCCESS as NOTIFICATIONS_SUCCESS
} from 'sagas/app/notifications/notificationsSaga';

const REQUEST = '@app/notifications/notificationspagesaga/REQUEST';
const SUCCESS = '@app/notifications/notificationspagesaga/SUCCESS';
const FAILURE = '@app/notifications/notificationspagesaga/FAILURE';
const SET_LOADING = '@app/notifications/notificationspagesaga/SET_LOADING';
const CLEAR = '@app/notifications/notificationspagesaga/CLEAR';
const MARK_ALL_AS_READ = '@app/notifications/notificationspagesaga/MARK_ALL_AS_READ';
const MARK_ONE_AS_READ = '@app/notifications/notificationspagesaga/MARK_ONE_AS_READ';

/**
 * Initial state
 */
const initState = {
    loading: true,
    list: [],
    meta: {
        current_page: 1,
        last_page: 1
    },
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.loading = true;
                break;
            case SUCCESS:
                draft.list = payload.data;
                draft.meta = payload.meta;
                draft.loading = false;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    markAllAsRead: (data) => creator(MARK_ALL_AS_READ, data),
    markOneAsRead: (data) => creator(MARK_ONE_AS_READ, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload = 1 }) {
        try {
            yield put(creator(CLEAR));
            const response = yield axios.get(`/user/notifications?page=${payload}`);
            yield put(actions.success(response.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *markAllAsRead() {
        yield put(creator(SET_LOADING, true));
        yield put(notificationsActions.markAllAsRead());
        yield take(NOTIFICATIONS_SUCCESS);
        yield put(actions.request());
    },

    *markOneAsRead({ payload }) {
        try {
            const { id, page } = payload;
            yield axios.get(`/user/notifications/${id}`);
            yield put(actions.request(page));
        } catch (e) {
            console.error(e);
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(MARK_ALL_AS_READ, sagas.markAllAsRead);
    yield takeLatest(MARK_ONE_AS_READ, sagas.markOneAsRead);
};
