// the composition of navigation config
// should be from array of objects
// with interface ( last two carachters tell the localication)
/*
    navigationEn = [
        {
            to: '{path}', // path as in react router "to" parameter
            name: '{name}',
            slug: '{slug}', // unique key
            icon: 'dashboard' // icon name which should be included in ICON UI Compoonent
            children: [{
                to: '{path}',
                name: '{name}',
                slug: '{slug}' // unique key
            }] // children property should be added only if there are dropdown items
            userStage: [50] // display for users that are in stage
        },
        ...
    ]
*/

/**
 * Utils
 */
import { userRoleTypes as ROLES } from 'uwork-app-core';

export const navigationEn = (t) => [
    {
        to: '/',
        name: t('admin.navbar.home'),
        slug: 'min-oversikt',
        icon: 'dashboard',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '',
        name: t('admin.navbar.workers'),
        slug: 'workers',
        icon: 'user',
        children: [
            {
                to: '/workers?sort_date=desc',
                name: t('admin.navbar.workersPool'),
                slug: '/workers'
            },
            {
                to: '/recommendations?page=1&requested_date=desc',
                name: t('admin.navbar.recommendations'),
                slug: '/recommendations'
            },
            {
                to: '/workers-availability',
                name: t('admin.navbar.workersAvailaibility'),
                slug: '/workers-availability'
            }
        ],
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '',
        name: t('admin.navbar.companies'),
        slug: 'bedrifter',
        icon: 'hotel',
        children: [
            {
                to: '/companies',
                name: t('admin.navbar.companies'),
                slug: '/bedrifter'
            },
            {
                to: '/departments',
                name: t('admin.navbar.departments'),
                slug: '/avdelinger'
            }
        ],
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '',
        name: t('admin.navbar.orders'),
        slug: 'vakter',
        icon: 'case',
        children: [
            {
                to: '/orders/active',
                name: t('admin.navigation.orders.active'),
                slug: '/orders/active'
            },
            {
                to: '/orders/ongoing',
                name: t('admin.navigation.orders.ongoing'),
                slug: '/orders/ongoing'
            },
            {
                to: '/orders/completed',
                name: t('admin.navigation.orders.completed'),
                slug: '/orders/completed'
            }
        ],
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '/timesheet',
        name: t('admin.navbar.timesheet'),
        slug: 'timelister',
        icon: 'calendar',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '/overtime',
        name: t('admin.navbar.overtime'),
        slug: 'overtid',
        icon: 'sidebar-clock',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '',
        name: t('admin.navbar.administration'),
        slug: 'administrasjon',
        icon: 'gear',
        children: [
            {
                to: '/administration/guidelines',
                name: t('admin.administration.guidelines.title'),
                slug: '/administration/guidelines'
            },
            {
                to: '/administration/skills',
                name: t('admin.administration.skills.title'),
                slug: '/administration/skills'
            },
            {
                to: '/administration/courses',
                name: t('admin.administration.courses.title'),
                slug: '/administration/courses'
            },
            {
                to: '/administration/titles',
                name: t('admin.administration.titles.title'),
                slug: '/administration/titles'
            },
            {
                to: '/administration/tags',
                name: t('admin.administration.tags.title'),
                slug: '/administration/tags'
            },
            {
                to: '/administration/badges',
                name: t('admin.administration.badges.title'),
                slug: '/administration/badges'
            },
            {
                to: '/administration/emails',
                name: t('admin.administration.emails.pageTitle'),
                slug: '/administration/emails'
            }
        ],
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        to: '/metrics',
        name: t('admin.navbar.metrics'),
        slug: 'metrics',
        icon: 'metrics',
        stage: [],
        role: [ROLES.PARENT]
    }
    // {
    //     to: '',
    //     name: 'Example',
    //     slug: 'example',
    //     icon: 'case',
    //     children: [{
    //         to: '/orders/active',
    //         name: 'Utlyste vakter',
    //         slug: 'utlyste-vakter'
    //     },
    //     {
    //         to: '/orders/ongoing',
    //         name: 'Bestilte vakter',
    //         slug: 'bestilte-vakter'
    //     },
    //     {
    //         to: '/orders/completed',
    //         name: 'Avsluttede vakter',
    //         slug: 'avsluttede-vakter'
    //     }],
    //     stage: [],
    //     role: [ROLES.PARENT]
    // },
];

export default {
    en: navigationEn,
    backToDashboard: false
};
