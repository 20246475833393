import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/administration/emails/single/REQUEST';
export const SUCCESS = '@app/administration/emails/single/SUCCESS';
export const FAILURE = '@app/administration/emails/single/FAILURE';

export const RESET = '@app/administration/emails/single/RESET';

/**
 * Initial State
 */
const initialState = {
    data: null,
    loading: false,
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = '';
                break;

            case SUCCESS:
                draft.data = payload;
                draft.loading = false;
                break;

            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;

            // RESET
            case RESET:
                draft.data = null;
                draft.loading = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    get: (data) => creator(REQUEST, data),
    failure: (data) => creator(FAILURE, data),
    success: (data) => creator(SUCCESS, data),
    reset: () => creator(RESET)
};

export const sagas = {
    *get({ payload }) {
        try {
            const response = yield axios.get(`/email-logs/${payload}`);
            yield put(actions.success(response.data.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.get);
};
