import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Actions
 */
import { actions as companyActions } from 'sagas/app/companies/companySaga';

/**
 * Constants
 */
const ADD_REQUEST = '@app/company/edit/parent/nda/modal/REQUEST';
const ADD_SUCCESS = '@app/company/edit/parent/nda/modal/SUCCESS';
const ADD_FAILURE = '@app/company/edit/parent/nda/modal/FAILURE';
const OPEN_MODAL = '@app/company/edit/parent/nda/modal/OPEN';
const CLOSE_MODAL = '@app/company/edit/parent/nda/modal/CLOSE';

const DELETE_REQUEST = '@app/company/edit/parent/nda/delete/REQUEST';
const DELETE_SUCCESS = '@app/company/edit/parent/nda/delete/SUCCESS';
const DELETE_FAILURE = '@app/company/edit/parent/nda/delete/FAILURE';
const DELETE_VIEW_RESET = '@app/company/edit/parent/nda/delete/RESET';

/**
 * Initial state
 */
const initState = {
    add: {
        loading: false,
        error: '',
        modal: false
    },
    delete: {
        loading: false,
        error: '',
        success: null
    }
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            // add cases
            case ADD_REQUEST:
                draft.add.loading = true;
                draft.add.error = '';
                break;
            case ADD_SUCCESS:
                draft.add.loading = false;
                break;
            case ADD_FAILURE:
                draft.add.loading = false;
                draft.add.error = payload;
                break;
            case OPEN_MODAL:
                draft.add.modal = true;
                break;
            case CLOSE_MODAL:
                //reset
                draft.add = initState.add;
                break;

            // delete cases
            case DELETE_REQUEST:
                draft.delete.loading = true;
                draft.delete.error = '';
                draft.delete.success = null;
                break;
            case DELETE_SUCCESS:
                draft.delete.loading = false;
                draft.delete.success = true;
                break;
            case DELETE_FAILURE:
                draft.delete.success = null;
                draft.delete.loading = false;
                draft.delete.error = payload;
                break;
            case DELETE_VIEW_RESET:
                draft.delete = initState.delete;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    //add actions
    add_request: (data) => creator(ADD_REQUEST, data),
    add_success: () => creator(ADD_SUCCESS),
    add_failure: (data) => creator(ADD_FAILURE, data),
    open_modal: () => creator(OPEN_MODAL),
    close_modal: () => creator(CLOSE_MODAL),

    // delete actions
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),
    view_reset: () => creator(DELETE_VIEW_RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *add({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('file', payload.file, payload.file.name);
            data.append('title', payload.title);
            data.append('company_id', payload.company_id);

            yield axios.post(`/companies/nda`, data, settings);

            yield put(actions.add_success());
            yield put(actions.close_modal());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.add_failure(errorMessageGenerator(e)));
        }
    },

    *delete({ payload }) {
        try {
            yield axios.delete(`/companies/nda/${payload.id}`, payload);
            yield put(actions.delete_success());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.delete_failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(ADD_REQUEST, sagas.add);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
};
