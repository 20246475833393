import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';

/**
 * Static Data
 */
import zipCodes from '../../../staticData/zipCodes';

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofilesaga/zipcodes/REQUEST';
const SUCCESS = '@app/workers/workersprofilesaga/zipcodes/SUCCESS';
const FAILURE = '@app/workers/workersprofilesaga/zipcodes/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: false,
    filtered: null,
    items: zipCodes
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.filtered = payload;
                break;
            case FAILURE:
                draft.filtered = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: () => creator(FAILURE)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        let zipCode = zipCodes.find((item) => item.zipCode === `${payload}`);
        if (!!zipCode) {
            yield put(actions.success(zipCode));
        } else {
            yield put(actions.failure());
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
