import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofile/workershifts/REQUEST';
const SUCCESS = '@app/workers/workersprofile/workershifts/SUCCESS';
const FAILURE = '@app/workers/workersprofile/workershifts/FAILURE';
const SHIFT_TYPE_REQUEST = '@app/workers/workersprofile/workershifts/shifttypes/REQUEST';
const SHIFT_TYPE_SUCCESS = '@app/workers/workersprofile/workershifts/shifttypes/SUCCESS';
const RESET = '@app/workers/workersprofile/workershifts/RESET';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                draft.success = null;
                break;
            case FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.success = 1;
                break;
            case SHIFT_TYPE_REQUEST:
                draft.loading = true;
                break;
            case SHIFT_TYPE_SUCCESS:
                draft.loading = false;
                draft.success = 2;
                break;
            case RESET:
                draft.loading = false;
                draft.errorStatus = '';
                draft.success = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: () => creator(SUCCESS),
    failure: (data) => creator(FAILURE, data),
    shifttypeRequest: (data) => creator(SHIFT_TYPE_REQUEST, data),
    shifttypeSuccess: () => creator(SHIFT_TYPE_SUCCESS),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { worker_id } = payload;
            const response = yield axios.post(`/workers/travel-distance/${worker_id}`, payload);
            yield put(actions.success(response.data));
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *shiftTypeChange({ payload }) {
        try {
            const { worker_id, user_id } = payload;
            const response = yield axios.put(`/user/shifts/${user_id}`, payload);
            yield put(actions.success(response.data));
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SHIFT_TYPE_REQUEST, sagas.shiftTypeChange);
};
