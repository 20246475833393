/**
 * Module import
 */
import React from 'react';
import * as Sentry from '@sentry/react';
import { Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * Core imports
 */
import { history, changeLanguageListener } from 'uwork-app-core';

/**
 * Routes import
 */
import DashboardRoutes from 'routes/DashboardRoutes';

/**
 * Components
 */
import ToastAlert from 'components/UI/Toasts/ToastAlert';

/**
 * Sentry Routes
 */
const SentryRoute = Sentry.withSentryRouting(Route);

const App = () => {
    const { i18n, t } = useTranslation();
    /**
     * Language
     */
    changeLanguageListener(i18n, t);

    return (
        <Router history={history}>
            <Switch>
                <div className="ds">
                    {/* Dashboard routes */}
                    <SentryRoute path="/*">
                        <DashboardRoutes />
                    </SentryRoute>
                </div>
            </Switch>
            <ToastAlert />
        </Router>
    );
};

export default App;
