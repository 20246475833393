import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/departmetns/categories/REQUEST';
export const SUCCESS = '@app/departmetns/categories/SUCCESS';
export const FAILURE = '@app/departmetns/categories/FAILURE';
export const CLEAR = '@app/departmetns/categories/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.errorStatus = '';
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get('categories', payload);

            // add title to categories
            const categories = response.data.data.map((item) => ({
                ...item,
                title: item.name
            }));

            yield put(actions.success(categories));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
