import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
const POST_REQUEST = '@app/workers/workersprofile/workerAttachments/POST_REQUEST';
const POST_FAILURE = '@app/workers/workersprofile/workerAttachments/POST_FAILURE';

const DELETE_REQUEST = '@app/workers/workersprofile/workerAttachments/DELETE_REQUEST';
const PUT_DELETE_SUCCESS = '@app/workers/workersprofile/workerAttachments/PUT_DELETE_SUCCESS';
const PUT_DELETE_FAILURE = '@app/workers/workersprofile/workerAttachments/PUT_DELETE_FAILURE';

const PUT_REQUEST = '@app/workers/workersprofile/workerAttachments/PUT_REQUEST';

const TRIGGER_MODAL = '@app/workers/workersprofile/workerAttachments/TRIGGER_MODAL';
const COLAPSE_MODAL = '@app/workers/workersprofile/workerAttachments/COLAPSE_MODAL';
const RESET_VIEW = '@app/workers/workersprofile/workerAttachments/RESET_VIEW';

/**
 * Initial state
 */
const initAttachment = {
    loading: false,
    errorStatus: '',
    file: '',
    title: '',
    type: 5,
    // 0 - add , 1 - edit
    mode: 0
};

const initState = {
    loading: false,
    errorStatus: '',
    attachment: initAttachment,
    modal: false,
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case DELETE_REQUEST:
            case PUT_REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                draft.success = null;
                break;
            case PUT_DELETE_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case PUT_DELETE_SUCCESS:
                draft.loading = false;
                draft.success = true;
                break;

            case POST_REQUEST:
                draft.attachment.loading = true;
                draft.attachment.errorStatus = '';
                break;
            case POST_FAILURE:
                draft.attachment.loading = false;
                draft.attachment.errorStatus = payload;
                break;

            case TRIGGER_MODAL:
                draft.modal = true;
                draft.attachment = { ...initAttachment, ...payload };
                break;

            case COLAPSE_MODAL:
                draft.modal = false;
                draft.attachment = initAttachment;
                draft.errorStatus = '';
                break;

            case RESET_VIEW:
                draft.loading = false;
                draft.errorStatus = '';
                draft.success = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    post_request: (data) => creator(POST_REQUEST, data),
    post_failure: (data) => creator(POST_FAILURE, data),

    delete_request: (data) => creator(DELETE_REQUEST, data),
    put_delete_success: () => creator(PUT_DELETE_SUCCESS),
    put_delete_failure: (data) => creator(PUT_DELETE_FAILURE, data),

    put_request: (data) => creator(PUT_REQUEST, data),

    trigger_modal: (data) => creator(TRIGGER_MODAL, data),
    colapse_modal: () => creator(COLAPSE_MODAL),
    reset_view: () => creator(RESET_VIEW)
};

/**
 * Saga functions
 */
export const sagas = {
    *post_request({ payload }) {
        try {
            const { worker_id, user_id } = payload;

            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('file', payload.file, payload.file.name);
            data.append('title', payload.title);
            data.append('type', payload.type);

            yield axios.post(`workers/attachments/${user_id}`, data, settings);

            yield put(actions.colapse_modal());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.post_failure('Submit failed, please contact support!'));
        }
    },
    *delete_request({ payload }) {
        try {
            const { id, worker_id } = payload;
            yield axios.delete(`workers/attachments/${id}`);
            yield put(actions.put_delete_success());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.put_delete_failure('Delete failed, please contact support!'));
        }
    },
    *put_request({ payload }) {
        try {
            const { id, worker_id } = payload;
            yield axios.put(`workers/attachments/${id}`, payload);

            yield put(actions.put_delete_success());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.put_delete_failure('Status change fail, please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(POST_REQUEST, sagas.post_request);
    yield takeLatest(DELETE_REQUEST, sagas.delete_request);
    yield takeLatest(PUT_REQUEST, sagas.put_request);
};
