import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';
import { push } from 'react-router-redux';

/**
 * External Ations
 */

import { actions as workersAction } from 'sagas/app/workers/workersSaga';

/**
 * Constants
 */
export const REQUEST = '@app/deleteworkersaga/REQUEST';
export const SUCCESS = '@app/deleteworkersaga/SUCCESS';
export const FAILURE = '@app/deleteworkersaga/FAILURE';
export const SET_LOADING = '@app/deleteworkersaga/SET_LOADING';
export const SET_ITEMS = '@app/deleteworkersaga/SET_ITEMS';
export const CLEAR = '@app/deleteworkersaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.addSuccessful = true;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.addSuccessful = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        yield put(actions.clear());
        yield put(creator(SET_LOADING, true));

        try {
            const response = yield axios.delete(`/workers/${payload?.id}`);
            if (payload.profileView) {
                yield put(push('/workers'));
            } else {
                yield put(workersAction.remove_worker(payload?.id));
            }
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }

        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
