import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/departments/departmentsaga/REQUEST';
export const SUCCESS = '@app/departments/departmentsaga/SUCCESS';
export const FAILURE = '@app/departments/departmentsaga/FAILURE';
export const CLEAR = '@app/departments/departmentsaga/CLEAR';
export const ADD_DEPARTMENT = '@app/departments/departmentsaga/ADD_DEPARTMENT';
export const UPDATE_TEAM = '@app/departments/departmentsaga/UPDATE_TEAM';
export const CHANGE_BMK = '@app/departments/departmentsaga/CHANGE_BMK';

/**
 * Initial state
 */
const initState = {
    data: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.data = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.data = null;
                break;
            case ADD_DEPARTMENT:
                draft.data.department.push(payload.data);
                break;
            case UPDATE_TEAM:
                draft.data.team = draft.data.team.map((el) =>
                    el.user.id === payload.user.id ? payload : el
                );
                break;
            case CHANGE_BMK:
                draft.data.admin = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),
    addDepartment: (data) => creator(ADD_DEPARTMENT, data),
    updateTeamMemeber: (data) => creator(UPDATE_TEAM, data),
    changeBMK: (data) => creator(CHANGE_BMK, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        put(actions.clear());
        try {
            const response = yield axios.get(`/companies/departments/show/${payload}`);
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
