import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'axios';

/**
 * Constants
 */
export const REQUEST = '@app/recommendations/departmentUsers/REQUEST';
export const SUCCESS = '@app/recommendations/departmentUsers/SUCCESS';
export const FAILURE = '@app/recommendations/departmentUsers/FAILURE';
export const CLEAR = '@app/recommendations/departmentUsers/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                draft.items = [];
                break;
            case SUCCESS:
                draft.items = payload;
                draft.loading = false;
                break;
            case CLEAR:
                draft.items = [];
                draft.loading = false;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(`/companies/team/multiple/${payload}`);
            yield put(actions.success(response?.data.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
