import { userRoleTypes as ROLES } from 'uwork-app-core';

export const navigationEn = (t) => [
    {
        name: t('admin.settings.departmentInfo.pageTitle'),
        slug: '/departments/settings/information',
        to: '/departments/settings/information',
        icon: 'gear',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER]
    },
    {
        name: t('admin.patientinfo.pageTitle'),
        slug: '/departments/settings/patients',
        to: '/departments/settings/patients',
        icon: 'user',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER]
    },
    {
        name: t('admin.edit.company.orderShiftTypes'),
        slug: '/departments/settings/order-shift-types',
        to: '/departments/settings/order-shift-types',
        icon: 'singleContent',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER]
    }
];

export default {
    en: navigationEn,
    backToDashboard: true
};
