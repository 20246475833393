import produce from 'immer';
import { takeLatest, select, put } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';

/**
 * External Ations, Selectors
 */
import { selectors as userSelectors } from 'sagas/app/auth/userSaga';
import getUserSettingsFromLocalStorage from 'utils/getUserSettingsFromLocalStorage';
import saveUserSettingOnLocalStorage from 'utils/saveUserSettingOnLocalStorage';
import { selectCurrentUserId } from 'sagas/selectors';

/**
 * Constants
 */
export const REQUEST = '@app/department/departmentsaga/REQUEST';
export const SET_DEPARTMENT = '@app/department/departmentsaga/SET_DEPARTMENT';
export const SUCCESS = '@app/department/departmentsaga/SUCCESS';
export const FAILURE = '@app/department/departmentsaga/FAILURE';
export const SET_LOADING = '@app/department/departmentsaga/SET_LOADING';
export const SET_ITEMS = '@app/department/departmentsaga/SET_ITEMS';
export const CLEAR = '@app/department/departmentsaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    current: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.current = payload;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.current = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */
export const selectors = {
    getCurrentDepartment: (state) => state.app.department.data.current
};

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    setDepartment: (data) => creator(SET_DEPARTMENT, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request() {
        const currentDepartment = yield select(selectors.getCurrentDepartment);

        const departments = yield select(userSelectors.getDepartments);
        let department = departments[0];

        if (currentDepartment) {
            const _deparment = departments.find((dep) => dep.id === currentDepartment.id);
            if (_deparment) {
                department = _deparment;
            }
        }

        yield put(actions.success(department));
    },

    *setDepartment({ payload }) {
        const departments = yield select(userSelectors.getDepartments);
        const department = departments.find((dep) => dep.id === payload);

        // Check if department exists
        if (department) {
            const userId = yield select(selectCurrentUserId);

            // Save as settings for the user
            const settings = getUserSettingsFromLocalStorage(userId);
            saveUserSettingOnLocalStorage(userId, {
                ...settings,
                selectedDepartment: department.id
            });

            yield put(actions.success(department));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SET_DEPARTMENT, sagas.setDepartment);
};
