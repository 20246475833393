import { getYear, getMonth } from 'date-fns';

/**
 * Utils
 */

/**
 * Constants
 */
export const CURRENT_YEAR = getYear(new Date());
export const CURRENT_MONTH = getMonth(new Date()) + 1;
export const CURRENT_QUARTER = Math.floor((new Date().getMonth() + 3) / 3);

export const getLastYearsOptions = (lastYears = 10) => {
    let currentYear = CURRENT_YEAR;
    // eslint-disable-next-line prefer-spread
    return Array.apply(null, { length: lastYears }).map(() => {
        // eslint-disable-next-line no-plusplus
        const year = currentYear--;
        return {
            id: year,
            title: year.toString()
        };
    });
};

export const getMonthsOptions = (year = null) => {
    // if it is current year limit future months
    if (year && year === CURRENT_YEAR) {
        // eslint-disable-next-line prefer-spread
        return Array.apply(null, { length: CURRENT_MONTH }).map((item, index) => ({
            id: index + 1,
            value: index + 1,
            title: (index + 1).toString()
        }));
    }

    // eslint-disable-next-line prefer-spread
    return Array.apply(null, { length: 12 }).map((item, index) => ({
        id: index + 1,
        title: (index + 1).toString()
    }));
};

export const getQuarterOptions = () =>
    // eslint-disable-next-line prefer-spread
    Array.apply(null, { length: 4 }).map((item, index) => ({
        id: index + 1,
        title: (index + 1).toString()
    }));

// CONSTANT OPTIONS
export const TRAVEL_DISTANCE_OPTIONS = [
    {
        id: '0.25',
        title: '15 min'
    },
    {
        id: '0.5',
        title: '30 min'
    },
    {
        id: '0.75',
        title: '40 min'
    },
    {
        id: '1',
        title: '1 timer'
    },
    {
        id: '2',
        title: '2 timer'
    },
    {
        id: '3',
        title: '3 timer'
    },
    {
        id: '4',
        title: '4 timer'
    },
    {
        id: '5',
        title: '5 timer'
    },
    {
        id: '6',
        title: '6 timer'
    },
    {
        id: '7',
        title: '7 timer'
    }
];
