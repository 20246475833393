import axios from 'utils/axios';
import { OvertimeModel } from '../../interfaces/models/Overtime.model';
import { OvertimeStorePayloadModel } from '../../interfaces/models/OvertimeStorePayload.model';
import { API_SALARY_URL } from '../../config/app';

export const storeOvertime = async (
    payload: OvertimeStorePayloadModel
): Promise<OvertimeModel[] | []> => {
    const { data } = await axios.post('/overtime', payload, {
        baseURL: API_SALARY_URL
    });

    return data.data;
};
