import {
    formatToTimeZone
    // eslint-disable-next-line import/no-unresolved
} from 'date-fns-timezone';

import { TIMEZONE } from 'config';

/**
 * Defualt reducer
 *
 * @param date
 * @param format
 * @param options (Optional)
 */

const format = (date, _format, options = { timeZone: TIMEZONE }) =>
    formatToTimeZone(date, _format, options);

export default format;
