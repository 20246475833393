import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * External Ations
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
export const REQUEST = '@app/workers/workersprofile/notifications/REQUEST';
export const SUCCESS = '@app/workers/workersprofile/notifications/SUCCESS';
export const FAILURE = '@app/workers/workersprofile/notifications/FAILURE';
export const CLEAR = '@app/workers/workersprofile/notifications/CLEAR';

/**
 * Initial state
 */
const initState = {
    errorMessage: '',
    success: false,
    loading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.loading = false;
                draft.success = true;
                break;
            case FAILURE:
                draft.errorMessage = payload;
                draft.loading = false;
                break;
            case REQUEST:
                draft.errorMessage = '';
                draft.loading = true;
                draft.success = false;
                break;
            case CLEAR:
                draft.errorMessage = '';
                draft.loading = false;
                draft.success = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { user_id, body } = payload;
            const response = yield axios.put(`/workers/notification-settings/${user_id}`, body);
            yield put(actions.success());
            yield put(profileActions.success(response.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
