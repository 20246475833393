import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function RouteMotion({ animation, animationProps, className, children }) {
    return (
        <motion.div className={`w-100 ${className}`} variants={animation} {...animationProps}>
            {children}
        </motion.div>
    );
}

RouteMotion.defaultProps = {
    className: '',
    animation: {
        initial: {
            opacity: 0,
            x: 0,
            y: 10
        },
        animate: {
            opacity: 1,
            x: 0,
            y: 0
        },
        transition: {
            duration: 0.5
        }
    },
    animationProps: {
        exit: 'initial',
        initial: 'initial',
        animate: 'animate',
        transition: 'transition'
    }
};

RouteMotion.propTypes = {
    className: PropTypes.string,
    animation: PropTypes.object,
    animationProps: PropTypes.object
};

export default RouteMotion;
