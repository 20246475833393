import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Actions
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */

const GET_REQUEST = '@app/workers/workersprofile/accountSettings/advanced/tags/get/REQUEST';
const GET_SUCCESS = '@app/workers/workersprofile/accountSettings/advanced/tags/get/SUCCESS';
const PUT_REQUEST = '@app/workers/workersprofile/accountSettings/advanced/tags/put/REQUEST';
const PUT_SUCCESS = '@app/workers/workersprofile/accountSettings/advanced/tags/put/SUCCESS';
const RESET = '@app/workers/workersprofile/accountSettings/advanced/tags/RESET';
const FAILURE = '@app/workers/workersprofile/accountSettings/advanced/tags/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: false,
    data: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
                draft.loading = true;
                draft.data = [];
                break;
            case GET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            case PUT_REQUEST:
                draft.loading = true;
                break;
            case FAILURE:
            case PUT_SUCCESS:
                draft.loading = false;
                break;
            case RESET:
                draft.loading = false;
                draft.data = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: () => creator(GET_REQUEST),
    success: (data) => creator(GET_SUCCESS, data),
    failure: () => creator(FAILURE),
    update: (data) => creator(PUT_REQUEST, data),
    updateSuccess: (data) => creator(PUT_SUCCESS, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *get() {
        try {
            const response = yield axios.get(`/user/tags?limit=100`);
            yield put(actions.success(response.data.data));
        } catch (e) {
            console.error(e);
            yield put(actions.failure());
        }
    },
    *put({ payload }) {
        try {
            const { worker_id, user_id } = payload;
            yield axios.put(`/user/user-tags/${user_id} `, payload);
            yield put(actions.updateSuccess());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            console.error(e);
            yield put(actions.failure());
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get);
    yield takeLatest(PUT_REQUEST, sagas.put);
};
