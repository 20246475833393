import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/workers/admins/get/REQUEST';
export const SUCCESS = '@app/workers/admins/get/SUCCESS';
export const FAILURE = '@app/workers/admins/get/FAILURE';
export const CLEAR = '@app/workers/admins/get/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                break;
            case SUCCESS:
                draft.items = payload.data;
                draft.loading = false;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case CLEAR:
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request() {
        try {
            const response = yield axios.get(`/admins`);
            yield put(actions.success(response.data));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
