import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */

const REQUEST = '@app/orders/view/filter/companies/REQUEST';
const SUCCESS = '@app/orders/view/filter/companies/SUCCESS';
const FAILURE = '@app/orders/view/filter/companies/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: true,
    error: '',
    data: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.error = payload;
                draft.loading = false;
                break;
            case REQUEST:
                draft.loading = true;
                draft.data = [];
                break;
            case SUCCESS:
                draft.data = payload;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            // payload can be ongoing or completed
            const response = yield axios.get(`/orders/${payload}/companies`);
            yield put(actions.success(response.data.data[0]));
        } catch (e) {
            console.error(e);
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
