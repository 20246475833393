/* eslint-disable camelcase */
import copy from 'copy-to-clipboard';
import { PUBLIC_PROFILE_URL } from 'config';

export const copyToClipboard = (item, prev_public) => {
    if (item.public_token && (prev_public === 0 || !prev_public)) {
        const copyText = `${PUBLIC_PROFILE_URL}/${item.public_token}`;
        copy(copyText);
    }
};
