import produce from 'immer';
import { put, takeLatest, delay, select } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import format from 'utils/customTimeFormat';

/**
 * External actions
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */

const GET_REQUEST = '@app/workers/workersprofile/reference/get/REQUEST';
const GET_FAILURE = '@app/workers/workersprofile/reference/get/FAILURE';
const GET_SUCCESS = '@app/workers/workersprofile/reference/get/SUCCESS';

const CREATE_REQUEST = '@app/workers/workersprofile/reference/post/REQUEST';
const CREATE_FAILURE = '@app/workers/workersprofile/reference/post/FAILURE';
const CREATE_SUCCESS = '@app/workers/workersprofile/reference/post/SUCCESS';

const EDIT_REQUEST = '@app/workers/workersprofile/reference/put/REQUEST';
const EDIT_FAILURE = '@app/workers/workersprofile/reference/put/FAILURE';
const EDIT_SUCCESS = '@app/workers/workersprofile/reference/put/SUCCESS';

const DELETE_REQUEST = '@app/workers/workersprofile/reference/delete/REQUEST';
const DELETE_FAILURE = '@app/workers/workersprofile/reference/delete/FAILURE';
const DELETE_SUCCESS = '@app/workers/workersprofile/reference/delete/SUCCESS';

const RESET = '@app/workers/workersprofile/reference/RESET';
const OPEN_MODAL = '@app/workers/workersprofile/reference/OPEN_MODAL';
const CLOSE_MODAL = '@app/workers/workersprofile/reference/CLOSE_MODAL';

/**
 * Initial state
 */
const initModalState = {
    show: false,
    loading: false,
    error: '',
    worker_id: '',
    name: '',
    description: '',
    summary: '',
    date: new Date(new Date(new Date().setHours(9)).setMinutes(0)).toISOString(),
    approved: 0,
    referenceId: null,
    mode: 0 // 0-create , 1-edit
};

const initViewState = {
    loading: false,
    error: '',
    data: []
};

const initState = {
    modal: initModalState,
    view: initViewState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            //get
            case GET_REQUEST:
                draft.view.loading = true;
                draft.view.error = '';
                draft.view.data = [];
                break;
            case GET_SUCCESS:
                draft.view.loading = false;
                draft.view.data = payload;
                break;
            case GET_FAILURE:
                draft.view.loading = false;
                draft.view.error = payload;
                break;

            //put post
            case CREATE_REQUEST:
            case EDIT_REQUEST:
                draft.modal.loading = true;
                draft.modal.error = '';
                break;
            case CREATE_FAILURE:
            case EDIT_FAILURE:
                draft.modal.loading = false;
                draft.modal.error = payload;
                break;
            case CREATE_SUCCESS:
            case EDIT_SUCCESS:
                draft.modal = initModalState;
                break;

            //delete
            case DELETE_REQUEST:
                draft.view.loading = payload.note_id;
                draft.view.error = '';
                break;
            case DELETE_SUCCESS:
                draft.view.loading = false;
                break;
            case DELETE_FAILURE:
                draft.view.loading = false;
                draft.view.error = payload;
                break;

            //modal
            case OPEN_MODAL:
                draft.modal.loading = false;
                draft.modal.error = '';
                draft.modal.show = true;
                draft.modal.name = payload?.name || '';
                draft.modal.summary = payload?.summary || '';
                draft.modal.description = payload?.description || '';
                draft.modal.mode = payload?.mode || 0;
                draft.modal.referenceId = payload?.id || null;
                draft.modal.date = payload?.date || initModalState.date;
                draft.modal.approved = payload?.approved || 0;
                break;
            case CLOSE_MODAL:
                draft.modal = initModalState;
                break;

            // reset to initial
            case RESET:
                draft.view = initViewState;
                draft.modal = initModalState;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    get: (data) => creator(GET_REQUEST, data),
    getSuccess: (data) => creator(GET_SUCCESS, data),
    getFailure: (data) => creator(GET_FAILURE, data),

    create: (data) => creator(CREATE_REQUEST, data),
    createSuccess: (data) => creator(CREATE_SUCCESS, data),
    createFailure: (data) => creator(CREATE_FAILURE, data),

    update: (data) => creator(EDIT_REQUEST, data),
    updateSuccess: (data) => creator(EDIT_SUCCESS, data),
    updateFailure: (data) => creator(EDIT_FAILURE, data),

    delete: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    deleteFailure: (data) => creator(DELETE_FAILURE, data),

    reset: () => creator(RESET),
    openModal: (data) => creator(OPEN_MODAL, data),
    closeModal: () => creator(CLOSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *getWorkerReferences({ payload }) {
        try {
            const response = yield axios.get(`/workers/references/${payload}`);
            yield put(actions.getSuccess(response.data.data));
        } catch (e) {
            yield put(actions.getFailure(errorMessageGenerator(e)));
        }
    },

    *createReference({ payload }) {
        try {
            const { worker_id, date } = payload;
            const data = {
                ...payload,
                date: format(new Date(date), 'YYYY-MM-DD HH:mm:ss')
            };
            yield axios.post(`workers/references`, data);
            yield delay(500);
            yield put(actions.createSuccess());
            const refernces = yield select(
                (state) => state.app.workers.editAccess.references.view.data
            );
            if (refernces.length === 0) {
                yield put(profileActions.request(worker_id));
            }
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.createFailure(errorMessageGenerator(e)));
        }
    },

    *editReference({ payload }) {
        try {
            const { worker_id, reference_id, date } = payload;
            const data = {
                ...payload,
                date: format(new Date(date), 'YYYY-MM-DD HH:mm:ss')
            };
            yield axios.put(`/workers/references/${reference_id}`, data);
            yield delay(500);
            yield put(actions.updateSuccess());
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.updateFailure(errorMessageGenerator(e)));
        }
    },

    *deleteReference({ payload }) {
        try {
            const { worker_id, reference_id } = payload;
            yield axios.delete(`/workers/references/${reference_id}`);
            yield delay(500);
            yield put(actions.deleteSuccess());
            const refernces = yield select(
                (state) => state.app.workers.editAccess.references.view.data
            );
            if (refernces.length === 1) {
                yield put(profileActions.request(worker_id));
            }
            yield put(actions.get(worker_id));
        } catch (e) {
            yield put(actions.deleteFailure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.getWorkerReferences);
    yield takeLatest(CREATE_REQUEST, sagas.createReference);
    yield takeLatest(EDIT_REQUEST, sagas.editReference);
    yield takeLatest(DELETE_REQUEST, sagas.deleteReference);
};
