import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Actions
 */

import { actions as workerActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
export const REQUEST = '@app/workers/assignAdmin/edit/REQUEST';
export const SUCCESS = '@app/workers/assignAdmin/edit/SUCCESS';
export const FAILURE = '@app/workers/assignAdmin/edit/FAILURE';

/**
 * Initial state
 */
const initState = {
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.assignLoading = true;
                break;
            case SUCCESS:
                draft.worker = payload.data;
                draft.assignLoading = false;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.assignLoading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.put(`/workers/admin/${payload.worker_id}`, {
                admin_id: payload.admin_id
            });
            yield put(actions.success(response.data));
            yield put(workerActions.success(response.data));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
