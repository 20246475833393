import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const GET_REQUEST = '@app/workers/activity/GET_REQUEST';
export const GET_SUCCESS = '@app/workers/activity/GET_SUCCESS';
export const GET_FAILURE = '@app/workers/activity/GET_FAILURE';
export const RESET = '@app/workers/activity/RESET';

/**
 * Initial State
 */

const initialState = {
    items: [],
    last: 1,
    errorStatus: '',
    loading: false,
    total: 0
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case GET_SUCCESS:
                draft.loading = false;
                draft.items = [...draft.items, ...payload.items];
                draft.last = payload.last;
                draft.total = payload.total;
                break;
            case GET_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case RESET:
                draft.items = [];
                draft.loading = false;
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    request: (data) => creator(GET_REQUEST, data),
    failure: (data) => creator(GET_FAILURE, data),
    success: (data) => creator(GET_SUCCESS, data),

    reset: () => creator(RESET)
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(
                `/workers/activity-logs/${payload.worker_id}?page=${payload.page}`
            );
            yield put(
                actions.success({
                    items: response.data.data,
                    last: response.data.meta.last_page,
                    total: response.data.meta.total
                })
            );
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.request);
};
