import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const GET_REQUEST = '@app/company/tabs/salary/GET_REQUEST';
export const GET_SUCCESS = '@app/company/tabs/salary/GET_SUCCESS';
export const GET_FAILURE = '@app/company/tabs/salary/GET_FAILURE';
export const SEARCH_REQUEST = '@app/company/tabs/salary/SEARCH_REQUEST';

export const OPEN_MODAL = '@app/company/tabs/salary/modal/OPEN_MODAL';
export const CLOSE_MODAL = '@app/company/tabs/salary/modal/CLOSE_MODAL';
export const CHANGE_WORKER_NAME = '@app/company/tabs/salary/modal/CHANGE_WORKER_NAME';

export const POST_REQUEST = '@app/company/tabs/salary/modal/POST_REQUEST';
export const POST_SUCCESS = '@app/company/tabs/salary/modal/POST_SUCCESS';
export const POST_FAILURE = '@app/company/tabs/salary/modal/POST_FAILURE';

/**
 * Initial state
 */

const initialViewState = {
    data: [],
    loading: false,
    errorStatus: ''
};

const initialModalState = {
    show: false,
    loading: false,
    errorStatus: '',
    formData: {
        worker_name: '',
        worker_id: '',
        hourly_basic_salary: '',
        daily_basic_salary: '',
        seniority: ''
    }
};

const initState = {
    modal: initialModalState,
    view: initialViewState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
            case SEARCH_REQUEST:
                draft.view.loading = true;
                break;
            case GET_SUCCESS:
                draft.view.data = payload.data;
                draft.view.loading = false;
                break;
            case GET_FAILURE:
                draft.view.errorStatus = payload;
                draft.view.loading = false;
                break;
            case OPEN_MODAL:
                draft.modal.show = true;
                draft.modal.formData = payload || initialModalState.formData;
                draft.modal.loading = false;
                draft.modal.errorStatus = '';
                break;
            case CLOSE_MODAL:
                draft.modal = initialModalState;
                break;
            case CHANGE_WORKER_NAME:
                draft.modal.formData.worker_name = payload;
                break;
            case POST_REQUEST:
                draft.modal.loading = true;
                draft.modal.errorStatus = '';
                break;
            case POST_SUCCESS:
                let exists = draft.view.data.find((el) => el.id === payload.id);
                let data = [];

                if (exists)
                    data = draft.view.data.map((el) =>
                        el.id === payload.id ? { ...payload } : el
                    );
                else data = [payload, ...draft.view.data];

                draft.modal.loading = false;
                draft.view.data = data;
                break;
            case POST_FAILURE:
                draft.modal.loading = false;
                draft.modal.errorStatus = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(GET_REQUEST, data),
    success: (data) => creator(GET_SUCCESS, data),
    failure: (data) => creator(GET_FAILURE, data),
    openModal: (data) => creator(OPEN_MODAL, data),
    closeModal: () => creator(CLOSE_MODAL),
    changeWorkerName: (data) => creator(CHANGE_WORKER_NAME, data),
    postRequest: (data) => creator(POST_REQUEST, data),
    postSuccess: (data) => creator(POST_SUCCESS, data),
    postFailure: (data) => creator(POST_FAILURE, data),
    search: (data) => creator(SEARCH_REQUEST, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(`/companies/salaries/${payload}`);
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },

    *post({ payload }) {
        try {
            const { companyID, worker_id } = payload;
            const response = yield axios.post(
                `/companies/salaries/${worker_id}/${companyID}`,
                payload
            );
            yield put(actions.postSuccess(response.data.data));
            yield put(actions.closeModal());
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.postFailure(error));
        }
    },

    *search({ payload }) {
        try {
            const { companyID, query } = payload;
            const response = yield axios.get(
                `/companies/salaries/search/${companyID}?query=${query}`
            );
            yield put(actions.success(response.data));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.request);
    yield takeLatest(POST_REQUEST, sagas.post);
    yield takeLatest(SEARCH_REQUEST, sagas.search);
};
