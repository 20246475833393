import React from 'react';
import { PageSpinner } from 'uwork-app-core';
import loadable from '@loadable/component';
const Fallback = <PageSpinner />;

/**
 * Company pages
 */
export const CompanyShow = loadable(() => import('pages/companies/CompanyShowPage'), {
    fallback: Fallback
});

export const Companies = loadable(() => import('pages/companies/CompaniesPage'), {
    fallback: Fallback
});

export const CompanyPage = loadable(() => import('pages/CompanyPage'), {
    fallback: Fallback
});

export const DepartmentPage = loadable(() => import('./DepartmentPage'), {
    fallback: Fallback
});

export const CompanyEditAccount = loadable(
    () => import('pages/companies/CompaniesEditAccountPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditProfileUploads = loadable(
    () => import('pages/companies/CompaniesEditProfileUploadsPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditIndustries = loadable(
    () => import('pages/companies/CompaniesEditIndustriesPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditAbout = loadable(() => import('pages/companies/CompaniesEditAboutPage'), {
    fallback: Fallback
});

export const CompanyEditMarkupFeePage = loadable(() => import('pages/companies/MarkupFeePage'), {
    fallback: Fallback
});

export const CompanyEditSalaryRelationPage = loadable(
    () => import('./companies/CompanySalaryRelationPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryMatrixPage = loadable(() => import('./companies/SalaryMatrixPage'), {
    fallback: Fallback
});

export const CompanyEditSalaryRulePage = loadable(() => import('./companies/SalaryRulePage'), {
    fallback: Fallback
});

export const CompanyEditSalaryRuleClockAdditionPage = loadable(
    () => import('./companies/SalaryRuleClockAdditionPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryRuleDateAdditionPage = loadable(
    () => import('./companies/SalaryRuleDateAdditionPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryRuleLengthAdditionPage = loadable(
    () => import('./companies/SalaryRuleLengthAdditionPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryRuleWeekdayAdditionPage = loadable(
    () => import('./companies/SalaryRuleWeekdayAdditionPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryResultVersionPage = loadable(
    () => import('./companies/SalaryResultVersionPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryResultVersionDiffPage = loadable(
    () => import('./companies/SalaryResultVersionDiffPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditOrderShiftTypes = loadable(
    () => import('pages/companies/CompaniesEditOrderShiftTypesPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditNDA = loadable(() => import('pages/companies/CompaniesEditNDAPage'), {
    fallback: Fallback
});

export const CompanyEditBreaks = loadable(() => import('pages/companies/CompaniesEditBreaksPage'), {
    fallback: Fallback
});

export const CompanyEditWorkingHourScheme = loadable(
    () => import('pages/companies/CompaniesEditWorkingHourScheme'),
    {
        fallback: Fallback
    }
);

export const CompanyEditCollectiveAgreement = loadable(
    () => import('pages/companies/CompaniesEditCollectiveAgreement'),
    {
        fallback: Fallback
    }
);

export const CompanyEditContract = loadable(
    () => import('pages/companies/CompaniesEditContractPage'),
    {
        fallback: Fallback
    }
);

export const CompanyEditSalaryConditions = loadable(
    () => import('pages/companies/CompaniesEditSalaryConditionsPage'),
    {
        fallback: Fallback
    }
);

/**
 * Departments page
 */
export const DepartmentsShow = loadable(() => import('pages/departments/DepartmentsShowPage'), {
    fallback: Fallback
});

/**
 * Workers page
 */
export const WorkersProfileFeed = loadable(() => import('pages/workers/WorkerFeedController'), {
    fallback: Fallback
});

export const WorkerAccount = loadable(() => import('pages/workers/WorkersProfileAccountPage'), {
    fallback: Fallback
});

export const WorkerPersonalInformation = loadable(
    () => import('pages/workers/WorkerProfilePersonalInfoPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileUpload = loadable(() => import('pages/workers/WorkerProfileUploadPage'), {
    fallback: Fallback
});

export const WorkerProfilePayment = loadable(
    () => import('pages/workers/WorkerProfilePaymentPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileShifts = loadable(() => import('pages/workers/WorkerProfileShiftsPage'), {
    fallback: Fallback
});

export const WorkerProfileExport = loadable(() => import('pages/workers/WorkerProfileExportPage'), {
    fallback: Fallback
});

export const WorkerProfileSalaryRelation = loadable(
    () => import('./workers/WorkerProfileSalaryRelationPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileAttachments = loadable(
    () => import('pages/workers/WorkerProfileAttachmentPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileGCC = loadable(() => import('pages/workers/WorkerProfileGCCPage'), {
    fallback: Fallback
});

export const WorkerProfileAgreements = loadable(
    () => import('pages/workers/WorkerProfileAgreementsPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileIndustryPage = loadable(
    () => import('pages/workers/WorkerProfileIndustryPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileAdvancedSettings = loadable(
    () => import('pages/workers/WorkerProfileAdvancedSettingsPage'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileCoursesAndSkills = loadable(
    () => import('pages/workers/WorkerProfileCoursesAndSkillsSettings'),
    {
        fallback: Fallback
    }
);

export const WorkerProfileNotifications = loadable(
    () => import('pages/workers/WorkerProfileNotificationsPage'),
    {
        fallback: Fallback
    }
);
export const WorkersFilterPage = loadable(() => import('pages/workers/WorkersFilterPage'), {
    fallback: Fallback
});

export const WorkersAvailabilityPage = loadable(() => import('./workers/WorkersAvailabilityPage'), {
    fallback: Fallback
});

export const RecommendationsFilterPage = loadable(
    () => import('pages/recommendations/RecommendationsFilterPage'),
    {
        fallback: Fallback
    }
);

export const NewRecommendation = loadable(() => import('pages/recommendations/NewRecommendation'), {
    fallback: Fallback
});

/**
 * Orders pages
 */
export const SelectNewOrder = loadable(() => import('pages/orders/SelectNewOrderPage'), {
    fallback: Fallback
});

export const NewOrderManager = loadable(() => import('pages/orders/NewOrderManager'), {
    fallback: Fallback
});

export const NewOrderNewOrderManager = loadable(() => import('pages/orders/NewOrderPage'), {
    fallback: Fallback
});

export const ActiveOrders = loadable(() => import('pages/orders/ActiveOrdersPage'), {
    fallback: Fallback
});

export const CompletedOrders = loadable(() => import('pages/orders/CompletedOrdersPage'), {
    fallback: Fallback
});

export const OngoingOrders = loadable(() => import('pages/orders/OngoingOrdersPage'), {
    fallback: Fallback
});

export const OrderDetails = loadable(() => import('pages/orders/OrderPage'), {
    fallback: Fallback
});

export const OrderViewPage = loadable(() => import('pages/orders/ViewOrderPage'), {
    fallback: Fallback
});

export const SelectWorkers = loadable(() => import('pages/orders/SelectWorkerFilterPage'), {
    fallback: Fallback
});

/**
 * Administration pages
 */
export const Guidelines = loadable(() => import('pages/administration/GuidelinesPage'), {
    fallback: Fallback
});

export const Skills = loadable(() => import('pages/administration/SkillsPage'), {
    fallback: Fallback
});

export const Courses = loadable(() => import('pages/administration/CoursesPage'), {
    fallback: Fallback
});

export const Titles = loadable(() => import('pages/administration/TitlesPage'), {
    fallback: Fallback
});

export const Tags = loadable(() => import('pages/administration/TagsPage'), {
    fallback: Fallback
});

export const Badges = loadable(() => import('pages/administration/BadgesPage'), {
    fallback: Fallback
});

export const EmailLogs = loadable(() => import('pages/administration/EmailLogsPage'), {
    fallback: Fallback
});

export const SingleEmailLog = loadable(() => import('pages/administration/SingleEmailLogPage'), {
    fallback: Fallback
});

/**
 * Notificaitons Page
 */
export const Notifications = loadable(() => import('pages/notifications/NotificationsPage'), {
    fallback: Fallback
});

/**
 * Timesheet pages
 */
export const TimesheetLegacy = loadable(() => import('pages/TimesheetLegacyPage'), {
    fallback: Fallback
});

export const TimesheetPage = loadable(() => import('pages/TimesheetPage'), {
    fallback: Fallback
});

/**
 * Overtime pages
 */
export const OvertimePage = loadable(() => import('pages/OvertimePage'), {
    fallback: Fallback
});

/**
 * Settings Pages
 */
export const AccountSettings = loadable(() => import('pages/settings/AccountSettingsPage'), {
    fallback: Fallback
});

/**
 * Error Pages
 */
export const NotFound = loadable(() => import('pages/error/NotFound'), {
    fallback: Fallback
});

/**
 * Example Page
 */
export const Example = loadable(() => import('pages/ExamplePage'), {
    fallback: Fallback
});

/**
 * Dashboard
 */
export const Dashboard = loadable(() => import('pages/DashboardPage'), {
    fallback: Fallback
});

/**
 * Metrics
 */
export const Metrics = loadable(() => import('pages/MetricsPage'), {
    fallback: Fallback
});

// order page
export const ViewOrderPage = loadable(() => import('pages/orders/ViewOrderPage'), {
    fallback: Fallback
});

// playground
export const Playground = loadable(() => import('pages/Playgroud'), {
    fallback: Fallback
});

// departments 2.0
export const DeptPatientInformation = loadable(
    () => import('pages/departments/settings/PatientsInformation'),
    {
        fallback: Fallback
    }
);

export const DeptGlobalInformation = loadable(
    () => import('pages/departments/settings/DepartmentInfomation'),
    {
        fallback: Fallback
    }
);

export const DeptEditOrderShiftType = loadable(
    () => import('pages/departments/settings/DepartmentEditOrderShiftTypesPage'),
    {
        fallback: Fallback
    }
);
