import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Actions
 */
import { actions as getTeamMemberActions } from 'sagas/app/companies/fetchTeamMembersSaga';

/**
 * Constants
 */
const REQUEST = '@app/company/team/addNewMember/REQUEST';
const SUCCESS = '@app/company/team/addNewMember/SUCCESS';
const FAILURE = '@app/company/team/addNewMember/FAILURE';
const RESET = '@app/company/team/addNewMember/RESET';

/**
 * Initial state
 */
export const initialMember = {
    full_name: '',
    email: '',
    role: '',
    companies: []
};
const initState = {
    loading: false,
    errorStatus: '',
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                draft.success = null;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.success = true;
                break;
            case FAILURE:
                draft.loading = false;
                draft.success = false;
                draft.errorStatus = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.errorStatus = '';
                draft.success = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            if (payload.mode === 0) {
                // add
                yield axios.post('/companies/users', payload);
            } else {
                //edit
                yield axios.put(`/companies/users/${payload.userId}`, payload);
            }

            yield put(actions.success());
            yield put(getTeamMemberActions.request(payload.rf_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
