import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofile/gcc/categories/REQUEST';
const SUCCESS = '@app/workers/workersprofile/gcc/categories/SUCCESS';
const FAILURE = '@app/workers/workersprofile/gcc/categories/FAILURE';

/**
 * Initial state
 */
const initState = {
    categoriesLoading: false,
    categoriesErrorStatus: '',
    categories: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.categoriesLoading = true;
                break;
            case FAILURE:
                draft.categoriesLoading = false;
                draft.categoriesErrorStatus = payload;
                break;
            case SUCCESS:
                draft.categoriesLoading = false;
                draft.categoriesErrorStatus = '';
                draft.categories = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request() {
        try {
            const response = yield axios.get(`/categories`);
            const remapedCategories = response.data.data.map((el) => ({
                ...el,
                title: el.name
            }));
            yield put(actions.success(remapedCategories));
        } catch (e) {
            yield put(actions.failure('Submit failed, please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
