import { unstable_batchedUpdates } from 'react-dom';
import produce from 'immer';
import { put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import format from 'utils/customTimeFormat';
import { actions as orderFetchActions } from './orderSaga';
import { useOvertimeCreateStore } from '../../../zustand-store/overtimeCreateStore';
import { queryClient } from '../../../utils/rq';
import { storeOvertime } from '../../../rq/mutations/storeOvertime';

/**
 * Constants
 */
// order consts
export const CREATE_ORDER = '@app/orders/temporary/create/CREATE_ORDER';
export const REQUEST = '@app/orders/temporary/REQUEST';
export const SUCCESS = '@app/orders/temporary/SUCCESS';
export const FAILURE = '@app/orders/temporary/FAILURE';
export const CLEAR = '@app/orders/temporary/CLEAR';

// temoporary consts
export const SAVE_FILTER_QUERY = '@app/orders/temporary/SAVE_FILTER_QUERY';
export const CHANGE_FIELD = '@app/orders/temporary/CHANGE_FIELD';
export const CHANGE_TYPE = '@app/orders/temporary/CHANGE_TYPE';

export const INVITE_WORKER_REQUEST = '@app/orders/temporary/invite/REQUEST';
export const INVITE_WORKER_FAILURE = '@app/orders/temporary/invite/FAILURE';
export const INVITE_WORKER_SUCCESS = '@app/orders/temporary/invite/SUCCESS';

/**
 * Initial state
 */
const orderTypes = {
    'legg-ut-vakt': 50,
    'direkte-bestilling': 60,
    'legg-til-vakter-i-ettertid': 70,
    'turnus-bestilling': 80
};

const initState = {
    dirty: false,
    orderType: 'legg-ut-vakt',
    order_shifts: [],
    shift_type_id: null,
    description: '',
    selectedCompany: null,
    selectedDepartment: null,
    selectedWorkers: [],
    orderCreatorId: null,
    notify: 1,
    bonus_amount: null,
    note_ccu_rights_regulation: '',
    filterQuery: '',
    loading: false,
    errorStatus: '',
    type: 50
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHANGE_FIELD:
                draft[payload.field] = payload.value;
                draft.dirty = true;
                break;
            case CHANGE_TYPE:
                if (payload !== draft.orderType) {
                    draft.dirty = false;
                    draft.orderType = payload;
                    draft.order_shifts = [];
                    draft.shift_type_id = null;
                    draft.description = '';
                    draft.note_ccu_rights_regulation = '';
                    draft.selectedCompany = null;
                    draft.selectedDepartment = null;
                    draft.selectedWorkers = [];
                    draft.orderCreatorId = null;
                    draft.notify = 1;
                    draft.bonus_amount = null;
                    draft.is_skilled_worker_loan = null;
                    draft.filterQuery = '';
                    draft.loading = false;
                    draft.errorStatus = '';
                    draft.type = orderTypes[payload];
                }
                break;
            case CLEAR:
                draft.orderType = 'legg-ut-vakt';
                draft.order_shifts = [];
                draft.shift_type_id = null;
                draft.description = '';
                draft.note_ccu_rights_regulation = '';
                draft.selectedCompany = null;
                draft.selectedDepartment = null;
                draft.selectedWorkers = [];
                draft.orderCreatorId = null;
                draft.notify = 1;
                draft.bonus_amount = null;
                draft.is_skilled_worker_loan = null;
                draft.filterQuery = '';
                draft.dirty = false;
                draft.loading = false;
                draft.errorStatus = '';
                draft.type = 50;
                break;

            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case SUCCESS:
                draft.loading = false;
                break;

            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: () => creator(SUCCESS),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),
    saveFilterQuery: (data) => creator(SAVE_FILTER_QUERY, data),
    changeOrderFiled: (data) => creator(CHANGE_FIELD, data),
    changeOrderType: (data) => creator(CHANGE_TYPE, data),
    createOrder: (data) => creator(CREATE_ORDER, data),
    inviteWorker: (data) => creator(INVITE_WORKER_REQUEST, data),
    inviteFailure: (data) => creator(INVITE_WORKER_FAILURE, data),
    inviteSuccess: (data) => creator(INVITE_WORKER_SUCCESS, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield put(actions.createOrder(payload));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },

    *createOrder({ payload }) {
        try {
            const { company_id, type, worker_id, user_id, order_shifts } = payload;

            const formatedShifts = order_shifts.map((el) => ({
                ...el,
                from: format(new Date(el.from), 'YYYY-MM-DD HH:mm:ss'),
                to: format(new Date(el.to), 'YYYY-MM-DD HH:mm:ss')
            }));

            const response = yield axios.post(`/orders?company_id=${company_id}`, {
                ...payload,
                order_shifts: formatedShifts
            });

            yield put(actions.success());
            yield put(actions.clear());

            if (type !== 50) {
                yield put(
                    actions.inviteWorker({
                        worker_id,
                        user_id,
                        order_id: response.data.data.id
                    })
                );

                const { billableType, overtimeData, billableCompanyId, workerId } =
                    yield useOvertimeCreateStore.getState();

                if (overtimeData.length > 0) {
                    const overtime = {
                        order_id: response.data.data.id,
                        worker_id: workerId,
                        billable_type: billableType,
                        billable_company_id: billableCompanyId,
                        shifts: overtimeData
                    };

                    yield queryClient.fetchQuery(['overtime-store', overtime.order_id], () =>
                        storeOvertime(overtime)
                    );
                }
            }

            yield put(push(`/orders/view/${response.data.data.id}`));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },

    *addWorkerToOrder({ payload }) {
        try {
            const { order_id, user_id } = payload;
            yield axios.put(`/orders/workers/${order_id}`, { user_id });
            yield put(actions.inviteSuccess(payload));
            yield put(orderFetchActions.request(order_id));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.inviteFailure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(CREATE_ORDER, sagas.createOrder);
    yield takeLatest(INVITE_WORKER_REQUEST, sagas.addWorkerToOrder);
};
