import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/administration/guidelines/companies/REQUEST';
export const SUCCESS = '@app/administration/guidelines/companies/SUCCESS';
export const FAILURE = '@app/administration/guidelines/companies/FAILURE';
export const CLEAR = '@app/administration/guidelines/companies/CLEAR';

/**
 * Initial State
 */
const initialState = {
    loading: true,
    companies: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.error = payload;
                break;
            case CLEAR:
                draft.companies = [];
                break;
            case SUCCESS:
                draft.companies = payload;
                draft.loading = false;
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

export const sagas = {
    *get({ payload }) {
        try {
            yield put(actions.clear());
            const response = yield axios.get(`/companies?query=${payload || 'A'}`);
            yield put(actions.success(response.data.data));
        } catch (e) {
            yield put(actions.failure('Oops something went wrong'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.get);
};
