import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/workers/workermapfiltersaga/REQUEST';
export const ZOOM = '@app/workers/workermapfiltersaga/ZOOM';
export const SUCCESS = '@app/workers/workermapfiltersaga/SUCCESS';
export const SUCCESS_ZOOM = '@app/workers/workermapfiltersaga/SUCCESS_ZOOM';
export const FAILURE = '@app/workers/workermapfiltersaga/FAILURE';
export const CLEAR = '@app/workers/workermapfiltersaga/CLEAR';
export const CLEAR_LOCATION = '@app/workers/workermapfiltersaga/CLEAR_LOCATION';

/**
 * Initial state
 */
const initState = {
    items: {},
    latitude: [],
    longitude: [],
    center: {},
    zoom: 0,
    loading: false,
    errorStatus: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                draft.latitude = payload.lat;
                draft.longitude = payload.lng;
                draft.center = payload.center;
                draft.loading = false;
                break;
            case SUCCESS_ZOOM:
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case ZOOM:
                draft.zoom = payload;
                break;
            case CLEAR:
                draft.items = [];
                draft.loading = false;
                draft.latitude = [];
                draft.longitude = [];
                draft.center = {};
                draft.zoom = 0;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    zoom: (data) => creator(ZOOM, data),
    success: (data) => creator(SUCCESS, data),
    success_zoom: (data) => creator(SUCCESS_ZOOM, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),
    clearLocation: () => creator(CLEAR_LOCATION)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield put(creator(CLEAR));
            yield put(actions.success(payload));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    },
    *zoom({ payload }) {
        try {
            yield put(actions.success_zoom({ zoom: payload }));
        } catch (error) {
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
    },
    *clearLocation() {
        try {
            yield put(actions.clear());
        } catch (error) {
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(ZOOM, sagas.zoom);
    yield takeLatest(CLEAR_LOCATION, sagas.clearLocation);
};
