import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Actions import
 */
import { actions as userActions } from 'sagas/app/auth/userSaga';

/**
 * Components import
 */
import { PageSpinner } from 'uwork-app-core';
import { redirectToAuth } from 'utils/redirectToAuth';

function PrivateContent(props) {
    const { dispatch, logedIn, me, children } = props;

    /**
     * Effects
     */
    useEffect(() => {
        if (logedIn && !me) {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('token');
            window.history.pushState(null, '', window.location.pathname + queryParams.toString());
            dispatch(userActions.getMe());
        }
    }, [dispatch, logedIn, me]);

    // if not loged in
    if (!logedIn) {
        return redirectToAuth();
    }

    // if still no me
    if (!me) {
        return <PageSpinner />;
    }

    // if everythign ok return children
    return children;
}

PrivateContent.defaultProps = {
    me: null
};

PrivateContent.propTypes = {
    children: PropTypes.element.isRequired,
    logedIn: PropTypes.bool.isRequired,
    me: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    logedIn: state.app.user.logedIn,
    me: state.app.user && state.app.user.me
});

const connectedPrivateContent = connect(mapStateToProps)(PrivateContent);

export default withRouter(connectedPrivateContent);
