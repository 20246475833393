import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/companies/switchdepartment/companiesplacessaga/REQUEST';
export const SUCCESS = '@app/companies/switchdepartment/companiesplacessaga/SUCCESS';
export const FAILURE = '@app/companies/switchdepartment/companiesplacessaga/FAILURE';
export const CLEAR = '@app/companies/switchdepartment/companiesplacessaga/CLEAR';

export const OPEN_MODAL = '@app/companies/switchdepartment/companiesplacessaga/OPEN_MODAL';
export const CLOSE_MODAL = '@app/companies/switchdepartment/companiesplacessaga/CLOSE_MODAL';

/**
 * Initial state
 */
const initialModalState = {
    show: false,
    loading: false,
    errorStatus: '',
    formData: {
        worker_name: '',
        worker_id: '',
        hourly_basic_salary: '',
        daily_basic_salary: '',
        seniority: ''
    }
};

const initState = {
    modal: initialModalState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.placeItems = [];
                break;
            case OPEN_MODAL:
                draft.modal.show = true;
                draft.modal.formData = payload || initialModalState.formData;
                draft.modal.loading = false;
                draft.modal.errorStatus = '';
                break;
            case CLOSE_MODAL:
                draft.modal = initialModalState;
                break;
            case SUCCESS:
                draft.placeItems = payload;
                draft.modal.show = false;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    openModal: (data) => creator(OPEN_MODAL, data),
    closeModal: () => creator(CLOSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield put(creator(CLEAR));
            const response = yield axios.post(
                `/companies/merge/${payload.parentId}/${payload.departmentId}`
            );
            yield put(actions.success(response));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
