import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Actions
 */
import { actions as companyActions } from 'sagas/app/companies/companySaga';

/**
 * Constants
 */
const REQUEST = '@app/company/edit/parent/profile-uploads/REQUEST';
const SUCCESS = '@app/company/edit/parent/profile-uploads/SUCCESS';
const FAILURE = '@app/company/edit/parent/profile-uploads/FAILURE';

const DELETE_REQUEST = '@app/company/edit/parent/profile-uploads/DELETE_REQUEST';
const DELETE_SUCCESS = '@app/company/edit/parent/profile-uploads/DELETE_SUCCESS';
const DELETE_FAILURE = '@app/company/edit/parent/profile-uploads/DELETE_FAILURE';

const RESET = '@app/company/edit/parent/profile-uploads/RESET';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                draft.success = null;
                break;
            case DELETE_REQUEST:
                draft.loading = true;
                break;
            case DELETE_SUCCESS:
                draft.loading = false;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.errorStatus = '';
                draft.success = true;
                break;
            case DELETE_FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case FAILURE:
                draft.loading = false;
                draft.success = false;
                draft.errorStatus = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.success = null;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),

    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),

    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const settings = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const data = new FormData();

            data.append('avatar', payload.file, payload.file.name);

            yield axios.post(`/companies/avatar/${payload.company_id}`, data, settings);

            yield put(actions.success());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *delete_request({ payload }) {
        try {
            yield axios.delete(`/companies/avatar/${payload.company_id}`);

            yield put(actions.delete_success());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.delete_failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(DELETE_REQUEST, sagas.delete_request);
};
