import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofile/interviewMapping/templates/get/REQUEST';
const SUCCESS = '@app/workers/workersprofile/interviewMapping/templates/get/SUCCESS';
const FAILURE = '@app/workers/workersprofile/interviewMapping/templates/get/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: false,
    data: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = false;
                draft.data = [];
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = true;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request() {
        try {
            const response = yield axios.get(`/workers/screening-templates?type=3`);
            yield put(actions.success(response.data.data));
        } catch (e) {
            console.error(e);
            yield put(actions.failure());
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
