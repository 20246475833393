import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Actions
 */
import { actions as userActions } from 'sagas/app/auth/userSaga';

/**
 * Constants
 */

const REQUEST = '@app/notificationSwitch/REQUEST';
const SUCCESS = '@app/notificationSwitch/SUCCESS';
const FAILURE = '@app/notificationSwitch/FAILURE';
const TOGGLE = '@app/notificationSwitch/TOGGLE';
const DISABLE_SWITCH = '@app/notificationSwitch/DISABLE_SWITCH';

/**
 * Initial state
 */
const initState = {
    enabled: true,
    disableSwitch: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TOGGLE:
                draft.enabled = payload;
                break;
            case DISABLE_SWITCH:
                draft.disableSwitch = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(DISABLE_SWITCH, true));
        try {
            yield put(userActions.cahngeNotificationStatus(payload));
            // if enable
            if (payload) {
                yield axios.put('/user/update-notification/1');
            } else {
                yield axios.put('/user/update-notification/10');
            }
            yield put(creator(DISABLE_SWITCH, false));
        } catch (e) {
            console.error(e);
            yield put(userActions.cahngeNotificationStatus(!payload));
            yield put(creator(DISABLE_SWITCH, false));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
