import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// import config
import { NAVBAR_SIDEBAR_BREAKPOINT, NAVBAR_SIDEBAR_CLOSED_BREAKPOINT } from 'config';
import { REACT_APP_ENV } from 'config/env';

// import components
import { Menu, Nav, userRoleTypes as ROLES } from 'uwork-app-core';

// import actions
import { actions as userActions } from 'sagas/app/auth/userSaga';
import { actions as notificationActions } from 'sagas/app/notifications/notificationsSaga';
import { actions as navigationActions } from 'sagas/app/navigation';
import { actions as uiActions } from 'sagas/app/uiSaga';

const Navigation = (props) => {
    // deconstruct props
    const { dispatch, mobileView } = props;

    // if not production add class dev-menu
    const productionMenu = REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'production';

    /**
     * Effects
     */
    // on resize custom hook effect
    useEffect(() => {
        const resizeEventFunc = () => {
            const _windowWidth = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            );

            // if screen is not mobile && mboile view on
            // toggle mobile view
            if (_windowWidth >= NAVBAR_SIDEBAR_BREAKPOINT && props.mobileView) {
                dispatch(uiActions.toggleMobileView(false));
            }

            // if screen is mobile && mobile view off
            // toggle mobile view
            if (_windowWidth < NAVBAR_SIDEBAR_BREAKPOINT && !props.mobileView) {
                dispatch(uiActions.toggleMobileView(true));
            }

            if (_windowWidth < NAVBAR_SIDEBAR_CLOSED_BREAKPOINT && !props.mobileView) {
                dispatch(navigationActions.navToggle(false));
            }
        };

        // init functions
        resizeEventFunc();

        // add event listeners
        window.addEventListener('resize', resizeEventFunc);

        // on component unmout clenaup
        return () => {
            window.removeEventListener('resize', resizeEventFunc);
        };
    }, [dispatch, props.mobileView]);

    /**
     * Handlers
     */
    const handleNavToggle = (show = null) => {
        dispatch(navigationActions.navToggle(show));
    };

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(userActions.logout());
    };

    const handleNavItemClick = (slug) => {
        dispatch(navigationActions.setActiveSlug(slug));

        // if menu closed and a menu item widh dropdown is clicked
        // open menu to dropdown list
        if (!mobileView && slug.hasDropdown && !props.nav.showNav) {
            handleNavToggle(true);
        }

        if (mobileView && (slug.to !== '' || slug.dropdownActiveItemSlug !== '')) {
            handleNavToggle(false);
        }
    };

    const handleMarkAllNotificationsAsRead = () => {
        dispatch(notificationActions.markAllAsRead());
    };

    const handleMarkSingleNotificationAsRead = (id) => {
        dispatch(notificationActions.markOneAsRead({ id, page: 1 }));
    };

    // dynamic window width navigation
    // change body padding
    if (!props.logedIn) {
        // if not logedin do not padd body
        document.body.style.paddingLeft = '0px';
        document.body.style.paddingTop = '0px';
        document.body.paddingBottom = '0px';
    } else if (props.nav.showNav && !mobileView) {
        // if show navigation and not mobile view
        document.body.setAttribute('style', 'padding-left:401px !important');
        document.body.style.paddingTop = '0px';
        document.body.paddingBottom = '0px';
    } else if (!props.nav.showNav && !mobileView) {
        // if hide navigation and not moblie view
        document.body.setAttribute('style', 'padding-left:94px !important');
        document.body.style.paddingTop = '0px';
        document.body.paddingBottom = '0px';
    } else if (mobileView) {
        // if mobile view
        document.body.style.paddingLeft = '0px';
        document.body.style.paddingTop = '60px';
        document.body.paddingBottom = '70px';
    }

    return (
        <nav
            className={`navbar navbar-expand-lg navbar-light bg-light fixed-left ${
                props.nav.showNav ? '' : 'menu-only'
            }`}
        >
            <Menu
                mobileView={props.mobileView}
                bodyClassName={`overflow-hidden ${!props.nav.showNav ? 'overflow-y-hover' : ''}`}
                handleLogout={handleLogout}
                avatar={props.avatar || {}}
                admin
                fullName={props.fullName}
                notifications={props.notifications}
                markRead={handleMarkSingleNotificationAsRead}
                handleMarkAllNotificationsAsRead={handleMarkAllNotificationsAsRead}
                isProduction={productionMenu}
            >
                {!props.mobileView ? (
                    <div className="icon-only-nav">
                        <Nav
                            handleItemClick={handleNavItemClick}
                            handleNavToggle={handleNavToggle}
                            {...props.nav}
                            iconOnly
                            userStage={props.userStage}
                            userRole={props.userRole}
                            isProduction={productionMenu}
                        />
                    </div>
                ) : null}
            </Menu>
            <Nav
                handleItemClick={handleNavItemClick}
                handleNavToggle={handleNavToggle}
                userStage={props.userStage}
                userRole={props.userRole}
                {...props.nav}
            />
        </nav>
    );
};

Navigation.defaultProps = {
    avatar: null,
    userStage: 0,
    userRole: ROLES.PARENT
};

Navigation.propTypes = {
    dispatch: PropTypes.func.isRequired,
    mobileView: PropTypes.bool.isRequired,
    logedIn: PropTypes.bool.isRequired,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fullName: PropTypes.string.isRequired,
    notifications: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    userStage: PropTypes.number
};

const mapStateToProps = (state) => ({
    nav: {
        navItems: state.app.ui.navItems,
        activeItem: state.app.navigation.nav.activeItem,
        showNav: state.app.navigation.nav.showNav,
        mobileView: state.app.ui.mobileView
    },
    mobileView: state.app.ui.mobileView,
    logedIn: state.app.user.logedIn,
    avatar: state.app.user.me.avatar,
    fullName: `${state.app.user.me.first_name} ${state.app.user.me.last_name}`,
    notifications: state.app.notifications.data,
    // userRole: state.app.user.me.role,
    userStage: state.app.user.me.stage
});

const connectedComponent = connect(mapStateToProps)(Navigation);
export default withRouter(connectedComponent);
