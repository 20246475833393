import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/timesheet/shifttypessaga/REQUEST';
export const SUCCESS = '@app/timesheet/shifttypessaga/SUCCESS';
export const FAILURE = '@app/timesheet/shifttypessaga/FAILURE';
export const SET_LOADING = '@app/timesheet/shifttypessaga/SET_LOADING';
export const CLEAR = '@app/timesheet/shifttypessaga/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.data;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.items = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request() {
        yield put(creator(SET_LOADING, true));
        try {
            const response = yield axios.get('/orders/shifts');
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
