import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Actions
 */
import { actions as companyActions } from 'sagas/app/companies/companySaga';

/**
 * Constants
 */

// CREATE NEW CONSTS
const CREATE_REQUEST = '@app/company/edit/parent/salary/modal/create/REQUEST';
const CREATE_FAILURE = '@app/company/edit/parent/salary/modal/create/FAILURE';

// UPDATE EXISTING CONSTS
const UPDATE_REQUEST = '@app/company/edit/parent/salary/modal/update/REQUEST';
const UPDATE_FAILURE = '@app/company/edit/parent/salary/modal/update/FAILURE';

// MODAL CONSTS
const OPEN_MODAL = '@app/company/edit/parent/salary/modal/OPEN';
const CLOSE_MODAL = '@app/company/edit/parent/salary/modal/CLOSE';

// RESET CONSTS
const RESET_FORM = '@app/company/edit/parent/salary/modal/RESET';
const RESET_VIEW = '@app/company/edit/parent/salary/delete/RESET';

// DELETE CONSTS
const DELETE_REQUEST = '@app/company/edit/parent/salary/delete/REQUEST';
const DELETE_SUCCESS = '@app/company/edit/parent/salary/delete/SUCCESS';
const DELETE_FAILURE = '@app/company/edit/parent/salary/delete/FAILURE';

/**
 * Initial state
 */
export const initFormData = {
    title: '',
    type: '',
    description: '',
    value: 0
};
const initState = {
    form: {
        add: true,
        modal: false,
        data: initFormData,
        loading: false,
        error: ''
    },
    delete: {
        loading: false,
        error: '',
        success: null
    }
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            // form cases
            case OPEN_MODAL:
                draft.form.modal = true;
                draft.form.data = payload.data;
                draft.form.add = payload.add;
                draft.form.loading = false;
                draft.form.error = '';
                break;
            case CLOSE_MODAL:
            case RESET_FORM:
                draft.form = initState.form;
                break;
            case CREATE_REQUEST:
            case UPDATE_REQUEST:
                draft.form.loading = true;
                draft.form.error = '';
                break;
            case CREATE_FAILURE:
            case UPDATE_FAILURE:
                draft.form.loading = false;
                draft.form.error = payload;
                break;

            //delete cases
            case DELETE_REQUEST:
                draft.delete.loading = true;
                draft.delete.success = null;
                draft.delete.error = '';
                break;
            case DELETE_SUCCESS:
                draft.delete.loading = false;
                draft.delete.success = true;
                break;
            case DELETE_FAILURE:
                draft.delete.loading = false;
                draft.delete.error = payload;
                break;
            case RESET_VIEW:
                draft.delete = initState.delete;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    // create
    create_request: (data) => creator(CREATE_REQUEST, data),
    create_failure: (data) => creator(CREATE_FAILURE, data),

    // update
    update_request: (data) => creator(UPDATE_REQUEST, data),
    update_failure: (data) => creator(UPDATE_FAILURE, data),

    // delete
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),

    // other
    open_modal: (data) => creator(OPEN_MODAL, data),
    close_modal: () => creator(CLOSE_MODAL),
    reset_form: () => creator(RESET_FORM),
    reset_view: () => creator(RESET_VIEW)
};

/**
 * Saga functions
 */
export const sagas = {
    *create({ payload }) {
        try {
            yield axios.post(`/companies/payments`, payload);
            // on success close modal and reset its data - also refetch company
            yield put(actions.close_modal());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.create_failure(errorMessageGenerator(e)));
        }
    },
    *update({ payload }) {
        try {
            yield axios.put(`/companies/payments/${payload.payment_id}`, payload);
            // on success close modal and reset its data - also refetch company
            yield put(actions.close_modal());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.update_failure(errorMessageGenerator(e)));
        }
    },
    *delete({ payload }) {
        try {
            yield axios.delete(`/companies/payments/${payload.payment_id}`);
            yield put(actions.delete_success());
            yield put(companyActions.request(payload.company_id));
        } catch (e) {
            yield put(actions.delete_failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(CREATE_REQUEST, sagas.create);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
    yield takeLatest(UPDATE_REQUEST, sagas.update);
};
