import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import { push } from 'react-router-redux';
import axios from 'utils/axios';

/**
 * External Ations
 */
import { actions as alertActions } from 'sagas/app/alertSaga';
import { actions as recommendationsFilterActions } from './recommendationsFilterSaga';

/**
 * Constants
 */
export const ARCHIVE_REQUEST =
    '@app/workers/recommendUWorker/archiveRecommendation/ARCHIVE_REQUEST';
export const ARCHIVE_SUCCESS =
    '@app/workers/recommendUWorker/archiveRecommendation/ARCHIVE_SUCCESS';
export const ARCHIVE_FAILURE =
    '@app/workers/recommendUWorker/archiveRecommendation/ARCHIVE_FAILURE';

export const UNARCHIVE_REQUEST =
    '@app/workers/recommendUWorker/archiveRecommendation/UNARCHIVE_REQUEST';
export const UNARCHIVE_SUCCESS =
    '@app/workers/recommendUWorker/archiveRecommendation/UNARCHIVE_SUCCESS';
export const UNARCHIVE_FAILURE =
    '@app/workers/recommendUWorker/archiveRecommendation/UNARCHIVE_FAILURE';

export const CLEAR = '@app/workers/recommendUWorker/archiveRecommendation/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: ''
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ARCHIVE_REQUEST:
                draft.loading = true;
                break;
            case UNARCHIVE_REQUEST:
                draft.loading = true;
                break;
            case ARCHIVE_SUCCESS:
                draft.loading = false;
                break;
            case UNARCHIVE_SUCCESS:
                draft.loading = false;
                break;
            case ARCHIVE_FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case UNARCHIVE_FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    archiveRequest: (data) => creator(ARCHIVE_REQUEST, data),
    archiveSuccess: (data) => creator(ARCHIVE_SUCCESS, data),
    archiveFailure: (data) => creator(ARCHIVE_FAILURE, data),
    unarchiveRequest: (data) => creator(UNARCHIVE_REQUEST, data),
    unarchiveSuccess: (data) => creator(UNARCHIVE_SUCCESS, data),
    unarchiveFailure: (data) => creator(UNARCHIVE_FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *archiveRequest({ payload }) {
        try {
            const response = yield axios.put(
                `/workers/worker-recommendations/archive/${payload.workerRecommendationId}`
            );
            yield put(actions.archiveSuccess(response.data));
            yield put(push('/recommendations'));
            yield put(recommendationsFilterActions.request({}));
            yield put(alertActions.success({ message: payload.message }));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *unarchiveRequest({ payload }) {
        try {
            const response = yield axios.put(
                `/workers/worker-recommendations/unarchive/${payload.workerRecommendationId}`
            );
            yield put(actions.unarchiveSuccess(response.data));
            yield put(recommendationsFilterActions.request('?page=1&archived=1'));
            yield put(push('/recommendations?page=1&archived=1'));
            yield put(alertActions.success({ message: payload.message }));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(ARCHIVE_REQUEST, sagas.archiveRequest);
    yield takeLatest(UNARCHIVE_REQUEST, sagas.unarchiveRequest);
};
