import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */

const SET_REQUEST = '@app/workers/workersprofile/presentation/set/SET_REQUEST';
const SET_SUCCESS = '@app/workers/workersprofile/presentation/set/SET_SUCCESS';

const CHECK_REQUEST = '@app/workers/workersprofile/presentation/check/CHECK_REQUEST';
const CHECK_SUCCESS = '@app/workers/workersprofile/presentation/check/CHECK_SUCCESS';

const FAILURE = '@app/workers/workersprofile/presentation/check/FAILURE';
const RESET = '@app/workers/workersprofile/presentation/reset/check/RESET';

/**
 * Initial state
 */
const initState = {
    loading: false,
    data: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHECK_REQUEST:
            case SET_REQUEST:
                draft.loading = true;
                break;
            case CHECK_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            case SET_SUCCESS:
                draft.loading = false;
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.data = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    set_request: (data) => creator(SET_REQUEST, data),
    check_request: (data) => creator(CHECK_REQUEST, data),
    set_success: () => creator(SET_SUCCESS),
    check_success: (data) => creator(CHECK_SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    reset: () => creator(RESET)
};

/**
 * Saga functions
 */
export const sagas = {
    *set_request({ payload }) {
        try {
            const { worker_id } = payload;
            yield axios.post(`/workers/worker-presentations/${worker_id}`);
            yield put(actions.set_success());
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *check_request({ payload }) {
        try {
            const { worker_id } = payload;
            const response = yield axios.get(
                `/workers/worker-presentations/worker-presented/${worker_id}`
            );
            yield put(actions.check_success(response?.data?.data ? response?.data?.data : []));
        } catch (e) {
            console.error(e);
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(SET_REQUEST, sagas.set_request);
    yield takeLatest(CHECK_REQUEST, sagas.check_request);
};
