import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';
import { push } from 'react-router-redux';

/**
 * Constants
 */

const GET_REQUEST = '@app/workers/workersprofile/presentation/get/GET_REQUEST';
const GET_SUCCESS = '@app/workers/workersprofile/presentation/get/GET_SUCCESS';

const UPDATE_REQUEST = '@app/workers/workersprofile/presentation/update/UPDATE_REQUEST';
const UPDATE_SUCCESS = '@app/workers/workersprofile/presentation/update/UPDATE_SUCCESS';

const FAILURE = '@app/workers/workersprofile/presentation/update/FAILURE';
const RESET = '@app/workers/workersprofile/presentation/reset/RESET';

const CLEAR_ERROR = '@app/workers/workersprofile/presentation/update/CLEAR_ERROR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    data: [],
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REQUEST:
            case UPDATE_REQUEST:
                draft.loading = true;
                break;
            case CLEAR_ERROR:
                draft.error = '';
                break;
            case GET_SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            case UPDATE_SUCCESS:
                draft.loading = false;
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.data = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    update_request: (data) => creator(UPDATE_REQUEST, data),
    get_request: (data) => creator(GET_REQUEST, data),
    update_success: () => creator(UPDATE_SUCCESS),
    get_success: (data) => creator(GET_SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    reset: () => creator(RESET),
    clear_alert: () => creator(CLEAR_ERROR)
};

/**
 * Saga functions
 */
export const sagas = {
    *get_request({ payload }) {
        try {
            const { worker_id } = payload;
            const response = yield axios.get(`/workers/worker-presentations/${worker_id}`);
            yield put(actions.get_success(response?.data?.data ? response?.data?.data : []));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },
    *update_request({ payload }) {
        try {
            const { worker_presentation_id, accepted } = payload;
            yield axios.put(`/workers/worker-presentations/${worker_presentation_id}`, {
                accepted: accepted
            });
            yield put(push('/workers'));
            yield put(actions.update_success());
        } catch (e) {
            console.error(e);
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get_request);
    yield takeLatest(UPDATE_REQUEST, sagas.update_request);
};
