import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * External Actions and Constant
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */

const CREATE_REQUEST = '@app/workers/workersprofile/notes/post/REQUEST';
const CREATE_FAILURE = '@app/workers/workersprofile/notes/post/FAILURE';
const CREATE_SUCCESS = '@app/workers/workersprofile/notes/post/SUCCESS';

const EDIT_REQUEST = '@app/workers/workersprofile/notes/put/REQUEST';
const EDIT_FAILURE = '@app/workers/workersprofile/notes/put/FAILURE';
const EDIT_SUCCESS = '@app/workers/workersprofile/notes/put/SUCCESS';

const DELETE_REQUEST = '@app/workers/workersprofile/notes/delete/REQUEST';
const DELETE_FAILURE = '@app/workers/workersprofile/notes/delete/FAILURE';
const DELETE_SUCCESS = '@app/workers/workersprofile/notes/delete/SUCCESS';

const RESET = '@app/workers/workersprofile/notes/RESET';
const OPEN_MODAL = '@app/workers/workersprofile/notes/OPEN_MODAL';
const CLOSE_MODAL = '@app/workers/workersprofile/notes/CLOSE_MODAL';

/**
 * Initial state
 */
const initModalState = {
    show: false,
    loading: false,
    error: '',
    title: '',
    description: '',
    noteId: null,
    mode: 0 // 0-create , 1-edit
};

const initViewState = {
    loading: false,
    error: ''
};

const initState = {
    modal: initModalState,
    view: initViewState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CREATE_REQUEST:
            case EDIT_REQUEST:
                draft.modal.loading = true;
                draft.modal.error = '';
                break;
            case CREATE_FAILURE:
            case EDIT_FAILURE:
                draft.modal.loading = false;
                draft.modal.error = payload;
                break;
            case CREATE_SUCCESS:
            case EDIT_SUCCESS:
                draft.modal = initModalState;
                break;

            case DELETE_REQUEST:
                draft.view.loading = payload.note_id;
                draft.view.error = '';
                break;
            case DELETE_SUCCESS:
                draft.view.loading = false;
                break;
            case DELETE_FAILURE:
                draft.view.loading = false;
                draft.view.error = payload;
                break;

            case OPEN_MODAL:
                draft.modal.loading = false;
                draft.modal.error = '';
                draft.modal.show = true;
                draft.modal.title = payload?.title || '';
                draft.modal.description = payload?.description || '';
                draft.modal.mode = payload?.mode || 0;
                draft.modal.noteId = payload?.id || null;
                break;
            case CLOSE_MODAL:
                draft.modal = initModalState;
                break;
            case RESET:
                draft.view = initViewState;
                draft.modal = initModalState;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    create: (data) => creator(CREATE_REQUEST, data),
    createSuccess: (data) => creator(CREATE_SUCCESS, data),
    createFailure: (data) => creator(CREATE_FAILURE, data),

    update: (data) => creator(EDIT_REQUEST, data),
    updateSuccess: (data) => creator(EDIT_SUCCESS, data),
    updateFailure: (data) => creator(EDIT_FAILURE, data),

    delete: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    deleteFailure: (data) => creator(DELETE_FAILURE, data),

    reset: () => creator(RESET),
    openModal: (data) => creator(OPEN_MODAL, data),
    closeModal: () => creator(CLOSE_MODAL)
};

/**
 * Saga functions
 */
export const sagas = {
    *createNote({ payload }) {
        try {
            const { worker_id } = payload;
            yield axios.post(`/workers/notes`, payload);
            yield put(actions.createSuccess());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.createFailure(errorMessageGenerator(e)));
        }
    },

    *editNote({ payload }) {
        try {
            const { worker_id, note_id } = payload;
            yield axios.put(`/workers/notes/${note_id}`, payload);
            yield put(actions.updateSuccess());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.updateFailure(errorMessageGenerator(e)));
        }
    },

    *deleteNote({ payload }) {
        try {
            const { worker_id, note_id } = payload;
            yield axios.delete(`/workers/notes/${note_id}`);
            yield put(actions.deleteSuccess());
            yield put(profileActions.request(worker_id));
        } catch (e) {
            yield put(actions.deleteFailure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(CREATE_REQUEST, sagas.createNote);
    yield takeLatest(EDIT_REQUEST, sagas.editNote);
    yield takeLatest(DELETE_REQUEST, sagas.deleteNote);
};
