// the composition of navigation config
// should be from array of objects
// with interface ( last two carachters tell the localication)
/*
    navigationEn = [
        {
            to: '{path}', // path as in react router "to" parameter
            name: '{name}',
            slug: '{slug}', // unique key
            icon: 'dashboard' // icon name which should be included in ICON UI Compoonent
            children: [{
                to: '{path}',
                name: '{name}',
                slug: '{slug}' // unique key
            }] // children property should be added only if there are dropdown items
            userStage: [50] // display for users that are in stage
        },
        ...
    ]
*/

/**
 * Utils
 */
import { userRoleTypes as ROLES } from 'uwork-app-core';

export const navigationEn = (t) => [
    {
        name: t('admin.settings.account'),
        slug: '/settings/account',
        to: '/settings/account',
        icon: 'settings-gear',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER, ROLES.MEMBER]
    },
    {
        name: t('admin.settings.uploads'),
        slug: '/settings/profile-uploads',
        to: '/settings/profile-uploads',
        icon: 'camera',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER, ROLES.MEMBER]
    },
    {
        name: t('admin.settings.notifications'),
        slug: '/settings/notifications',
        to: '/settings/notifications',
        icon: 'settings-bell',
        stage: [],
        role: [ROLES.PARENT, ROLES.MANAGER, ROLES.MEMBER]
    }
];

export default {
    en: navigationEn,
    backToDashboard: true
};
