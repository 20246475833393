/**
 * Utils
 */
import { userRoleTypes as ROLES } from 'uwork-app-core';

export const navigationEn = (t) => [
    // {
    //     name: t('admin.edit.company.userInfo'),
    //     slug: '/company/settings/account',
    //     to: '/company/settings/account',
    //     icon: 'settings-gear',
    //     stage: [],
    //     role: [ROLES.PARENT]
    // },
    {
        name: t('admin.edit.company.profilePicture'),
        slug: '/company/settings/profile-uploads',
        to: '/company/settings/profile-uploads',
        icon: 'camera',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.industries'),
        slug: '/company/settings/industries',
        to: '/company/settings/industries',
        icon: 'bulletList',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.about'),
        slug: '/company/settings/about',
        to: '/company/settings/about',
        icon: 'office',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.orderShiftTypes'),
        slug: '/company/settings/order-shift-types',
        to: '/company/settings/order-shift-types',
        icon: 'singleContent',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.nda'),
        slug: '/company/settings/nda',
        to: '/company/settings/nda',
        icon: 'singleContent',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.breaks'),
        slug: '/company/settings/breaks',
        to: '/company/settings/breaks',
        icon: 'restore',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.contract'),
        slug: '/company/settings/contract',
        to: '/company/settings/contract',
        icon: 'singleContent',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.overtime.workinghourscheme.title'),
        slug: '/company/settings/overtime/workinghourscheme',
        to: '/company/settings/overtime/workinghourscheme',
        icon: 'restore',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.overtime.collectiveAgreement.title'),
        slug: '/company/settings/overtime/collectiveagreement',
        to: '/company/settings/overtime/collectiveagreement',
        icon: 'restore',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryConditions'),
        slug: '/company/settings/salary',
        to: '/company/settings/salary',
        icon: 'coins',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryResultVersions'),
        slug: '/company/settings/salary-result-versions',
        to: '/company/settings/salary-result-versions',
        icon: 'restore',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryMatrix'),
        slug: '/company/settings/salary-matrix',
        to: '/company/settings/salary-matrix',
        icon: 'grid-view',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRelation'),
        slug: '/company/settings/salary-relations',
        to: '/company/settings/salary-relations',
        icon: 'dollar',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRules'),
        slug: '/company/settings/salary-rules',
        to: '/company/settings/salary-rules',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRuleClockAdditions'),
        slug: '/company/settings/salary-rules/clock-additions',
        to: '/company/settings/salary-rules/clock-additions',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRuleDateAdditions'),
        slug: '/company/settings/salary-rules/date-additions',
        to: '/company/settings/salary-rules/date-additions',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRuleLengthAdditions'),
        slug: '/company/settings/salary-rules/length-additions',
        to: '/company/settings/salary-rules/length-additions',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.salaryRuleWeekdayAdditions'),
        slug: '/company/settings/salary-rules/weekday-additions',
        to: '/company/settings/salary-rules/weekday-additions',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.company.markupFee'),
        slug: '/company/settings/markup-fee',
        to: '/company/settings/markup-fee',
        icon: 'coins',
        stage: [],
        role: [ROLES.PARENT]
    }
];

export default {
    en: navigationEn,
    backToDashboard: true
};
