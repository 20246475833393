import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Ations
 */
import { actions as orderAction } from './orderSaga';
/**
 * Constants
 */

// ORDER Types
const REQUEST = '@app/orders/notify/REQUEST';
const SUCCESS = '@app/orders/notify/SUCCESS';
const SET_LOADING = '@app/orders/notify/SET_LOADING';
const FAILURE = '@app/orders/notify/FAILURE';
const CLEAR = '@app/orders/notify/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    item: {}
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.item = {};
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case SUCCESS:
                draft.item = payload.data;
                // draft.workerStatus = payload.worker_status;
                draft.loading = false;
                // draft.applicationPending = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        yield put(creator(SET_LOADING, true));
        try {
            yield put(creator(CLEAR));
            const orderResponse = yield axios.post(`/orders/notify/${payload}`);
            yield put(actions.success(orderResponse.data));
            yield put(orderAction.request(payload));
        } catch (e) {
            console.error(e);
            yield put(actions.failure('Failed to fetch data, please contact support!'));
        }
        yield put(creator(SET_LOADING, false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
