const serialize = (obj) => {
    const str = [];
    const objKeys = Object.keys(obj);
    objKeys
        .filter((o) => obj[o])
        .forEach((key) => {
            str.push(`${key}=${obj[key]}`);
        });
    return str.join('&');
};

export default serialize;
