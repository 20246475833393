import produce from 'immer';
import { creator } from 'uwork-app-core';

/**
 * Constants
 */
export const SUCCESS = '@app/alert/SUCCESS';
export const ERROR = '@app/alert/ERROR';
export const CLEAR = '@app/alert/CLEAR';

/**
 * Initial state
 */
const initState = {
    type: null,
    message: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.type = 'success';
                draft.message = payload.message;
                break;
            case ERROR:
                draft.type = 'error';
                draft.message = payload.message;
                break;
            case CLEAR:
                draft.type = null;
                draft.message = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    success: (data) => creator(SUCCESS, data),
    error: (data) => creator(ERROR, data),
    clear: () => creator(CLEAR)
};
