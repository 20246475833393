/**
 * Utils
 */
import { userRoleTypes as ROLES } from 'uwork-app-core';

export const navigationEn = (t) => [
    {
        name: t('admin.edit.worker.personalInfo'),
        slug: '/woker/settings/account',
        to: '/woker/settings/account',
        icon: 'settings-gear',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.industries'),
        slug: '/worker/settings/industries',
        to: '/worker/settings/industries',
        icon: 'office',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.whoAreYou'),
        slug: '/woker/settings/personal-information',
        to: '/woker/settings/personal-information',
        icon: 'profile-circle',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.pictureAndVideo'),
        slug: '/woker/settings/profile-uploads',
        to: '/woker/settings/profile-uploads',
        icon: 'camera',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.gcc.title'),
        slug: '/woker/settings/gcc',
        to: '/woker/settings/gcc',
        icon: 'single-content',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.attachment.header'),
        slug: '/woker/settings/attachments',
        to: '/woker/settings/attachments',
        icon: 'attach',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.coursesAndSkills'),
        slug: '/woker/settings/courses-skills',
        to: '/woker/settings/courses-skills',
        icon: 'books',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.payment'),
        slug: '/woker/settings/payment',
        to: '/woker/settings/payment',
        icon: 'wallet',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.salaryRelation'),
        slug: '/woker/settings/salary-relations',
        to: '/woker/settings/salary-relations',
        icon: 'dollar',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.shiftsAndTravel'),
        slug: '/woker/settings/shift-travel',
        to: '/woker/settings/shift-travel',
        icon: 'night',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.notificationsSettings'),
        slug: '/woker/settings/notifications',
        to: '/woker/settings/notifications',
        icon: 'settings-bell',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.manageData'),
        slug: '/woker/settings/export',
        to: '/woker/settings/export',
        icon: 'analytics',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.agreements'),
        slug: '/woker/settings/agreements',
        to: '/woker/settings/agreements',
        icon: 'file-text',
        stage: [],
        role: [ROLES.PARENT]
    },
    {
        name: t('admin.edit.worker.advancedSettings'),
        slug: '/woker/settings/advanced',
        to: '/woker/settings/advanced',
        icon: 'gear',
        stage: [],
        role: [ROLES.PARENT]
    }
];

export default {
    en: navigationEn,
    backToDashboard: true
};
