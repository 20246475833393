import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { APP_ENV } from '../config/env';
import { SENTRY_DSN } from '../config/sentry';

Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
});
