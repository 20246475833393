import create from 'zustand';
import { OvertimeCreateStoreTypes } from './overtimeCreateStore.types';
import { OvertimeBillableTypeEnum } from '../interfaces/enums/overtime/OvertimeBillableType.enum';
import { OvertimeModel } from '../interfaces/models/Overtime.model';

export const useOvertimeCreateStore = create<OvertimeCreateStoreTypes>((set) => ({
    workerId: null,
    billableCompanyId: null,
    billableType: null,
    overtimeData: [],
    setOvertimeData: (data: OvertimeModel[]) => set(() => ({ overtimeData: data })),
    setWorkerId: (id: number) => set(() => ({ workerId: id })),
    setBillableCompanyId: (id: number) => set(() => ({ billableCompanyId: id })),
    setBillableType: (status: OvertimeBillableTypeEnum) => set(() => ({ billableType: status }))
}));
