import produce from 'immer';
import { put, takeLatest, all } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */

export const REQUEST = '@app/notifications/notificationssaga/REQUEST';
export const SUCCESS = '@app/notifications/notificationssaga/SUCCESS';
export const FAILURE = '@app/notifications/notificationssaga/FAILURE';
export const CLEAR = '@app/notifications/notificationssaga/CLEAR';
export const READ_ALL = '@app/notifications/notificationssaga/READ_ALL';
export const READ_ONE = '@app/notifications/notificationssaga/READ_ONE';

/**
 * Initial state
 */
const initState = {
    read: [],
    unread: [],
    loading: true
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                // mark read and set
                draft.read = payload.read;

                // mark unread and set
                draft.unread = payload.unread;

                draft.allNotifications = [...payload.unread, ...payload.read];

                draft.loading = false;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    markAllAsRead: () => creator(READ_ALL),
    markOneAsRead: (data) => creator(READ_ONE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request() {
        try {
            const [read, unread] = yield all([
                axios.get('/user/notifications/read'),
                axios.get('/user/notifications/unread')
            ]);

            yield put(
                actions.success({
                    read: read.data.data,
                    unread: unread.data.data
                })
            );
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *markAllAsRead() {
        try {
            yield axios.post('/user/notifications/read-all');
            yield put(actions.request());
        } catch (e) {
            console.error(e);
        }
    },

    *markOneAsRead({ payload }) {
        try {
            const { id, page } = payload;
            yield axios.get(`/user/notifications/${id}`);
            yield put(actions.request(page));
        } catch (e) {
            console.error(e);
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(READ_ALL, sagas.markAllAsRead);
    yield takeLatest(READ_ONE, sagas.markOneAsRead);
};
