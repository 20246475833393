import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';
import { PUBLIC_PROFILE_URL } from 'config';

/**
 * Utils import
 */
import { copyToClipboard } from 'utils/copyToClipboard';

/**
 * External Actions
 */

import { actions as workerActions } from 'sagas/app/workers/workersSaga';
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
export const MAKE_PUBLIC = '@app/workers/publicappearance/publicprofile/MAKE_PUBLIC';
export const SHOULD_APPEAR_ON_WEB =
    '@app/workers/publicappearance/publicprofile/SHOULD_APPEAR_ON_WEB';

/**
 * Redux actions
 */
export const actions = {
    makePublic: (data) => creator(MAKE_PUBLIC, data),
    appearOnWeb: (data) => creator(SHOULD_APPEAR_ON_WEB, data)
};

/**
 * Sagas
 */
export const sagas = {
    *makePublic({ payload }) {
        yield put(workerActions.set_loading(true));
        try {
            const { user_id } = payload;
            const response = yield axios.put(`/workers/make-public/${user_id}`, payload);
            yield put(workerActions.toggle_is_public(response.data.data));
            copyToClipboard(response.data.data, payload.prev_public);
            if (payload?.profile) {
                yield put(profileActions.success({ data: payload.profile }));
            } else {
                yield put(
                    workerActions.set_profile_url(
                        `${PUBLIC_PROFILE_URL}/${response.data.data.public_token}`
                    )
                );
            }
        } catch (e) {
            console.error(e);
        }
        yield put(workerActions.set_loading(false));
    },
    *appearOnWeb({ payload }) {
        yield put(workerActions.set_loading(true));
        try {
            const { user_id } = payload;
            const response = yield axios.put(`/workers/display-on-website/${user_id}`, payload);
            if (payload?.profile) {
                yield put(profileActions.success({ data: payload.profile }));
            } else {
                yield put(workerActions.toogle_show_on_web(response.data.data));
            }
        } catch (e) {
            console.error(e);
        }
        yield put(workerActions.set_loading(false));
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(MAKE_PUBLIC, sagas.makePublic);
    yield takeLatest(SHOULD_APPEAR_ON_WEB, sagas.appearOnWeb);
};
