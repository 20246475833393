import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
/**
 * Utils import
 */
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * External Actions
 */
import { actions as profileActions } from 'sagas/app/workers/workersProfileSaga';

/**
 * Constants
 */
export const REQUEST = '@app/workers/workersprofile/accountSettings/blocked_companies/post/REQUEST';
export const SUCCESS = '@app/workers/workersprofile/accountSettings/blocked_companies/post/SUCCESS';
export const FAILURE = '@app/workers/workersprofile/accountSettings/blocked_companies/post/FAILURE';
export const CLEAR = '@app/workers/workersprofile/accountSettings/blocked_companies/post/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case SUCCESS:
                draft.data = payload;
                draft.loading = false;
                break;
            case FAILURE:
                draft.error = payload;
                draft.loading = false;
                break;
            case CLEAR:
                draft.loading = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.post('/company-worker/blocked-relations', payload);
            yield put(
                profileActions.updateSingleParam({
                    key: 'blocked_companies',
                    value: response.data.data.blocked_companies
                })
            );
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
