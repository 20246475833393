import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/workers/workerssaga/simple_search/REQUEST';
export const SUCCESS = '@app/workers/workerssaga/simple_search/SUCCESS';
export const FAILURE = '@app/workers/workerssaga/simple_search/FAILURE';
export const SET_LOADING = '@app/workers/workerssaga/simple_search/SET_LOADING';
export const CLEAR = '@app/workers/workerssaga/simple_search/CLEAR';

/**
 * Initial state
 */
const initState = {
    items: [],
    meta: {
        current_page: 1,
        last_page: 1,
        total: 0
    },
    loading: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload.items.data;
                draft.meta = payload.items.meta;
                draft.loading = false;
                break;
            case FAILURE:
                draft.errorStatus = payload;
                draft.loading = false;
                break;
            case REQUEST:
                draft.loading = true;
                draft.errorStatus = '';
                break;
            case CLEAR:
                draft.items = [];
                draft.meta = {
                    current_page: 1,
                    last_page: 1,
                    total: 0
                };
                break;
            case SET_LOADING:
                draft.loading = payload;
                draft.errorStatus = '';
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    set_loading: (data) => creator(SET_LOADING, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const searchParam = new URLSearchParams(payload.search);

            if (searchParam.has('query')) {
                let _query = searchParam.get('query');
                if (_query) {
                    _query = _query.replace('+', '%20');
                    searchParam.set('query', _query);
                } else {
                    searchParam.delete('query');
                }
            }

            const queryString = searchParam.toString().replace('+', '%20');
            const response = yield axios.get(`/workers/search?${queryString}`);

            yield put(actions.success({ items: response.data }));
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
            yield put(actions.clear());
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
