import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';
import { APP_COMPANY_URL, APP_UWORKER_URL } from '../../../config/app';
import { ADMIN_SERVICE_URL } from 'config';

/**
 * Constants
 */
const REQUEST = '@app/workers/magic-link-login-as-user/REQUEST';
const SUCCESS = '@app/workers/magic-link-login-as-user/SUCCESS';
const FAILURE = '@app/workers/magic-link-login-as-user/FAILURE';

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(`users/${payload.userId}/login-as`, {
                baseURL: ADMIN_SERVICE_URL
            });

            const { token, role } = response.data?.data;
            if (role === 3) return;

            const redirectUrl =
                role === 1
                    ? `${APP_UWORKER_URL}?token=${token}`
                    : `${APP_COMPANY_URL}?token=${token}`;

            // redirect admin to that particular user
            window.open(redirectUrl, 'security', 'noopener', '_blank');
            yield put(actions.success(response.data));
        } catch (e) {
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
