import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/departmetns/all-courses/REQUEST';
export const SUCCESS = '@app/departmetns/all-courses/SUCCESS';
export const FAILURE = '@app/departmetns/all-courses/FAILURE';
export const CLEAR = '@app/departmetns/all-courses/CLEAR';

/**
 * Initial state
 */
const initState = {
    courses: [],
    loading: false,
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case SUCCESS:
                draft.loading = false;
                draft.courses = payload;
                break;
            case FAILURE:
                draft.loading = false;
                draft.error = '';
                break;
            case CLEAR:
                draft.loading = false;
                draft.error = '';
                draft.courses = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request() {
        try {
            const response = yield axios.get('/user/courses');
            yield put(actions.success(response.data.data));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
