import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { setupAxios } from 'utils/axios';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import './initializers/sentry';
import './initializers/dayjs';
import 'i18n';
import { queryClient } from './utils/rq';

/**
 * global stylesheet
 * !main styles should import after ui styles
 *
 */
// eslint-disable-next-line import/no-unresolved
import '@ornio-no/ds/dist/style.css';
import 'styles/main.scss';

// import components
import {
    history,
    PageSpinner,
    GoogleMapsLoader as GoogleMapsAutocompleteProvider
} from 'uwork-app-core';
// import store configurator
import configureStore from 'store';

// config redux store
const initialState = {};
const store = configureStore(initialState, history);

// configure axios
setupAxios(store, history);

ReactDOM.render(
    <Suspense fallback={<PageSpinner />}>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <GoogleMapsAutocompleteProvider>
                    <App />
                </GoogleMapsAutocompleteProvider>
            </QueryClientProvider>
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

// add redux store to Cypress
if (window.Cypress) {
    window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
