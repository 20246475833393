import produce from 'immer';
import creator from 'utils/action-creator';

/**
 * Constants
 */
export const RESET = '@app/workers/workersprofile/accountSettings/advanced/RESET';
export const SUCCESS = '@app/workers/workersprofile/accountSettings/advanced/SUCCESS';
export const FAILURE = '@app/workers/workersprofile/accountSettings/advanced/FAILURE';

/**
 * Initial state
 */
const initState = {
    error: '',
    success: null
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.error = '';
                draft.success = payload;
                break;
            case FAILURE:
                draft.success = null;
                draft.error = payload;
                break;
            case RESET:
                draft.loading = false;
                draft.success = null;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    reset: () => creator(RESET),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};
