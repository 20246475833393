import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const REQUEST = '@app/company/tabs/workers/REQUEST';
export const SUCCESS = '@app/company/tabs/workers/SUCCESS';
export const FAILURE = '@app/company/tabs/workers/FAILURE';
export const DELETE_REQUEST = '@app/company/tabs/workers/delete/REQUEST';
export const DELETE_SUCCESS = '@app/company/tabs/workers/delete/SUCCESS';
export const DELETE_FAILURE = '@app/company/tabs/workers/delete/FAILURE';

/**
 * Initial state
 */
const initState = {
    loading: true,
    last: 1,
    data: [],
    error: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                if (payload.page === 1) {
                    draft.data = [];
                }
                draft.loading = true;
                draft.error = '';
                break;
            case SUCCESS:
                draft.data = [...draft.data, ...payload.data];
                draft.last = payload.last;
                draft.loading = false;
                break;

            case DELETE_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case DELETE_SUCCESS:
                draft.loading = false;
                draft.data = draft.data.filter((w) => w.id !== payload);
                break;
            case DELETE_FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    delete: (data) => creator(DELETE_REQUEST, data),
    deleteSuccess: (data) => creator(DELETE_SUCCESS, data),
    deleteError: (data) => creator(DELETE_FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(
                `/companies/workers/${payload.companyID}?page=${payload.page}`
            );
            yield put(
                actions.success({
                    data: response.data.data,
                    last: response.data.meta.last_page
                })
            );
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    },

    *delete({ payload }) {
        const { companyID, id } = payload;
        try {
            yield axios.delete(`/companies/workers/${companyID}/${id}`);
            yield put(actions.deleteSuccess(id));
        } catch (e) {
            yield put(actions.deleteError(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
};
