import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import creator from 'utils/action-creator';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
const REQUEST = '@app/workers/workersprofilesaga/titles/REQUEST';
const SUCCESS = '@app/workers/workersprofilesaga/titles/SUCCESS';
const FAILURE = '@app/workers/workersprofilesaga/titles/FAILURE';
const CLEAR = '@app/workers/workersprofilesaga/titles/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: true,
    errorStatus: '',
    items: []
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

// Reducer
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FAILURE:
                draft.loading = false;
                draft.errorStatus = payload;
                break;
            case CLEAR:
                draft.loading = true;
                draft.errorStatus = '';
                draft.items = [];
                break;
            case SUCCESS:
                draft.loading = false;
                draft.items = payload.data;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data)
};

/**
 * Saga functions
 */
export const sagas = {
    *request() {
        try {
            yield put(creator(CLEAR));
            const response = yield axios.get('/workers/contractor-titles');
            yield put(actions.success(response.data));
        } catch (e) {
            yield put(actions.failure('Failed to fetch titles, please contact support!'));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
