import { creator as actionCreator } from 'uwork-app-core';

/**
 * Action Types
 */
export const actionTypes = {
    REMOVE: '@app/loaders/REMOVE',
    RESET: '@app/loaders/RESET'
};

/**
 * Init state
 */
export const initState = {};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
export default function (state = initState, { type, data }) {
    /*
     * Reset state
     */
    if (type === actionTypes.RESET) {
        return initState;
    }

    /*
     * Remove single action
     */
    if (type === actionTypes.REMOVE) {
        const { [data]: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');

    if (isRequest) {
        return { ...state, [type]: true };
    }

    if (isSuccess || isFailure) {
        return {
            ...state,
            [type.replace(/SUCCESS|FAILURE/, 'REQUEST')]: false
        };
    }

    return state;
}

/**
 * Redux actions
 */
export const loadersActions = {
    remove: actionCreator(actionTypes.REMOVE),
    reset: actionCreator(actionTypes.RESET)
};
