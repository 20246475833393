import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { creator } from 'uwork-app-core';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/tempnewordersaga/REQUEST';
export const SUCCESS = '@app/tempnewordersaga/SUCCESS';
export const FAILURE = '@app/tempnewordersaga/FAILURE';
export const SET_LOADING = '@app/tempnewordersaga/SET_LOADING';
export const SET_ITEMS = '@app/tempnewordersaga/SET_ITEMS';
export const CLEAR = '@app/tempnewordersaga/CLEAR';
export const UPDATE = '@app/tempnewordersaga/UPDATE';
export const DELETE = '@app/tempnewordersaga/DELETE';
export const SAVE_FILTER_QUERY = '@app/tempnewordersaga/SAVE_FILTER_QUERY';

/**
 * Initial state
 */
const initState = {
    loading: false,
    errorStatus: '',
    order_shifts: [],
    shift_type_id: null,
    description: '',
    descriptionDraft: null,
    notify: 1,
    companyId: 0,
    companyValue: '',
    departmentId: 0,
    filterQuery: ''
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.order_shifts = payload.order_shifts;
                draft.description = payload.description;
                draft.descriptionDraft = payload.descriptionDraft;
                draft.shift_type_id = payload.shift_type_id;
                draft.notify = payload.notify;
                draft.companyId = payload.companyId;
                draft.companyValue = payload.companyValue;
                draft.departmentId = payload.departmentId;
                draft.success = true;
                draft.filterQuery = '';
                break;
            case FAILURE:
                draft.errorStatus = payload;
                break;
            case SET_LOADING:
                draft.loading = payload;
                break;
            case CLEAR:
                draft.loading = false;
                draft.errorStatus = '';
                draft.data = null;
                break;
            case UPDATE:
                draft.order_shifts = payload.order_shifts;
                draft.type = payload.type;
                break;
            case DELETE:
                draft.order_shifts = draft.order_shifts.filter((item) => item.id !== payload);
                break;
            case SAVE_FILTER_QUERY:
                draft.filterQuery = payload;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR),
    saveFilterQuery: (data) => creator(SAVE_FILTER_QUERY, data)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            yield put(actions.success(payload));
            yield put(push(`/filter${payload.filterQuery}`));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            yield put(creator(CLEAR));
            yield put(actions.failure(error));
        }
        yield put(creator(SET_LOADING, false));
    },
    *clear() {
        try {
            yield put(
                actions.success({
                    loading: false,
                    errorStatus: '',
                    order_shifts: [],
                    shift_type_id: null,
                    description: '',
                    descriptionDraft: null,
                    notify: 1,
                    companyId: 0,
                    companyValue: '',
                    departmentId: 0,
                    filterQuery: ''
                })
            );
        } catch (error) {
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(CLEAR, sagas.clear);
};
