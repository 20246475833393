/* eslint-disable no-case-declarations */
import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * Constants
 */
export const GET_REQUEST = '@app/administration/courses/GET_REQUEST';
export const GET_SUCCESS = '@app/administration/courses/GET_SUCCESS';
export const GET_FAILURE = '@app/administration/courses/GET_FAILURE';
export const POST_REQUEST = '@app/administration/courses/POST_REQUEST';
export const POST_SUCCESS = '@app/administration/courses/POST_SUCCESS';
export const POST_FAILURE = '@app/administration/courses/POST_FAILURE';
export const PUT_REQUEST = '@app/administration/courses/PUT_REQUEST';
export const PUT_SUCCESS = '@app/administration/courses/PUT_SUCCESS';
export const PUT_FAILURE = '@app/administration/courses/PUT_FAILURE';
export const DELETE_REQUEST = '@app/administration/courses/DELETE_REQUEST';
export const DELETE_SUCCESS = '@app/administration/courses/DELETE_SUCCESS';
export const DELETE_FAILURE = '@app/administration/courses/DELETE_FAILURE';
export const TRIGGER_ADD_MODAL = '@app/administration/courses/TRIGGER_ADD_MODAL';
export const TRIGGER_EDIT_MODAL = '@app/administration/courses/TRIGGER_EDIT_MODAL';
export const COLLAPSE_MODAL = '@app/administration/courses/COLLAPSE_MODAL';
export const CLEAR = '@app/administration/courses/CLEAR';

/**
 * Initial State
 */

// modal for data model
const initFormData = {
    title: '',
    description: ''
};

// modal state management
const initModalState = {
    open: false,
    newCourse: true,
    values: initFormData,
    loading: false,
    error: ''
};

// view state management
const initViewState = {
    data: [],
    last: 1,
    error: '',
    listLoading: false,
    itemLoading: null, // add item id here when delete or edit
    deleteSuccess: false
};

// initial state
const initState = {
    modal: initModalState,
    view: initViewState
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get request
            case GET_REQUEST:
                draft.view.listLoading = true;
                draft.view.error = '';
                draft.view.itemLoading = null;
                draft.view.deleteSuccess = false;
                break;
            case GET_FAILURE:
                draft.view.listLoading = false;
                draft.view.error = payload;
                break;
            case GET_SUCCESS:
                draft.view.data = [...draft.view.data, ...payload.data];
                draft.view.last = payload.last;
                draft.view.listLoading = false;
                break;

            // post and put requests
            case PUT_REQUEST:
                draft.modal.loading = true;
                draft.modal.error = '';
                draft.view.itemLoading = payload.id;
                break;
            case POST_REQUEST:
                draft.modal.loading = true;
                draft.modal.error = '';
                break;
            case PUT_FAILURE:
            case POST_FAILURE:
                draft.modal.loading = false;
                draft.modal.error = payload;
                draft.view.itemLoading = null;
                break;
            // add success
            case POST_SUCCESS:
                draft.view.data = [payload, ...draft.view.data];
                break;
            // edit success
            case PUT_SUCCESS:
                const data = draft.view.data.map((el) => {
                    if (el.id === payload.id) {
                        return payload;
                    }
                    return el;
                });
                draft.view.itemLoading = null;
                draft.view.data = data;
                break;

            case DELETE_REQUEST:
                draft.view.itemLoading = payload;
                draft.view.deleteSuccess = false;
                break;
            case DELETE_SUCCESS:
                draft.view.itemLoading = false;
                draft.view.deleteSuccess = true;
                draft.view.data = draft.view.data.filter((el) => el.id !== payload);
                break;
            case DELETE_FAILURE:
                draft.view.itemLoading = null;
                draft.view.deleteSuccess = false;
                draft.view.error = payload;
                break;

            // modals
            case TRIGGER_ADD_MODAL:
                draft.modal.values = initFormData;
                draft.modal.newCourse = true;
                draft.modal.open = true;
                draft.modal.loading = false;
                draft.modal.error = '';
                break;
            case TRIGGER_EDIT_MODAL:
                draft.modal.values = payload;
                draft.modal.newCourse = false;
                draft.modal.open = true;
                draft.modal.loading = false;
                draft.modal.error = '';
                break;
            case COLLAPSE_MODAL:
                draft.modal = initModalState;
                break;
            case CLEAR:
                draft.modal = initModalState;
                draft.view = initViewState;
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    get_request: (data) => creator(GET_REQUEST, data),
    get_success: (data) => creator(GET_SUCCESS, data),
    get_failure: (data) => creator(GET_FAILURE, data),
    post_request: (data) => creator(POST_REQUEST, data),
    post_success: (data) => creator(POST_SUCCESS, data),
    post_failure: (data) => creator(POST_FAILURE, data),
    put_request: (data) => creator(PUT_REQUEST, data),
    put_success: (data) => creator(PUT_SUCCESS, data),
    put_failure: (data) => creator(PUT_FAILURE, data),
    delete_request: (data) => creator(DELETE_REQUEST, data),
    delete_success: (data) => creator(DELETE_SUCCESS, data),
    delete_failure: (data) => creator(DELETE_FAILURE, data),
    toggle_add_modal: (data) => creator(TRIGGER_ADD_MODAL, data),
    toggle_edit_modal: (data) => creator(TRIGGER_EDIT_MODAL, data),
    collapse_modal: () => creator(COLLAPSE_MODAL),
    clear: () => creator(CLEAR)
};

export const sagas = {
    *get({ payload }) {
        try {
            const response = yield axios.get(`/user/courses?pagination=1&page=${payload}`);
            yield put(
                actions.get_success({
                    data: response.data.data,
                    last: response.data.meta.last_page
                })
            );
        } catch (e) {
            yield put(actions.get_failure(errorMessageGenerator(e)));
        }
    },

    *post({ payload }) {
        try {
            const response = yield axios.post('/user/courses', payload);
            yield put(actions.post_success(response.data.data));
            yield put(actions.collapse_modal());
        } catch (e) {
            yield put(actions.post_failure(errorMessageGenerator(e)));
        }
    },

    *put({ payload }) {
        try {
            const response = yield axios.put(`/user/courses/${payload.id}`, payload);
            yield put(actions.put_success(response.data.data));
            yield put(actions.collapse_modal());
        } catch (e) {
            yield put(actions.put_failure(errorMessageGenerator(e)));
        }
    },

    *delete({ payload }) {
        try {
            yield axios.delete(`/user/courses/${payload}`);
            yield put(actions.delete_success(payload));
        } catch (e) {
            yield put(actions.delete_failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(GET_REQUEST, sagas.get);
    yield takeLatest(POST_REQUEST, sagas.post);
    yield takeLatest(PUT_REQUEST, sagas.put);
    yield takeLatest(DELETE_REQUEST, sagas.delete);
};
