/* eslint-disable camelcase */
import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import { replace } from 'react-router-redux';
import { useOvertimeCreateStore } from '../../../zustand-store/overtimeCreateStore';
import { queryClient } from '../../../utils/rq';
import { storeOvertime } from '../../../rq/mutations/storeOvertime';
import { isEmpty } from '../../../utils/isEmpty';

/**
 * Constants
 */
export const APPLY_REQUEST = '@app/orders/selectWorker/APPLY_REQUEST';
export const APPLY_SUCCESS = '@app/orders/selectWorker/APPLY_SUCCESS';
export const APPLY_FAILURE = '@app/orders/selectWorker/APPLY_FAILURE';
export const RESET = '@app/orders/selectWorker/RESET';
export const ADD_WORKER_TO_ORDER_REQUEST = '@app/orders/selectWorker/add/REQUEST';
export const ADD_WORKER_TO_ORDER_SUCCESS = '@app/orders/selectWorker/add/SUCCESS';
export const ADD_WORKER_TO_ORDER_FAILURE = '@app/orders/selectWorker/add/FAILURE';

export const SALARY_RELATION_CHECK_REQUEST =
    '@app/orders/selectWorker/SALARY_RELATION_CHECK_REQUEST';
export const VIOLATION_TYPE_SUCCESS = '@app/orders/selectWorker/VIOLATION_TYPE_SUCCESS';

/**
 * Initial state
 */
const initState = {
    applyLoading: false,
    applyError: '',
    typeViolation: []
};

const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            // apply
            case APPLY_SUCCESS:
            case ADD_WORKER_TO_ORDER_SUCCESS:
                draft.applyLoading = false;
                break;
            case APPLY_FAILURE:
            case ADD_WORKER_TO_ORDER_FAILURE:
                draft.applyError = payload;
                draft.applyLoading = false;
                break;
            case APPLY_REQUEST:
            case ADD_WORKER_TO_ORDER_REQUEST:
                draft.applyLoading = true;
                draft.applyError = '';
                break;

            case VIOLATION_TYPE_SUCCESS:
                draft.typeViolation = payload;
                break;
            case RESET:
                draft.applyLoading = false;
                draft.applyError = '';
                draft.typeViolation = [];
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(APPLY_REQUEST, data),
    success: () => creator(APPLY_SUCCESS),
    failure: (data) => creator(APPLY_FAILURE, data),
    reset: () => creator(RESET),

    typeViolationSuccess: (data) => creator(VIOLATION_TYPE_SUCCESS, data),

    addWorkerToOrder: (data) => creator(ADD_WORKER_TO_ORDER_REQUEST, data),
    addWorkerToOrderFailure: (data) => creator(ADD_WORKER_TO_ORDER_FAILURE, data),
    addWorkerToOrderSuccess: (data) => creator(ADD_WORKER_TO_ORDER_SUCCESS, data)
};

/**
 * Sagas
 */
export const sagas = {
    *apply({ payload }) {
        try {
            yield put(actions.addWorkerToOrder(payload));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },

    *addWorkerToOrder({ payload }) {
        try {
            const { orderID, userID, note_ccu_rights_regulation } = payload;
            const { billableType, overtimeData, billableCompanyId, workerId } =
                yield useOvertimeCreateStore.getState();

            if (overtimeData.length > 0) {
                const overtime = {
                    order_id: orderID,
                    worker_id: workerId,
                    billable_type: billableType,
                    billable_company_id: billableCompanyId,
                    shifts: overtimeData
                };

                yield queryClient.fetchQuery(['overtime-store', overtime.order_id], () =>
                    storeOvertime(overtime)
                );
            }

            if (!isEmpty(note_ccu_rights_regulation)) {
                yield axios.put(`/orders/${orderID}`, { note_ccu_rights_regulation });
            }

            yield axios.put(`/orders/workers/${orderID}`, { user_id: userID });

            yield put(replace(`/orders/view/${orderID}`));
            yield put(actions.addWorkerToOrderSuccess());
        } catch (e) {
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(APPLY_REQUEST, sagas.apply);
    yield takeLatest(ADD_WORKER_TO_ORDER_REQUEST, sagas.addWorkerToOrder);
};
