/**
 * Import Watchers
 */

// auth watchers
import { watcher as userWatcher } from 'sagas/app/auth/userSaga';
import { watcher as magicLinkLoginAsUser } from 'sagas/app/auth/magicLinkLoginAsUserSaga';

// companies watchers
import { watcher as companiesWatcher } from 'sagas/app/companies/companiesPageSaga';
import { watcher as companyWatcher } from 'sagas/app/companies/companySaga';
import { watcher as companiesPlacesWatcher } from 'sagas/app/companies/companiesPlacesSaga';
import { watcher as companiesCategoryWatcher } from 'sagas/app/companies/companiesCategorySaga';
import { watcher as companiesWorkersWatcher } from 'sagas/app/companies/companiesWorkersSaga';
import { watcher as companiesAddCompanySaga } from 'sagas/app/companies/companiesAddCompanySaga';
import { watcher as companiesSearchWorkersWatcher } from 'sagas/app/companies/companiesSearchWorkerSaga';
import { watcher as companiesAddNewTeamMemberWatcher } from 'sagas/app/companies/companiesAddNewTeamMemberSaga';
import { watcher as companiesSalaryTabWatcher } from 'sagas/app/companies/salaryTabSaga';
import { watcher as companiesWorkersTabWatcher } from 'sagas/app/companies/workersTabSaga';
import { watcher as switchDepartamentWatcher } from 'sagas/app/companies/switchDepartamentSaga';
import { watcher as companiesTabTeamMembersWatcher } from 'sagas/app/companies/teamMembersSaga';
import { watcher as companiesAssignBMKWatcher } from 'sagas/app/companies/companyAssignBMKSaga';
import { watcher as companiesAssignRequestRecieverWatcher } from 'sagas/app/companies/orderNotificationRequesters';
import { watcher as companiesDeleteWatcher } from 'sagas/app/companies/companiesDeleteSaga';

// company edit parent watchers
import { watcher as companiesParentEditAccountWatcher } from 'sagas/app/companies/companiesParentEditAccountSaga';
import { watcher as companiesParrentEditProfileUploadsWatcher } from 'sagas/app/companies/companiesParrentEditProfileUploadsSaga';
import { watcher as companiesParentEditCategoriesWatcher } from 'sagas/app/companies/companiesParentEditCategoriesSaga';
import { watcher as companiesParentEditAboutWatcher } from 'sagas/app/companies/companiesParentEditAboutSaga';
import { watcher as companyParentEditNDAWatcher } from 'sagas/app/companies/companyParentEditNDASaga';
import { watcher as companiesParentEditBreaksWatcher } from 'sagas/app/companies/companiesParentEditBreaksSaga';
import { watcher as companiesParentEditContractWatcher } from 'sagas/app/companies/companiesParentEditContractSaga';
import { watcher as companiesParentEditAddSalaryConditionWatcher } from 'sagas/app/companies/companiesParentEditAddSalaryConditionSaga';
import { watcher as comapniesTeamMembersFetchWatcher } from 'sagas/app/companies/fetchTeamMembersSaga';
import { watcher as companiesTeamMembersUpdateTitleWatcher } from 'sagas/app/companies/updateTitleSaga';

// departmetns watchers
import { watcher as departmentWatcher } from 'sagas/app/departments/departmentSaga';
import { watcher as departmentCategoriesWatcher } from 'sagas/app/departments/categoriesSaga';
import { watcher as addNewDepartmentWatcher } from 'sagas/app/departments/addNewDepartmentSaga';
import { watcher as getCompanyDepartmentsWatcher } from 'sagas/app/departments/getCompanyDepartments';
import { watcher as departmentMembersSagaWatcher } from 'sagas/app/departments/departmentMembersSaga';

// departmets settings watchers
import { watcher as patientInformationWatcher } from 'sagas/app/departments/patientInformationSaga';
import { watcher as departmentCoursesWatcher } from 'sagas/app/departments/departmentCoursesSaga';
import { watcher as allDepartmentCoursesWatcher } from 'sagas/app/departments/allCoursesSaga';
import { watcher as departmentSkillsWatcher } from 'sagas/app/departments/departmnetSkillsSaga';
import { watcher as allDepartmentSkillsWatcher } from 'sagas/app/departments/allSkillsSaga';
import { watcher as departmentBasicInfoSaga } from 'sagas/app/departments/departmentBasicInfoSaga';
import { watcher as editDepartmentInformationsSaga } from 'sagas/app/departments/editDepartmentInformations';

// department
import { watcher as singleDeparmetnWatcher } from 'sagas/app/department/departmentSaga';

// notifications watchers
import { watcher as notificationsPageWatchers } from 'sagas/app/notifications/notificationsPageSaga';
import { watcher as notificationsSwitchWatchers } from 'sagas/app/notifications/notificationSwitchSaga';
import { watcher as notificationsWatchers } from 'sagas/app/notifications/notificationsSaga';

// workers watchers
import { watcher as workersSagaWatchers } from 'sagas/app/workers/workersSaga';
import { watcher as workersSimpleSearchSagaWatchers } from 'sagas/app/workers/workersSimpleSearchSaga';
import { watcher as addWorkersSagaWatchers } from 'sagas/app/workers/addWorkerSaga';
import { watcher as workerFiltersWatcher } from 'sagas/app/workers/workerFiltersSaga';
import { watcher as workersLocationsWatchers } from 'sagas/app/workers/workersLocationsSaga';
import { watcher as workerProfile } from 'sagas/app/workers/workersProfileSaga';
import { watcher as exportCSVWatchers } from 'sagas/app/workers/exportCSVSaga';
import { watcher as selectedDepartamentWorkersWatchers } from 'sagas/app/workers/selectedDepartmentWorkersSaga';
import { watcher as workerMapFilterWatchers } from 'sagas/app/workers/workerMapFilterSaga';
import { watcher as updateWorkerStageWatchers } from 'sagas/app/workers/updateWorkerStageSaga';
import { watcher as workerCRUDEducationWatcher } from 'sagas/app/workers/workerProfileEducationSaga';
import { watcher as workerCRUDExperienceWatcher } from 'sagas/app/workers/workerProfileExperienceSaga';
import { watcher as workerZipCodeWatcher } from 'sagas/app/workers/zipCodeSaga';
import { watcher as workerTitlesWatcher } from 'sagas/app/workers/titlesSaga';
import { watcher as workerPersonalInformationWatcher } from 'sagas/app/workers/personalInfromationSaga';
import { watcher as workerAccountSettingsWatcher } from 'sagas/app/workers/accountSettingsSaga';
import { watcher as workerProfileUploadssWatcher } from 'sagas/app/workers/profileUploadsSaga';
import { watcher as workerPaymnetWatcher } from 'sagas/app/workers/workersPaymentSaga';
import { watcher as workerShiftsProfileWatcher } from 'sagas/app/workers/workersShiftSaga';
import { watcher as workerExportWatcher } from 'sagas/app/workers/workerExportSaga';
import { watcher as workerSalaryWatcher } from 'sagas/app/workers/workerSalarySaga';
import { watcher as workerAttachmentSaga } from 'sagas/app/workers/workerAttachmentSaga';
import { watcher as workerGCCCategoriesSaga } from 'sagas/app/workers/workerGCCCategoriesSaga';
import { watcher as workerTagsWatcher } from 'sagas/app/workers/workerTagsSaga';
import { watcher as workerGCCSaga } from 'sagas/app/workers/workerGCCSaga';
import { watcher as workerScreeningsSaga } from 'sagas/app/workers/workerScreeningsSaga';
import { watcher as workerDeleteSaga } from 'sagas/app/workers/workerDeleteSaga';
import { watcher as givAccessWatcher } from 'sagas/app/workers/giveAccessSaga';
import { watcher as workerNotesWatcher } from 'sagas/app/workers/notesSaga';
import { watcher as workerAgreementAndPrecentageWatcher } from 'sagas/app/workers/workerAgreementAndPrecentageSaga';
import { watcher as workerBadgesWatcher } from 'sagas/app/workers/workerBadgesSaga';
import { watcher as workerRollesWatcher } from 'sagas/app/workers/workerRoleSaga';
import { watcher as workerCategoriesWatcher } from 'sagas/app/workers/workerCategoriesSaga';
import { watcher as workerSkillsWatcher } from 'sagas/app/workers/workerSkillsSaga';
import { watcher as workerCoursesWatcher } from 'sagas/app/workers/workerCoursesSaga';
import { watcher as workerInterviewsWatcher } from 'sagas/app/workers/interviewSaga';
import { watcher as workerInterviewTemplatesWatcher } from 'sagas/app/workers/interviewTemplatesSaga';
import { watcher as workerInterviewMappingTemplatesWatcher } from 'sagas/app/workers/interviewMappingTemplatesSaga';
import { watcher as workerReferenceWatcher } from 'sagas/app/workers/referenceSaga';
import { watcher as workerReferenceTemplatesWatcher } from 'sagas/app/workers/referenceTemplatesSaga';
import { watcher as workerProfileAppearanceWatcher } from 'sagas/app/workers/updateWorkerAppearanceSaga';
import { watcher as workerNotificationsEditWatcher } from 'sagas/app/workers/workerNotficationsSettingsSaga';
import { watcher as workerAdminsWatcher } from 'sagas/app/workers/adminsSaga';
import { watcher as workerAssignAdminWatcher } from 'sagas/app/workers/assignAdminSaga';
import { watcher as workerFetchParentCompaniesWatcher } from 'sagas/app/workers/fetchParentCompanies';
import { watcher as workerBlockedCompaniesRelationsWatcher } from 'sagas/app/workers/blockedRelationsSaga';
import { watcher as workerFilterCoursesWatcher } from 'sagas/app/workers/coursesSaga';
import { watcher as workerActivityWatcher } from 'sagas/app/workers/workerActivitySaga';
import { watcher as renotifyWorkersWatcher } from 'sagas/app/workers/renotifyWorkersSaga';
import { watcher as workerReadyStageCheckerWatcher } from 'sagas/app/workers/workerReadyStageCheckerSaga';
import { watcher as workerCheckPresentationWatcher } from 'sagas/app/workers/workerCheckPresentationSaga';
import { watcher as workerUpdatePresentationWatcher } from 'sagas/app/workers/workerUpdatePresentationSaga';
import { watcher as workerFetchTeamMembersWatcher } from 'sagas/app/workers/fetchTeamMembersSaga';
import { watcher as workerSearchDepartmentWatcher } from 'sagas/app/workers/searchDepartmensSaga';

// recommendations watchers
import { watcher as recommendationsFilterWatcher } from 'sagas/app/recommendations/recommendationsFilterSaga';
import { watcher as recommendedWorkersWatcher } from 'sagas/app/recommendations/recommendedWorkersSaga';
import { watcher as recommendedCompaniesWatcher } from 'sagas/app/recommendations/recommendedCompaniesSaga';
import { watcher as recommendedDepartmentUsersWatcher } from 'sagas/app/recommendations/recommendedDepartmentUsersSaga';
import { watcher as recommendedAdminsWatcher } from 'sagas/app/recommendations/recommendedAdminsSaga';
import { watcher as deleteRecommendationWatcher } from 'sagas/app/recommendations/deleteRecommendationSaga';
import { watcher as newRecommendationWatcher } from 'sagas/app/recommendations/newRecommendationSaga';
import { watcher as archiveRecommendationWatcher } from 'sagas/app/recommendations/archiveRecommendationSaga';

// timesheet imports
import { watcher as timesheetTypesWatcher } from 'sagas/app/timesheet/shiftTypesSaga';
import { watcher as timesheetWatcher } from 'sagas/app/timesheet/timesheetSaga';
import { watcher as timesheetCompaniesWatcher } from 'sagas/app/timesheet/companiesSaga';
import { watcher as absenceRequestWatcher } from 'sagas/app/timesheet/absenceRequestSaga';
import { watcher as orderShiftWatcher } from 'sagas/app/timesheet/orderShiftSaga';
import { watcher as timesheetWorkerWatcher } from 'sagas/app/timesheet/timesheetWorkerSaga';

// orders watchers
import { watcher as getComapniesSagaWatchers } from 'sagas/app/orders/getCompaniesSaga';
import { watcher as newOrderWatchers } from 'sagas/app/orders/newOrderSaga';
import { watcher as orderTemplateWatcher } from 'sagas/app/orders/orderTemplateSaga';
import { watcher as selectWorkersWatcher } from 'sagas/app/workers/selectWorkersSaga';
import { watcher as tempNewOrderWatcher } from 'sagas/app/orders/tempNewOrderSaga';
import { watcher as temporaryOrderWatcher } from 'sagas/app/orders/temporaryOrderSaga';
import { watcher as orderWatcher } from 'sagas/app/orders/orderSaga';
import { watcher as notifyOrderWatcher } from 'sagas/app/orders/notifyOrderSaga';
import { watcher as removeOrderWatcher } from 'sagas/app/orders/removeOrderSaga';
import { watcher as removeActiveOrderWatcher } from 'sagas/app/orders/removeActiveOrderSaga';
import { watcher as editOrderWatcher } from 'sagas/app/orders/editOrderSaga';
import { watcher as editOrderShiftWatcher } from 'sagas/app/orders/editOrderShiftSaga';
import { watcher as filterOrderWorkersWatcher } from 'sagas/app/orders/filterOrderWorkersSaga';
import { watcher as selectOrderWorkerWatcher } from 'sagas/app/orders/selectWorkerSaga';
import { watcher as resendOrderConfirmationWatcher } from 'sagas/app/orders/resendOrderConfirmationSaga';
import { watcher as orderActivityLogsWatcher } from 'sagas/app/orders/activityLogSaga';
import { watcher as renotifyOrderParticipantsByChannelWatcher } from 'sagas/app/orders/renotifyOrderParticipantsByChannel';
import { watcher as syncSalaryOrderWatcher } from 'sagas/app/orders/syncSalaryOrderSaga';
import { watcher as directOrdersTypeViolationWatcher } from 'sagas/app/orders/directOrderTypeViolationSaga';

// order filter
import { watcher as orderFilterWorkersWatcher } from 'sagas/app/orders/orderFilterFetchWorker';
import { watcher as orderFilterCompaniesWatcher } from 'sagas/app/orders/orderFilterFetchCompanies';
import { watcher as notifiedOrderWorkersCountWatcher } from 'sagas/app/orders/getNumberOfNotifiedWorker';

import { watcher as activeOrderWatcher } from 'sagas/app/orders/activeOrdersSaga';
import { watcher as completedOrderWatcher } from 'sagas/app/orders/completedOrdersSaga';
import { watcher as ongoingOrderWatcher } from 'sagas/app/orders/ongoingOrdersSaga';

// settings watcher

import { watcher as accountSettingsWatchers } from 'sagas/app/settings/accountSettingsSaga';

// administration watchers
import { watcher as getGuidelinesWatchers } from 'sagas/app/administration/guidlinesSaga';
import { watcher as companiesWithougGuidlinesWatcher } from 'sagas/app/administration/companiesSaga';
import { watcher as coursesWatcher } from 'sagas/app/administration/coursesSaga';
import { watcher as administrationSkillsWatcher } from 'sagas/app/administration/skillsSaga';
import { watcher as administrationTitlesWatcher } from 'sagas/app/administration/titlesSaga';
import { watcher as administrationTagsWatcher } from 'sagas/app/administration/tagsSaga';
import { watcher as administrationBadgesWatcher } from 'sagas/app/administration/badgesSaga';
import { watcher as administrationEmailLogsWatcher } from 'sagas/app/administration/emailLogsSaga';
import { watcher as administrationEmailWatcher } from 'sagas/app/administration/singleEmailSaga';

// other
import { watcher as geolocationWatcher } from 'sagas/app/geolocationSaga';
import { watcher as zipcodeWatcher } from 'sagas/app/zipcodeSaga';
import { watcher as cityWatcher } from 'sagas/app/citySaga';

// dashboard
import { watcher as dashboardWatcher } from 'sagas/app/dashboardSaga';
import { watcher as lastSeenStatsWatcher } from 'sagas/app/lastSeenStatsSaga';

/**
 * Watchers array
 */
const watchers = [
    // auth watchers
    userWatcher(),
    magicLinkLoginAsUser(),

    // dashboard
    dashboardWatcher(),
    lastSeenStatsWatcher(),

    // company watchers
    companiesWatcher(),
    companyWatcher(),
    companiesPlacesWatcher(),
    companiesCategoryWatcher(),
    companiesWorkersWatcher(),
    companiesSearchWorkersWatcher(),
    companiesAddCompanySaga(),
    companiesAddNewTeamMemberWatcher(),
    companiesSalaryTabWatcher(),
    companiesWorkersTabWatcher(),
    switchDepartamentWatcher(),
    companiesTabTeamMembersWatcher(),
    companiesAssignBMKWatcher(),
    companiesAssignRequestRecieverWatcher(),
    companiesDeleteWatcher(),

    // company edit parent watchers
    companiesParentEditAccountWatcher(),
    companiesParrentEditProfileUploadsWatcher(),
    companiesParentEditCategoriesWatcher(),
    companiesParentEditAboutWatcher(),
    companyParentEditNDAWatcher(),
    companiesParentEditBreaksWatcher(),
    companiesParentEditContractWatcher(),
    companiesParentEditAddSalaryConditionWatcher(),
    comapniesTeamMembersFetchWatcher(),
    companiesTeamMembersUpdateTitleWatcher(),

    // department watchers
    departmentWatcher(),
    departmentCategoriesWatcher(),
    addNewDepartmentWatcher(),
    getCompanyDepartmentsWatcher(),
    departmentMembersSagaWatcher(),

    // department seettings watchers
    patientInformationWatcher(),
    departmentCoursesWatcher(),
    allDepartmentCoursesWatcher(),
    allDepartmentSkillsWatcher(),
    departmentSkillsWatcher(),
    departmentBasicInfoSaga(),
    editDepartmentInformationsSaga(),

    // single deparment
    singleDeparmetnWatcher(),

    // notifications watchers
    notificationsPageWatchers(),
    notificationsSwitchWatchers(),
    notificationsWatchers(),

    // workers
    workerTagsWatcher(),
    workersSagaWatchers(),
    workersSimpleSearchSagaWatchers(),
    addWorkersSagaWatchers(),
    workerFiltersWatcher(),
    workersLocationsWatchers(),
    workerProfile(),
    exportCSVWatchers(),
    selectedDepartamentWorkersWatchers(),
    workerMapFilterWatchers(),
    updateWorkerStageWatchers(),
    workerCRUDEducationWatcher(),
    workerCRUDExperienceWatcher(),
    workerZipCodeWatcher(),
    workerTitlesWatcher(),
    workerPersonalInformationWatcher(),
    workerAccountSettingsWatcher(),
    workerProfileUploadssWatcher(),
    workerPaymnetWatcher(),
    workerShiftsProfileWatcher(),
    workerExportWatcher(),
    workerSalaryWatcher(),
    workerAttachmentSaga(),
    workerGCCCategoriesSaga(),
    workerGCCSaga(),
    workerScreeningsSaga(),
    workerDeleteSaga(),
    givAccessWatcher(),
    workerNotesWatcher(),
    workerAgreementAndPrecentageWatcher(),
    workerBadgesWatcher(),
    workerRollesWatcher(),
    workerCategoriesWatcher(),
    workerSkillsWatcher(),
    workerCoursesWatcher(),
    workerInterviewsWatcher(),
    workerInterviewTemplatesWatcher(),
    workerInterviewMappingTemplatesWatcher(),
    workerReferenceWatcher(),
    workerReferenceTemplatesWatcher(),
    workerProfileAppearanceWatcher(),
    workerNotificationsEditWatcher(),
    workerAdminsWatcher(),
    workerAssignAdminWatcher(),
    workerFetchParentCompaniesWatcher(),
    workerBlockedCompaniesRelationsWatcher(),
    workerFilterCoursesWatcher(),
    workerActivityWatcher(),
    workerCheckPresentationWatcher(),
    workerUpdatePresentationWatcher(),
    renotifyWorkersWatcher(),
    workerReadyStageCheckerWatcher(),
    workerFetchTeamMembersWatcher(),
    workerSearchDepartmentWatcher(),

    // recommendatins
    recommendationsFilterWatcher(),
    recommendedWorkersWatcher(),
    recommendedCompaniesWatcher(),
    recommendedDepartmentUsersWatcher(),
    recommendedAdminsWatcher(),
    deleteRecommendationWatcher(),
    archiveRecommendationWatcher(),
    newRecommendationWatcher(),

    // timesheet
    timesheetTypesWatcher(),
    timesheetWatcher(),
    timesheetCompaniesWatcher(),
    absenceRequestWatcher(),
    orderShiftWatcher(),
    timesheetWorkerWatcher(),

    // orders
    activeOrderWatcher(),
    completedOrderWatcher(),
    ongoingOrderWatcher(),
    getComapniesSagaWatchers(),
    newOrderWatchers(),
    orderTemplateWatcher(),
    selectWorkersWatcher(),
    tempNewOrderWatcher(),
    orderWatcher(),
    notifyOrderWatcher(),
    removeOrderWatcher(),
    removeActiveOrderWatcher(),
    editOrderWatcher(),
    editOrderShiftWatcher(),
    temporaryOrderWatcher(),
    filterOrderWorkersWatcher(),
    selectOrderWorkerWatcher(),
    orderFilterWorkersWatcher(),
    orderFilterCompaniesWatcher(),
    resendOrderConfirmationWatcher(),
    orderActivityLogsWatcher(),
    notifiedOrderWorkersCountWatcher(),
    renotifyOrderParticipantsByChannelWatcher(),
    syncSalaryOrderWatcher(),
    directOrdersTypeViolationWatcher(),

    // administrations
    getGuidelinesWatchers(),
    companiesWithougGuidlinesWatcher(),
    coursesWatcher(),
    administrationSkillsWatcher(),
    administrationTitlesWatcher(),
    administrationTagsWatcher(),
    administrationBadgesWatcher(),
    administrationEmailLogsWatcher(),
    administrationEmailWatcher(),

    // settings
    accountSettingsWatchers(),

    // other
    geolocationWatcher(0),
    zipcodeWatcher(),
    cityWatcher()
];

export default watchers;
