import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/administration/emails/list/REQUEST';
export const SUCCESS = '@app/administration/emails/list/SUCCESS';
export const FAILURE = '@app/administration/emails/list/FAILURE';

export const RESET = '@app/administration/emails/list/RESET';

/**
 * Initial State
 */
const initialState = {
    data: [],
    meta: {
        current_page: 1,
        last_page: 1,
        per_page: 1,
        total: 0
    },
    loading: false,
    error: '',
    last: 1
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                draft.error = '';
                break;

            case SUCCESS:
                draft.data = payload.data;
                draft.meta = payload.meta;
                draft.loading = false;
                break;

            case FAILURE:
                draft.loading = false;
                draft.error = payload;
                break;

            // RESET
            case RESET:
                draft.data = [];
                draft.meta = {};
                draft.loading = false;
                draft.error = '';
                break;
            default:
                break;
        }
    });

export default reducer;

export const actions = {
    get: (data) => creator(REQUEST, data),
    failure: (data) => creator(FAILURE, data),
    success: (data) => creator(SUCCESS, data),
    reset: () => creator(RESET)
};

export const sagas = {
    *get({ payload }) {
        try {
            const searchParam = new URLSearchParams(payload);
            if (searchParam.has('query')) {
                let _query = searchParam.get('query');
                if (_query) {
                    _query = _query.replace('+', '%20');
                    searchParam.set('query', _query);
                } else {
                    searchParam.delete('query');
                }
            }
            const queryString = searchParam.toString();
            const response = yield axios.get(`/email-logs?${queryString}&limit=15`);
            yield put(actions.success(response.data));
        } catch (e) {
            yield put(actions.failure(errorMessageGenerator(e)));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.get);
};
