import { put, takeLatest } from 'redux-saga/effects';
import { creator } from 'uwork-app-core';
import axios from 'utils/axios';

/**
 * External Ations
 */

/**
 * Constants
 */
export const REQUEST = '@app/geolocationsaga/REQUEST';
export const SUCCESS = '@app/geolocationsaga/SUCCESS';
export const FAILURE = '@app/geolocationsaga/FAILURE';
export const CLEAR = '@app/geolocationsaga/CLEAR';

/**
 * Initial state
 */

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */

/**
 * Selectors
 */

/**
 * Redux actions
 */
export const actions = {
    request: (data, resolve, reject) => creator(REQUEST, data, resolve, reject),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload, resolve, reject }) {
        try {
            const response = yield axios.get(`/geolocation?address=${payload}`);
            resolve(response.data);
            yield put(actions.success(response.data));
        } catch (e) {
            console.error(e);
            const error =
                (e.response && e.response.data && e.response.data.message) ||
                'Error: Something went wrong please contact support!';
            reject(error);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
