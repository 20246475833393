import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { creator, errorMessageGenerator } from 'uwork-app-core';
import axios from 'utils/axios';
import mergeUniqueArray from 'utils/mergeUniqueArray';

/**
 * Constants
 */
export const REQUEST = '@app/departments/members/REQUEST';
export const SUCCESS = '@app/departments/members/SUCCESS';
export const FAILURE = '@app/departments/members/FAILURE';

export const INVITE_MEMBER_REQUEST = '@app/departments/members/INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCESS = '@app/departments/members/INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = '@app/departments/members/INVITE_MEMBER_FAILURE';

export const REMOVE_REQUEST = '@app/departments/members/REMOVE_REQUEST';
export const REMOVE_SUCCESS = '@app/departments/members/REMOVE_SUCCESS';
export const REMOVE_FAILURE = '@app/departments/members/REMOVE_FAILURE';

export const ADD_REQUEST = '@app/departments/members/ADD_REQUEST';
export const ADD_SUCCESS = '@app/departments/members/ADD_SUCCESS';
export const ADD_FAILURE = '@app/departments/members/ADD_FAILURE';

export const CLEAR = '@app/departments/members/CLEAR';

/**
 * Initial state
 */

const initState = {
    loading: false,
    items: []
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.items = payload;
                draft.loading = false;
                break;
            case ADD_SUCCESS:
                draft.loading = false;
                draft.items = state.items.map((item) =>
                    item.user.id === payload.user_id ? { ...item, checked: true } : { ...item }
                );
                break;
            case REMOVE_SUCCESS:
                draft.loading = false;
                draft.items = state.items.map((item) =>
                    item.user.id === payload.user_id ? { ...item, checked: false } : { ...item }
                );
                break;
            case REMOVE_REQUEST:
            case INVITE_MEMBER_REQUEST:
            case ADD_REQUEST:
            case REQUEST:
                draft.loading = true;
                break;
            case INVITE_MEMBER_SUCCESS:
                draft.invited = true;
                break;
            case REMOVE_FAILURE:
            case INVITE_MEMBER_FAILURE:
            case ADD_FAILURE:
            case FAILURE:
                draft.error = payload;
                draft.loading = false;
                break;
            case CLEAR:
                draft.items = [];
                draft.loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),

    invite_request: (data) => creator(INVITE_MEMBER_REQUEST, data),
    invite_success: () => creator(INVITE_MEMBER_SUCCESS),
    invite_failure: (data) => creator(INVITE_MEMBER_FAILURE, data),

    remove_request: (data) => creator(REMOVE_REQUEST, data),
    remove_success: (data) => creator(REMOVE_SUCCESS, data),
    remove_failure: (data) => creator(REMOVE_FAILURE, data),

    add_request: (data) => creator(ADD_REQUEST, data),
    add_success: (data) => creator(ADD_SUCCESS, data),
    add_failure: (data) => creator(ADD_FAILURE, data),

    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */

export const sagas = {
    *request({ payload }) {
        try {
            const result1 = yield axios.get(`/companies/show/${payload.parent_id}`);
            const result2 = yield axios.get(`/companies/users/${payload.department_id}`);

            const parentMembers = result1?.data?.data?.team.filter(
                (item) => item.role === 'MEMBER'
            );
            const departmentMembers = result2?.data?.data;

            // Checking if members are invited, by finding if member is in both arrays
            const checkedArr1 = departmentMembers.map((item) =>
                parentMembers.find((item2) => item2.id === item.user_id)
                    ? { ...item, checked: true }
                    : { ...item, checked: false }
            );
            const checkedArr2 = parentMembers.map((item) =>
                departmentMembers.find((item2) => item2.user_id === item.id)
                    ? { ...item, checked: true }
                    : { ...item, checked: false }
            );
            const uniqueMembers = mergeUniqueArray(checkedArr1, checkedArr2);
            yield put(actions.success(uniqueMembers));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    },
    *invite_request({ payload }) {
        try {
            yield axios.post(`/companies/users`, payload.data);
            yield put(
                actions.request({
                    parent_id: payload.parent_id,
                    department_id: payload.department_id
                })
            );
            yield put(actions.invite_success());
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.invite_failure(error));
        }
    },
    *add_request({ payload }) {
        try {
            yield axios.post(`/companies/users/invite/${payload.department_id}/${payload.user_id}`);
            yield put(actions.add_success({ user_id: payload.user_id }));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.add_failure(error));
        }
    },
    *remove_request({ payload }) {
        try {
            yield axios.delete(`/companies/users/${payload.department_id}/${payload.user_id}`);
            yield put(actions.remove_success({ user_id: payload.user_id }));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.remove_failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(INVITE_MEMBER_REQUEST, sagas.invite_request);
    yield takeLatest(ADD_REQUEST, sagas.add_request);
    yield takeLatest(REMOVE_REQUEST, sagas.remove_request);
    yield takeLatest(REQUEST, sagas.request);
};
