import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import axios from 'utils/axios';
import { creator, errorMessageGenerator } from 'uwork-app-core';

/**
 * Constants
 */
export const REQUEST = '@app/orders/direct-orders/type-violation/check/REQUEST';
export const SUCCESS = '@app/orders/direct-orders/type-violation/check/SUCCESS';
export const FAILURE = '@app/orders/direct-orders/type-violation/check/FAILURE';
export const CLEAR = '@app/orders/direct-orders/type-violation/check/CLEAR';

/**
 * Initial state
 */
const initState = {
    loading: false,
    data: [],
    error: '',
    finished: false
};

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
const reducer = (state = initState, { payload, ...action }) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.data = payload;
                draft.loading = false;
                draft.finished = true;
                break;
            case FAILURE:
                draft.error = payload;
                draft.finished = true;
                break;
            case REQUEST:
                draft.loading = true;
                draft.data = [];
                draft.error = '';
                draft.finished = false;
                break;
            case CLEAR:
                draft.loading = false;
                draft.data = [];
                draft.error = '';
                draft.finished = false;
                break;
            default:
                break;
        }
    });
export default reducer;

/**
 * Redux actions
 */
export const actions = {
    request: (data) => creator(REQUEST, data),
    success: (data) => creator(SUCCESS, data),
    failure: (data) => creator(FAILURE, data),
    clear: () => creator(CLEAR)
};

/**
 * Sagas
 */
export const sagas = {
    *request({ payload }) {
        try {
            const { companyId, workerId, route } = payload;
            const response = yield axios.get(
                `/orders/publish-checker/${route}/${companyId}/${workerId}`
            );
            yield put(actions.success(response.data.data || []));
        } catch (e) {
            console.error(e);
            const error = errorMessageGenerator(e);
            yield put(actions.failure(error));
        }
    }
};

/**
 * Saga watchers
 */
export const watcher = function* watch() {
    yield takeLatest(REQUEST, sagas.request);
};
